/* globals window, confirm */
/* eslint-disable no-fallthrough, no-restricted-globals */
import {seconds} from "@relativity/js-util"
import {call, fork, put, takeEvery, takeLatest, select, cancelled, take, cancel} from 'redux-saga/effects'
import {delay} from "redux-saga"
import Cookies from 'js-cookie'
import axios from 'axios'

import {
  ACTIVITY_SENDING_ATTENDANCE,
  ACTIVITY_WAITING_FOR_LOCATION,
  ACTIVITY_WAITING_TO_SEND,
  AT_TYPE_ID_TARDY,
  AT_TYPE_ID_ABSENT,
  AT_TYPE_ID_REPORT,
  AT_TYPE_ID_OUT,
  DELETE_ALL_CHARS,
  E_KEY_ENTER,
  STATUS_MESSAGE_TYPE_ERROR,
  atr,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER,
  CLIENT_ACTION_SUICIDE,
  rStudentPasscode,
  rStudentPasscodeXmit,
  ACTIVITY_ACCEPTING_ID_CODE,
  E_KEY_ATTENDANCE_POLICE,
  E_KEY_BACKSPACE,
  E_KEY_REPORT,
  E_KEY_IN,
  E_KEY_OUT,
  SECONDS_KIOSK_AUTO_LOCK,
  API_NEXT_ACTION_PRINT_SLIP,
  STATUS_MESSAGE_TYPE_INFO,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS,
  CLIENT_ACTION_GO_TO_INSTALL,
  KIOSK_CAMERA_CLEAR_BOX_PATTERN_DELAY_MS,
  KIOSK_CAMERA_PAUSE_AFTER_ID_CODE_RECOGNITION_MS,
  KIOSK_CAMERA_POLL_PAUSE_AFTER_ID_CODE_RECOGNITION_MS,
  CHECKIN_FLOW_INSTANT,
  CHECKIN_FLOW_STANDARD,
  CHECKIN_FLOW_CHOOSER, URL_API_ROOT,

} from "redux/constants"
import {
  SET_LOCATION,
  setActivity,
} from 'redux/actions'

import {
  BOOT_KIOSK,
  CAMERA_START,
  CAMERA_STOP,
  CAMERA_UPDATE_SETTING,
  CHECKIN_FLOW_POLL_START,
  CHECKIN_FLOW_POLL_STOP,
  DELETE_KIOSK,
  ID_CODE_PASTED_INPUT,
  ID_CODE_SCANNED_INPUT,
  KEYBOARD_INPUT,
  KIOSK_UI_SET_READY_TO_SEND,
  KIOSK_LOGIN_SEND,
  KIOSK_FORGOT_PASSWORD_SEND,
  KIOSK_RESET_PASSWORD,
  KIOSK_CHECK_PASSWORD_TOKEN,
  KIOSK_FACILITIES_CUSTOM_SETUP_SAVE_FROM_ACTIVE_KIOSK,
  LOCK_KIOSK,
  SEND_ATTENDANCE,
  SET_ATTENDANCE_TYPE,
  START_GPS_POLL,
  STOP_GPS_POLL,
  TOGGLE_SETTING,
  UNLOCK_KIOSK,
  UPDATE_SETTING,
  VIEW_ATTENDANCE_POLICY,
  addIdCodeChar,
  bootSetConfiguration,
  bootSetWizardData,
  cameraStart,
  cameraStop,
  isReady,
  loginSetUser,
  loginShowUI,
  loginUIHide,
  removeIdCodeChar,
  setActivityReadyToSend,
  setPrintableSlipContent,
  //showStaticContentDialog,
  startCheckinFlowPoll, CAMERA_TOGGLE_TORCH, cameraTorchOn,
  kioskFacilitiesCustomSetupEditDoneFromActiveKiosk
} from 'redux/kiosk/KioskActions'

import {
  bootPruneAppStateExcept,
  snackMessageError,
  snackMessageSuccess,
  snackMessageInfo,
  snackMessageShow,
  snackMessageHide,
} from 'redux/RootActions'

import {
  del,
  get,
  getStatePath,
  post,
  getLocation,
  uiSnackMessageOnResponse,
  determineFailedLoginMessage,
  showBootMessageIfAny,
  performBootActionIfAny,
} from "redux/utils"

import Quagga from "quagga";
import {cameraSetDeviceList} from "redux/kiosk/KioskActions";
import {SINGLETON_KIOSK_DESIGNATION, SUCCESS_LOGIN_BOOT_ACTION_UNLOCK_KIOSK} from "../../common/kioskConstants";

window.CC = Cookies

const payloadOnly = (fn) => (action) => fn(action.payload)

const kioskSuicide = () => {
  Cookies.remove('kioskId')
  Cookies.remove('kioskSecret')
}

const kioskHacks = {
  forcePrint:false,
  forceAtTypeId:false
}

window.kioskSuicide = kioskSuicide

window.kioskToggleForcePrint  = () => {
  kioskHacks.forcePrint = !kioskHacks.forcePrint
  return kioskHacks.forcePrint
}

window.kioskSetForceAtType = (atTypeId) => {
  kioskHacks.forceAtTypeId = atTypeId
  return kioskHacks.forceAtTypeId
}

window.kioskGetSlipAtTypeIds = function () {
  console.log('These numbers are hard-coded here and might not be accurate: 5, 7')
}

function getState(path) {
  return select(getStatePath, path)
}

const uiDecoration = uiSnackMessageOnResponse(snackMessageShow)
const gpsCoordinates = {lat:false, lon:false}

export function* viewAttendancePolicy() {
  const kiosk = yield getState( 'kiosk')
  if(kiosk.currentActivity !== ACTIVITY_WAITING_TO_SEND || kiosk.idCodeLengthMax < kiosk.idCode.length || kiosk.idCodeLengthMin > kiosk.idCode.length) return

  yield put(setActivity(ACTIVITY_SENDING_ATTENDANCE))
  yield put(snackMessageInfo('Getting your attendance policy'))
  const payload = {idCode: kiosk.idCode}
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kiosk.id}/view_attendance_policy`, payload)

  if(yield uiDecoration(obj)) {
    /*yield put(showStaticContentDialog({
      title: "Attendance Policy",
      content:obj.payload.attendancePolicy
    }))*/
    yield put(removeIdCodeChar(DELETE_ALL_CHARS))
  }
}

export function* sendAttendance(atTypeId=false) {
  const kiosk = yield getState( 'kiosk')
  if(kiosk.currentActivity !== ACTIVITY_WAITING_TO_SEND || kiosk.idCodeLengthMax < kiosk.idCode.length || kiosk.idCodeLengthMin > kiosk.idCode.length) return

  yield put(setActivity(ACTIVITY_SENDING_ATTENDANCE))
  yield put(snackMessageInfo('Sending Attendance'))
  const payload = {idCode: kiosk.idCode}

  if(atTypeId) {
    payload.atTypeId = atTypeId
  }
  if(kiosk.capabilities.enableGps.val && gpsCoordinates.lat !== false) {
    payload.lat = gpsCoordinates.lat
    payload.lon = gpsCoordinates.lon
  }
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kiosk.id}/at_record`, payload)

  if(yield uiDecoration(obj)) {
    if(!!kioskHacks.forcePrint || obj.nextAction === API_NEXT_ACTION_PRINT_SLIP) {
      const templateLib = yield getState(`kiosk.templates.atRecordSlips`)
      const atTypeId = (typeof kioskHacks.forceAtTypeId === 'number')? kioskHacks.forceAtTypeId : obj.payload.atRecord.atTypeId
      switch (atTypeId) {
        case AT_TYPE_ID_TARDY :
        case AT_TYPE_ID_ABSENT :
          const facilityId = obj.payload.printData.period.facilityId && obj.payload.printData.period.facilityId !== 0 
            ? obj.payload.printData.period.facilityId : obj.payload.printData.student.facilityId;
          const template = templateLib[atTypeId][facilityId].template;
          const style = templateLib[atTypeId][facilityId].style;
          try {
            yield delay(250)
            yield put(snackMessageHide())
            yield put(setPrintableSlipContent({html: template(obj.payload.printData), style}))
            window.print()
          } catch(e) {
            console.error('Error when attempting to print.')
            console.error(e)
            yield put(snackMessageError(`Error when printing. Tell the administrators this attendance type number: ${atTypeId}`, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
          }
          break
        default:
          yield put(snackMessageError(`Kiosk instructed to print an unsupported attendance type. Tell the administrators this number: ${atTypeId}`, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
      }
    }
  }
  yield put(removeIdCodeChar(DELETE_ALL_CHARS))
}

function* isValidPasscode(rPasscodeMatcher, passcode) {
  if(rPasscodeMatcher.exec(passcode) === null) {
    yield put(snackMessageError(`'${passcode}' does not look like a valid passcode.`))
    return false
  }
  return true
}

export function* idCodePastedInput(passcode) {
  if(yield isValidPasscode(rStudentPasscode, passcode)) {
    const chars = (passcode + '').split('')
    for(let i = 0; i < chars.length; i++) {
      yield idCodeKeyboardInput(chars[i])
      yield delay(50)
    }
  }
}

const idCodeScannedInputSettings = {
  scanLocked:false,
  uLastRun:0
}

/**
 * Takes a bar-code scanned set of chars (including carriage return and and the special 'send' character and broadcasts them for consumption by the reducer (and hopefully successful transmission of a checkin)
 * @param payload
 * @returns {IterableIterator<IterableIterator<SelectEffect|*|IterableIterator<SelectEffect|PutEffect<*>|PutEffect<*>|*>|IterableIterator<SelectEffect|*|PutEffect<*>|Promise<any>|IterableIterator<PutEffect<*>|PutEffect<*>|*>>>|IterableIterator<SelectEffect|*|IterableIterator<SelectEffect|PutEffect<*>|PutEffect<*>|*>|IterableIterator<SelectEffect|*|PutEffect<*>>>|*>}
 */
export function* idCodeScannedInput(payload) {
  if(idCodeScannedInputSettings.scanLocked) return
  idCodeScannedInputSettings.scanLocked = true
  const uNow = Date.now()
  if(uNow - idCodeScannedInputSettings.uLastRun < 1000) {
    yield delay(uNow - idCodeScannedInputSettings.uLastRun)
  }
  idCodeScannedInputSettings.uLastRun = Date.now()
  if(yield isValidPasscode(rStudentPasscodeXmit, payload)) {
    const chars = (payload + '').split('')
    for(let i = 0; i < chars.length; i++) {
      if(chars[i] === '\n') {
        yield idCodeCharInput(E_KEY_ENTER) //<-- TOTAL HACK. Sorry about that.
      } else {
        yield idCodeCharInput(chars[i])
      }
    }
  }
  idCodeScannedInputSettings.scanLocked = false
}

export function* kioskBootNew() {
  const obj = yield axios.get(`${URL_API_ROOT}/kiosk/boot`)
  yield showBootMessageIfAny() 
  yield put(loginSetUser(obj.data.user))      
  yield put(setActivity(ACTIVITY_WAITING_FOR_LOCATION))
  yield put(bootSetWizardData(obj.data))
  yield put(isReady())
  return obj
}

export function* kioskBootExisting(kioskId) {
  //Note, kiosk needs both kioskId and kioskSecret stored in cookie
  const obj = yield get(`${URL_API_ROOT}/kiosk/${kioskId}/boot`)
  if(obj.success) {
    if(obj.payload.enableGps) {
      if (!("geolocation" in navigator)) {
        yield put(snackMessageError("This kiosk requires GPS but the browser does not support it.", DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
      } else {
        yield fork(function*(){
          try {
            yield getLocation()
          } catch(e) {
            yield put(snackMessageError("This kiosk requires GPS be enabled. Attendance tracking may not function.", DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
          }
        })
      }
    }
    console.log('kioskBootExisting obj.payload', obj.payload)
    yield put(bootSetConfiguration(obj.payload))
    yield put(isReady())
    yield put(cameraStart())
    yield put(startCheckinFlowPoll())
  } else {
    const nextAction = obj.nextAction? obj.nextAction : obj.data.nextAction //really, we shouldn't need to do this. Legacy api has it off .data
    if(nextAction === CLIENT_ACTION_SUICIDE || nextAction === CLIENT_ACTION_GO_TO_INSTALL) {
      kioskSuicide()
      window.document.location.reload()
    }
    yield put(isReady())
    yield put(snackMessageError('There was an error connecting to the server. Please reload the page', DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  }

  yield performBootActionIfAny(bootActionDictionary)
}

const bootActionDictionary = {
  [SUCCESS_LOGIN_BOOT_ACTION_UNLOCK_KIOSK]: kioskUnlock 
}

export function* cameraUpdateSetting(payload) {
  const kioskId = Cookies.get('kioskId')
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kioskId}/toggle_setting`, {
    settingName: `cameraConfiguration.${payload.propertyName}`,
    val: payload.val
  })

  if(obj.success) {
    yield put(bootSetConfiguration(obj.payload))
  } else {
    yield put(snackMessageError(obj.userMessage))
  }
}

export function* kioskToggleSetting( settingName ) {
  const kioskId = Cookies.get('kioskId')
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kioskId}/toggle_setting`, {
    settingName
  })
  if(obj.success) {
    yield put(bootSetConfiguration(obj.payload))
    if(yield getState('kiosk.capabilities.enableCamera.val')) {
      yield put(cameraStart())
    } else {
      yield put(cameraStop())
    }
  } else {
    yield put(snackMessageError(obj.userMessage))
    if(obj.nextAction === 'showLogin') {
      yield put(loginShowUI({}))
    }
  }
}

export function* kioskUnlock() {  
  const kioskId = Cookies.get('kioskId')
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kioskId}/toggle_setting`, {
    settingName:atr.isLocked,
    val:false
  })  
  if(obj.success) {
    yield put(bootSetConfiguration(obj.payload))
  } else {
    if(obj.nextAction === 'showLogin') {
      yield put(loginShowUI({}))
    }
  }
}

export function* kioskLock() {
  const kioskId = Cookies.get('kioskId')
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kioskId}/toggle_setting`, {
    settingName:atr.isLocked,
    val:true
  })

  if(obj.success) {
    yield put(bootSetConfiguration(obj.payload))
    yield* logoutSend()
  } else {
    yield put(snackMessageError(obj.userMessage))
    if(obj.nextAction === 'showLogin') {
      yield put(loginShowUI({}))
    }
  }
}

export function* kioskSetLocation() {
  const {locationChooser } = yield getState('kiosk')
  const locationId = locationChooser.location.id
  const description = locationChooser.description
  const customAttendanceConfiguration = locationChooser.customAttendanceConfiguration;
  let expires = new Date();
  let maxChromeCookieLifetime = 400;
  expires.setDate(expires.getDate() + maxChromeCookieLifetime);
  Cookies.set('kioskSecret', Math.random(), {expires: expires});
  const obj = yield axios.post(`${URL_API_ROOT}/kiosk`, {locationId, description, customAttendanceConfiguration})
  if(yield uiDecoration(obj)) {
    Cookies.set('kioskId', obj.payload.id, {expires: expires})
    //yield* logoutSend()
    window.document.location.reload()
  } else {
    Cookies.remove('kioskSecret')
    console.log('Error', obj)
  }
}

export function* kioskSetAttendanceType(val) {
  const kioskId = Cookies.get('kioskId')
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kioskId}/toggle_setting`, {
    settingName: atr.atTypeId,
    val
  })
  if(obj.success) {
    yield put(bootSetConfiguration(obj.payload))
  } else {
    yield put(snackMessageError(obj.userMessage))
  }
}

export function* updateSetting(payload) {
  const kioskId = Cookies.get('kioskId')
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kioskId}/toggle_setting`, {
    settingName: payload.propertyName,
    val:payload.value
  })
  if(obj.success) {
    yield put(bootSetConfiguration(obj.payload))
  } else {
    yield put(snackMessageError(obj.userMessage))
  }
}

export function* kioskDelete() {
  const kioskId = Cookies.get('kioskId')
  const response = yield del(`${URL_API_ROOT}/kiosk/${kioskId}`)
  if(yield uiDecoration(response)) {
    kioskSuicide()
  } else {
    if(confirm('There was an error deleting on the server. Delete locally anyway?')) {
      kioskSuicide()
    }
  }
  window.document.location.reload()
}

export function* getSystemCameraDeviceList() {
  let systemCameraDeviceList
  try {
    systemCameraDeviceList = yield Quagga.CameraAccess.enumerateVideoDevices()
  } catch(e) {
    console.error(e)
    return []
  }
  return systemCameraDeviceList
}

export function* kioskBoot({payload}) {
  const kioskId = payload === SINGLETON_KIOSK_DESIGNATION? SINGLETON_KIOSK_DESIGNATION : Cookies.get('kioskId')

  yield put(bootPruneAppStateExcept('kiosk'))
  if(kioskId) {
    const systemCameraDeviceList = yield getSystemCameraDeviceList()
    const cameraDeviceList = []
    for(let device of systemCameraDeviceList) {
      cameraDeviceList.push({
        val:(device.deviceId || device.id),
        displayName:device.label.substring(0,30)
      })
    }
    yield put(cameraSetDeviceList(cameraDeviceList))
    yield* kioskBootExisting(kioskId)
  } else {
    const obj = yield kioskBootNew()
    if(yield uiDecoration(obj, false)) {
      const loggedInUser = yield getState('kiosk.loggedInUser')
      if(!loggedInUser) {
        yield put(loginShowUI({}))
      }
    }
  }
  yield fork(autoLogout)
}


export function* uiSetReadyToSend(payload=false) {
  const kiosk = (payload)? payload : yield getState('kiosk')
  if(kiosk.idCode.length < kiosk.idCodeLengthMin) {
    yield put(snackMessageError('That\'s too short to be an id'))
    return
  } else if(kiosk.idCode.length > kiosk.idCodeLengthMax) {
    yield put(snackMessageError('That\'s too long to be an id'))
    return
  }
  yield put(setActivityReadyToSend())
}

export function* idCodeKeyboardInput (char) {
  const kiosk = yield getState('kiosk')
  if(!kiosk.enabled.keyboardCodeInput || kiosk.menuDrawerOpen || !kiosk.isLocked) {
    return
  }
  yield idCodeCharInput(char)
}

export function* idCodeCharInput(char) {
  const kiosk = yield getState('kiosk')
  if(kiosk.currentActivity === ACTIVITY_ACCEPTING_ID_CODE) {
    switch (char) {
      case E_KEY_BACKSPACE :
        yield put(removeIdCodeChar(false))
        return
      case E_KEY_ENTER :
        yield uiSetReadyToSend(kiosk)
        return
      default :
        yield put(addIdCodeChar(char))
        return
    }
  }
  if(kiosk.currentActivity === ACTIVITY_WAITING_TO_SEND) {
    switch (char) {
      case E_KEY_BACKSPACE :
       yield put(removeIdCodeChar())
        return
      case E_KEY_ENTER :
      case E_KEY_IN :
        yield sendAttendance()
        return
      case E_KEY_REPORT :
        yield sendAttendance(AT_TYPE_ID_REPORT)
        return
      case E_KEY_OUT :
        yield sendAttendance(AT_TYPE_ID_OUT)
        return
      case E_KEY_ATTENDANCE_POLICE:
        yield viewAttendancePolicy()
        return
      default :
    }
  }
}

export function* forgotPasswordSend({email}) {
  if(!email || email.indexOf('@') < 0) {
    yield put(snackMessageError('Please enter a valid email address.'))
    return
  }
  const obj = yield post(
    `${URL_API_ROOT}/user_account/password/forgot`,
  {email}
  )
  yield uiDecoration(obj)
}

export function* loginSend ({email, password}) {
  const obj = yield post(
    `${URL_API_ROOT}/user_account/login`,
    {email,password}
  )
  if(!obj.success) {
    yield put(snackMessageShow(determineFailedLoginMessage(obj) , STATUS_MESSAGE_TYPE_ERROR, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  } else {
    yield put(loginUIHide())
    yield put(loginSetUser(obj.payload))
    const id = yield getState('kiosk.id')
    if(id !== 'New') {
      yield* kioskUnlock()
    }
  }
}

export function* logoutSend() {
  const obj = yield post(`${URL_API_ROOT}/user_account/logout`, {})
  if(!obj.success) {
    yield put(snackMessageShow('There was an error logging you out. Please try again' , STATUS_MESSAGE_TYPE_ERROR, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  } else {
    if(obj.nextAction === 'reload') {
      window.location.reload()
    } else {
      snackMessageInfo('You may have been auto-logged out. Please reload the page.')
    }
  }
}

export function* checkPasswordToken(token) {
  const obj = yield get(`${URL_API_ROOT}/user_account/password/reset?token=${token}`)
  yield uiDecoration(obj)
}

export function* resetPassword({password, token}) {
  const obj = yield post(`${URL_API_ROOT}/user_account/password/reset`, {
    token,
    password
  })

  if(obj.success) {
    yield put(snackMessageSuccess(obj.userMessage, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  } else {
    yield put(snackMessageError(obj.userMessage, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  }
}

export function* autoLogout(props) {
  while(true) {
    const loggedInUser = yield getState('kiosk.loggedInUser')
    const now = seconds()
    if(loggedInUser && (loggedInUser.loggedInSeconds + SECONDS_KIOSK_AUTO_LOCK) < now) {
      yield* logoutSend()
    }
    yield call(delay, 1000)
  }
}

export function* comingSoon(message) {
  const theMessage = (typeof message === 'string')? message : 'Feature coming soon'
  yield put(snackMessageShow(theMessage, STATUS_MESSAGE_TYPE_INFO, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS))
}

export function* bgPoll(pollingFn, delayMS=5000) {
  try {
    while (true) {
      yield call(pollingFn)
      yield delay(delayMS)
    }
  } finally {
    yield cancelled()
  }
}

const cameraBuffer = {}
const clearCameraBuffer = (pollRunning=false) => {
  cameraBuffer.pollRunning= pollRunning
  cameraBuffer.newDetection = false
  cameraBuffer.idCode = ''
  cameraBuffer.uLastCodeDetection = 0
}

let quaggaStarted = false
/**
 * We've moved this out of cameraPollStart because the only way to get settings into Quagga is to stop it and restart it.
 * Stopping & restarting the entire poll every time would trigger UI that we don't want, and conflict with our other patterns.
 * @returns {IterableIterator<SelectEffect>}
 */
export function* cameraRestartQuagga() {
  console.log('quaggaStarted', quaggaStarted)
  try{
    if(quaggaStarted){
      Quagga.stop()
    }
  } catch (e) {
    console.warn('Caught camera error', e)
  }

  const cameraConfiguration = yield getState('kiosk.cameraConfiguration')
  const inputStreamResolution = cameraConfiguration.inputStreamResolution.split('x')
  const constraints = {
    facingMode: "environment",
    aspectRatio: {min: 1, max: 2},
    width: {min: parseInt(inputStreamResolution[0])},
    height: {min: parseInt(inputStreamResolution[1])},
  }
  if(cameraConfiguration.cameraDeviceName.length > 0) {
    constraints.deviceId = cameraConfiguration.cameraDeviceName
  }
  const quaggaState = {
    inputStream: {
      type: "LiveStream",
      constraints,
      target: document.querySelector('#scanner-container')
    },
    locator: {
      patchSize: cameraConfiguration.locatorPatchSize,
      halfSample: !!cameraConfiguration.halfSample
    },
    numOfWorkers: cameraConfiguration.numWorkers,
    frequency: 10,
    decoder: {
      readers: [{
        format: cameraConfiguration.barcodeType,
        config: {}
      }]
    },
    locate: true
  }

  Quagga.init(quaggaState, (err) => {
    if (err) {
      return console.log('Init error', err)
    }
    Quagga.start()
    quaggaStarted = true
  });


  const clearScreen = () => {
    clearTimeout(clearScreenTimeout)
    const drawingCtx = Quagga.canvas.ctx.overlay,
      drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
  }

  let clearScreenTimeout;
  let pauseOnProcessedUntil = 0;

  //this thing needs to broadcast an action to handle this
  Quagga.onDetected((result) => {
    if(pauseOnProcessedUntil > Date.now()) return
    clearScreen()

    const drawingCtx = Quagga.canvas.ctx.overlay

    cameraBuffer.idCode = result.codeResult.code
    cameraBuffer.newDetection = true

    if (result.box) {
      Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "blue", lineWidth: 5});
      const {box} = result
      drawingCtx.fillStyle = 'lightgreen'
      drawingCtx.moveTo(box[0][0], box[0][1])
      drawingCtx.lineTo(box[1][0], box[1][1])
      drawingCtx.lineTo(box[2][0], box[2][1])
      drawingCtx.fill()
    }

    /*if (result.codeResult && result.codeResult.code) { //left here in-case there is a desire at some point for a horizontal line going through the middle of the detected code.
      Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'green', lineWidth: 3});
    } */

    pauseOnProcessedUntil = Date.now() + KIOSK_CAMERA_PAUSE_AFTER_ID_CODE_RECOGNITION_MS
    setTimeout(clearScreen, KIOSK_CAMERA_PAUSE_AFTER_ID_CODE_RECOGNITION_MS)
  });


  Quagga.onProcessed(function(result) {
    if(pauseOnProcessedUntil > Date.now()) return
    const drawingCtx = Quagga.canvas.ctx.overlay //,
     // drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes && !result.box) {
        clearTimeout(clearScreenTimeout)
        clearScreen();
        result.boxes.filter(function (box) {
          return box !== result.box;
        }).forEach(function (box, i) {
          Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "blue", lineWidth: 2})
        });
        clearScreenTimeout = setTimeout(clearScreen, KIOSK_CAMERA_CLEAR_BOX_PATTERN_DELAY_MS)
      }
    }
  });
}

export function* cameraPoll() {
  if(!cameraBuffer.newDetection) return
  cameraBuffer.newDetection = false
  const idCode = yield getState( 'kiosk.idCode')
  const uNow = Date.now()
  const uTempuLastDetection = cameraBuffer.uLastCodeDetection
  cameraBuffer.uLastCodeDetection = uNow

  if(idCode.length > 0) {
    const msSinceLastDetection = uNow - uTempuLastDetection
    if(msSinceLastDetection < KIOSK_CAMERA_POLL_PAUSE_AFTER_ID_CODE_RECOGNITION_MS) { //if < this number of milliseconds has passed && current idCode is same as cameraBuffer.idCode then bail
      const testCode = cameraBuffer.idCode.slice(-1) === E_KEY_IN? cameraBuffer.idCode.slice(0, -1) : cameraBuffer.idCode
      if(testCode === idCode) {
        yield put(snackMessageInfo(`Please wait ${msSinceLastDetection/1000} seconds`, msSinceLastDetection))
        return
      }
    }
    yield put(removeIdCodeChar(DELETE_ALL_CHARS))
  }
  yield idCodeScannedInput(cameraBuffer.idCode)
}

export function* cameraPollStart() {
  const cameraEnabled = yield getState( 'kiosk.capabilities.enableCamera.val')
  if(cameraEnabled) {
    if(!cameraBuffer.pollRunning) {
      clearCameraBuffer(true)
      yield cameraRestartQuagga()
      //console.log('cameraPollStart.start')
      const bgPollTask = yield fork(bgPoll, cameraPoll, 500)
      //console.log('cameraPollStart.start2')
      yield take(CAMERA_STOP)
      yield cancel(bgPollTask)
      Quagga.stop()
      yield put(snackMessageInfo("Camera input disabled. Led indicators may take 30 seconds to turn off", 15000))
      cameraBuffer.pollRunning = false
    } else {
      //console.log('cameraPollStart.restarting')
      yield cameraRestartQuagga()
    }
  }
}

export function* gpsPoll() {
  const gpsEnabled = yield getState( 'kiosk.capabilities.enableGps.val')
  if(gpsEnabled) {
    try {
      const location = yield getLocation()
      gpsCoordinates.lat = location.coords.latitude
      gpsCoordinates.lon = location.coords.longitude
    } catch(e) {
      console.warn('Encountered error when attempting to get gps coordinates', e)
    }
  }
}

export function* startGpsPoll() {
  const bgPollTask = yield fork(bgPoll, gpsPoll, 1000)
  yield take(STOP_GPS_POLL)
  yield cancel(bgPollTask)
}


export function* checkinFlowPoll() {
  const kiosk = yield getState('kiosk')
  if(kiosk.idCode.length >= kiosk.idCodeLengthMin && kiosk.currentActivity === ACTIVITY_ACCEPTING_ID_CODE) {
    if(kiosk.checkinFlow !== CHECKIN_FLOW_STANDARD && (Date.now() - kiosk.checkinFlowDelayMS >= kiosk.uIdCodeLastModified)) {
      if(kiosk.checkinFlow === CHECKIN_FLOW_CHOOSER) {
        yield uiSetReadyToSend(kiosk)
      }if(kiosk.checkinFlow === CHECKIN_FLOW_INSTANT) {
        yield uiSetReadyToSend(kiosk)
        yield sendAttendance()
      }
    }
  }
}

export function* checkinFlowPollStart() {
  const bgPollTask = yield fork(bgPoll, checkinFlowPoll, 100)
  yield take(CHECKIN_FLOW_POLL_STOP)
  yield cancel(bgPollTask)
}

export function* cameraToggleTorch() {
  const kiosk = yield getState('kiosk')
  try {
    const track = Quagga.CameraAccess.getActiveTrack()
    yield track.applyConstraints({advanced: [{torch: !kiosk.cameraTorchOn}]})
    yield put(cameraTorchOn(!kiosk.cameraTorchOn))
  } catch(e) {
    yield put(snackMessageError("Flash did not respond"))
  }
}

export function* kioskFacilitiesCustomSetupSaveFromActiveKiosk(facilitiesOverrides) {
  const kioskId = Cookies.get('kioskId');
  const obj = yield post(`${URL_API_ROOT}/kiosk/${kioskId}/toggle_setting`, {
    settingName: 'customAttendanceConfiguration',
    val: facilitiesOverrides.filter(override => override.overrideTypeId > 0)
  });
  if(obj.success) {
    yield put(bootSetConfiguration(obj.payload));
    yield put(kioskFacilitiesCustomSetupEditDoneFromActiveKiosk());
  } else {
    yield put(snackMessageError(obj.userMessage));
  }
}

export const kioskWatches = [
  takeEvery(SEND_ATTENDANCE, payloadOnly(sendAttendance)),
  takeEvery(VIEW_ATTENDANCE_POLICY, payloadOnly(viewAttendancePolicy)),
  takeEvery(BOOT_KIOSK, kioskBoot),
  takeEvery(SET_LOCATION, kioskSetLocation),
  takeEvery(SET_ATTENDANCE_TYPE, payloadOnly(kioskSetAttendanceType)),
  takeEvery(UPDATE_SETTING, payloadOnly(updateSetting)),
  takeEvery(KEYBOARD_INPUT, payloadOnly(idCodeKeyboardInput)),
  takeEvery(ID_CODE_SCANNED_INPUT, payloadOnly(idCodeScannedInput)),
  takeEvery(DELETE_KIOSK, kioskDelete),
  takeEvery(TOGGLE_SETTING, payloadOnly(kioskToggleSetting)),
  takeEvery(UNLOCK_KIOSK, kioskUnlock),
  takeEvery(LOCK_KIOSK, kioskLock),
  takeEvery(ID_CODE_PASTED_INPUT, payloadOnly(idCodePastedInput)),
  takeLatest(KIOSK_UI_SET_READY_TO_SEND, payloadOnly(uiSetReadyToSend)),
  takeEvery(KIOSK_LOGIN_SEND, payloadOnly(loginSend)),
  takeEvery(KIOSK_FORGOT_PASSWORD_SEND, payloadOnly(forgotPasswordSend)),
  takeEvery(KIOSK_RESET_PASSWORD, payloadOnly(resetPassword)),
  takeEvery(KIOSK_CHECK_PASSWORD_TOKEN, payloadOnly(checkPasswordToken)),
  takeEvery(START_GPS_POLL, payloadOnly(startGpsPoll)),
  takeEvery(CHECKIN_FLOW_POLL_START, payloadOnly(checkinFlowPollStart)),
  takeEvery(CAMERA_START, payloadOnly(cameraPollStart)),
  takeEvery(CAMERA_UPDATE_SETTING, payloadOnly(cameraUpdateSetting)),
  takeEvery(CAMERA_TOGGLE_TORCH, payloadOnly(cameraToggleTorch)),
  takeEvery(KIOSK_FACILITIES_CUSTOM_SETUP_SAVE_FROM_ACTIVE_KIOSK, payloadOnly(kioskFacilitiesCustomSetupSaveFromActiveKiosk)),
]