import React from 'react';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FancySelect from 'common/components/FancySelect'
import KioskFacilitiesCustomSetupDialog from "apps/kiosk/components/KioskFacilitiesCustomSetupDialog";
import ListItem from "@material-ui/core/ListItem";

import {
  closeMenuDrawer,
  lockConfiguration,
  unlockConfiguration,
  kioskUpdateLocationChooserFacility,
  updateLocationChooserLocation, 
  setLocationFromChooser,
  setActivityPreviousActivity,
} from "../../../redux/actions";

import { kioskWizardUpdateProperty, 
  kioskFacilitiesCustomSetupEdit } from 'redux/kiosk/KioskActions'

import {
  ACTIVITY_WAITING_FOR_LOCATION,
  ID_ZERO,
  OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES,
  OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE,
  CUSTOMIZE_FACILITIES_TITLE,
} from "../../../redux/constants";

import {
  getAtTypesByFacilityId,
  getAtReasonsByFacilityId,
  getFacilityOverrideSelectedValues
} from 'redux/utils'


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  facilityLabel: {
    fontWeight: '500',
    marginRight:12
  },
  overrideLabel: {
    fontWeight: '400', 
    color: 'gray',
    fontSize: 12
  }

});

class ChooseLocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth:100
    };
  };

  handleClose() {

  }

  handleFacilitySetupChange = (facilityId) => ({ propertyName, val }) => {
    const payload = { facilityId, propertyName, val };
    this.props.kioskWizardUpdateProperty(payload);
  };

  render() {
    const {
      buildings,
      classes,
      currentActivity,
      kioskWizardUpdateProperty,
      loggedInUser,
      setLocationFromChooser,
      locationChooser,
      facilities,
      positiveAttendanceIsEnabled
    } = this.props

    const {building, description, location, locations, customAttendanceConfiguration} = locationChooser
    const open = currentActivity === ACTIVITY_WAITING_FOR_LOCATION
    const canCreate = (location.id > 0 && building.id > 0)
    const accessibleBuildings = buildings.reduce((acc, item) => {
      if(loggedInUser.securityAccess.buildingIds.includes(item.id) || loggedInUser.securityAccess.buildingIds.includes(0)) {
        acc.push(item)
      } else if((!building || building.id === ID_ZERO) && item.id === ID_ZERO) {
        acc.push(item)
      }
      return acc
    }, [])

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"

      >
        <DialogTitle id="form-dialog-title">Create a new Kiosk</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Where is this Kiosk being run?
          </DialogContentText>
          <Grid container>
            <Grid item xs={12}>
              <FancySelect
                dispatchUpdate={kioskWizardUpdateProperty}
                label='Building'
                propertyName='buildingId'
                currentItem={building}
                items={accessibleBuildings}
                />
            </Grid>
            <Grid item xs={12}>
              <FancySelect
                dispatchUpdate={kioskWizardUpdateProperty}
                label='Location'
                propertyName='locationId'
                currentItem={location}
                items={locations}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined"
                label="Description"
                fullWidth
                className={classes.textField}
                onChange={(e) => kioskWizardUpdateProperty({propertyName:'description', val: e.target.value})}
                margin="normal"
                variant="outlined"
                value={description}
                helperText="What makes this kiosk special?"
              />
            </Grid>
          </Grid>
          <Grid style={{marginTop:'20px'}}>
            {customAttendanceConfiguration && customAttendanceConfiguration.facilitiesOverrides &&
              customAttendanceConfiguration.facilitiesOverrides.map(facilityOverride => {
                const defaultAtType = 
                {id: 0, facilityId: 0, isKioskMode: false, name: 'NOTSET', displayName: 'not set'};

                const allAtTypes = facilities.filter(fac => fac.id > 0).flatMap(facility => facility.atTypes).concat(defaultAtType);
                const allAtReasons = this.props.atReasons;
      
                 const atTypesByFacilityId = getAtTypesByFacilityId(allAtTypes, facilities);
                 const atReasonsByFacilityId = getAtReasonsByFacilityId(allAtReasons, facilities);

                const {facility, overrideTypeName, singleAttendanceItem, tardyItem, absentItem, atReasonItem} = getFacilityOverrideSelectedValues(
                  facilityOverride, allAtTypes, allAtReasons, facilities);
                return (
                  <ListItem key={facilityOverride.facilityId} style={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px', paddingLeft:0, paddingRight:0 }}>
                    <div>
                      <span className={classes.facilityLabel}>{facility.name}</span>
                      <span className={classes.overrideLabel}>{overrideTypeName}</span>
                    </div>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE && (
                          <FancySelect
                            dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                            label='Single Attendance Type'
                            propertyName='atTypeSingleId'
                            currentItem={singleAttendanceItem}
                            items={atTypesByFacilityId[facilityOverride.facilityId]}
                            valAtr="id"
                            nameAttr='displayName'
                          />
                        )}
                        {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES && (
                          <>
                            <Grid container >
                              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                                <FancySelect
                                  dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                                  label='Tardy Type'
                                  propertyName='atTypeTardyId'
                                  currentItem={tardyItem}
                                  items={atTypesByFacilityId[facilityOverride.facilityId]}
                                  valAtr="id"
                                  nameAttr='displayName'
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FancySelect
                                  dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                                  label='Absent Type'
                                  propertyName='atTypeAbsentId'
                                  currentItem={absentItem}
                                  items={atTypesByFacilityId[facilityOverride.facilityId]}
                                  valAtr="id"
                                  nameAttr='displayName'
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <FancySelect
                        dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                        label='Reason'
                        propertyName='atReasonId'
                        currentItem={atReasonItem}
                        items={atReasonsByFacilityId[facilityOverride.facilityId]}
                        valAtr="id"
                        nameAttr='displayName'
                      />
                    </Grid>
                  </ListItem>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions style={{justifyContent:'space-between'}} title={canCreate? 'Click "Create!" to create the kiosk' : 'Please finish choosing a location'}>
          <Grid>
          { positiveAttendanceIsEnabled &&
            <div style={{ display: 'flex'}}>
              <Button onClick={() => this.props.kioskFacilitiesCustomSetupEdit()} color="primary">{CUSTOMIZE_FACILITIES_TITLE}</Button>
              <KioskFacilitiesCustomSetupDialog />
            </div>
          }
          </Grid>
          <Button
            disabled={!canCreate}
            onClick={() => setLocationFromChooser()} color="primary">
            Create!
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => state.kiosk

const mapDispatchToProps = {
  closeMenuDrawer,
  lockConfiguration,
  unlockConfiguration,
  updateLocationChooserLocation,
  kioskUpdateLocationChooserFacility,
  kioskWizardUpdateProperty,
  setLocationFromChooser,
  setActivityPreviousActivity,
  kioskFacilitiesCustomSetupEdit
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ChooseLocation));