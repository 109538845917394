import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import {
  workerJobViewLogsDone,
  workerJobViewLogsToggleExpandDetail
} from 'redux/admin/Actions'

import {
  ID_ZERO,
  SYNC_RUN_STATUS_RUNNING,
  syncRunStatusDisplayNameByName
} from 'redux/constants'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import styles from './style'
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/es/Typography";
import {seconds} from "@relativity/js-util";
import moment from "moment-timezone";
import {jsPretty} from "redux/utils";

const pad2 = (num) => {
  return (num + '').padStart(2, '0')
}

const runElapsed = (worker) => {
  const totalSeconds = (worker.runStatus !== SYNC_RUN_STATUS_RUNNING)? worker.uRunEnd - worker.uRunStart : seconds() - worker.uLastRunStart
  return `${Math.floor(totalSeconds / 60) + ':' + pad2(totalSeconds % 60)}`
}

class WorkerJobDialog extends React.Component {

  render() {
    if(!this.props.workerJob) return null

    const {
      classes,
      workerJob,
      workerJobViewLogsDone,
      workerJobViewLogsToggleExpandDetail
       } = this.props

    const logs = workerJob.logs

    return (
      <Dialog
        onClose={workerJobViewLogsDone}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={workerJobViewLogsDone}>
          {workerJob.displayName} - Details
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          {(workerJob.id > ID_ZERO)?
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell style={{width:'10px'}}>Start</TableCell>
                  <TableCell>Time / Status</TableCell>
                  <TableCell>Summary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.length === 0? <TableRow><TableCell colSpan={4}><Typography variant="h6">No Logs. </Typography></TableCell></TableRow> : null}
                {logs.reduce((acc, log) => {
                  acc.push(<TableRow key={log.id} onClick={()=>workerJobViewLogsToggleExpandDetail(log.id)}>
                    <TableCell style={{whiteSpace:'nowrap'}} className={classes.logViewerCell}>{moment.unix(log.uRunStart).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell className={classes.logViewerCell}>{runElapsed(log)} / {syncRunStatusDisplayNameByName[log.runStatus]}</TableCell>
                    <TableCell className={classes.logViewerCell}>{log.runStatusSummary}</TableCell>
                  </TableRow>)
                  if(log.expand )
                  acc.push(<TableRow key={`${log.id}_detail`}>
                      <TableCell style={{whiteSpace: 'pre', overflowX: "scroll", maxWidth: "500px"}}
                                 colspan={5}>{jsPretty(log.jsbRunStatusDetail)}</TableCell>
                  </TableRow>)
                  return acc;
                }, [])

                }
              </TableBody>
            </Table>
          :
          null
          }
        </DialogContent>
        <DialogActions style={{marginTop:10}}>
          <Button onClick={()=>workerJobViewLogsDone()} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => (state.admin.dialogs.workerJobLogs)?  {workerJob:state.admin.dialogs.workerJobLogs} :  {}


const mapDispatchToProps = {
  workerJobViewLogsDone,
  workerJobViewLogsToggleExpandDetail
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(WorkerJobDialog))