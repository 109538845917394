import React from "react";
import IconButton from "@material-ui/core/IconButton";

export class ToggleButton extends React.PureComponent {
  render() {
    const { onClick, style={}, title='', iconMap, iconKey} = this.props
    const Icon = iconMap[iconKey.toString()]
    return (
      <IconButton
        onClick={onClick}
        style={style}
        title={title}
      >
        <Icon/>
      </IconButton>
    )
  }
}
