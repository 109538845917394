import {
  AT_TYPE_ID_ABSENT,
  AT_TYPE_ID_ABSENT_EXCUSED,
  AT_TYPE_ID_OUT,
  AT_TYPE_ID_OUT_OF_BUILDING,
  AT_TYPE_ID_PRESENT,
  AT_TYPE_ID_REPORT,
  AT_TYPE_ID_TARDY,
  AT_TYPE_ID_TARDY_EXCUSED, AT_TYPE_ID_UNKNOWN,
  AT_TYPE_ID_SKYWARD_CUSTOM,
  AT_TYPE_ID_ON_SITE,
  COLOR_ABSENT,
  COLOR_ABSENT_BORDER,
  COLOR_ABSENT_EX,
  COLOR_ABSENT_EX_BORDER,
  COLOR_OUT,
  COLOR_OUT_BORDER, COLOR_OUT_OF_BUILDING, COLOR_OUT_OF_BUILDING_BORDER,
  COLOR_PRESENT,
  COLOR_PRESENT_BORDER,
  COLOR_REPORT,
  COLOR_REPORT_BORDER,
  COLOR_TARDY,
  COLOR_TARDY_BORDER,
  COLOR_TARDY_EX,
  COLOR_TARDY_EX_BORDER,
  COLOR_UNKNOWN,
  COLOR_UNKNOWN_BORDER,
  COLOR_SKYWARD_CUSTOM,
  COLOR_SKYWARD_CUSTOM_BORDER,
  COLOR_ON_SITE,
  GENERIC_AT_TYPES_IN_DATABASE_COUNT
} from "redux/constants";

const atRecordDotType = 'at_record_dot_type_',
  schedulePeriodWeekView = 'schedule_period_week_view_'

const classRootStructure = {
  [`${atRecordDotType}${AT_TYPE_ID_ABSENT_EXCUSED}`]: {
    background: COLOR_ABSENT_EX,
    borderColor: COLOR_ABSENT_EX_BORDER
  },
  [`${atRecordDotType}${AT_TYPE_ID_ABSENT}`]: {
    background: COLOR_ABSENT,
    borderColor: COLOR_ABSENT_BORDER + ' !important',
    borderRadius: 0,
    color: '#FFFFFF',
  },
  [`${atRecordDotType}${AT_TYPE_ID_TARDY_EXCUSED}`]: {
    background: COLOR_TARDY_EX,
    borderColor: COLOR_TARDY_EX_BORDER
  },
  [`${atRecordDotType}${AT_TYPE_ID_TARDY}`]: {
    background: COLOR_TARDY,
    borderColor: COLOR_TARDY_BORDER + ' !important',
    color: '#FFFFFF',
    borderRadius: 0
  },
  [`${atRecordDotType}${AT_TYPE_ID_OUT}`]: {
    background: COLOR_OUT,
    borderColor: COLOR_OUT_BORDER,
    borderRadius: 0
  },
  [`${atRecordDotType}${AT_TYPE_ID_OUT_OF_BUILDING}`]: {
    background: COLOR_OUT_OF_BUILDING,
    borderColor: COLOR_OUT_OF_BUILDING_BORDER,
    borderRadius: 0
  },
  [`${atRecordDotType}${AT_TYPE_ID_REPORT}`]: {
    background: COLOR_REPORT,
    borderColor: COLOR_REPORT_BORDER,
    borderRadius: 0
  },
  [`${atRecordDotType}${AT_TYPE_ID_UNKNOWN}`]: {
    background: COLOR_UNKNOWN,
    borderColor: COLOR_UNKNOWN_BORDER + ' !important',
    borderRadius: 0,
    color: '#FFFFFF'
  },
  [`${atRecordDotType}${AT_TYPE_ID_PRESENT}`]: {
    background: COLOR_PRESENT,
    borderColor: COLOR_PRESENT_BORDER,
    borderRadius: 0
  },
  [`${atRecordDotType}${AT_TYPE_ID_ON_SITE}`]: {
    background: COLOR_ON_SITE,
    borderColor: COLOR_ON_SITE,
    borderRadius: 0
  },
  [`${atRecordDotType}${AT_TYPE_ID_ABSENT_EXCUSED}`]: {
    background: COLOR_ABSENT_EX,
    borderColor: COLOR_ABSENT_EX_BORDER
  },
  [`${atRecordDotType}${AT_TYPE_ID_ABSENT}`]: {
    background: COLOR_ABSENT,
    borderColor: COLOR_ABSENT_BORDER + ' !important',
    borderRadius: 0,
    color: '#FFFFFF',

  },
  [`${atRecordDotType}${AT_TYPE_ID_SKYWARD_CUSTOM}`]: {
    background: COLOR_SKYWARD_CUSTOM,
    borderColor: COLOR_SKYWARD_CUSTOM_BORDER,
    borderRadius: 0,
    color: COLOR_SKYWARD_CUSTOM_BORDER,
  },

  auspRoot: {
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: 0,
    position: 'absolute',
    zIndex: 1000
  },
  auspName: {
    display: 'inline-block',
    margin: 5,
    position: 'relative',
    zIndex: 1001
  },
  [`${schedulePeriodWeekView}default`]: {
  },
  [`${schedulePeriodWeekView}locked`]: {
    background: 'red',
    border: '1px solid',
    borderColor:'orange',
    opacity: 0.2
  },
  [`${schedulePeriodWeekView}empty`]: {
    background: '#f2f2f2',
    border: '1px solid',
    borderColor:'#ededed',
    opacity: 1
  },
  [`${schedulePeriodWeekView}on_site`]: {
    background: '#CFF5D4',
    border: '1px solid',
    borderColor:'#CFF5D4',
    opacity: 1
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_ABSENT_EXCUSED}`]: {
    background: COLOR_ABSENT_EX,
    borderColor: COLOR_ABSENT_EX,
    borderRadius: 0,
    opacity: 0.7,
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_ABSENT}`]: {
    background: COLOR_ABSENT,
    borderColor: COLOR_ABSENT,
    color: '#FFFFFF',
    opacity: 0.5,
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_TARDY_EXCUSED}`]: {
    background: COLOR_TARDY_EX,
    borderColor: COLOR_TARDY_EX,
    borderRadius: 0,
    opacity: 0.7,
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_TARDY}`]: {
    background: COLOR_TARDY,
    borderColor: COLOR_TARDY,
    borderRadius: 0,
    opacity: 0.5,
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_UNKNOWN}`]: {
    background: COLOR_UNKNOWN,
    borderColor: COLOR_UNKNOWN,
    borderRadius: 0,
    color: '#FFFFFF',
    opacity: 0.7,
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_PRESENT}`]: {
    background: COLOR_PRESENT,
    borderColor: COLOR_PRESENT,
    borderRadius: 0,
    opacity: 0.5,
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_OUT_OF_BUILDING}`]: {
    background: COLOR_OUT_OF_BUILDING,
    borderColor: COLOR_OUT_OF_BUILDING_BORDER,
    borderRadius: 0,
    opacity: 0.5,
  },
  [`${schedulePeriodWeekView}${AT_TYPE_ID_SKYWARD_CUSTOM}`]: {
    background: COLOR_SKYWARD_CUSTOM,
    borderColor: COLOR_SKYWARD_CUSTOM_BORDER,
    borderRadius: 0,
    opacity: 0.5,
  },
}

const styles = theme => {
  const classes = {...classRootStructure}
  for(let cssClassName in classes) if(cssClassName.startsWith(atRecordDotType)) {
    classes[cssClassName] = Object.assign({},
      {
        width: 12,
        height: 12,
        cursor: 'pointer',
        padding: 0,
        borderRadius: 10,
        fontSize: '12px',
        lineHeight: '10px',
        textAlign: 'center',
        textTransform: 'lowercase',
        zIndex: 1010
      },
      classes[cssClassName]
    )
  }
  for(let cssClassName in classes) if(cssClassName.startsWith(schedulePeriodWeekView)) {
    classes[cssClassName] = Object.assign({},
      {
        cursor: 'pointer',
        background: '#d9d9d9',
        border: '1px solid',
        borderColor:'#E9E9E9',
        borderRadius: 0,
        width: '100%', height: '100%',
        opacity: 1,
        position: 'absolute',
      },
      classes[cssClassName]
    )
  }
  return classes
}

export const makeAtTypeClassName = (atTypeId) =>{
  const adjustedAtTypeId = getAdjustedAtTypeId(atTypeId);
  return `${atRecordDotType}${adjustedAtTypeId}`
}

export const makeAuspClassName = (ausp, atRecord=false) =>{
  if(ausp.isLocked) {
    return `${schedulePeriodWeekView}locked`
  }
  if(atRecord && atRecord.isReportingAtRecord) {
    const adjustedAtTypeId = getAdjustedAtTypeId(atRecord.atTypeId);
    const className = `${schedulePeriodWeekView}${adjustedAtTypeId}`
    if(classRootStructure[className]) return className
  }
  if(atRecord && !atRecord.isReportingAtRecord && atRecord.atTypeId === AT_TYPE_ID_ON_SITE){
    return `${schedulePeriodWeekView}on_site`;
  }

  return ausp.isEmptyPeriod ? `${schedulePeriodWeekView}empty` : `${schedulePeriodWeekView}default`
}

const getAdjustedAtTypeId = (atTypeId) => {
  return atTypeId > GENERIC_AT_TYPES_IN_DATABASE_COUNT ? AT_TYPE_ID_SKYWARD_CUSTOM : atTypeId;
};

export default styles