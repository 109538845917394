import React from 'react'
import {connect} from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import styles from 'common/components/style'

import {sendSkywardApiInfo, settingsSetTempProperty, returnToSetKioskDefaultConfiguration} from 'redux/install/Actions'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FullTextField from "common/components/FullTextField";
import {INSTALL_STEP_SET_SKYWARD_API_INFO} from "redux/install/constants";

const skywardApiUrl = 'skywardApiUrl';
const skywardClientId = 'skywardClientId';
const skywardClientSecret = 'skywardClientSecret';

class SkywardConnectionDialogClass extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.state = {
      controlsDisabled:true,
      fieldsEnabled:false,
      password:'',
      email:'',
      skywardApiUrl:'',
      skywardClientId:'',
      skywardClientSecret:'',
    }
  }

  confirmThisStep(fn) {
    this.props.settingsSetTempProperty({ propertyName: skywardApiUrl, val: this.state.skywardApiUrl });
    this.props.settingsSetTempProperty({ propertyName: skywardClientId, val: this.state.skywardClientId });
    this.props.settingsSetTempProperty({ propertyName: skywardClientSecret, val: this.state.skywardClientSecret });
    this.setState({
      controlsDisabled:true,
      skywardClientSecret: ''
    })
    fn()
  }

  addHooks() {
    window.stHooks.addHook(skywardApiUrl, (e)=>this.props.settingsSetTempProperty({propertyName: skywardApiUrl, val:e}))
    window.stHooks.addHook(skywardClientId, (e)=>this.props.settingsSetTempProperty({propertyName: skywardClientId, val:e}))
    window.stHooks.addHook(skywardClientSecret, (e)=>this.props.settingsSetTempProperty({propertyName: skywardClientSecret, val:e}))
  }
  removeHooks() {
    window.stHooks.removeHook([skywardApiUrl, skywardClientId, skywardClientSecret])
  }

  componentDidMount() {
    const { settings } = this.props;
    const { skywardApiUrl, skywardClientId } = settings;

    this.setState({
      skywardApiUrl: skywardApiUrl || '',
      skywardClientId: skywardClientId || '',
      skywardClientSecret: '',
      controlsDisabled: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.skywardApiUrl !== this.state.skywardApiUrl ||
      prevState.skywardClientId !== this.state.skywardClientId ||
      prevState.skywardClientSecret !== this.state.skywardClientSecret
    ) {
      this.validateFields();
    }
  }

  validateFields() {
    const { skywardApiUrl, skywardClientId, skywardClientSecret } = this.state;
    const allFieldsFilled = skywardApiUrl && skywardClientId && skywardClientSecret;
    this.setState({ controlsDisabled: !allFieldsFilled });
  }

  handleFieldChange = (field, value) => {
    this.setState(
      {
        [field]: value,
      },
      () => {
        this.props.settingsSetTempProperty({ propertyName: field, val: value });
      }
    );
  };

  render() {
    const {classes, close, sendSkywardApiInfo, browserCurrentStep, returnToSetKioskDefaultConfiguration} = this.props
    const onClose = (close)? close : ()=>{console.log('This should be an easter egg, but it\'s not')}
    if(browserCurrentStep !== INSTALL_STEP_SET_SKYWARD_API_INFO) {
      this.removeHooks()
      return null
    } else {
      this.addHooks()
    }

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title">
          Skyward Integration - (Step 4)
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container className={classes.firstRow}>
            <Grid item className={classes.gridItem} xs={12}>
              <FullTextField
                id="settings-skywardAPI"
                label="URL for Skyward API"
                propertyName='skywardApiUrl'
                value={this.state.skywardApiUrl}
                 onChange={(e) => this.handleFieldChange(skywardApiUrl, e.target.value)}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item className={classes.gridItem} xs={12} sm={6}>
              <FullTextField
                id="settings-skywardKey"
                label="Key for Skyward API"
                propertyName='skywardClientId'
                value={this.state.skywardClientId}
                onChange={(e) => this.handleFieldChange(skywardClientId, e.target.value)}
                margin="normal"/>
            </Grid>
            <Grid item className={classes.gridItem} xs={12} sm={6}>
              <FullTextField
                id="settings-skywardSecret"
                type=""
                label="Secret for Skyward API"
                propertyName='skywardClientSecret'
                value={this.state.skywardClientSecret}
                onChange={(e) => this.handleFieldChange(skywardClientSecret, e.target.value)}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{justifyContent:"space-between"}}>
          <Button onClick={returnToSetKioskDefaultConfiguration} color="secondary">
            Back
          </Button>
          <Button disabled={this.state.controlsDisabled}  onClick={()=>this.confirmThisStep(sendSkywardApiInfo)} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const SkywardConnectionDialog = connect(
  state => state.install,
  {
    settingsSetTempProperty,
    sendSkywardApiInfo,
    returnToSetKioskDefaultConfiguration
  }

)(withStyles(styles, { withTheme: true })(SkywardConnectionDialogClass))

export default SkywardConnectionDialog
