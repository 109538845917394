import AtRecord from './AtRecord'

const Student = (student) => {
  const x = Object.assign({}, student)
  if(x.hasOwnProperty('atRecords')) {
    x.atRecords = x.atRecords.map(atRecord => AtRecord(atRecord))
  } else {
    x.atRecords = []
  }
  return x
}

export default Student