import React, { useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import styles from 'common/components/style';
import DialogTitle from 'common/components/dialog/DialogTitle';
import DialogContent from 'common/components/dialog/DialogContent';
import DialogActions from 'common/components/dialog/DialogActions';
import { useStateWithDep } from '../../../../hooks';

// ###   some types of props given below:
// ###
// ###   type TListItem = {
// ###     name: string,
// ###     checked: boolean,
// ###   }
// ###   type TSelectionModal = {
// ###     title?: string | JSX.Element,
// ###     description?: string | JSX.Element,
// ###     list: TListItem,
// ###     setList: (list: TListItem[]) => void,
// ###     getItem: (itemProps: TListItem, checkboxHandler: (opt: TListItem) => void) => JSX.Element,
// ###     isSearchHidden?: true,
// ###     isModalVisible: boolean,
// ###     setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
// ###     searchPlaceholder: string,
// ###   }

const SelectionModal = React.memo((props) => {
  const {
    title,
    classes,
    description,
    list,
    setList,
    getItem,
    isSearchHidden,
    isModalVisible,
    setIsModalVisible,
    searchPlaceholder,
  } = props;

  const [search, setSearch] = useState('');
  const [copiedList, setCopiedList] = useStateWithDep(list);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const inputHandler = (value) => {
    setSearch(value);
    setList(value);
  }

  const checkboxHandler = (opt) => {
    const preparedList = copiedList.map((item) => {
      if (item.name === opt.name) return {
        name: opt.name,
        checked: !item.checked,
      };
      return item;
    })
    setCopiedList(preparedList);
  }

  // TODO search logic will be added later

  return (
    <Dialog
      onClose={() => setIsModalVisible(false)}
      aria-labelledby="course-selection-dialog-title"
      open={isModalVisible}
      PaperProps={{
        style: { minWidth: '600px' },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => setIsModalVisible(false)}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContentRoot}>
        {description && <div>{description}</div>}
        {isSearchHidden && <TextField
          style={{ marginTop: '10px' }}
          placeholder={searchPlaceholder}
          variant="outlined"
          fullWidth
          onChange={(e) => inputHandler(e.target.value)}
          value={search}
        />}
        <List>{copiedList.map((item) => {
          return getItem(item, checkboxHandler);
        })}</List>
      </DialogContent>
      <DialogActions style={{ marginTop: 10 }}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => setList(copiedList)} color="primary">
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const mapStateToProps = state => state.admin

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectionModal));
