import {action} from "@relativity/js-util";


export const IS_READY = 'INSTALL_IS_READY'
export const isReady = () => {
  return action(IS_READY)
}

export const LOGIN_SHOW_UI = 'INSTALL_LOGIN_SHOW_UI'
export const loginShowUI = (payload) => {
  return action(LOGIN_SHOW_UI, payload)
}

export const LOGIN_SEND = 'INSTALL_LOGIN_SEND'
export const loginSend = (payload) => {
  return action(LOGIN_SEND, payload)
}
export const LICENSE_SEND = 'INSTALL_LICENSE_SEND'
export const licenseSend = (payload) => {
  return action(LICENSE_SEND, payload)
}

export const BOOT_APP = 'INSTALL_BOOT_APP'
export const bootApp = () => {
  return action(BOOT_APP)
}

export const STATE_UPDATE = 'INSTALL_STATE_UPDATE'
export const stateUpdate = (payload) => {
  return action(STATE_UPDATE, payload)
}

export const LOGIN_SET_USER = 'INSTALL_LOGIN_SET_USER'
export const loginSetUser = (payload) => {
  return action(LOGIN_SET_USER, payload)
}

export const SETTINGS_UPDATE_PROPERTY = 'INSTALL_SETTINGS_UPDATE_PROPERTY'
export const settingsUpdateProperty = (payload) => {
  return action(SETTINGS_UPDATE_PROPERTY, payload)
}

export const SETTINGS_SET_TEMP_PROPERTY = 'INSTALL_SETTINGS_SET_TEMP_PROPERTY'
export const settingsSetTempProperty = (payload) => {
  return action(SETTINGS_SET_TEMP_PROPERTY, payload)
}

export const SET_KDC_PROPERTY = 'SET_KDC_PROPERTY'
export const setKdcProperty = (propertyName, val) => {
  return action(SET_KDC_PROPERTY, {propertyName, val})
}

export const SEND_ADMIN_PASSWORD = 'INSTALL_SEND_ADMIN_PASSWORD'
export const sendAdminPassword = (payload) => {
  return action(SEND_ADMIN_PASSWORD, payload)
}

export const SEND_SKYWARD_API_INFO = 'INSTALL_SEND_SKYWARD_API_INFO'
export const sendSkywardApiInfo = (payload) => {
  return action(SEND_SKYWARD_API_INFO, payload)
}

export const SEND_KIOSK_DEFAULT_CONFIGURATION = 'INSTALL_SEND_KIOSK_DEFAULT_CONFIGURATION'
export const sendKioskDefaultConfiguration = (payload) => {
  return action(SEND_KIOSK_DEFAULT_CONFIGURATION, payload)
}

export const SEND_DISTRICT_ID = 'INSTALL_SEND_DISTRICT_ID'
export const sendDistrictId = (payload) => {
  return action(SEND_DISTRICT_ID, payload)
}

export const GO_TO_STEP = 'INSTALL_GO_TO_STEP'
export const goToStep = (payload) => {
  return action(GO_TO_STEP, payload)
}

export const SET_ENTITY_IS_ENABLED = 'INSTALL_SET_ENTITY_IS_ENABLED'
export const setEntityIsEnabled = (payload) => {
  return action(SET_ENTITY_IS_ENABLED, payload)
}

export const SET_BUILDING_IS_ENABLED = 'INSTALL_SET_BUILDING_IS_ENABLED'
export const setBuildingIsEnabled = (payload) => {
  return action(SET_BUILDING_IS_ENABLED, payload)
}

export const SEND_BUILDING = 'INSTALL_SEND_BUILDING'
export const sendBuilding = (payload) => {
  return action(SEND_BUILDING, payload)
}

export const SEND_ENTITY_SCHOOL_YEAR = 'INSTALL_SEND_ENTITY_SCHOOL_YEAR'
export const sendEntitySchoolYear = (payload) => {
  return action(SEND_ENTITY_SCHOOL_YEAR, payload)
}

export const SEND_INSTALL_COMPLETE = 'INSTALL_SEND_INSTALL_COMPLETE'
export const sendInstallComplete = (payload) => {
  return action(SEND_INSTALL_COMPLETE, payload)
}

export const START_INSTALL_STATE_POLLING = 'INSTALL_START_INSTALL_STATE_POLLING'
export const startInsallStatePolling = () => {
  return action(START_INSTALL_STATE_POLLING)
}

export const STOP_INSTALL_STATE_POLLING = 'INSTALL_STOP_INSTALL_STATE_POLLING'
export const stopInsallStatePolling = (payload) => {
  return action(STOP_INSTALL_STATE_POLLING, payload)
}

export const RETURN_TO_DISTRICT_SELECTION = 'RETURN_TO_DISTRICT_SELECTION'
export const returnToDistrictSelection = (payload) => {
  return action(RETURN_TO_DISTRICT_SELECTION, payload)
}

export const RETURN_TO_SET_SKYWARD_API_INFO = 'RETURN_TO_SET_SKYWARD_API_INFO'
export const returnToSetSkywardApiInfo = (payload) => {
  return action(RETURN_TO_SET_SKYWARD_API_INFO, payload)
}

export const RETURN_TO_SET_KIOSK_DEFAULT_CONFIGURATION = 'RETURN_TO_SET_KIOSK_DEFAULT_CONFIGURATION'
export const returnToSetKioskDefaultConfiguration = (payload) => {
  return action(RETURN_TO_SET_KIOSK_DEFAULT_CONFIGURATION, payload)
}

export const DISABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION = 'DISABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION'
export const disableSaveKioskDefaultConfiguration = (payload) => {
  return action(DISABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION, payload)
}

export const ENABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION = 'ENABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION'
export const enableSaveKioskDefaultConfiguration = (payload) => {
  return action(ENABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION, payload)
}

export const RETURN_TO_SET_PASSWORD = 'RETURN_TO_SET_PASSWORD'
export const returnToSetPassword = (payload) => {
  return action(RETURN_TO_SET_PASSWORD, payload)
}

export const RETURN_TO_SET_LICENSING = 'RETURN_TO_SET_LICENSING'
export const returnToSetLicensing = (payload) => {
  return action(RETURN_TO_SET_LICENSING, payload)
}

export const RETURN_TO_SET_ENTITIES = 'RETURN_TO_SET_ENTITIES'
export const returnToSetEntities = (payload) => {
  return action(RETURN_TO_SET_ENTITIES, payload)
}

export const RETURN_TO_SET_BUILDINGS = 'RETURN_TO_SET_BUILDINGS'
export const returnToSetBuildings = (payload) => {
  return action(RETURN_TO_SET_BUILDINGS, payload)
}

export default {}