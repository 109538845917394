  import React from 'react'
import connect from 'react-redux/es/connect/connect'
import { findOneBy } from '@relativity/js-util'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import FancySelect from 'common/components/FancySelect'
import ListItem from "@material-ui/core/ListItem";
import KioskFacilitiesCustomSetupDialog from "apps/admin/components/KioskFacilitiesCustomSetupDialog";

import KioskSettingListItem from 'common/components/KioskSettingListItem'
import {
  computeKioskCapabilities,
  computePPKKey, 
  getAtTypesByFacilityId,
  getAtReasonsByFacilityId,
  getFacilityOverrideSelectedValues
} from 'redux/utils'

import styles from './style'
import {
  deleteKiosk,
  ppkEditPropSet,
  ppkEditSave,
  ppkEditClose,
  kioskFacilitiesCustomSetupPPKEdit,
  kioskFacilitiesCustomSetupUpdateEditorProperty
} from 'redux/admin/Actions'

import {createPPKSettings} from 'common/kioskSettings'

import {
  ATR_ATTENDANCE_TYPE_ID,
  ATR_IS_ENABLED, ATR_IS_OUT_OF_BUILDING,
  ATR_REQUEST_SEND_REPORT,
  ATR_ENABLE_EMAIL_SLIP
} from 'common/kioskConstants'

import {
  KDCL_INSTANCE,
  KIOSK_TYPE_PPK,
  OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES,
  OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE,
  CUSTOMIZE_FACILITIES_TITLE
} from 'redux/constants'
import TableBody from '@material-ui/core/es/TableBody/TableBody'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FullTextField, {FullTextFieldD} from "common/components/FullTextField";
import Grid from "@material-ui/core/Grid";

/**
 * Currys a function to the current configurationId, locationId, and the dispatcher (defaultConfiguration, or kiosk specific configuration)
 * @param configurationId
 * @param locationId
 * @param changeDispatcher
 * @returns {Function}
 */
const contextualizeChangeSetting = (configurationId, dispatcher) => {
  return (propName, val) => {
    dispatcher({
      id: configurationId,
      propName,
      val
    })
  }
}

const contextualizeChangeStructuredPayload = (configurationId, dispatcher) => {
  return (payload) => {
    payload.id = configurationId
    dispatcher(payload)
  }
}

class KioskSettingsDialog extends React.Component {
  handleFacilitySetupChange = (facilityId) => ({ propertyName, val }) => {
    const payload = { facilityId, propertyName, val,  kioskType: KIOSK_TYPE_PPK };
    this.props.kioskFacilitiesCustomSetupUpdateEditorProperty(payload);
  };

  render() {
    if(!this.props.ppkEditor) return null

    const {
      classes,
      deleteKiosk,
      ppkEditor: {
        atTypes,
        kioskDefaultConfiguration,
        kiosk,
        editorTitle,
        editorDescription
      },
      ppkEditClose,
      ppkEditPropSet,
      ppkEditSave,
      level,
      positiveAttendanceIsEnabled
    } = this.props

    let description, onEdit, onEditFullTextField, onSave, ppkGroupNum, ppkDeviceNum

    if (level === KDCL_INSTANCE) {
      onEdit = contextualizeChangeSetting(kiosk.id, ppkEditPropSet)
      onEditFullTextField = contextualizeChangeStructuredPayload(kiosk.id, ppkEditPropSet)
      onSave = contextualizeChangeSetting(kiosk.id, ppkEditSave)
    } else {
      console.error(`ppk level should be ${KDCL_INSTANCE} but is instead ${level}`)
      return null
    }

    const capabilities = computeKioskCapabilities(kioskDefaultConfiguration, kiosk, true)

    const settingInit = [
      {
        name:ATR_REQUEST_SEND_REPORT,
        isOn: capabilities[ATR_REQUEST_SEND_REPORT].val,
        clickOn: () => onEdit(ATR_REQUEST_SEND_REPORT, !capabilities[ATR_REQUEST_SEND_REPORT].val),
      },
      {
        name:ATR_ENABLE_EMAIL_SLIP,
        isOn: capabilities[ATR_ENABLE_EMAIL_SLIP].val,
        clickOn: () => onEdit(ATR_ENABLE_EMAIL_SLIP, !capabilities[ATR_ENABLE_EMAIL_SLIP].val),
      },
      {
        name:ATR_ATTENDANCE_TYPE_ID,
        val:capabilities[ATR_ATTENDANCE_TYPE_ID].val,
        possibleValues:atTypes,
        clickOn: (val) => onEdit(ATR_ATTENDANCE_TYPE_ID, val),
        textProvider: findOneBy(atTypes, 'id', capabilities[ATR_ATTENDANCE_TYPE_ID].val)
      },
      {
        name: ATR_IS_OUT_OF_BUILDING,
        isOn: capabilities[ATR_IS_OUT_OF_BUILDING].val,
        changeable: capabilities[ATR_IS_OUT_OF_BUILDING].changeable,
        clickOn:() => onEdit(ATR_IS_OUT_OF_BUILDING, !capabilities[ATR_IS_OUT_OF_BUILDING].val),
      }, /*
      {
        name:ATR_MAX_LEASE_LENGTH,
        val: kioskDefaultConfiguration[ATR_MAX_LEASE_LENGTH],
        possibleValues: secondsIn,
        clickOn: (val) => onEdit(ATR_MAX_LEASE_LENGTH, val),
      }, */
    ]

    if(kiosk) {
      ppkGroupNum = kiosk.ppkGroupNum
      ppkDeviceNum = kiosk.ppkDeviceNum
      description = kiosk.description
      settingInit.unshift({
        name:ATR_IS_ENABLED,
        isOn: capabilities[ATR_IS_ENABLED].val,
        clickOn:() => onEdit(ATR_IS_ENABLED, !capabilities.isEnabled.val),
      })
    } else {
      settingInit.push()
    }

    const settingConfiguration = createPPKSettings(settingInit)
    return (
      <Dialog
        onClose={ppkEditClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={ppkEditClose}>
          {editorTitle}
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Typography>{editorDescription}</Typography>
          <Grid container>
            <Grid item xs={4}>
              {(kiosk.id === -1)?
                <FullTextField
                  label={'GroupID'}
                  value={ppkGroupNum}
                  dispatchUpdate={onEditFullTextField}
                  propertyName={`ppkGroupNum`}
                  type='number'
                /> :
                <FullTextFieldD
                  label={'GroupID'}
                  value={ppkGroupNum}
                />
              }
            </Grid>
            <Grid item xs={4}>
              {(kiosk.id === -1)?
                <FullTextField
                  label={'DeviceID'}
                  value={ppkDeviceNum}
                  dispatchUpdate={onEditFullTextField}
                  propertyName={`ppkDeviceNum`}
                  type='number'
                />:
                <FullTextFieldD
                  label={'DeviceID'}
                  value={ppkDeviceNum}
                />
              }
            </Grid>
            <Grid item xs={4}>
              <FullTextFieldD
                label={'Key'}
                value={computePPKKey(ppkGroupNum, ppkDeviceNum)}
              />
            </Grid>
            <Grid item xs={12}>
              <FullTextField
                label={'Description'}
                value={description}
                dispatchUpdate={onEditFullTextField}
                propertyName={`description`}
              />
            </Grid>
          </Grid>

          <Table style={{width:'100%'}}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">{(!!kiosk)? 'Enabled' : 'Enabled by Default'}</TableCell>
                <TableCell component="th"></TableCell>
                <TableCell padding="checkbox" align="right" style={{width:'20%'}}>Changeable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { settingConfiguration.map((item, index) => {
                return <KioskSettingListItem key={index} {...item} adminContext />
              })
              }
              <TableRow>
                <TableCell style={{borderBottom:'none'}} padding="checkbox" onClick={() => {
                  if(window.confirm('Delete this kiosk forever? The PPK Group & Device combination cannot be re-used.')) {
                    deleteKiosk(kiosk.id)
                  }}}><IconButton><DeleteForeverIcon/></IconButton></TableCell>
                <TableCell style={{borderBottom:'none'}}>Delete Kiosk</TableCell>
                <TableCell style={{borderBottom:'none'}} padding="checkbox" ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
         
          <Grid>
            {kiosk.customAttendanceConfiguration && kiosk.customAttendanceConfiguration.facilitiesOverrides &&
              kiosk.customAttendanceConfiguration.facilitiesOverrides.map(facilityOverride => {    
                const atTypesByFacilityId = getAtTypesByFacilityId(this.props.allAtTypes, this.props.facilities);
                const atReasonsByFacilityId = getAtReasonsByFacilityId(this.props.allAtReasons, this.props.facilities);

                const {facility, overrideTypeName, singleAttendanceItem, tardyItem, absentItem, atReasonItem} = getFacilityOverrideSelectedValues(
                  facilityOverride, this.props.allAtTypes, this.props.allAtReasons, this.props.facilities);
                return (
                  <ListItem key={facilityOverride.facilityId} style={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
                    <div>
                      <span className={classes.facilityLabel}>{facility.name}</span>
                      <span className={classes.overrideLabel}>{overrideTypeName}</span>
                    </div>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE && (
                          <FancySelect
                            dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                            label='Single Attendance Type'
                            propertyName='atTypeSingleId'
                            currentItem={singleAttendanceItem}
                            items={atTypesByFacilityId[facilityOverride.facilityId]}
                            valAtr="id"
                            nameAttr='displayName'
                          />
                        )}
                        {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES && (
                          <>
                            <Grid container >
                              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                                <FancySelect
                                  dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                                  label='Tardy Type'
                                  propertyName='atTypeTardyId'
                                  currentItem={tardyItem}
                                  items={atTypesByFacilityId[facilityOverride.facilityId]}
                                  valAtr="id"
                                  nameAttr='displayName'
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FancySelect
                                  dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                                  label='Absent Type'
                                  propertyName='atTypeAbsentId'
                                  currentItem={absentItem}
                                  items={atTypesByFacilityId[facilityOverride.facilityId]}
                                  valAtr="id"
                                  nameAttr='displayName'
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <FancySelect
                        dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                        label='Reason'
                        propertyName='atReasonId'
                        currentItem={atReasonItem}
                        items={atReasonsByFacilityId[facilityOverride.facilityId]}
                        valAtr="id"
                        nameAttr='displayName'
                      />
                    </Grid>
                  </ListItem>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
         { positiveAttendanceIsEnabled &&
          <Grid>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={() => this.props.kioskFacilitiesCustomSetupPPKEdit()} color="primary">{CUSTOMIZE_FACILITIES_TITLE}</Button>
              <KioskFacilitiesCustomSetupDialog />
            </div>
          </Grid>
          }
          <div style={{flexGrow:8}}/>
          <Button onClick={ppkEditClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  const ppkSettingsState = state.admin.dialogs;
  ppkSettingsState.locationDetailPage = state.admin.locationDetailPage;
  ppkSettingsState.facilities = state.admin.facilities;
  ppkSettingsState.currentFacilityId = state.admin.currentFacilityId;
  ppkSettingsState.currentItemId = state.admin.currentItemId;
  ppkSettingsState.currentPage = state.admin.currentPage;
  ppkSettingsState.allAtTypes = state.admin.atTypes;
  ppkSettingsState.allAtReasons = state.admin.atReasons;
  ppkSettingsState.positiveAttendanceIsEnabled = state.admin.positiveAttendanceIsEnabled;
  return ppkSettingsState;
}

const mapDispatchToProps = {
  ppkEditClose,
  ppkEditPropSet,
  ppkEditSave,
  deleteKiosk,
  kioskFacilitiesCustomSetupPPKEdit,
  kioskFacilitiesCustomSetupUpdateEditorProperty
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(KioskSettingsDialog))