import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import moment from 'moment';
import styles from 'common/components/style';

import DialogTitle from 'common/components/dialog/DialogTitle';
import DialogContent from 'common/components/dialog/DialogContent';
import DialogActions from 'common/components/dialog/DialogActions';

import ToggleIconButton from "common/components/ToggleIconButton";

import {
  semesterRuleEditDone,
  attendanceTermSetIsCountResetEnabled,
  semesterRuleEditSave
} from 'redux/admin/Actions';

const formatUnixTimestamp = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YYYY');
};

class AttendanceTermsSelectionDialog extends React.Component {
  handleSave = () => {
    if (this.props.semesterRuleEditSave) {
      this.props.semesterRuleEditSave();
    }
  };

  handleItemToggle = (attendanceTerm) => {
     const { attendanceTermSetIsCountResetEnabled } = this.props;
     attendanceTermSetIsCountResetEnabled({ id: attendanceTerm.id, isAttendanceCountResetEnabled: !attendanceTerm.isAttendanceCountResetEnabled });
  }

  render() {
    if (!this.props.semesterRuleEditor) return null

    const { classes, semesterRuleEditor, semesterRuleEditDone } = this.props;

    const attendanceTermsList = semesterRuleEditor.attendanceTerms.reduce((acc, attendanceTerm) => {
      acc.push(
        <ListItem title={`id: ${attendanceTerm.id}`} key={attendanceTerm.id}>
          <ToggleIconButton
            isOn={attendanceTerm.isAttendanceCountResetEnabled}
            onClick={() => this.handleItemToggle(attendanceTerm)}
          />
          {attendanceTerm.attendanceTermCode}  
          <span style={{fontSize:14, marginLeft:15}}>
            {formatUnixTimestamp(attendanceTerm.uStart)} - {formatUnixTimestamp(attendanceTerm.uEnd)}
          </span>
        </ListItem>
      );
      return acc;
    }, []);

    return (
      <Dialog
        onClose={semesterRuleEditDone}
        aria-labelledby="location-selection-dialog-title"
        open={true}
        PaperProps={{
          style: { minWidth: '600px' }
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={semesterRuleEditDone}>
          Choose attendance terms
        </DialogTitle>

        <DialogContent className={classes.dialogContentRoot}>
          <Typography style={{ marginTop: '10px' }}>Choose which terms will reset tardy and absent counts when the term starts</Typography>
          <List>{attendanceTermsList}</List>
        </DialogContent>

        <DialogActions style={{ marginTop: 10 }}>
          <Button onClick={semesterRuleEditDone} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  semesterRuleEditDone,
  attendanceTermSetIsCountResetEnabled,
  semesterRuleEditSave
};

const mapStateToProps = state => {
  const semesterRuleEditor = state.admin.dialogs.semesterRuleEditor;
  const currentFacilityId = state.admin.currentFacilityId;
  return { semesterRuleEditor, currentFacilityId };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AttendanceTermsSelectionDialog));

