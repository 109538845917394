import React from "react";

import {
  COLOR_ABSENT,
  COLOR_CLASS_PERIOD,
  COLOR_PRESENT,
  COLOR_TARDY,
} from "redux/constants";

class BellRuleSVG extends React.Component {
  render() {
    //10 pixels of height == 1 minute
    const { bellPeriodPreStartPresentSeconds, bellPeriodPostStartTardySeconds, bellPeriodPostStartAbsentSeconds, positiveAttendanceIsEnabled=false, style={}, periodWidth=40, subPeriodWidth=200, subPeriodSpacing=5} = this.props
    const periodHeight  = 600
    const viewBoxHeight = 700

    const presentY = 100 - Math.round(bellPeriodPreStartPresentSeconds / 6)
    const presentHeight = Math.round((bellPeriodPreStartPresentSeconds + bellPeriodPostStartTardySeconds) / 6)
    const tardyY = 100 + Math.round(bellPeriodPostStartTardySeconds / 6)
    const tardyHeight =  Math.round((bellPeriodPostStartAbsentSeconds - bellPeriodPostStartTardySeconds) / 6)
    const absentY = tardyY + tardyHeight
    const absentHeight = viewBoxHeight - absentY - 1

    return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 300 ${viewBoxHeight}`}>
      <g>
        <title>Class Period</title>
        <rect rx="10" id="svg_1" height={`${periodHeight}`} width={periodWidth} y={viewBoxHeight - periodHeight} x={subPeriodWidth + 40} strokeWidth="1.5" fill={COLOR_CLASS_PERIOD}/>
      </g>
      {positiveAttendanceIsEnabled?
        <g>
          <title>Present</title>
          <rect stroke="null" rx="10" id="svg_2" height={presentHeight - subPeriodSpacing} width={subPeriodWidth} y={presentY} x="18" strokeWidth="1.5" fill={COLOR_PRESENT}/>
        </g>
        :
        null
      }
      <g>
        <title>Tardy</title>
        <rect stroke="null" rx="10" id="svg_3" height={tardyHeight - subPeriodSpacing} width={subPeriodWidth} y={tardyY} x="18" strokeOpacity="null" strokeWidth="1.5" fill={COLOR_TARDY}/>
      </g>
      <g>
        <title>Absent</title>
        <rect rx="10" stroke="null" id="svg_4" height={absentHeight} width={subPeriodWidth} y={absentY} x="18" strokeOpacity="null" strokeWidth="1.5" fill={COLOR_ABSENT}/>
      </g>
    </svg>
  }
}

export default BellRuleSVG