import React from 'react';
import { withStyles, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

// ### ---/ Some types of CustomSelect component /---
// ### type TSelectedItem = {
// ###   id: number,
// ###   value: string,
// ### }
// ###
// ### type TCustomSelect = {
// ###   labelName?: string,  
// ###   list: TSelectedItem[],
// ###   getItem: (id, value) => JsxElement,
// ###   selectedItem: TSelectedItem,
// ###   onSelect: (item: TSelectedItem) => void,
// ###   renderValue?: true,
// ###   fullWidth?: true,
// ###   disabled?: boolean,
// ### }

const CustomSelect = React.memo((props) => {
  const {
    labelName,
    list,
    getItem,
    selectedItem,
    onSelect,
    styles,
    renderValue,
    fullWidth,
    disabled,
  } = props;
  const elementId = `custom-select-${labelName}`;

  const onChange = (e) => {
    onSelect(list.find((opt) => opt.value === e.target.value));
  }

  return (
    <FormControl
      style={styles}
      variant="outlined"
      fullWidth={fullWidth}
    >
      {labelName && <InputLabel htmlFor={elementId}>{labelName}</InputLabel>}
      <Select
        disabled={disabled}
        id={elementId}
        value={selectedItem.value}
        renderValue={renderValue && (() => selectedItem.value)}
        autoWidth
        onChange={onChange}
      >
        {list.map((item) => (
          getItem ? getItem(item.id, item.value) : <MenuItem
            key={item.id}
            value={item.value}
          >
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default withStyles({ withTheme: true })(CustomSelect);
