export default theme => ({
  singleContentRow: {
    marginTop: '21px',
  },
  singleContentSegment: {
    marginTop: '30px',
  },
  queueDivider: {
    marginBottom: '30px'
  },
  tableRoot: {
    marginTop: '22px',
    overflowX: 'auto',
  },
  tablePadding: {
    paddingBottom: '46px'
  },
  input: {
    color: 'black',
  },
  datePicker: {
    color: '#434391',
    cursor: 'pointer'
  },
  datePickerDisalbed: {
    color: 'black',
    cursor: 'default'
  },
  textInfoField: {
    display: 'flex',
    alignItems: 'center',
  }
})
