import React from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/es/Button";
import Typography from "@material-ui/core/es/Typography/Typography";

import {
  ID_ZERO,
  SECURITY_ROLE_ADMIN,
  TXT_EN_ALL_FACILITIES,
  TXT_EN_YOUR_FACILITIES,
  URL_FRAG_FACILITY_ID
} from 'redux/constants'
import styles from "./style";
import ArrowDropDown from "@material-ui/core/es/internal/svg-icons/ArrowDropDown";
import { findOneBy } from "redux/utils";

class FacilityMenuItem extends React.Component {
  render() {
    const { currentPage, classes, menuHide, facility, search = '' } = this.props
    const urlReplace = (facility.id ? `/${facility.id}` : '')
    return <Link to={currentPage.to.replace(URL_FRAG_FACILITY_ID, urlReplace).replace('/:itemId', '') + search} className={classes.linkNoDecoration}><MenuItem onClick={menuHide}>{facility.displayName}</MenuItem></Link>
  }
}

class FacilityChooser extends React.Component {
  state = {
    anchorEl: null,
  }

  constructor(props) {
    super(props)
    this.facilityChooserMenuHide = this.facilityChooserMenuHide.bind(this)
  }


  facilityChooserMenuShow = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  facilityChooserMenuHide = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, currentFacilityId, currentPage, facilities, history, loggedInUser } = this.props
    const userFacilities = loggedInUser.securityAccess.facilityIds
    const anchorEl = this.state.anchorEl
    const facility = findOneBy(facilities, 'id', currentFacilityId)
    const allFacilitiesText = (!loggedInUser.securityRoles.includes(SECURITY_ROLE_ADMIN)  && !userFacilities.includes(ID_ZERO)) ? TXT_EN_YOUR_FACILITIES : TXT_EN_ALL_FACILITIES
    const facilityName = (facility) ? facility.displayName : allFacilitiesText
    const openMenu = Boolean(anchorEl)
    return (
      <span>
        <Button
          aria-owns={openMenu ? 'menu-choose-facility-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.facilityChooserMenuShow}
          variant="contained"
          size="small"
          color="primary"
          className={classes.margin}>
          <Typography color="inherit" >{facilityName}</Typography>
          <ArrowDropDown className={classes.rightIcon} />
        </Button>
        <Menu
          id="menu-choose-facility-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openMenu}
          onClose={this.facilityChooserMenuHide}
        >{
            Object.keys(facilities).reduce((acc, i) => {
              const facility = facilities[i]
              acc.push(<FacilityMenuItem key={i} currentPage={currentPage} search={history.location.search} classes={classes} menuHide={this.facilityChooserMenuHide} facility={facility} />)
              // if(loggedInUser.role === SECURITY_ROLE_ADMIN || userFacilities.includes(facility.id) || userFacilities.includes(ID_ZERO)) {
              // }
              return acc
            }, [])
          }
          <FacilityMenuItem currentPage={currentPage} classes={classes} menuHide={this.facilityChooserMenuHide} facility={{ displayName: allFacilitiesText }} />
        </Menu>
      </span>
    )
  }
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withRouter(FacilityChooser)))