export default theme => ({
  dialogTitleRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  dialogTitleCloseButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  dialogContentRoot:{
    minWidth:500,
    paddingBottom:0,
    paddingTop:0,
  },
  formControlLabel: {
    margin: theme.spacing.unit,
    marginLeft:0,
    minWidth: 120,
  },
  facilityLabel: {
    fontWeight: '500',
    marginRight:12
  },
  overrideLabel: {
    fontWeight: '400', 
    color: 'gray',
    fontSize: 12
  }
})
