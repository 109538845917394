import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import styles from 'common/components/style'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import {sendDistrictId, settingsUpdateProperty, returnToSetSkywardApiInfo} from 'redux/install/Actions'

import FancySelect from "common/components/FancySelect";
import {INSTALL_STEP_SET_DISTRICT} from "redux/install/constants";

class DistrictDialogClass extends React.Component {
  constructor() {
    super()
    this.state = {controlsDisabled:false}
  }

  confirmThisStep(fn) {
    this.setState({
      controlsDisabled:true
    })
    fn()
  }

  addHooks() {
    //testing
    window.stHooks.addHook('vendorId', (e)=>this.props.settingsUpdateProperty({propertyName:'vendorId', val:e}))
  }

  removeHooks() {
    window.stHooks.removeHook(['vendorId'])
  }

  componentDidUpdate(prevProps) {
    if (this.props.settings.vendorId !== prevProps.settings.vendorId) {
      this.setState({
        controlsDisabled: !(parseInt(this.props.settings.vendorId) > 0)
      });
    }
  }

  render() {
    if(!this.props.settings) return null;
    const {browserCurrentStep, classes, close, sendDistrictId, settingsUpdateProperty, settings, jsbVendor, returnToSetSkywardApiInfo} = this.props
    const onClose = (close)? close : ()=>{console.log('This should be an easter egg, but it\'s not')}
    if(browserCurrentStep !== INSTALL_STEP_SET_DISTRICT) {
      this.removeHooks()
      return null
    } else {
      this.addHooks()
    }

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title">
          Choose the District for this installation
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container className={classes.firstRow}>
            <Grid item className={classes.gridItem} xs={12}>
              <FancySelect
                label='District'
                currentVal={settings.vendorId}
                dispatchUpdate={settingsUpdateProperty}
                className={classes.selectEmpty}
                valAtr={'EntityID'}
                propertyName={'vendorId'}
                nameAtr={
                  (item)=>{
                    return item.Name
                  }}
                items={jsbVendor.Districts}
              >
              </FancySelect>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{justifyContent:"space-between"}}>
          <Button onClick={returnToSetSkywardApiInfo} color="secondary">
            Back
          </Button>
          <Button disabled={this.state.controlsDisabled || parseInt(settings.vendorId) < 0 || isNaN(parseInt(settings.vendorId))}
            onClick={()=>this.confirmThisStep(sendDistrictId)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const DistrictDialog = connect(
  state => state.install,
  {sendDistrictId,settingsUpdateProperty, returnToSetSkywardApiInfo}
)(withStyles(styles, { withTheme: true })(DistrictDialogClass))

export default DistrictDialog
