export const INSTALL_STEP_SET_LICENSE_KEY = 1
export const INSTALL_STEP_SET_ADMIN_PASSWORD = 2
export const INSTALL_STEP_KIOSK_DEFAULT_CONFIGURATION = 3
export const INSTALL_STEP_SET_SKYWARD_API_INFO = 4
export const INSTALL_STEP_SET_DISTRICT = 5
export const INSTALL_STEP_RESET_DISTRICT = -5//returning step
// export const INSTALL_STEP_BUILDINGS_ENTITIES_SCHOOL_YEAR = 6
// export const INSTALL_STEP_SYNCHRONIZING_REASONS_ROOMS_STUDENTS_TYPES = 7
export const INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR = 6
export const INSTALL_STEP_SYNCHRONIZE_BUILDINGS = 7
export const INSTALL_STEP_SET_BUILDINGS = 8 //6
export const INSTALL_STEP_SYNCHRONIZING_REASONS_ROOMS_STUDENTS_TYPES = 9 //7
export const INSTALL_STEP_CONFIRM_ALL = 10


