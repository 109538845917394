import React, { Component } from 'react'
import {connect} from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import TopAppBar from './components/TopAppBar'
import SnackMessage from 'common/components/SnackMessage'
import Footer from './components/Footer'

import styles from './style'

import {snackMessageHide, snackMessageError, resetPassword, checkPasswordToken} from "redux/kiosk/KioskActions";

import {FullTextField} from "common/components/FullTextField";

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password:'',
      passwordConfirmation: '',
      enableSend: false
    }
    this.passwordMatchCheck = this.passwordMatchCheck.bind(this)
    this.setPassword = this.setPassword.bind(this)
    this.setPasswordConfirmation = this.setPasswordConfirmation.bind(this)
  }

  setPassword(e) {
    const self = this
    this.setState({password:e.target.value}, ()=> {
      if(self.state.passwordConfirmation.length > 0) {
        self.passwordMatchCheck()
      }
    })
  }
  componentDidMount() {
    const token = this.props.location.search.replace('?token=', '')
    this.setState({token})
    this.props.checkPasswordToken(token)
  }

  setPasswordConfirmation(e) {
    this.setState({passwordConfirmation:e.target.value}, this.passwordMatchCheck)
  }

  passwordMatchCheck() {
    if(this.state.password !== this.state.passwordConfirmation) {
      this.props.snackMessageError("Passwords do not match")
      this.setState({enableSend:false})
      return
    } else {
      this.props.snackMessageHide()
    }
    if(this.state.password.length > 5) {
      this.setState({enableSend:true})
    } else {
      this.setState({enableSend:false})
    }
  }

  render() {
    const {classes, snackMessageHide, resetPassword} = this.props

    return (
      <div>
        <SnackMessage snackMessageHide={snackMessageHide}/>
        <TopAppBar />
        <Dialog open>
          <DialogTitle>Password Reset</DialogTitle>
          <DialogContent className={classes.dialogContentRoot}>
            <Grid container>
              <Grid item xs={12} >
                <FullTextField
                  label="Password"
                  autoComplete={'off'}
                  type={'password'}
                  onChange={this.setPassword}
                  propertyName={'password'}
                />
                <Grid item xs={12}>
                  <FullTextField
                    label="Password Again"
                    autoComplete={'off'}
                    type={'password'}
                    onChange={this.setPasswordConfirmation}
                    propertyName={'password'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={!this.state.enableSend}
                    onClick={()=>resetPassword({password: this.state.password, token:this.state.token})}
                    color="primary">
              Reset
            </Button>
          </DialogActions>
        </Dialog>

        <Footer/>
      </div>
    )
  }
}

export default connect(
  null,
  {
    resetPassword,
    snackMessageHide,
    snackMessageError,
    checkPasswordToken
  }
)(withStyles(styles)(App))
