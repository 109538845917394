import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

import styles from 'common/components/style'


import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ToggleIconButton from "common/components/ToggleIconButton";
import {
  buildingChooserConfirm,
  buildingChooserDone,
  buildingChooserValidateSet
} from "redux/admin/Actions";



class BuildingChooserDialogClass extends React.Component {

  render() {
    if (!this.props.buildings) return null

    const { buildings, buildingChooserDone, buildingChooserConfirm, buildingChooserValidateSet, classes,  } = this.props

    return (
      <Dialog
        onClose={buildingChooserDone}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title" onClose={buildingChooserDone}>
          Buildings used by SchoolTRAK
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <List>
            {buildings.map((building) => {
              return <ListItem key={building.id}><ToggleIconButton isOn={building.isEnabled} onClick={() => buildingChooserValidateSet({ id: building.id, isEnabled: !building.isEnabled })} />{building.displayName}</ListItem>
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Typography>Activating a building is final, and cannot be altered after confirmation.</Typography>
          <Button onClick={buildingChooserDone} color="secondary">
            Cancel
          </Button>
          <Button onClick={buildingChooserConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const BuildingChooserDialog = connect(
  state => state.admin.buildingChooser,
  {
    buildingChooserConfirm,
    buildingChooserValidateSet,
    buildingChooserDone,
  }
)(withStyles(styles, { withTheme: true })(BuildingChooserDialogClass))

export default BuildingChooserDialog
