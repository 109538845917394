import React from 'react'
import connect from "react-redux/es/connect/connect"
import { Link as RouterLink } from 'react-router-dom'
import {URL_FRAG_FACILITY_ID, URL_FRAG_ITEM_ID, URL_FRAG_ITEM_ID_OPTIONAL} from 'redux/constants'

export const pageLink = (to, facilityId, itemId) => {
  const urlFragFacilityIdVal = (facilityId)? `/${facilityId}`: ''
  const urlFragItemIdVal = (itemId)? `/${itemId}` : ''
  let newLink = to.replace(URL_FRAG_FACILITY_ID, urlFragFacilityIdVal)
  if(newLink.indexOf(URL_FRAG_ITEM_ID_OPTIONAL) > -1) {
    return newLink.replace(URL_FRAG_ITEM_ID_OPTIONAL, urlFragItemIdVal)
  }
  return newLink.replace(URL_FRAG_ITEM_ID, urlFragItemIdVal)
}

class Link extends React.Component {
  render () {
    const { to, currentFacilityId, itemId, facilityId } = this.props
    const newTo = pageLink(to, ((facilityId)? facilityId : currentFacilityId), itemId)
    return <RouterLink style={{textDecoration: 'none', color: 'inherit'}} to={newTo}>{this.props.children}</RouterLink>
  }
}

const mapStateToProps = state => {
    const {currentFacilityId} = state.admin
  return { currentFacilityId }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Link)