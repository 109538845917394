/* globals window */
import React from "react"
import connect from "react-redux/es/connect/connect"
import {IconButton, Paper, withStyles} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Tooltip from '@material-ui/core/Tooltip'
import Typography from "@material-ui/core/es/Typography/Typography"
import CurrentFacility from "apps/admin/components/CurrentFacility"
import AtRecordEditor from "apps/admin/components/AtRecordEditor"
import {
  snackMessageInfo
} from 'redux/RootActions'
import {
  fetchRichStudentById,
  saveStudent,
  atRecordEdit,
  comingSoon,
  fetchStudentPageScheduleAttendance,
  setStudentDetailPageAttendanceView,
  studentDetailPageToggleShowReportingOnly,
  fetchStudentPageListAttendance,
  studentDetailPageReportEditUpdateProperty,
  studentDetailPageReportFetchAttendanceLog,
  fetchScheduleDisplaySettings,
  saveScheduleDisplaySettings,
} from 'redux/admin/Actions'
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import PollIcon from '@material-ui/icons/Poll'

import {
  AT_TYPE_ID_ABSENT,
  AT_TYPE_ID_ABSENT_EXCUSED, AT_TYPE_ID_OUT, AT_TYPE_ID_OUT_OF_BUILDING, AT_TYPE_ID_UNKNOWN,
  AT_TYPE_ID_PRESENT, AT_TYPE_ID_REPORT, AT_TYPE_ID_TARDY, AT_TYPE_ID_TARDY_EXCUSED, AT_TYPE_ID_SKYWARD_CUSTOM, AT_TYPE_ID_ON_SITE,
  ATTENDANCE_VIEW_LIST, ATTENDANCE_VIEW_WEEK,
  URL_MANAGE_STUDENT_DETAIL, URL_MANAGE_STUDENT_DETAIL_WEEK,
  SCHED_DISP_SETTINGS
} from "redux/constants"
import StudentAttendanceWeekView from './WeekView'
import styles from '../style'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import StudentAttendanceListView from "apps/admin/pages/StudentAttendance/ListView";
import AtRecordDot from "apps/admin/pages/StudentAttendance/AtRecordDot";
import ToggleIconButton from "common/components/ToggleIconButton";
import DatePickerFirstAndLastGrid from "apps/admin/components/DatePickerFirstAndLastGrid";
import ReportControls from "apps/admin/components/ReportControls";
import { SelectionModal } from '../../components/reusableComponents';
import ListItem from '@material-ui/core/ListItem';
import EditIcon from '@material-ui/icons/Edit'

class LegendItem extends React.PureComponent {
  render() {
    const {atTypeId, detailText = '', isReportingAtRecord=false} = this.props
    const title = this.props.title? this.props.title : this.props.children
    return <div><AtRecordDot atTypeId={atTypeId} title={title} isReportingAtRecord={isReportingAtRecord} inline/>
      <Typography style={{display:'inline'}}><b>{this.props.children}</b> {detailText}</Typography>
    </div>
  }
}

const settingsBtn = {
  badge: 'badge',
  tooltip: 'tooltip',
}

class StudentAttendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAttendanceLogChooser: false,
      isBadgeModalVisible: false,
      isTooltipModalVisible: false,
      badgeSettings: null,
      tooltipSettings: null,
    }
  }
  componentDidMount() {
    const payload = {
      atUserId: this.props.currentItemId
    }

    this.props.fetchScheduleDisplaySettings();

    const pathFrags = this.props.location.pathname.split('/week/')

    if(pathFrags.length > 1) {
      payload.dateInWeek = pathFrags[1]
    }
    if(this.props.currentItemId) this.props.fetchRichStudentById(payload)
  }

  componentDidUpdate() {
    const initialSchedDispSettings = this.props.schedDispSettings.view.student.calendar.event;
    if (!this.state.badgeSettings && initialSchedDispSettings.nameFields) {
          this.setState({
            badgeSettings: SCHED_DISP_SETTINGS.badge.map((opt) => ({
              name: opt,
              checked: initialSchedDispSettings.nameFields.some((el) =>  el === opt),
            }))
          })
        }
    if (!this.state.tooltipSettings && initialSchedDispSettings.toolTipFields) {
          this.setState({
            tooltipSettings: SCHED_DISP_SETTINGS.tooltip.map((opt) => ({
              name: opt,
              checked: initialSchedDispSettings.toolTipFields.some((el) =>  el === opt),
            }))
          })
    }
  }

  toggleKioskUse = () => {
    let {canUseKiosks, id, restrictAtRecordLocations} = this.props.student
    if(!canUseKiosks) {
      canUseKiosks = true
      restrictAtRecordLocations = false
    } else if(!restrictAtRecordLocations) {
        canUseKiosks = true
        restrictAtRecordLocations = true
    } else {
        canUseKiosks = false
        restrictAtRecordLocations = false
    }

    this.props.saveStudent({
      id,
      canUseKiosks,
      restrictAtRecordLocations
    })
  }

  toggleShowAttendanceLogChooser = () => {
    this.setState({
      showAttendanceLogChooser: !this.state.showAttendanceLogChooser
    })
    if(!this.state.showAttendanceLogChooser) {
      this.props.studentDetailPageReportEditUpdateProperty({
        propertyName:'report',
        val:false
      })
    }
  }

  setAttendanceView = (e, val) => {
    this.props.setStudentDetailPageAttendanceView({attendanceView:val})
  }

  setAttendanceViewToWeek = (payload) => {
    this.props.setStudentDetailPageAttendanceView(Object.assign({attendanceView:ATTENDANCE_VIEW_WEEK}, payload))
  }

  setIsModalVisible = (isVisible, modalName) => {
    settingsBtn.badge === modalName
      ? this.setState({
        isBadgeModalVisible: isVisible,
      }) : this.setState({
        isTooltipModalVisible: isVisible,
      })
  }

  onClickDispSettings = (btnName) => {
    settingsBtn.badge === btnName
        ? this.setState({
      isBadgeModalVisible: true,
    }) : this.setState({
      isTooltipModalVisible: true,
    })
  }

  saveSchDispSettings = (newList, modalName) => {
    const preparedData = {
      view: {
        student: {
          calendar: {
            event: {
              ...this.props.schedDispSettings.view.student.calendar.event,
              [modalName === settingsBtn.badge ? 'nameFields' : 'toolTipFields']: newList
                .filter((opt) => opt.checked).map((opt) => opt.name),
            }
          }
        }
      }
    }

    this.props.saveScheduleDisplaySettings(preparedData);
    this.setState({
      [modalName === settingsBtn.badge ? 'badgeSettings' : 'tooltipSettings']: newList
    });
    this.setIsModalVisible(false, settingsBtn[modalName]);
  }

  render() {
    if(!this.props.student || !this.props.weekCalendar) return null
    const {
      attendanceReport: {
        firstDateYMD,
        lastDateYMD,
        report
      },
      classes,
      loggedInUser,
      fetchStudentPageListAttendance,
      fetchStudentPageScheduleAttendance,
      studentDetailPageReportEditUpdateProperty,
      studentDetailPageReportFetchAttendanceLog,
      schedDispSettings,
      saveScheduleDisplaySettings,
      history,
      listView,
      location,
      positiveAttendanceIsEnabled,
      weekCalendar,
      showReportingOnly,
      snackMessageInfo,
      studentDetailPageToggleShowReportingOnly} = this.props
    const {attendanceView = ATTENDANCE_VIEW_WEEK, student, atRecordsByDate, byId, atRecordEdit} = this.props
    const typeById = byId.atType
    const atReasonById = byId.atReason

    const kioskUseOn = student.restrictAtRecordLocations? GpsFixedIcon : ImportantDevicesIcon
    const kioskUseOnTitle = student.restrictAtRecordLocations? 'Only at assigned class locations' : 'Student can use all kiosks'
    const {showAttendanceLogChooser} = this.state
    return <div>
      <AtRecordEditor/>
      <CurrentFacility/>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography variant="h4">{student.fullName}
            <ToggleIconButton
              style={{marginTop:-5}}
              isOn={student.canUseKiosks}
              onIcon={kioskUseOn}
              onTitle={kioskUseOnTitle}
              offIcon={HighlightOffIcon}
              offTitle='Kiosk use prevented'
              onClick={this.toggleKioskUse}
            />
            {positiveAttendanceIsEnabled?
              <IconButton style={{marginTop:-5}} onClick={this.toggleShowAttendanceLogChooser}>
                <PollIcon/>
              </IconButton>
              : null
            }
            {positiveAttendanceIsEnabled ?
              <div style={{position: 'relative', display: 'inline-block', width: 1, height: 1}}>
                <Paper
                  style={{
                    display: showAttendanceLogChooser ? 'block' : 'none',
                  }}
                  className={classes.studentDetailPageReportControl}
                >
                  <DatePickerFirstAndLastGrid
                    firstDateYMD={firstDateYMD}
                    lastDateYMD={lastDateYMD}
                    onChange={studentDetailPageReportEditUpdateProperty}
                    xs={6} sm={6} md={6}/>
                  <ReportControls
                    style={{marginTop: 20}}
                    firstDateYMD={firstDateYMD}
                    lastDateYMD={lastDateYMD}
                    fetchReportAction={studentDetailPageReportFetchAttendanceLog}
                    report={report}
                    reportType={{displayName: `${student.fullName} ${student.attendancePasscode} Attendance Log`}}
                    xs={12} sm={12} md={12}
                  />
                </Paper>
              </div>
              : null
            }

          </Typography>
        </Grid>
        <Grid item style={{width:200}}>
          <Typography><b>DOB:</b> {student.dob}</Typography>
          <Typography style={{marginBottom:'10px'}}><Tooltip title={`SchoolTRAK ID:${student.id} / Skyward ID:${student.vendorId}`}><span><b>S/N:</b> {student.attendancePasscode}</span></Tooltip></Typography>
        </Grid>
        <Grid item xs={12} lg={5} md={5} sm={5}>
          <Grid container>
            {positiveAttendanceIsEnabled && <Grid item xs={2} lg={2}>
              <LegendItem atTypeId={AT_TYPE_ID_PRESENT} isReportingAtRecord>Present</LegendItem>
              <LegendItem atTypeId={AT_TYPE_ID_ON_SITE} isReportingAtRecord>On Site</LegendItem>
              <LegendItem atTypeId={AT_TYPE_ID_SKYWARD_CUSTOM} isReportingAtRecord>Specific</LegendItem>
            </Grid>}
            <Grid item xs={2} lg={2} style={{minWidth:140}}>
              <LegendItem atTypeId={AT_TYPE_ID_OUT} isReportingAtRecord>Out</LegendItem>
              <LegendItem atTypeId={AT_TYPE_ID_OUT_OF_BUILDING} isReportingAtRecord>Out Of Building</LegendItem>
              <LegendItem atTypeId={AT_TYPE_ID_REPORT} isReportingAtRecord>Report</LegendItem>
            </Grid>
            <Grid item xs={2} lg={2} style={{minWidth:130}}>
              <LegendItem atTypeId={AT_TYPE_ID_ABSENT} isReportingAtRecord detailText={student.numAbsent}>Absent</LegendItem>
              <LegendItem atTypeId={AT_TYPE_ID_ABSENT_EXCUSED} title={'Absent Excused'} isReportingAtRecord detailText={student.numAbsentEx}>Absent Ex</LegendItem>
              <LegendItem atTypeId={AT_TYPE_ID_UNKNOWN} isReportingAtRecord>Unknown</LegendItem>
            </Grid>
            <Grid item xs={2} lg={2}>
              <LegendItem atTypeId={AT_TYPE_ID_TARDY} isReportingAtRecord detailText={student.numTardy}>Tardy</LegendItem>
              <LegendItem atTypeId={AT_TYPE_ID_TARDY_EXCUSED} title={'Tardy Excused'} isReportingAtRecord detailText={student.numTardyEx}>Tardy Ex</LegendItem>
            </Grid>
            <Grid item xs={1} lg={1}>
              <Typography><ToggleIconButton
                style={{marginTop:-5}}
                isOn={showReportingOnly}
                onIcon={CheckCircleIcon}
                onTitle='Show Reporting only'
                offIcon={CheckCircleOutlineIcon}
                offTitle='Show all records'
                onClick={studentDetailPageToggleShowReportingOnly}
              /> </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container>
          <><div style={{display: 'block'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography style={{ marginRight:5 }}>Schedule fields</Typography>
                <IconButton style={{ width:35, height:35 }} onClick={()=>this.onClickDispSettings(settingsBtn.badge)}><EditIcon style={{ width:20, height:20 }}/></IconButton>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography style={{ marginRight:5 }}>Schedule tooltip</Typography>
                <IconButton style={{ width:35, height:35 }} onClick={()=>this.onClickDispSettings(settingsBtn.tooltip)}><EditIcon style={{ width:20, height:20 }}/></IconButton>
              </div>
          </div>
          <SelectionModal
            title='Badge display settings'
            description={
              <Typography style={{ marginTop: '10px' }}>
                Choose what you want to see in the schedule badge
              </Typography>
            }
            isSearchHidden={false}
            isModalVisible={this.state.isBadgeModalVisible}
            setIsModalVisible={(isVisible) => this.setIsModalVisible(isVisible, settingsBtn.badge)}
            searchPlaceholder='Search'
            list={this.state.badgeSettings || []}
            setList={(newList) => this.saveSchDispSettings(newList, settingsBtn.badge)}
            getItem={(itemProps, checkboxHandler) => <ListItem
              title={itemProps.name}
              key={itemProps.name}
            >
              <ToggleIconButton isOn={itemProps.checked} onClick={() => checkboxHandler(itemProps)} />
              {itemProps.name.replace('_', ' ')}
            </ListItem>}
          />
          <SelectionModal
            title='Tooltip display settings'
            description={
              <Typography style={{ marginTop: '10px' }}>
                Choose what you want to see in the schedule tooltip
              </Typography>
            }
            isSearchHidden={false}
            isModalVisible={this.state.isTooltipModalVisible}
            setIsModalVisible={(isVisible) => this.setIsModalVisible(isVisible, settingsBtn.tooltip)}
            searchPlaceholder='Search'
            list={this.state.tooltipSettings || []}
            setList={(newList) => this.saveSchDispSettings(newList, settingsBtn.tooltip)}
            getItem={(itemProps, checkboxHandler) => <ListItem
              title={itemProps.name}
              key={itemProps.name}
            >
              <ToggleIconButton isOn={itemProps.checked} onClick={() => checkboxHandler(itemProps)} />
              {itemProps.name.replace('_', ' ')}
            </ListItem>}
          /></>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={attendanceView}
            onChange={this.setAttendanceView}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Week"  />
            <Tab label="Attendance List"  />
          </Tabs>
        </Grid>
      </Grid>

      <Grid container spacing={16} >
          <StudentAttendanceWeekView show={attendanceView === ATTENDANCE_VIEW_WEEK} atRecordEdit={atRecordEdit} student={student}
                                     history={history}
                                     location={location}
                                     typeById={typeById}
                                     atReasonById={atReasonById}
                                     fetchStudentPageScheduleAttendance={fetchStudentPageScheduleAttendance}
                                     weekCalendar={weekCalendar}
                                     showReportingOnly={showReportingOnly}
                                     snackMessageInfo={snackMessageInfo}
                                     schedDispSettings={schedDispSettings}
                                     saveScheduleDisplaySettings={saveScheduleDisplaySettings}
                                     loggedInUser={loggedInUser}
          />
          <StudentAttendanceListView show={attendanceView === ATTENDANCE_VIEW_LIST} atRecordEdit={atRecordEdit} student={student}
                                     listView={listView}
                                     setAttendanceViewWeek={this.setAttendanceViewToWeek}
                                     fetchStudentPageListAttendance={fetchStudentPageListAttendance}
                                     atRecordsByDate={atRecordsByDate}
                                     showReportingOnly={showReportingOnly}
                                     byId={byId}
          />
      </Grid>
    </div>
  }
}

const mapStateToProps = state => {
  const studentDetailPage = Object.assign({positiveAttendanceIsEnabled:state.admin.positiveAttendanceIsEnabled}, state.admin.studentDetailPage)
  studentDetailPage.currentItemId = state.admin.currentItemId
  studentDetailPage.byId = state.admin.byId
  studentDetailPage.editorOpen = !!state.admin.dialogs.atRecordEditor
  studentDetailPage.loggedInUser = state.admin.loggedInUser

  return studentDetailPage
}

const mapDispatchToProps = {
  atRecordEdit,
  comingSoon,
  fetchRichStudentById,
  fetchStudentPageScheduleAttendance,
  fetchStudentPageListAttendance,
  studentDetailPageReportEditUpdateProperty,
  studentDetailPageReportFetchAttendanceLog,
  saveStudent,
  setStudentDetailPageAttendanceView,
  snackMessageInfo,
  studentDetailPageToggleShowReportingOnly,
  fetchScheduleDisplaySettings,
  saveScheduleDisplaySettings
}

export const StudentAttendancePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(StudentAttendance))

export const studentAttendancePageDetail = {
  icon: CheckCircleIcon,
  component: StudentAttendancePage,
  name: 'Student Detail',
  displayName: 'Student Detail',
  to: URL_MANAGE_STUDENT_DETAIL,
  routes: [
    URL_MANAGE_STUDENT_DETAIL_WEEK + '/:ymd',
    URL_MANAGE_STUDENT_DETAIL_WEEK,
    URL_MANAGE_STUDENT_DETAIL + '/list',
    URL_MANAGE_STUDENT_DETAIL
  ]
}

export default StudentAttendancePage