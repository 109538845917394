import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import MenuIcon from '@material-ui/icons/Menu'
import VideocamIcon from '@material-ui/icons/Videocam'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import FastForwardIcon from '@material-ui/icons/FastForward'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {seconds} from "@relativity/js-util"
import {openMenuDrawer} from "../../../redux/actions"
import {cameraToggleShowVideo, cameraToggleTorch} from "redux/kiosk/KioskActions";
import {CHECKIN_FLOW_STANDARD} from "redux/constants";

const styles = {
  root: {
    flexGrow: 1,
    '@media print': {
      display:'none'
    }
  },
}

class TopAppBar extends React.Component {
  render() {
    const {cameraToggleShowVideo,
      cameraToggleTorch, cameraTorchOn,
      checkinFlow, classes, capabilities:{enableCamera}, location, openMenuDrawer, building, leaseExpires, showVideo } = this.props
    const now = seconds()
    let appBarStyle = {}, appBarMessage = ''

    if(leaseExpires > 0) {
      if (leaseExpires - 600 <= now) {
        appBarStyle = {background: 'orange'}
      }
      if (leaseExpires <= now) {
        appBarStyle = {background: 'darkorange'}
        appBarMessage = 'EXPIRED'
      }
    }

    return (
      <div className={classes.root}>
        <AppBar position="static" style={appBarStyle}>
          <Toolbar style={{width:'100%!important'}}>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={openMenuDrawer}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {location.displayName}
            </Typography>
            {enableCamera.val?
              <IconButton onClick={cameraToggleTorch} style={{color:cameraTorchOn? 'white' : 'black'}}>
                <FlashOnIcon/>
              </IconButton>
              :
              null
            }
            {enableCamera.val?
            <IconButton onClick={cameraToggleShowVideo}>
              {showVideo? <KeyboardIcon/> : <VideocamIcon/>}
            </IconButton>
            :
            null}
            {checkinFlow !== CHECKIN_FLOW_STANDARD?
            <IconButton>
              <FastForwardIcon/>
            </IconButton>
            :
            null}

            <Typography variant='h4' color="inherit" style={{flexGrow:8}}>{appBarMessage? appBarMessage : null}</Typography>
            <div >{(building)? building.displayName : ''}</div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}


TopAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => state.kiosk
const mapDispatchToProps = {
  openMenuDrawer,
  cameraToggleShowVideo,
  cameraToggleTorch
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TopAppBar))
