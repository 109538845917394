import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import FancySelect from 'common/components/FancySelect'
import {FullTextField} from "common/components/FullTextField"
import Typography from "@material-ui/core/Typography"
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import moment from 'moment-timezone'
import styles from './style'
import ToggleIconButton from "common/components/ToggleIconButton"
import MomentUtils from "@date-io/moment"
import {MuiPickersUtilsProvider, DateTimePicker} from "material-ui-pickers"

class ToggleDefaultResetButton extends React.PureComponent {
  render() {
    const {editUpdateProperty, setting} = this.props
    return <ToggleIconButton
      onIcon={SettingsBackupRestoreIcon}
      offIcon={TrendingFlatIcon}
      onTitle={`Will reset to default after next run (${setting.defaultVal})`}
      offTitle={'Value will be used forever'}
      onClick={()=> {
        editUpdateProperty({
          propertyName:`jsbCustomConfiguration.settings.${setting.name}.resetToDefault`,
          val:!setting.resetToDefault
        })
      }}
      isOn={setting.resetToDefault}/>
  }
}

class CustomSettingClass extends React.PureComponent {
  render() {
    const {classes, setting, editUpdateProperty} = this.props
    const datehourCols = 5;
    let inputFieldCols = 12
    let showResetToDefault = false
    if(setting.hasOwnProperty('defaultVal')) {
      inputFieldCols--
      showResetToDefault = true
    }
    if('datehour' === setting.valMetaType) {
      inputFieldCols -= datehourCols
    }

    return <Grid item xs={12}>
      <Grid container>
          <Grid item xs={inputFieldCols}>
            <FullTextField
              label={setting.displayName}
              dispatchUpdate={(payload) => {
                if(setting.valType === 'number') {
                  payload.val = parseFloat(payload.val)
                }
                editUpdateProperty(payload)
              }}
              propertyName={`jsbCustomConfiguration.settings.${setting.name}.val`}
              type={setting.valType === 'number'? 'number': 'text'}
              value={setting.val}
            />
          </Grid>
        {('datehour' === setting.valMetaType)?
          <Grid item xs={datehourCols}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                label={'local time'}
                style={{width:'100%', margin:'10px', marginTop:'16px'}}
                variant={'outlined'}
                format={'YYYY-MM-DD hh:mm:ss A'}
                value={moment.unix(setting.val)}
                onChange={(date)=>{
                  editUpdateProperty({
                    propertyName:`jsbCustomConfiguration.settings.${setting.name}.val`,
                    val:date.unix()
                  })
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          : null
        }
        {showResetToDefault?
          <Grid item xs={1} style={{paddingTop:'16px'}}>
            <ToggleDefaultResetButton setting={setting} editUpdateProperty={editUpdateProperty}/>
          </Grid>
        : null}
        {setting.description && setting.description.length > 0 ?
          <Grid item xs={12}>
            <Typography className={setting.recommendedSetting? classes.customSettingDescriptionWithRecommended : classes.customSettingDescription}>{setting.description}</Typography>
          </Grid>
          : null
        }{setting.recommendedSetting && setting.recommendedSetting.length > 0 ?
          <Grid item xs={12}>
            <Typography className={classes.customSettingDescription}><b>Recommended:</b>{setting.recommendedSetting}</Typography>
          </Grid>
          : null
        }
      </Grid>
    </Grid>
  }
}

const CustomSetting = (withStyles(styles, { withTheme: true }))(CustomSettingClass)

const trueFalseList = [{displayName:'true', val:true}, {displayName:'false', val:false}]

class CustomSettingSelectClass extends React.PureComponent {
  render() {
    const {classes, setting, editUpdateProperty, isBool=false} = this.props
    return <Grid item xs={12}>{isBool ? <FancySelect
        dispatchUpdate={(payload) => {
          payload.val = !!payload.val
          editUpdateProperty(payload)
        }}
        label={setting.displayName}
        propertyName={`jsbCustomConfiguration.settings.${setting.name}.val`}
        currentVal={setting.val}
        valAtr='val'
        items={trueFalseList}
      />
      : <FancySelect
        dispatchUpdate={editUpdateProperty}
        label={setting.displayName}
        propertyName={`jsbCustomConfiguration.settings.${setting.name}.val`}
        currentVal={setting.val}
        valAtr=''
        items={setting.valOptions}
      />}
      {setting.description && setting.description.length > 0 ? <Typography className={classes.customSettingDescription}>{setting.description}</Typography> : null}
    </Grid>

  }
}

const CustomSettingSelect = (withStyles(styles, { withTheme: true }))(CustomSettingSelectClass)

export default class CustomConfigurationClass extends React.Component {

  render() {
    if(!this.props.jsbCustomConfiguration) return null

    const {
      jsbCustomConfiguration,
      editUpdateProperty
    } = this.props

    const editableSettings = jsbCustomConfiguration.settingNames.reduce((acc, settingName) => {
      const setting = jsbCustomConfiguration.settings[settingName]
      if (setting.isEditable) {
        acc.push(setting)
      }
      return acc
    }, [])

    if(editableSettings.length === 0) return null

    return <Grid container>
      <Grid item xs={12}>
        <Typography variant={'h5'} style={{marginTop:10}}>Custom Settings</Typography>
      </Grid>
        {editableSettings.map((setting) => {
          if(setting.valOptions && setting.valOptions.length > 0) {
            return <CustomSettingSelect key={setting.name} setting={setting} editUpdateProperty={editUpdateProperty}/>
          } else if(setting.valType === 'bool') {
            return <CustomSettingSelect key={setting.name} setting={setting} editUpdateProperty={editUpdateProperty} isBool/>
          }
          return <CustomSetting key={setting.name} setting={setting} editUpdateProperty={editUpdateProperty}/>
        })}
    </Grid>
  }
}

