import { all } from 'redux-saga/effects'
import { watches as installWatches } from "redux/install/Saga"
import { kioskWatches } from "redux/kiosk/KioskSaga"
import { adminWatches } from "redux/admin/Saga"
import {takeEvery} from 'redux-saga/effects'
import {
  post,
  changePageToHtml,
  uiDecoration
} from "redux/utils"

import {
  LOGIN_WITH_SKYWARD
} from 'redux/actions'

import {
  URL_API_ROOT
} from "redux/constants"


const payloadOnly = (fn) => (action) => fn(action.payload)

export function* loginWithSkyward({redirectUrl, parameters}) {
  const obj = yield post(`${URL_API_ROOT}/skywardAccount/redirectToLogin`, {
    returnClientAddress: redirectUrl,
    ...parameters
  });
  if(yield uiDecoration(obj)){
    changePageToHtml(obj.data)
  }  
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    ...installWatches,
    ...kioskWatches,
    ...adminWatches,
    ...[
      takeEvery(LOGIN_WITH_SKYWARD,  payloadOnly(loginWithSkyward))
    ]
  ])  
}