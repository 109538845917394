import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import styles from 'common/components/style';

import DialogTitle from 'common/components/dialog/DialogTitle';
import DialogContent from 'common/components/dialog/DialogContent';
import DialogActions from 'common/components/dialog/DialogActions';

import ToggleIconButton from "common/components/ToggleIconButton";

import {
  coursesFetchResponse,
  attendanceMonitorCoursesSave,
  attendanceMonitorCoursesSaveResponse,
  attendanceMonitorCoursesEditDone,
  attendanceMonitorCoursesChooserSetIsEnabled,
  attendanceMonitorChooserCoursesResponse,
  attendanceMonitorCoursesEdit
} from 'redux/admin/Actions';

class CourseSelectionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.setSearch = this.setSearch.bind(this);

    this.state = {
      controlsDisabled: false,
      tabIndex: 0,
      searchString: '',
      selectAllChecked: this.getIsAllChecked(props.courses),
    };
  };

  getIsAllChecked(courses) {
    return courses.every(course => course.isAttendanceMonitorEnabled);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const isAllChecked = nextProps.courses.every(course => course.isAttendanceMonitorEnabled);
    if (isAllChecked !== prevState.selectAllChecked) {
      return { selectAllChecked: isAllChecked };
    }
    return null;
  };

  setSearch(event) {
    this.setState({
      searchString: event.target.value
    })
  };

  handleClose = () => {
    this.setState({ searchString: '' });
    if (this.props.attendanceMonitorCoursesEditDone) {
      this.props.attendanceMonitorCoursesEditDone();
    }
  };

  handleSave = () => {
    this.setState({ searchString: '' });
    if (this.props.attendanceMonitorCoursesSave) {
      this.props.attendanceMonitorCoursesSave();
    }
  };

  handleSelectAllChange = () => {
    const { selectAllChecked } = this.state;
    const { attendanceMonitorCoursesChooserSetIsEnabled, courses } = this.props;

    courses.forEach(course => {
      attendanceMonitorCoursesChooserSetIsEnabled({ id: course.id, isEnabled: !selectAllChecked });
    });

    this.setState({ selectAllChecked: !selectAllChecked });
  };

  handleItemToggle = (course) => {
    const { attendanceMonitorCoursesChooserSetIsEnabled, courses } = this.props;
    attendanceMonitorCoursesChooserSetIsEnabled({ id: course.id, isEnabled: !course.isAttendanceMonitorEnabled });
    const allSelected = courses.every(course => course.isAttendanceMonitorEnabled);
    this.setState({ selectAllChecked: allSelected });
  };

  render() {
    if (!this.props.attendanceMonitorCoursesEditor) return null;

    const { classes, courses } = this.props;

    const enabledCoursesCount = courses.filter(course => course.isAttendanceMonitorEnabled).length;
    const totalCoursesCount = courses.length;

    const coursesSorted = courses;
    coursesSorted.sort((a, b) => {
      const aDisplayName = a.courseCode.toLowerCase();
      const bDisplayName = b.courseCode.toLowerCase();
      return bDisplayName < aDisplayName ? 1 : bDisplayName > aDisplayName ? -1 : 0;
    });

    const coursesList = coursesSorted.reduce((acc, course) => {
      if (course.courseCode.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1 ||
        course.courseDescription.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1 ||
        course.facility.displayName.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1 ||
        (`${course.courseCode} ${course.courseDescription} - ${course.facility.displayName}`)
        .toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1 ) {
        acc.push(
          <ListItem title={`id: ${course.id}`} key={course.id}>
            <ToggleIconButton isOn={course.isAttendanceMonitorEnabled} onClick={() => this.handleItemToggle(course)} />
            {course.courseCode} {course.courseDescription} - {course.facility.displayName}
          </ListItem>
        );
      }
      return acc;
    }, []);

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="course-selection-dialog-title"
        open={true}
        PaperProps={{
          style: { minWidth: '600px' }
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Choose courses
        </DialogTitle>

        <DialogContent className={classes.dialogContentRoot}>
          <Typography style={{ marginTop: '10px' }}>Choose which courses will be used for Attendance Monitor</Typography>
          <Typography style={{ marginBottom: '10px' }}>{enabledCoursesCount} of {totalCoursesCount}.</Typography >
          <TextField
            style={{ marginTop: '10px' }}
            label={`Search for a course`}
            variant="outlined"
            fullWidth
            onKeyUp={this.setSearch}
          />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', paddingLeft: '16px' }}>
            <Checkbox checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />
            <Typography>Select All</Typography>
          </div>
          <List>{coursesList}</List>
        </DialogContent>

        <DialogActions style={{ marginTop: 10 }}>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  coursesFetchResponse,
  attendanceMonitorCoursesSave,
  attendanceMonitorCoursesSaveResponse,
  attendanceMonitorCoursesEditDone,
  attendanceMonitorCoursesChooserSetIsEnabled,
  attendanceMonitorChooserCoursesResponse,
  attendanceMonitorCoursesEdit
};

const mapStateToProps = state => {
  const courses = state.admin.attendanceMonitorCoursesChooser.courses;
  const attendanceMonitorCoursesEditor = state.admin.dialogs.attendanceMonitorCoursesEditor ? state.admin.dialogs.attendanceMonitorCoursesEditor : null;
  return { courses, attendanceMonitorCoursesEditor };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CourseSelectionDialog));
