import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Grid from '@material-ui/core/Grid'
import FancySelect from 'common/components/FancySelect'
import styles from 'common/components/style';
import DialogTitle from 'common/components/dialog/DialogTitle';
import DialogContent from 'common/components/dialog/DialogContent';
import DialogActions from 'common/components/dialog/DialogActions';

import {
   kioskFacilitiesCustomSetupEditDone,
  kioskFacilitiesCustomSetupApply,
  kioskWizardUpdateProperty
} from 'redux/kiosk/KioskActions';

import {
  kioskFacilityAttendanceOverridesTypes
} from "redux/constants"

class KioskFacilitiesCustomSetupDialog extends React.Component {
  handleClose = () => {
    if (this.props.kioskFacilitiesCustomSetupEditDone) {
      this.props.kioskFacilitiesCustomSetupEditDone();
    }
  };

  handleApply = () => {
    if (this.props.kioskFacilitiesCustomSetupApply) {
      this.props.kioskFacilitiesCustomSetupApply();
    }
  };

  handleSelectChange = (facilityId) => ({ propertyName, val }) => {
    const updatePayload = { facilityId, propertyName, val };
    this.props.kioskWizardUpdateProperty(updatePayload);
  };

  render() {
    if (!this.props.kioskFacilitiesCustomSetupEditor) return null;

    const { classes, locationChooser, facilities } = this.props;

    const facilitiesConfigurationList = locationChooser.facilitiesOverridesChooser.facilitiesOverrides.map(facilityOverride => {

      const facility = facilities.find(f => f.id === facilityOverride.facilityId);
      const selectedOverrideType = kioskFacilityAttendanceOverridesTypes.find(t => t.id === facilityOverride.overrideTypeId)
      return (
        <ListItem key={facilityOverride.facilityId}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography style={{ paddingTop: 15 }}>{facility.displayName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <FancySelect
                dispatchUpdate={this.handleSelectChange(facilityOverride.facilityId)}
                label=''
                propertyName='overrideTypeId'
                currentItem={selectedOverrideType}
                items={kioskFacilityAttendanceOverridesTypes}
                valAtr="id"
                nameAttr='displayName'
              />
            </Grid>
          </Grid>
        </ListItem>
      )
    });
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="location-selection-dialog-title"
        open={true}
        PaperProps={{
          style: { minWidth: '600px' }
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
        Customize entity settings
        </DialogTitle>

        <DialogContent className={classes.dialogContentRoot}>
          <List>{facilitiesConfigurationList}</List>
        </DialogContent>

        <DialogActions style={{ marginTop: 10 }}>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  kioskFacilitiesCustomSetupEditDone,
  kioskFacilitiesCustomSetupApply,
  kioskWizardUpdateProperty
};

const mapStateToProps = state => state.kiosk;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(KioskFacilitiesCustomSetupDialog));