import React from "react"
import ReactDOM from "react-dom"
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import {withStyles} from "@material-ui/core"
import styles from './style'


export class FancySelectClass extends React.Component {
  state = {
    labelWidth: 0,
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    })
  }

  render () {
    const {disabled=false, dispatchUpdate, classes, label, propertyName, currentItem, currentVal, items, valAtr='id', nameAtr='displayName'} = this.props

    const currentValue = (typeof this.props.currentVal !== 'undefined') ? currentVal : currentItem[valAtr]

    const display = (typeof nameAtr === 'function')? nameAtr : (item)=>item[nameAtr]

    const simpleItems = valAtr === ''

    return <FormControl style={{marginTop:16}} variant="outlined" fullWidth>
      <InputLabel
        htmlFor={`${propertyName}-selector`}
        ref={ref => {
          this.InputLabelRef = ref
        }}
      >{label}</InputLabel>
      <Select
        disabled={disabled}
        value={currentValue}
        onChange={(e) => {
          dispatchUpdate({propertyName, val:e.target.value})
        }}
        input={<OutlinedInput
          disabled={disabled}
          className={classes.selectField}
          labelWidth={this.state.labelWidth}
          name={propertyName}
          id={`${propertyName}-selector`}
          style={{textTransform:'capitalize'}}
        />}
        className={classes.selectEmpty}
      >{simpleItems?
        items.map(item => <MenuItem key={item} value={item} style={{textTransform:'capitalize'}}>{item}</MenuItem>)
        : items.map(item => <MenuItem key={item[valAtr]} value={item[valAtr]} style={{textTransform:'capitalize'}}>{display(item)}</MenuItem>)
      }
      </Select>
    </FormControl>
  }
}

FancySelectClass.propTypes = {
  dispatchUpdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  currentItem: PropTypes.any,
  currentVal: PropTypes.any,
  items: PropTypes.array.isRequired,
  valAtr: PropTypes.string,
  nameAtr: PropTypes.any,
}

export const reducePayload = (obj) => {return {[obj.propertyName]:obj.val} }

export const FancySelect = withStyles(styles, { withTheme: true })(FancySelectClass)

export default FancySelect