/* globals document */
import React from 'react'
import connect from 'react-redux/es/connect/connect'
import {Paper, TextField, withStyles} from '@material-ui/core'
import Typography from '@material-ui/core/es/Typography/Typography'
import CurrentFacility from 'apps/admin/components/CurrentFacility'
import MessageIcon from '@material-ui/icons/Message'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/es/TableBody/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  snackMessageInfo
} from 'redux/RootActions'
import {
  messagingFetch,
  messagingEditUpdateContent,
  messagingEditUpdateContentStyle,
  messagingEditChooseContentMessage,
  messagingEditSaveContent,
  searchFromUrl, messagingEditPreviewContent
} from 'redux/admin/Actions'
import FancySelect, {reducePayload} from 'common/components/FancySelect'
import styles from './style'
import {SEARCH_CONTEXT_MESSAGING_PAGE, URL_MESSAGING} from 'redux/constants'
import {findOneBy} from 'redux/utils'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AppBar from '@material-ui/core/AppBar'

const fontOptions = [
  {
    displayName:'Arial',
    val:'Arial'
  },
  {
    displayName:'Bookman',
    val:'Bookman'
  },
  {
    displayName:'Courier New',
    val:'Courier New'
  },
  {
    displayName:'Roboto',
    val:'Roboto'
  },
  {
    displayName:'Times New Roman',
    val:'Times New Roman'
  },
]



class Messaging extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex:0
    }
  }
  componentDidMount() {
    this.props.searchFromUrl()
  }

  switchTab = (event, tabIndex) => {
    if(tabIndex === 1) {
      this.props.messagingEditPreviewContent()
    }
    this.setState({ tabIndex });
  }

  render() {
    if(!this.props.contentRoles) return null
    const {classes, contentRoles, role, messageContent, isDirty, messagingEditUpdateContent, messagingEditChooseContentMessage, messagingEditSaveContent, messagingEditUpdateContentStyle, preview, snackMessageInfo} = this.props
    const docProperties = role.jsbDocumentation.properties? role.jsbDocumentation.properties : {}
    const docPropertiesKeys = Object.keys(docProperties)
    const currentFont = (messageContent.contentStyle && messageContent.contentStyle.fontFamily)? findOneBy(fontOptions, 'val', messageContent.contentStyle.fontFamily) : fontOptions[0]
    const {tabIndex} = this.state
    docPropertiesKeys.sort()

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <CurrentFacility />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <FancySelect
                label='Edit Messaging'
                items={contentRoles}
                currentItem={role}
                valAtr={'id'}
                propertyName={'contentRoleId'}
                nameAtr={'displayName'}
                dispatchUpdate={messagingEditChooseContentMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <FancySelect
                label='Font'
                items={fontOptions}
                currentItem={currentFont}
                valAtr={'val'}
                propertyName={'fontFamily'}
                dispatchUpdate={(e)=>messagingEditUpdateContentStyle({
                  messagingContentId: messageContent.id,
                  contentStyle:reducePayload(e)
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <AppBar position="static" color="default">
                <Tabs value={tabIndex} onChange={this.switchTab}>
                  <Tab label="Edit" />
                  <Tab label="Preview" />
                </Tabs>
              </AppBar>
                {tabIndex === 0 && <Paper square style={{padding:10}}>
                  <TextField
                    variant="outlined"
                    style={{background:'white'}}
                    multiline
                    fullWidth
                    rows={6}
                    rowsMax="40"
                    value={messageContent.content}
                    onChange={(e)=>{
                      messagingEditUpdateContent({
                        messagingContentId: messageContent.id,
                        messageContent: e.target.value
                      })
                    }}
                  />
                </Paper>}
                {tabIndex === 1 && <Paper square style={{padding:25, fontFamily:currentFont.val, whiteSpace:'pre'}}>
                  {preview}
                </Paper>}


            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={16} justify='flex-end' direction='row'>
                <Grid item >
                  <Button variant="contained" color="primary" disabled={!isDirty} onClick={messagingEditSaveContent}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper spacing={16} style={{padding:10}}>
            <Typography  component="h3">
            Details
            </Typography>
            <Typography className={classes.legendParagraph}>
              {role.description}
            </Typography>
            {(docPropertiesKeys.length === 0) ?
              <Typography  style={{fontStyle:'italic'}}>
                This is a static document.
              </Typography>
              :
              <div>
                <Typography  component="h3">
                  Available Values
                </Typography>
                <Typography className={classes.legendParagraph}>
                  Below are the variables you can put into the template.
                  They can typed out or copied and pasted where ever you like. These templates allow input of raw HTML.
                  How it looks will depend on where the template is used. (In print, email, etc)
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Variable</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {docPropertiesKeys.map((key) => {
                      return <TableRow key={key}>
                        <TableCell style={{userSelect: 'all', cursor:'pointer'}} title='Click to select' onClick={()=>{
                          document.execCommand("copy")
                          snackMessageInfo('Copied {{' + key + '}} to clipboard.')
                        }}>{'{{' + key + '}}'}</TableCell><TableCell>{docProperties[key]}</TableCell></TableRow>
                    })}
                  </TableBody>
                </Table>
              </div>
            }
            <hr/>
            <Typography className={classes.legendParagraph}>
              These templates use <a target='_blank' rel='noopener noreferrer' href='https://handlebarsjs.com/'>{'{{handlebars}}'}</a> for their logic.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={16}>

          </Grid>
        </Grid>
      </Grid>
    )
  }
}

/*
dispatchUpdate: PropTypes.func,
  label: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  currentItem: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
  valAtr: PropTypes.string,
  nameAtr: PropTypes.string,
 */

const mapStateToProps = state => {
  if(!state.admin.dialogs.messageContentEditor) return {messageContentEditor:false}
  const contentRoles = state.admin.contentRoles? state.admin.contentRoles.reduce((acc, contentRole) => {
    if(contentRole.isVisibleInAdmin && contentRole.isEnabled) {
      acc.push(contentRole)
    }
    return acc
  }, []) : false

  return Object.assign(
    {},
    state.admin.dialogs.messageContentEditor,
    {contentRoles}
  )
}

const mapDispatchToProps = {
  searchFromUrl,
  messagingFetch,
  messagingEditPreviewContent,
  messagingEditUpdateContent,
  messagingEditUpdateContentStyle,
  messagingEditChooseContentMessage,
  messagingEditSaveContent,
  snackMessageInfo
}

export const MessagingPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Messaging))

export const messagingPage = {
  searchContext: SEARCH_CONTEXT_MESSAGING_PAGE,
  searchOnPathChange:true,
  icon: MessageIcon,
  component: MessagingPage,
  name: 'Messaging',
  displayName: 'Messaging',
  to: URL_MESSAGING
}

export default MessagingPage