import React from 'react'
import connect from 'react-redux/es/connect/connect'
import {withStyles} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {
  SEARCH_CONTEXT_AT_USER_LOCATOR_PAGE,
  URL_LOCATE_STUDENTS
} from 'redux/constants'

import CurrentFacility from 'apps/admin/components/CurrentFacility'
import StudentPicker from './StudentPicker'
import StudentLocatorInfo from './StudentLocatorInfo'
import LocatorSchedule from './LocatorSchedule'
import QueueInfo from './QueueInfo'
import PersonPinIcon from '@material-ui/icons/PersonPin';

import styles from './style'

class StudentLocator extends React.Component {

  render() {
    return (
      <div>
        <Grid container spacing={24}>    
          <Grid item sm={12} xl={9}>
            <CurrentFacility />
            <StudentPicker />
            <StudentLocatorInfo />
            <QueueInfo />
            <LocatorSchedule />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {

}

export const StudentLocatorPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(StudentLocator))

export const studentLocatorPage = {
  searchContext: SEARCH_CONTEXT_AT_USER_LOCATOR_PAGE,
  searchOnPathChange:true,
  icon: PersonPinIcon,
  component: StudentLocatorPage,
  name: 'Student Locator',
  displayName: 'Student Locator',
  to: URL_LOCATE_STUDENTS
}

export default StudentLocatorPage