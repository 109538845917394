import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Grid from '@material-ui/core/Grid'
import FancySelect from 'common/components/FancySelect'
import styles from 'common/components/style';
import DialogTitle from 'common/components/dialog/DialogTitle';
import DialogContent from 'common/components/dialog/DialogContent';
import DialogActions from 'common/components/dialog/DialogActions';

import {
  kioskFacilitiesCustomSetupEditDoneFromActiveKiosk,
  kioskFacilitiesCustomSetupUpdatePropertyFromActiveKiosk,
  kioskFacilitiesCustomSetupSaveFromActiveKiosk
} from 'redux/kiosk/KioskActions';

import { kioskFacilityAttendanceOverridesTypes } from "redux/constants"

class KioskFacilitiesCustomSetupFromActiveKioskDialog extends React.Component {
  handleClose = () => {
    if (this.props.kioskFacilitiesCustomSetupEditDoneFromActiveKiosk) {
      this.props.kioskFacilitiesCustomSetupEditDoneFromActiveKiosk();
    }
  };

  handleSave = () => {
    if (this.props.kioskFacilitiesCustomSetupSaveFromActiveKiosk) {
      this.props.kioskFacilitiesCustomSetupSaveFromActiveKiosk(
        this.props.kioskFacilitiesCustomSetupFromActiveKioskEditor.facilitiesOverrides);
    }
  };

  handleSelectChange = (facilityId) => ({ propertyName, val }) => {
    const updatePayload = { facilityId, propertyName, val };
    this.props.kioskFacilitiesCustomSetupUpdatePropertyFromActiveKiosk(updatePayload);
  };

  render() {
    if (!this.props.kioskFacilitiesCustomSetupFromActiveKioskEditor) return null;

    const { classes, kioskFacilitiesCustomSetupFromActiveKioskEditor, allFacilities } = this.props;

    const facilitiesConfigurationList = kioskFacilitiesCustomSetupFromActiveKioskEditor
      .facilitiesOverrides.map(facilityOverride => {
        const facility = allFacilities.find(f => f.id === facilityOverride.facilityId);
        const selectedOverrideType = kioskFacilityAttendanceOverridesTypes.find(t => t.id === facilityOverride.overrideTypeId)
        return (
          <ListItem key={facilityOverride.facilityId}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography style={{ paddingTop: 15 }}>{facility.displayName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <FancySelect
                  dispatchUpdate={this.handleSelectChange(facilityOverride.facilityId)}
                  label=''
                  propertyName='overrideTypeId'
                  currentItem={selectedOverrideType}
                  items={kioskFacilityAttendanceOverridesTypes}
                  valAtr="id"
                  nameAttr='displayName'
                />
              </Grid>
            </Grid>
          </ListItem>
        )
      });
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="location-selection-dialog-title"
        open={true}
        PaperProps={{
          style: { minWidth: '600px' }
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Customize entity settings
        </DialogTitle>

        <DialogContent className={classes.dialogContentRoot}>
          <List>{facilitiesConfigurationList}</List>
        </DialogContent>

        <DialogActions style={{ marginTop: 10 }}>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  kioskFacilitiesCustomSetupEditDoneFromActiveKiosk,
  kioskFacilitiesCustomSetupUpdatePropertyFromActiveKiosk,
  kioskFacilitiesCustomSetupSaveFromActiveKiosk
};

const mapStateToProps = state => state.kiosk;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(KioskFacilitiesCustomSetupFromActiveKioskDialog));