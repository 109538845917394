import React from "react"
import connect from "react-redux/es/connect/connect"
import {withStyles} from "@material-ui/core"
import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/es/Typography/Typography"
import ToggleIconButton from 'common/components/ToggleIconButton'
import {InlineDatePicker, MuiPickersUtilsProvider} from "material-ui-pickers"
import SyncIcon from '@material-ui/icons/Sync'
import SchoolIcon from '@material-ui/icons/School'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import {seconds} from '@relativity/js-util'
import styles from '../style'
import {
  FORMAT_YMD,
  negativeAttendanceTypes
} from 'redux/constants'

import {
  updateGeneralSetting,
  dangerousUpdateClearGeneralPropertyCookies,
  dangerousUpdateGeneralProperty,
  dangerousPirateDeleteAllAttendanceFromDB,
  dangerousPirateTriggerDeleteAllAttendanceFromQ,
  dangerousPirateUndeleteSyncedAndMakeSyncNeeded,
  dangerousPirateGeneratePositiveAttendance,
  dangerousPirateFetchDbMockdatasets,
  dangerousPirateFetchDbMockdataset,
  dangerousPirateExportDbMockdataset,
  pirateDeleteNegativeAttendanceFromDB,
  pirateMarkNegativeAttendanceForDeletionFromQ,
  dangerousPirateDeletePositiveAttendanceFromDB,
  pirateAddKiosksToAllLocations,
  pirateGenerateNegativeAttendance,
  pirateUpdateGeneralProperty
} from "redux/admin/Actions"

import Divider from "@material-ui/core/Divider";
import FullTextField from "common/components/FullTextField";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import MomentUtils from "@date-io/moment";
import FancySelect from "common/components/FancySelect";
import Button from "@material-ui/core/Button";
import {jsPretty} from "redux/utils";
import DownloadLink from "react-download-link";
import Dropzone from "apps/admin/components/Dropzone";

/*
const negativeAttendanceCurves = [
  //'inverse-square',
  'linear',
]
*/

class NegativeAttendanceSetting extends React.PureComponent {
  render () {
    const {
      atType,
      facilities,
      negativeAttendance,
      pirateUpdateGeneralProperty
    } = this.props
    return <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} sm={2} style={{paddingTop:'32px'}}>
          {atType.displayName}
        </Grid>
        <Grid item xs={4} sm={1}>
          <FullTextField
            label='Start'
            autoComplete={'off'}
            type={'number'}
            value={negativeAttendance[atType.name].start}
            dispatchUpdate={pirateUpdateGeneralProperty}
            propertyName={`negativeAttendance.${atType.name}.start`}
          />
        </Grid>
        {/*<Grid item xs={4} sm={1}>
          <FullTextField
            label='Max'
            autoComplete={'off'}
            type={'number'}
            value={negativeAttendance[atType.name].max}
            dispatchUpdate={pirateUpdateGeneralProperty}
            propertyName={`negativeAttendance.${atType.name}.max`}
          />
        </Grid>*/}
        <Grid item xs={4} sm={1}>
          <FullTextField
            label='End'
            autoComplete={'off'}
            type={'number'}
            value={negativeAttendance[atType.name].end}
            dispatchUpdate={pirateUpdateGeneralProperty}
            propertyName={`negativeAttendance.${atType.name}.end`}
          />
        </Grid>
        {/*<Grid item xs={12} sm={2}>
          <FancySelect
            label='Curve'
            valAtr=''
            currentVal={negativeAttendance[atType.name].curve}
            items={negativeAttendanceCurves}
            propertyName={`negativeAttendance.${atType.name}.curve`}
            dispatchUpdate={pirateUpdateGeneralProperty}
          />
        </Grid>*/}
        <Grid item xs={12} sm={1} style={{paddingTop:'16px', textAlign:'right'}}>
          <ToggleIconButton
            isOn={negativeAttendance[atType.name].useAtUserIdCsv}
            onIcon={SchoolIcon}
            offIcon={LocationCityIcon}
            propertyName={`negativeAttendance.${atType.name}.useAtUserIdCsv`}
            dispatchUpdate={pirateUpdateGeneralProperty}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          {negativeAttendance[atType.name].useAtUserIdCsv ?
            <FullTextField
              label='AtUser id csv'
              title='this can be empty or like `1,503,1700,36`'
              autoComplete={'off'}
              type={'text'}
              value={negativeAttendance[atType.name].atUserIdCsv}
              dispatchUpdate={pirateUpdateGeneralProperty}
              propertyName={`negativeAttendance.${atType.name}.atUserIdCsv`}
            />
            :
            <FancySelect
              label='Facility'
              title='this can be empty or like `1,503,1700,36`'
              items={facilities}
              currentVal={negativeAttendance[atType.name].facilityId? negativeAttendance[atType.name].facilityId : false}
              dispatchUpdate={pirateUpdateGeneralProperty}
              propertyName={`negativeAttendance.${atType.name}.facilityId`}
            />
          }
        </Grid>
      </Grid>
    </Grid>
  }
}

class PiratesAndDragonsClass extends React.Component {
  parseNegativeAttendanceConfig = (files)=> {
    const reader = new FileReader()
    const file = files[0]

    reader.onload = (e) => {
      const config = JSON.parse(e.target.result)
      this.props.pirateUpdateGeneralProperty(
        'negativeAttendance',
        Object.assign(this.props.pirate.negativeAttendance, config)
      )
    }
    reader.readAsText(file)
  }
  render() {
    const {
      byId,
      classes,
      dangerousUpdateClearGeneralPropertyCookies,
      dangerousUpdateGeneralProperty,
      dangerousPirateDeleteAllAttendanceFromDB,
      dangerousPirateDeletePositiveAttendanceFromDB,
      dangerousPirateGeneratePositiveAttendance,
      enableAtRecordManipulation = false,
      facilities,
      pirate,
      piratesAndDragonsEnabled,
      pirateAddKiosksToAllLocations,
      pirateDeleteNegativeAttendanceFromDB,
      pirateDeletePositiveAttendanceFromDB = false,
      pirateDeleteAllAttendanceFromDB = false,
      pirateMarkNegativeAttendanceForDeletionFromQ,
      pirateGeneratePositiveAttendance = false,
      pirateGeneratePositiveAttendanceAtUserIdCsv,
      pirateGeneratePositiveAttendanceYMDStart,
      pirateGeneratePositiveAttendanceYMDEnd,
      pirateGenerateNegativeAttendance,
      pirateUpdateGeneralProperty,
      pretendSkywardConnected = false,
      settings: {
        licensingJson,
      },
      updateGeneralSetting,
    } = this.props
    return piratesAndDragonsEnabled ? (
      <div className={classes.root}>
        <Typography>This tab will not be available in the production version of the app.</Typography>
        <Typography>Clear in-browser stuff (page reload required)<ToggleIconButton
          title='Clear in-browser settings: home page, fake connection, AtRecord manipulation settings'
          isOn={pretendSkywardConnected}
          onIcon={OfflineBoltIcon}
          onClick={dangerousUpdateClearGeneralPropertyCookies}
        /></Typography>
        <h3>Enable Positive attendance on server. <ToggleIconButton
          onTitle='Positive Attendance Enabled'
          offTitle='Positive Attendance Disabled'
          isOn={!!licensingJson.positiveAttendanceIsEnabled} onClick={()=>{updateGeneralSetting(
          {
            propertyName:'licensingJson.positiveAttendanceIsEnabled',
            val: !licensingJson.positiveAttendanceIsEnabled
          }
        )}}/></h3>
        <h3>Enable PPKs. <ToggleIconButton
          onTitle='PPKs Enabled'
          offTitle='PPKs Disabled'
          isOn={!!licensingJson.ppkIsEnabled} onClick={()=>{updateGeneralSetting(
          {
            propertyName:'licensingJson.ppkIsEnabled',
            val: !licensingJson.ppkIsEnabled
          }
        )}}/></h3>
        <Divider/>
        <Grid container style={{marginBottom:20}}>
          <Grid item xs={12} style={{marginBottom:20}}>
            <h3>Demo settings in browser</h3>
          </Grid>
          <Grid item xs={4}>
            <Typography>Pretend Skyward-Q Connection is ok <ToggleIconButton
              title='Do not set `checked` with live Q connection!)'
              isOn={!!pretendSkywardConnected} onClick={()=>{dangerousUpdateGeneralProperty(
              {
                propertyName:'pretendSkywardConnected',
                val: !pretendSkywardConnected
              }
            )}}/></Typography>
          </Grid>
        </Grid>
        <Divider/>
        <Grid container>
          <Grid item xs={12}>
            <h3>AtRecord Manipulation <ToggleIconButton
              title='Enable AtRecord Manipulation'
              isOn={!!enableAtRecordManipulation} onClick={()=>{dangerousUpdateGeneralProperty(
              {
                propertyName:'enableAtRecordManipulation',
                val: !enableAtRecordManipulation
              }
            )}}/></h3>
          </Grid>
          {enableAtRecordManipulation?
            <Grid container>
              <Grid item xs={12}>
                It's possible to seriously ruin your day with these buttons. There are no confirmation dialogs so be sure you know what you're doing!
              </Grid>
              <Grid item xs={12}>
                <h4>Negative Attendance</h4>
                <Typography>Create/upload/use profiles for generating negative attendance</Typography>
              </Grid>
              <Grid item xs={12}>
                <Dropzone onFilesAdded={this.parseNegativeAttendanceConfig} text={'Upload config'} height={55}/>
              </Grid>
              <Grid item xs={3} sm={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <InlineDatePicker
                    style={{marginTop:16}}
                    label={'First Date'}
                    variant={'outlined'}
                    format={'YYYY-MM-DD'}
                    value={pirate.negativeAttendance.ymdStart}
                    onChange={(date)=>{
                      pirateUpdateGeneralProperty('negativeAttendance.ymdStart', date.format(FORMAT_YMD))
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3} sm={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <InlineDatePicker
                    style={{marginTop:16}}
                    label={'Last Date'}
                    variant={'outlined'}
                    format={'YYYY-MM-DD'}
                    value={pirate.negativeAttendance.ymdEnd}
                    onChange={(date)=>{
                      pirateUpdateGeneralProperty('negativeAttendance.ymdEnd', date.format(FORMAT_YMD))
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {negativeAttendanceTypes.map(({id}) => {
                return <NegativeAttendanceSetting
                  key={id}
                  atType={byId.atType(id)}
                  facilities={facilities}
                  negativeAttendance={pirate.negativeAttendance}
                  pirateUpdateGeneralProperty={pirateUpdateGeneralProperty}
                />
              })}

              <Grid item xs={2}>
                <Button>
                  <DownloadLink
                    label='Download Configuration'
                    style={{textDecoration:'none'}}
                    exportFile={()=>jsPretty(pirate.negativeAttendance)}
                    filename={`negative attendance generator profile ${seconds()}.json` }></DownloadLink>
                </Button>
              </Grid>
              <Grid item xs={2}>
                  <ToggleIconButton
                    onTitle='Trigger generation'
                    offTitle='Probably (maybe) running generation. Hang on to your hats!'
                    isOn={!pirate.generatingNegativeAttendance}
                    onIcon={OfflineBoltIcon}
                    offIcon={SyncIcon}
                    onClick={pirateGenerateNegativeAttendance}
                  />
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <h4>Positive Attendance</h4>
                <Typography>This will add a positive attendance record to every schedule period between the two dates, without a reporting record</Typography>
              </Grid>
              <Grid item xs={3} sm={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <InlineDatePicker
                    style={{marginTop:16}}
                    label={'First Date'}
                    variant={'outlined'}
                    format={'YYYY-MM-DD'}
                    value={pirateGeneratePositiveAttendanceYMDStart}
                    onChange={(date)=>{
                      dangerousUpdateGeneralProperty('pirateGeneratePositiveAttendanceYMDStart', date.format(FORMAT_YMD))
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3} sm={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <InlineDatePicker
                    style={{marginTop:16}}
                    label={'Last Date'}
                    variant={'outlined'}
                    format={'YYYY-MM-DD'}
                    value={pirateGeneratePositiveAttendanceYMDEnd}
                    onChange={(date)=>{
                      dangerousUpdateGeneralProperty('pirateGeneratePositiveAttendanceYMDEnd', date.format(FORMAT_YMD))
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FullTextField
                  label='AtUser id csv'
                  title='this can be empty or like `1,503,1700,36`'
                  autoComplete={'off'}
                  type={'text'}
                  value={pirateGeneratePositiveAttendanceAtUserIdCsv}
                  dispatchUpdate={dangerousUpdateGeneralProperty}
                  propertyName={'pirateGeneratePositiveAttendanceAtUserIdCsv'}
                />
              </Grid>
              <Grid item xs={3}>
                <ToggleIconButton
                  style={{marginTop:16}}
                  onTitle='Trigger generation'
                  offTitle='Probably (maybe) running generation. Hang on to your hats!'
                  isOn={!pirateGeneratePositiveAttendance}
                  onIcon={OfflineBoltIcon}
                  offIcon={SyncIcon}
                  onClick={dangerousPirateGeneratePositiveAttendance}/>
              </Grid>
              <Grid item xs={12}>
                <h4>Locations</h4>
              </Grid>
              <Grid item xs={12}>
                <Typography>Add a kiosk to every location<ToggleIconButton
                  onTitle='Add a kiosk to every location in the db'
                  offTitle='Adding. This could take a minute or two'
                  isOn={!pirate.addKiosksToAllLocations}
                  onIcon={OfflineBoltIcon}
                  offIcon={SyncIcon}
                  onClick={pirateAddKiosksToAllLocations}/></Typography>
              </Grid>
              <Grid item xs={12}>
                <h4>Deleting things!</h4>
                <Typography>Please note that in Pirate land, student AR counts are only updated when creating attendance, not when deleting.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Hard-Delete Negative Attendance from DB<ToggleIconButton
                  onTitle='Delete all Negative Attendance from the db'
                  offTitle='Deleting. It`s not so bad'
                  isOn={!pirate.deleteNegativeAttendanceFromDB}
                  onIcon={OfflineBoltIcon}
                  offIcon={SyncIcon}
                  onClick={pirateDeleteNegativeAttendanceFromDB}/></Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Hard-Delete Positive Attendance from DB<ToggleIconButton
                  onTitle='Delete all Positive Attendance from the db'
                  offTitle='Deleting. It`s not so bad'
                  isOn={!pirateDeletePositiveAttendanceFromDB}
                  onIcon={OfflineBoltIcon}
                  offIcon={SyncIcon}
                  onClick={dangerousPirateDeletePositiveAttendanceFromDB}/></Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Hard-Delete <b>ALL Attendance</b> from DB<ToggleIconButton
                  onTitle='Delete EVERY scrap of attendance from the db'
                  offTitle='A hard reset huh? Yowch.'
                  isOn={!pirateDeleteAllAttendanceFromDB}
                  onIcon={OfflineBoltIcon}
                  offIcon={SyncIcon}
                  onClick={dangerousPirateDeleteAllAttendanceFromDB}/></Typography>
              </Grid>
              <Grid item xs={12}>
                <h4>Deleting things <i>from Q!</i></h4>
                <Typography>The following is a way of mass deleting attendance from Q. It's down here at the bottom because there is no undo!</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Hard-Delete Negative Attendance from Q<ToggleIconButton
                  onTitle='Delete all Negative Attendance from the db'
                  offTitle='Deleting. It`s not so bad'
                  isOn={!pirate.markNegativeAttendanceForDeletionFromQ}
                  onIcon={OfflineBoltIcon}
                  offIcon={SyncIcon}
                  onClick={pirateMarkNegativeAttendanceForDeletionFromQ}/></Typography>
                <Typography>This will mark linked records as "not-reporting, sync-needed" which should trigger a delete.</Typography>
              </Grid>
            </Grid>
            : null
          }
        </Grid>
      </div>
    ) : null
  }
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {
  updateGeneralSetting,
  dangerousUpdateClearGeneralPropertyCookies,
  dangerousUpdateGeneralProperty,
  dangerousPirateDeleteAllAttendanceFromDB,
  dangerousPirateDeletePositiveAttendanceFromDB,
  dangerousPirateTriggerDeleteAllAttendanceFromQ,
  dangerousPirateUndeleteSyncedAndMakeSyncNeeded,
  dangerousPirateGeneratePositiveAttendance,
  dangerousPirateFetchDbMockdatasets,
  dangerousPirateFetchDbMockdataset,
  dangerousPirateExportDbMockdataset,
  pirateAddKiosksToAllLocations,
  pirateDeleteNegativeAttendanceFromDB,
  pirateMarkNegativeAttendanceForDeletionFromQ,
  pirateUpdateGeneralProperty,
  pirateGenerateNegativeAttendance,
}

export const PiratesAndDragons = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(PiratesAndDragonsClass))


export default PiratesAndDragons

/**

 export const DANGEROUS_PIRATE_ACTION_TRIGGER_DELETE_ALL_SYNCED_ATTENDANCE_FROM_Q = 'ADMIN_DANGEROUS_PIRATE_ACTION_TRIGGER_DELETE_ALL_ATTENDANCE_FROM_Q'
 export const dangerousPirateTriggerDeleteAllAttendanceFromQ = () => {
  return action(DANGEROUS_PIRATE_ACTION_TRIGGER_DELETE_ALL_ATTENDANCE_FROM_Q, {})
}

 export const DANGEROUS_PIRATE_ACTION_UNDELETE_SYNCED_ATTENDANCE_AND_MAKE_SYNC_NEEDED = 'ADMIN_DANGEROUS_PIRATE_ACTION_UNDELETE_SYNCED_ATTENDANCE_AND_MAKE_SYNC_NEEDED'
 export const dangerousPirateUndeleteSyncedAndMakeSyncNeeded = () => {
  return action(DANGEROUS_PIRATE_ACTION_UNDELETE_SYNCED_ATTENDANCE_AND_MAKE_SYNC_NEEDED, {})
}


 export const DANGEROUS_PIRATE_ACTION_FETCH_DB_MOCKDATASETS = 'ADMIN_DANGEROUS_PIRATE_ACTION_FETCH_DB_MOCKDATA_SETS'
 export const dangerousPirateFetchDbMockdatasets = (payload) => {
  return action(DANGEROUS_PIRATE_ACTION_FETCH_DB_MOCKDATASETS, payload)
}

 export const DANGEROUS_PIRATE_ACTION_USE_DB_MOCKDATASET = 'ADMIN_DANGEROUS_PIRATE_ACTION_USE_DB_MOCKDATASET'
 export const dangerousPirateFetchDbMockdataset = (payload) => {
  return action(DANGEROUS_PIRATE_ACTION_USE_DB_MOCKDATASET, payload)
}

 export const DANGEROUS_PIRATE_ACTION_EXPORT_DB_MOCKDATASET = 'ADMIN_DANGEROUS_PIRATE_ACTION_EXPORT_DB_MOCKDATASET'
 export const dangerousPirateExportDbMockdataset = (payload) => {
  return action(DANGEROUS_PIRATE_ACTION_EXPORT_DB_MOCKDATASET, payload)
}

 */


