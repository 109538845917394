import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Lock from '@material-ui/icons/Lock';
import Wifi from '@material-ui/icons/Wifi'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {seconds} from '@relativity/js-util'

import Cookies from 'js-cookie'
import KioskFacilitiesCustomSetupFromActiveKioskDialog from "apps/kiosk/components/KioskFacilitiesCustomSetupFromActiveKioskDialog";

import {
  closeMenuDrawer,
  lockConfiguration,
  unlockConfiguration,
} from "../../../redux/actions";

import {ACTIVITY_KIOSK_DISABLED, AT_TYPE_ID_BELL_RULES, atr, kioskCheckinFlowModes,
  OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES,
  OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE,
  CUSTOMIZE_FACILITIES_TITLE} from '../../../redux/constants'
import {
  ATR_ACCEPT_KEYBOARD_INPUT,
  ATR_ATTENDANCE_TYPE_ID,
  ATR_CAN_PRINT, ATR_CHECKIN_FLOW,
  ATR_ENABLE_EMAIL_SLIP,
  ATR_ENABLE_CAMERA,
  //ATR_ENABLE_GPS,
  ATR_ENABLE_KEYPAD,
  ATR_IS_LOCKED, ATR_IS_OUT_OF_BUILDING, ATR_LEASE_EXPIRES,
} from "common/kioskConstants";
import {createSettings} from "common/kioskSettings";
import KioskSettingListItem from "common/components/KioskSettingListItem";
import {
  cameraUpdateSetting,
  lockKiosk,
  setAttendanceType,
  toggleSetting,
  unlockKiosk,
  updateSetting
} from 'redux/kiosk/KioskActions'
import {findOneBy, getAtTypesByFacilityId,  getAtReasonsByFacilityId,  getFacilityOverrideSelectedValues} from "redux/utils";
import STASwirl from "apps/admin/icons/STASwirl";
import FancySelect from "common/components/FancySelect";
import {barcodeTypeList} from "redux/constants";
import {deleteKiosk, kioskFacilitiesCustomSetupEditFromActiveKiosk, kioskFacilitiesCustomSetupSaveFromActiveKiosk} from "redux/kiosk/KioskActions";

const styles = {
  list: {
    width: 280,
  },
  fullList: {
    width: 'auto',
  },
  facilityLabel: {
    fontWeight: '500'
  },
  facilityLabelDisabled: {
    color: 'gray'
  },
};

class MenuDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.set = props.set;
  }

  handleFacilitySetupChange = (facilityId) => ({ propertyName, val }) => {
    const facilitiesOverrides = this.props.customAttendanceConfiguration.facilitiesOverrides.map(facilityOverride => {
      if (facilityOverride.facilityId === facilityId) {
        return { ...facilityOverride, [propertyName]: val, };
      }
      return facilityOverride;
    });
    this.props.kioskFacilitiesCustomSetupSaveFromActiveKiosk(facilitiesOverrides);
  };

  render() {
    let { appVersion, atType } = this.props
    const {
      id,
      cameraConfiguration,
      cameraDeviceList,
      cameraUpdateSetting,
      classes,
      isLocked,
      isDeletable,
      menuDrawerOpen,
      location,
      closeMenuDrawer,
      unlockConfiguration, //used when the kiosk is 'new' might be replaced with a wizard, or default to a login display
      capabilities,
      currentActivity,
      deleteKiosk,
      setAttendanceType,
      toggleSetting,
      unlockKiosk,
      lockKiosk,
      atTypes,
      leaseExpires,
      updateSetting,
      customAttendanceConfiguration,
      allFacilities,
      allAtTypes,
      allAtReasons,
      kioskFacilitiesCustomSetupEditFromActiveKiosk,
      positiveAttendanceIsEnabled
    } = this.props;

    const menuTitle = (id)? `Kiosk ${id} - ${location.displayName}` : 'New Kiosk'
    const atTypesFiltered = atTypes.reduce((acc, atType)=>{
      if(atType.isKioskMode) {
        acc.push(atType)
      }
      return acc
    },[])

    if(!atType) {
      console.error("This kiosk does not have an atType. A developer must investigate")
      atType = findOneBy(atTypesFiltered, 'id', AT_TYPE_ID_BELL_RULES)
    }

    const menuItems = createSettings(
      [
        {
          name:  ATR_IS_LOCKED,
          clickOn:(e) => {
            e.stopPropagation();
            if(isLocked) {
              if(!Cookies.get('kioskId') || !Cookies.get('kioskSecret')) {
                unlockConfiguration()
              } else {
                unlockKiosk()
              }
            } else {
              lockKiosk()
            }},
          isOn: isLocked,
        },
        {
          name: ATR_LEASE_EXPIRES,
          clickOn: () => toggleSetting(ATR_LEASE_EXPIRES),
          isDisabled: isLocked,
          isOn: (leaseExpires === 0 || leaseExpires > seconds()),
          textProvider: leaseExpires
        },
        {
          name: ATR_CAN_PRINT,
          clickOn:() => toggleSetting(ATR_CAN_PRINT),
          isDisabled: isLocked,
          isOn: capabilities.canPrint.val,
          changeable: capabilities.canPrint.changeable,
        },
        {
          name: ATR_ENABLE_EMAIL_SLIP,
          clickOn:() => toggleSetting(ATR_ENABLE_EMAIL_SLIP),
          isDisabled: isLocked,
          isOn: capabilities.enableEmailSlip.val,
          changeable: capabilities.enableEmailSlip.changeable,
        },
        {
          name: ATR_ENABLE_KEYPAD,
          clickOn:() => toggleSetting(ATR_ENABLE_KEYPAD),
          isDisabled: isLocked,
          isOn: capabilities.enableKeyPad.val,
          changeable: capabilities.enableKeyPad.changeable,
        },
        {
          name: ATR_ACCEPT_KEYBOARD_INPUT,
          clickOn:() => toggleSetting(atr.acceptKeyboardInput),
          isDisabled: isLocked,
          isOn: capabilities.acceptKeyboardInput.val,
          changeable: capabilities.acceptKeyboardInput.changeable,
        },
        {
          name: ATR_ATTENDANCE_TYPE_ID,
          val: capabilities.atTypeId.val,
          textProvider: atType,
          possibleValues: atTypesFiltered,
          clickOn: setAttendanceType,
          isDisabled: isLocked,
          changeable: capabilities.atTypeId.changeable,
        },
        {
          name: ATR_IS_OUT_OF_BUILDING,
          val: capabilities.isOutOfBuilding.val,
          textProvider: atType,
          possibleValues: atTypesFiltered,
          clickOn: ()=>toggleSetting(ATR_IS_OUT_OF_BUILDING),
          isDisabled: isLocked,
          isOn: capabilities.isOutOfBuilding.val,
          changeable: capabilities.isOutOfBuilding.changeable,
        },
        {
          name: ATR_CHECKIN_FLOW,
          title: findOneBy(kioskCheckinFlowModes, 'id', capabilities.checkinFlow.val).displayName,
          val: capabilities.checkinFlow.val,
          textProvider: findOneBy(kioskCheckinFlowModes, 'id', capabilities.checkinFlow.val),
          possibleValues: kioskCheckinFlowModes,
          clickOn: (val)=>updateSetting(ATR_CHECKIN_FLOW, val),
          isDisabled: isLocked,
          changeable: capabilities.checkinFlow.changeable,
        },
        /*{
          name: ATR_ENABLE_GPS,
          clickOn:() => toggleSetting(ATR_ENABLE_GPS),
          isDisabled: isLocked,
          isOn: capabilities.enableGps.val,
          changeable: capabilities.enableGps.changeable,
        }, */
        {
          name: ATR_ENABLE_CAMERA,
          clickOn:() => toggleSetting(ATR_ENABLE_CAMERA),
          isDisabled: isLocked,
          isOn: capabilities.enableCamera.val,
          changeable: capabilities.enableCamera.changeable,
        },
      ]
    )

    const isEntityCustomizationDisabled = !capabilities[ATR_ATTENDANCE_TYPE_ID].changeable || isLocked;

    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem>{menuTitle}</ListItem>
          { menuItems.map((item, index) => <KioskSettingListItem key={index} {...item} />)}
        </List>
        <Divider />
        <List>
          <ListItem button  disabled={isLocked} onClick={() => toggleSetting(atr.isEnabled)}>
            <ListItemIcon>{
              (currentActivity === ACTIVITY_KIOSK_DISABLED)? <HighlightOffIcon/> : <Wifi/>
            }</ListItemIcon>
            <ListItemText primary={(currentActivity === ACTIVITY_KIOSK_DISABLED)? 'Kiosk Disabled' : 'Kiosk Active'} />
          </ListItem>
        </List>
        {capabilities.enableCamera.val? <Divider/>:null}
        {capabilities.enableCamera.val?
          <List>
            <ListItem>
              <FancySelect
                disabled={isLocked}
                dispatchUpdate={cameraUpdateSetting}
                label={"Selected Camera"}
                propertyName={`cameraDeviceName`}
                currentVal={cameraConfiguration.cameraDeviceName}
                valAtr='val'
                items={cameraDeviceList}
                />
            </ListItem>
            <ListItem>
              <FancySelect
                disabled={isLocked}
                dispatchUpdate={cameraUpdateSetting}
                label={"Barcode Type"}
                propertyName={`barcodeType`}
                currentVal={cameraConfiguration.barcodeType}
                valAtr='val'
                items={barcodeTypeList}
                />
            </ListItem>
          </List>
          :
          null}
        <Divider />
     
        <Grid>
          { positiveAttendanceIsEnabled &&
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 8 }}>
              <Button
                disabled={isEntityCustomizationDisabled}
                style={{ width: '100%', paddingTop: 12, paddingBottom: 12 }}
                onClick={kioskFacilitiesCustomSetupEditFromActiveKiosk}
                color="primary"
              >
                {CUSTOMIZE_FACILITIES_TITLE}
              </Button>
              {!capabilities[ATR_ATTENDANCE_TYPE_ID].changeable && <Lock style={{ marginLeft: -40, opacity:0.3 }} />}
            </div>
            <KioskFacilitiesCustomSetupFromActiveKioskDialog />
            <Divider />
          </div>
          }
          <Grid >
            {customAttendanceConfiguration && customAttendanceConfiguration.facilitiesOverrides &&
              customAttendanceConfiguration.facilitiesOverrides.map(facilityOverride => {   
                const atTypesByFacilityId = getAtTypesByFacilityId(allAtTypes, allFacilities);
                const atReasonsByFacilityId = getAtReasonsByFacilityId(allAtReasons, allFacilities);

                const {facility, singleAttendanceItem, tardyItem, absentItem, atReasonItem} = getFacilityOverrideSelectedValues(
                  facilityOverride, allAtTypes, allAtReasons, allFacilities);

                return (
                  <ListItem key={facilityOverride.facilityId} style={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: '8px' }}>
                    <div>
                      <span className={!isEntityCustomizationDisabled ? classes.facilityLabel : classes.facilityLabelDisabled}>{facility.name}</span>
                    </div>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE && (
                          <FancySelect disabled={isEntityCustomizationDisabled}
                            dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                            label='Single Attendance Type'
                            propertyName='atTypeSingleId'
                            currentItem={singleAttendanceItem}
                            items={atTypesByFacilityId[facilityOverride.facilityId]}
                            valAtr="id"
                            nameAttr='displayName'
                          />
                        )}
                        {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES && (
                          <>
                            <FancySelect disabled={isEntityCustomizationDisabled}
                              dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                              label='Tardy Type'
                              propertyName='atTypeTardyId'
                              currentItem={tardyItem}
                              items={atTypesByFacilityId[facilityOverride.facilityId]}
                              valAtr="id"
                              nameAttr='displayName'
                            />
                            <FancySelect disabled={isEntityCustomizationDisabled}
                              dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                              label='Absent Type'
                              propertyName='atTypeAbsentId'
                              currentItem={absentItem}
                              items={atTypesByFacilityId[facilityOverride.facilityId]}
                              valAtr="id"
                              nameAttr='displayName'
                            />
                          </>
                        )}
                      </Grid>
                      <FancySelect disabled={isEntityCustomizationDisabled}
                        dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                        label='Reason'
                        propertyName='atReasonId'
                        currentItem={atReasonItem}
                        items={atReasonsByFacilityId[facilityOverride.facilityId]}
                        valAtr="id"
                        nameAttr='displayName'
                      />
                    </Grid>
                  </ListItem>
                );
              })}
          </Grid>
          <Divider />
        </Grid>

        <List>
          {isDeletable ? <ListItem button  disabled={isLocked} onClick={() => {
            if(window.confirm('Delete this kiosk forever?')) {
              deleteKiosk()
            }}}>
            <ListItemIcon>{
              <DeleteForeverIcon/>
            }</ListItemIcon>
            <ListItemText primary={'Delete Kiosk'} />
          </ListItem> : null}
          <a href='https://k12sta.com/support/schooltrak/' target="_blank" rel="noopener noreferrer">
            <ListItem button >
              <ListItemIcon><STASwirl/></ListItemIcon>
              <ListItemText primary={`Support - ${appVersion}`} />
            </ListItem>
          </a>
        </List>
      </div>
    );


    return (
        <Drawer open={ menuDrawerOpen || !isLocked} onClose={closeMenuDrawer} >
          <div
            tabIndex={0}
            role="button"
            onClick={closeMenuDrawer}
            onKeyDown={closeMenuDrawer}
          >
            {sideList}
          </div>
        </Drawer>
    );
  }
}

MenuDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => state.kiosk
const mapDispatchToProps = {
  cameraUpdateSetting,
  closeMenuDrawer,
  lockConfiguration,
  unlockConfiguration,
  deleteKiosk,
  setAttendanceType,
  toggleSetting,
  unlockKiosk,
  updateSetting,
  lockKiosk,
  kioskFacilitiesCustomSetupEditFromActiveKiosk,
  kioskFacilitiesCustomSetupSaveFromActiveKiosk
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MenuDrawer));