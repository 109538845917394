import React from "react"
import connect from "react-redux/es/connect/connect"
import {Typography, withStyles} from "@material-ui/core"
import TodayIcon from '@material-ui/icons/Today'
import List from '@material-ui/core/List'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { Link as RouterLink } from 'react-router-dom'
import { URL_SEMESTER_RULES, URL_FRAG_FACILITY_ID } from "redux/constants"
import { semesterRuleEdit, semesterRuleFetchByFaciltyId } from "redux/admin/Actions"

import CurrentFacility from "apps/admin/components/CurrentFacility"
import SemesterRuleEditor from 'apps/admin/components/SemesterRule/Editor'
import SemesterRuleSummary from 'apps/admin/components/SemesterRule/Summary'

const styles = {}

class ListItemLink extends React.Component {
  render() {
    const {facilityId, children} = this.props
    const link = URL_SEMESTER_RULES.replace(URL_FRAG_FACILITY_ID, `/${facilityId}`)
    return <RouterLink to={link} style={{textDecoration: 'none'}}><ListItem button children={children} /></RouterLink>
  }
}

class SemesterRules extends React.Component {
  componentDidMount() {
    if(this.props.currentFacilityId) this.props.semesterRuleFetchByFaciltyId(this.props.currentFacilityId)
  }

  render() {
    const {facilities, currentFacilityId, semesterRuleEdit, currentSemesterRuleSummary} = this.props
    if (!currentFacilityId) {
      return (
        <div>
          <CurrentFacility/>
          <Typography>Please choose an entity to view its semester rules.</Typography>
          <List>
            {
              facilities.map(facility =>
                <ListItemLink key={facility.id} facilityId={facility.id}>
                  <ListItemIcon><LocationCityIcon/></ListItemIcon>
                  <ListItemText primary={facility.displayName}/>
                </ListItemLink>)
            }
          </List>
        </div>
      )
    } else {
      if(!currentSemesterRuleSummary) return null
      return <div>
        <SemesterRuleEditor/>
        <CurrentFacility/>
        <Typography variant='h5'>Semester Rules <IconButton onClick={()=>semesterRuleEdit(currentSemesterRuleSummary)}><EditIcon/></IconButton></Typography>
        <SemesterRuleSummary currentSemesterRuleSummary={currentSemesterRuleSummary}/>
      </div>
    }
  }
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {
  semesterRuleEdit,
  semesterRuleFetchByFaciltyId
}

export const SemesterRulesPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(SemesterRules))

export const semesterRulesPage = {
  icon: TodayIcon,
  component: SemesterRulesPage,
  name: 'Semester Rules',
  displayName: 'Semester Rules',
  to: URL_SEMESTER_RULES
}

export default SemesterRulesPage