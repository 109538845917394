export const ATR_LOCATION_ID = 'locationId'
export const ATR_IS_LOCKED = 'isLocked'
export const ATR_IS_ENABLED = 'isEnabled'
export const ATR_IS_OUT_OF_BUILDING = 'isOutOfBuilding'
export const ATR_CAN_PRINT = 'canPrint'
export const ATR_ENABLE_EMAIL_SLIP = 'enableEmailSlip'
export const ATR_ENABLE_KEYPAD = 'enableKeyPad'
export const ATR_ENABLE_GPS = 'enableGps'
export const ATR_ENABLE_CAMERA = 'enableCamera'
export const ATR_ACCEPT_KEYBOARD_INPUT = 'acceptKeyboardInput'
export const ATR_ATTENDANCE_TYPE = 'atType'
export const ATR_ATTENDANCE_TYPE_ID = 'atTypeId'
export const ATR_CHECKIN_FLOW = 'checkinFlow'
export const ATR_REQUEST_SEND_REPORT = 'requestSendReport'
export const ATR_MAX_LEASE_LENGTH = 'maxLeaseLength'
export const ATR_LEASE_EXPIRES = 'leaseExpires'
export const SINGLETON_KIOSK_DESIGNATION = 'SINGLETON_KIOSK'
export const URL_RESET_PASSWORD = 'rstpwd'
export const SUCCESS_LOGIN_BOOT_ACTION_UNLOCK_KIOSK = 'SUCCESS_LOGIN_BOOT_ACTION_UNLOCK_KIOSK'