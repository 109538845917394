import React, { Component } from 'react'
import {connect} from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'


import styles from './../style'

import {snackMessageHide, snackMessageError} from "redux/RootActions"
import {sendAdminPassword, returnToSetLicensing} from 'redux/install/Actions'

import {FullTextField} from "common/components/FullTextField";

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import {INSTALL_STEP_SET_ADMIN_PASSWORD} from "redux/install/constants";


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password:'',
      passwordConfirmation: '',
      enableSend: false,
      controlsDisabled:false
    }
    this.passwordMatchCheck = this.passwordMatchCheck.bind(this)
    this.setPassword = this.setPassword.bind(this)
    this.setPasswordConfirmation = this.setPasswordConfirmation.bind(this)
  }

  confirmThisStep = (fn) => {
    fn()
  }

  addHooks() {
    //testing
    window.stHooks.addHook('adminPwd', (e)=>this.setPassword({target:{value:e}}))
    window.stHooks.addHook('adminPwdConfirmation', (e)=>this.setPasswordConfirmation({target:{value:e}}))
  }

  removeHooks() {
    window.stHooks.removeHook(['adminPwd', 'adminPwdConfirmation'])
  }

  setPassword(e) {
    const self = this
    this.setState({password:e.target.value}, ()=> {
      if(self.state.passwordConfirmation.length > 0) {
        self.passwordMatchCheck()
      }
    })
  }

  setPasswordConfirmation(e) {
    this.setState({passwordConfirmation:e.target.value}, this.passwordMatchCheck)
  }

  passwordMatchCheck() {
    if(this.state.password !== this.state.passwordConfirmation) {
      this.props.snackMessageError("Passwords do not match")
      this.setState({enableSend:false})
      return
    } else {
      this.props.snackMessageHide()
    }
    if(this.state.password.length > 5) {
      this.setState({enableSend:true})
    } else {
      this.setState({enableSend:false})
    }
  }

  render() {
    const {classes, sendAdminPassword, browserCurrentStep, returnToSetLicensing} = this.props
    if(browserCurrentStep !== INSTALL_STEP_SET_ADMIN_PASSWORD) {
      this.removeHooks()
      return null
    } else {
      this.addHooks()
    }

    return (
      <Dialog
        open
      >
        <DialogTitle>Password Reset</DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container>
            <Grid item xs={12} >
              <FullTextField
                label="Password"
                autoComplete={'off'}
                type={'password'}
                value={this.state.password}
                onChange={this.setPassword}
                propertyName={'password'}
              />
              <Grid item xs={12}>
                <FullTextField
                  label="Password Again"
                  autoComplete={'off'}
                  type={'password'}
                  value={this.state.passwordConfirmation}
                  onChange={this.setPasswordConfirmation}
                  propertyName={'password'}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={returnToSetLicensing} color="secondary">
            Back
          </Button>
          <Button disabled={!this.state.enableSend || this.state.controlsDisabled}
                  onClick={()=>this.confirmThisStep(()=>sendAdminPassword({password: this.state.password}))}
                  color="primary">
            Set Password
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(
  state => state.install,
  {
    sendAdminPassword,
    snackMessageHide,
    snackMessageError,
    returnToSetLicensing
  }
)(withStyles(styles)(App))
