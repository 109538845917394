import { withStyles } from '@material-ui/core/styles'
import MuiDialogActions from '@material-ui/core/DialogActions'

export const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    marginTop: 10,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions)

export default DialogActions