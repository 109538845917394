import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Print} from 'react-easy-print'

import TopAppBar from './components/TopAppBar'
import MenuDrawer from './components/MenuDrawer'
import TenKeyboard from './components/TenKeyboard'
import SnackMessage from 'common/components/SnackMessage'
import SetupWizard from "./components/SetupWizard"
import Footer from './components/Footer'

import {ACTIVITY_WAITING_FOR_LOCATION} from "redux/constants";
import {SUCCESS_LOGIN_BOOT_ACTION_UNLOCK_KIOSK} from "../../common/kioskConstants";

import {
  bootKiosk,
  idCodeKeyboardInput,
  startGpsPoll,
  forgotPasswordSend,
  snackMessageInfo,
  snackMessageError,
  loginSend,
  loginUIHide,
  logoutSend,
  snackMessageHide,
} from "redux/kiosk/KioskActions";
import LoginDialog from 'common/components/LoginDialog'
import StaticContentDialog from "apps/kiosk/components/StaticContentDialog";


const mapStateToProps = state => state.kiosk

class KeyboardListenerClass extends React.Component {
    render() {
        const { idCodeKeyboardInput, children} = this.props;
        return (
            <div tabIndex="0" className="App" onKeyDown={(e)=>{
              if(!e.altKey && !e.metaKey && !e.ctrlKey) {
                idCodeKeyboardInput(e.key)
              }
            }}>
                {children}
            </div>)
    }
}

const KeyboardListener = connect(
    mapStateToProps,
  {idCodeKeyboardInput},
)(KeyboardListenerClass)

class Kiosk extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bootKiosk:props.bootKiosk
    }
  }

  componentDidMount(){
    this.state.bootKiosk()
    this.props.startGpsPoll()
  }

  render() {
    const {currentActivity, isReady, printSlip, dialogs, loggedInUser, loginSend, loginUIHide, forgotPasswordSend, organizationName, snackMessageInfo, snackMessageError, } = this.props
    if(!isReady) {
      return null
    }
    const printStyle = Object.assign({whiteSpace:'pre', textAlign:'left'}, printSlip.style)
    return (
      (currentActivity === ACTIVITY_WAITING_FOR_LOCATION)?
        <div>
          <SnackMessage/>
          <TopAppBar />
          {dialogs.login?
            <LoginDialog
              {...dialogs.login}
              organizationName={organizationName}
              loginType='kiosk'
              loginSend={loginSend}
              forgotPasswordSend={forgotPasswordSend}
              snackMessageInfo={snackMessageInfo}
              snackMessageError={snackMessageError}
            />
            :
            null }
          {loggedInUser? <SetupWizard/> : null}
          <Footer/>
        </div>
        :
        <KeyboardListener>
          <Print printOnly><p style={printStyle} dangerouslySetInnerHTML={{ __html: printSlip.html }} /></Print>
            <SnackMessage snackMessageHide={snackMessageHide}/>
            <TopAppBar />
            <StaticContentDialog/>
            <MenuDrawer  />
            {dialogs.login?
              <LoginDialog
                loginType='kiosk'
                organizationName={organizationName}
                {...dialogs.login}
                loginSend={loginSend}
                snackMessageInfo={snackMessageInfo}
                snackMessageError={snackMessageError}
                loginWithSkywardParameters={{successLoginBootAction: SUCCESS_LOGIN_BOOT_ACTION_UNLOCK_KIOSK}}
                close={loginUIHide}/> : null }
            <TenKeyboard/>
            <Footer/>
        </KeyboardListener>
      )
  }
}

export default connect(
  mapStateToProps,
  {
    bootKiosk,
    loginSend,
    logoutSend,
    loginUIHide,
    forgotPasswordSend,
    snackMessageInfo,
    snackMessageError,
    snackMessageHide,
    startGpsPoll
  }
)(Kiosk)
