import {
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_INFO,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS,
  STATUS_MESSAGE_TYPE_ERROR,
  STATUS_MESSAGE_TYPE_INFO, STATUS_MESSAGE_TYPE_SUCCESS,
  STATUS_MESSAGE_TYPE_WARNING
} from "redux/constants";
import {action} from "@relativity/js-util";


export const SNACK_MESSAGE_SHOW = 'SNACK_MESSAGE_SHOW'
export const snackMessageShow = (userMessage, messageType, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS) => {
  return action(SNACK_MESSAGE_SHOW,  {userMessage, messageType, autoHideMillis})
}

export const snackMessageInfo = (userMessage, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_INFO) => {
  return snackMessageShow(userMessage, STATUS_MESSAGE_TYPE_INFO, autoHideMillis)
}

export const snackMessageError = (userMessage, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR) => {
  return snackMessageShow(userMessage, STATUS_MESSAGE_TYPE_ERROR, autoHideMillis)
}

export const snackMessageWarning = (userMessage, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_INFO) => {
  return snackMessageShow(userMessage, STATUS_MESSAGE_TYPE_WARNING, autoHideMillis)
}

export const snackMessageSuccess = (userMessage , autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS) => {
  return snackMessageShow(userMessage, STATUS_MESSAGE_TYPE_SUCCESS, autoHideMillis)
}

export const SNACK_MESSAGE_HIDE = 'SNACK_MESSAGE_HIDE'
export const snackMessageHide = (payload={}) => {
  return action(SNACK_MESSAGE_HIDE, payload)
}


export const BOOT_PRUNE_APP_STATE_EXCEPT = 'BOOT_PRUNE_APP_STATE_EXCEPT'
export const bootPruneAppStateExcept = (appName) => {
  return action(BOOT_PRUNE_APP_STATE_EXCEPT, appName)
}