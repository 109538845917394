/* globals window */
import React from "react"
import connect from "react-redux/es/connect/connect"
import moment from 'moment-timezone'
import {Divider, Paper, Typography, withStyles} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import HomeIcon from '@material-ui/icons/Home'
import Tooltip from "@material-ui/core/Tooltip"
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import MomentUtils from "@date-io/moment"
import {InlineDatePicker, MuiPickersUtilsProvider} from "material-ui-pickers"


import {
  Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend,
  PieChart, Pie, Cell,
  Tooltip as RechartsTooltip
} from 'recharts'


import {
  AT_TYPE_ID_ABSENT,
  AT_TYPE_ID_ABSENT_EXCUSED,
  AT_TYPE_ID_OUT,
  AT_TYPE_ID_OUT_OF_BUILDING,
  AT_TYPE_ID_PRESENT,
  AT_TYPE_ID_TARDY,
  AT_TYPE_ID_TARDY_EXCUSED,
  AT_TYPE_ID_UNKNOWN,
  AT_TYPE_ID_SKYWARD_CUSTOM,
  COLOR_ABSENT,
  COLOR_ABSENT_EX,
  COLOR_OUT,
  COLOR_OUT_OF_BUILDING,
  COLOR_PRESENT,
  COLOR_TARDY,
  COLOR_TARDY_EX,
  COLOR_UNKNOWN,
  COLOR_SKYWARD_CUSTOM,
  FORMAT_YMD,
  TYPE_YMD,
  URL_HOME, URL_HOME_ROUTE,
  GENERIC_AT_TYPES_IN_DATABASE_COUNT,
  SKYWARD_CUSTOM_AT_TYPE,
  PIE_DISPLAY_ORDER
} from "redux/constants";
import { pageLink } from 'apps/admin/components/Link'
import {
  startAllWorkerJobsPoll,
  startHomePageDataPoll,
  stopAllWorkerJobsPoll,
  stopHomePagePoll
} from 'redux/admin/Actions'
import ComposedChart from "recharts/es6/chart/ComposedChart"
import {reduce} from "redux/utils"
import SynchronizerStatus from "apps/admin/components/SynchronizerStatus"
import IconButton from "@material-ui/core/IconButton";

const colors = {
  present: COLOR_PRESENT,
  tardy: COLOR_TARDY,
  tardyEx: COLOR_TARDY_EX,
  absent: COLOR_ABSENT,
  absentEx: COLOR_ABSENT_EX,
  [AT_TYPE_ID_PRESENT]: COLOR_PRESENT,
  [AT_TYPE_ID_TARDY]: COLOR_TARDY,
  [AT_TYPE_ID_TARDY_EXCUSED]: COLOR_TARDY_EX,
  [AT_TYPE_ID_ABSENT]: COLOR_ABSENT,
  [AT_TYPE_ID_ABSENT_EXCUSED]: COLOR_ABSENT_EX,
  [AT_TYPE_ID_OUT]: COLOR_OUT,
  [AT_TYPE_ID_OUT_OF_BUILDING]: COLOR_OUT_OF_BUILDING,
  [AT_TYPE_ID_UNKNOWN]: COLOR_UNKNOWN,
  [AT_TYPE_ID_SKYWARD_CUSTOM]: COLOR_SKYWARD_CUSTOM
}

class Home extends React.Component {

  constructor() {
    super()
    this.state = {
      showDatepicker:false
    }
  }

  showDatepicker = () => {
    this.setState({showDatepicker:true})
  }

  chooseDate = (date) => {
    this.setState({showDatepicker:false})
    const dateYMD = date.format(FORMAT_YMD)
    const itemId = moment().format(FORMAT_YMD) === dateYMD? '' : dateYMD
    this.props.history.push(pageLink(URL_HOME_ROUTE, this.props.currentFacilityId, itemId))
  }

  componentDidMount() {
    this.props.startHomePageDataPoll({msPolling:1000})
    this.props.startAllWorkerJobsPoll({msPolling:20000})
  }

  componentWillUnmount() {
    this.props.stopHomePagePoll()
    this.props.stopAllWorkerJobsPoll()
  }

  render() {

    const {
      byId,
      homePage,
    } = this.props
    window.props = this.props
    const {todayWeekdayName, weekdays, todayYMD} = homePage

    const weekDatesAsc = reduce(weekdays, (acc, weekday) => {
      acc.push(weekday)
      return acc
    }, [])

    const dayTotals = reduce(homePage.dayTotals, (acc, total, atTypeId) => {
      if(atTypeId !== 'name' && parseInt(atTypeId) !== AT_TYPE_ID_PRESENT) {
        acc.push({
          name: atTypeId > GENERIC_AT_TYPES_IN_DATABASE_COUNT ? SKYWARD_CUSTOM_AT_TYPE.displayName : byId.atType(atTypeId).displayName,
          value: total,
          colorKey: atTypeId > GENERIC_AT_TYPES_IN_DATABASE_COUNT ? AT_TYPE_ID_SKYWARD_CUSTOM : atTypeId
        })
      }
      return acc
    }, [])


    const mo = moment().minute(0)
    const dayByHour = reduce(homePage.dayByHour, (acc, hourTotals, hourNum) => {
      mo.hour(hourNum)
      acc.push(Object.assign({name: mo.format('h:mma')}, hourTotals))
      return acc
    }, [])

    const todayText = todayYMD === moment().format(FORMAT_YMD)? 'Today' : todayWeekdayName

    const sortedDayTotals = [...dayTotals].sort((a, b) => {
      return PIE_DISPLAY_ORDER.indexOf(a.name) - PIE_DISPLAY_ORDER.indexOf(b.name);
    });

    return (
      <div>
         <h1>Home</h1>
        <SynchronizerStatus />
         <Grid container spacing={16}>
           <Grid item xs={12}>
             {
               this.state.showDatepicker ?
                 <MuiPickersUtilsProvider utils={MomentUtils}>
                   <InlineDatePicker
                     style={{padding:0, marginBottom:-7}}
                     variant={'outlined'}
                     format={'YYYY-MM-DD'}
                     value={todayYMD}
                     onChange={this.chooseDate}
                   />
                 </MuiPickersUtilsProvider>
                 :
                 <Typography variant='h5'>
                   <Tooltip title={todayYMD+''}><span style={{position:'relative', top:'2px'}}>{todayText}</span></Tooltip>
                   <Tooltip title={'Choose a different date'}>
                     <IconButton onClick={this.showDatepicker}><CalendarTodayIcon/></IconButton>
                   </Tooltip>
                 </Typography>
             }
           </Grid>
           <Grid item xs={12} md={6} xl={5}>
             <Paper style={{padding:10}}>
               <Typography>Time of Day</Typography>
               <ComposedChart
                 width={500}
                 height={300}
                 data={dayByHour}
                 margin={{
                   top: 5, right: 30, left: 0, bottom: 5,
                 }}
               >
                 <CartesianGrid strokeDasharray="3 3" />
                 <XAxis dataKey="name" />
                 <YAxis />
                 <RechartsTooltip />
                 <Legend />
                 <Line type="monotone" dataKey={AT_TYPE_ID_ABSENT} name={byId.atType(AT_TYPE_ID_ABSENT).displayName}  stroke={colors.absent} activeDot={{ r: 8 }} />
                 <Line type="monotone" dataKey={AT_TYPE_ID_TARDY} name={byId.atType(AT_TYPE_ID_TARDY).displayName} stroke={colors.tardy} activeDot={{ r: 6 }}/>
               </ComposedChart>

             </Paper>
           </Grid>
           <Grid item xs={12} md={6} lg={5}>
             <Paper style={{padding:10}}>
               <Typography >Daily Totals</Typography>
               <PieChart style={{marginLeft:10}} width={500} height={300} onMouseEnter={this.onPieEnter}>
                 <Pie
                   data={sortedDayTotals}
                   dataKey='value'
                   cx={130}
                   cy={130}
                   innerRadius={50}
                   outerRadius={90}
                   fill="#8884d8"
                   paddingAngle={0}
                   label
                   isAnimationActive={false}
                 >
                   {
                     sortedDayTotals.map((item, index) => <Cell key={index} fill={colors[item.colorKey]}/>)
                   }
                 </Pie>
                 <Legend />
               </PieChart>
             </Paper>
           </Grid>

         </Grid>
         <Divider style={{marginTop:'10px'}}/>
         <Grid container spacing={16}>
           <Grid item xs={12}>
             <Typography variant='h6'>This Week</Typography>
           </Grid>
           <Grid item xs={12} lg={11} xl={10}>
             <Paper style={{padding:10}}>
             <BarChart
               width={1000}
               height={300}
               data={weekDatesAsc}
               margin={{
                 top: 20, right: 30, left: 20, bottom: 5,
               }}
             >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis dataKey="name" />
               <YAxis />
               <RechartsTooltip />
               <Legend />
               <Bar dataKey={AT_TYPE_ID_TARDY} stackId="a" name="Tardy" fill={colors[AT_TYPE_ID_TARDY]} />
               <Bar dataKey={AT_TYPE_ID_TARDY_EXCUSED} stackId="a" name="Tardy Excused" fill={colors[AT_TYPE_ID_TARDY_EXCUSED]} />
               <Bar dataKey={AT_TYPE_ID_ABSENT} stackId="b" name="Absent" fill={colors[AT_TYPE_ID_ABSENT]} />
               <Bar dataKey={AT_TYPE_ID_ABSENT_EXCUSED} stackId="b" name="Absent Excused" fill={colors[AT_TYPE_ID_ABSENT_EXCUSED]} />
             </BarChart>
             </Paper>
           </Grid>
         </Grid>
       </div>
      )
    }
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {
  startHomePageDataPoll,
  stopHomePagePoll,
  startAllWorkerJobsPoll,
  stopAllWorkerJobsPoll,
}

export const HomePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(Home))


export const homePage = {
  icon: HomeIcon,
  component: HomePage,
  name: 'Home',
  displayName: 'Home',
  to: URL_HOME,
  routes: [
    URL_HOME_ROUTE
  ],
  itemType:TYPE_YMD
}

export default HomePage