import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {openMenuDrawer} from "../../../redux/actions"

const styles = {
  root: {
    flexGrow: 1,
    '@media print': {
      display:'none'
    }
  },
}

class TopAppBar extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.root}>
        <AppBar position="static" >
          <Toolbar style={{width:'100%!important'}}>
            <Typography variant='h4' color="inherit" style={{flexGrow:8}}>Password Reset</Typography>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}


TopAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => state.kiosk
const mapDispatchToProps = {
  openMenuDrawer
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TopAppBar))
