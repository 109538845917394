import React from 'react'
import {connect} from "react-redux"
import { sendAttendance, idCodePastedInput, idCodeScannedInput, addIdCodeChar, removeIdCodeChar, uiSetReadyToSend, viewAttendancePolicy} from 'redux/kiosk/KioskActions'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import InputAdornment from '@material-ui/core/InputAdornment'
import BackspaceIcon from '@material-ui/icons/Backspace'
import DoneIcon from '@material-ui/icons/Done'

import {
  ACTIVITY_ACCEPTING_ID_CODE,
  ACTIVITY_WAITING_TO_SEND,
  DELETE_ALL_CHARS,
  AT_TYPE_ID_REPORT,
  AT_TYPE_ID_OUT,
  COLOR_ACTION,
  KIOSK_ATTENDANCE_DIRECTION_IN,
  KIOSK_ATTENDANCE_DIRECTION_OUT,
  KIOSK_ATTENDANCE_DIRECTION_MULTI, AT_TYPE_ID_OUT_OF_BUILDING,
} from 'redux/constants'
import styles from './styles'


class KeyRowClass extends React.Component {
  render() {
    const { classes, buttons, spacing, addIdCodeChar } = this.props

    return (
      <Grid container className={classes.demo} justify="center" spacing={Number(spacing)}>
        {buttons.map((btn, i) => {
          return <Grid key={i} item>
            <Button disabled={btn.disabled} className={classes.numkey} onClick={btn.onClick? btn.onClick : ()=> addIdCodeChar(btn.text) } variant="contained" color="primary">
              {(typeof btn.text === 'function')? React.createElement(btn.text) : btn.text }
            </Button>
          </Grid>
        })}
      </Grid>
    )
  }
}


const mapStateToProps = state => state.kiosk

const mapDispatchToProps = {
  addIdCodeChar,
  removeIdCodeChar,
  sendAttendance,
  idCodePastedInput,
  idCodeScannedInput,
  uiSetReadyToSend,
  viewAttendancePolicy,
}

const KeyRow = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(KeyRowClass))

class GuttersGrid extends React.Component {
  state = {
    spacing: '16',
  }

  constructor(props) {
    super(props);
    this.idCodeInputField = false;
    this.currentActivity = props.currentActivity
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.idCodeInputField && this.currentActivity === ACTIVITY_ACCEPTING_ID_CODE /*&& this.props.capabilities.acceptKeyboardInput.val*/) {
      this.idCodeInputField.focus()
    }
  }

  setIdCodeInputField = input => {
    this.idCodeInputField = input
  }

  render() {
    const {
      attendanceDirection,
      capabilities: {
        requestSendReport: {
          val: requestSendReport
        },
        isOutOfBuilding: {
          val: isOutOfBuilding
        }
      },
      classes,
      currentActivity,
      enabled,
      idCode,
      idCodeLengthMax,
      idCodeLengthMin,
      idCodePastedInput,
      removeIdCodeChar,
      sendAttendance,
      viewAttendancePolicy,
      showVideo,
      uiSetReadyToSend} = this.props

    const { spacing } = this.state
    const numDisabled = (!enabled.keypad)
    this.currentActivity = currentActivity

    const classInOut = requestSendReport? classes.bigbtn : classes.btnHuge
    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <TextField
            onPaste={(e)=>
              {
                console.log("e.clipboardData.getData('Text')", e.clipboardData.getData('Text'))
                idCodePastedInput(e.clipboardData.getData('Text'))
              }
            }
            autoFocus={this.props.capabilities.acceptKeyboardInput.val}
            id="outlined-password-input"
            label="Student ID"
            className={classes.keycodeField}
            margin="normal"
            variant="outlined"
            value={ idCode }
            inputRef={this.setIdCodeInputField}
            disabled={(currentActivity !== ACTIVITY_ACCEPTING_ID_CODE && currentActivity !== ACTIVITY_WAITING_TO_SEND)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Delete"
                    onClick={() => removeIdCodeChar()}
                  >
                    <BackspaceIcon/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} style={{display:(currentActivity === ACTIVITY_ACCEPTING_ID_CODE && showVideo)?'flex':'none'}}>
          <div style={{flexGrow:1}}/>
          <div id="scanner-container" key="scanner-container" style={{flexGrow:0, position:'relative', width:'640px', height:'480px'}} />
          <div style={{flexGrow:1}}/>
        </Grid>
        {(currentActivity === ACTIVITY_WAITING_TO_SEND)?
          <Grid item xs={12}>
            <Grid container className={classes.demo} justify="center" spacing={Number(spacing)}>
              {(attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_IN || attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_MULTI)? <Grid item xs={12}>
                <Button
                  className={ classInOut}
                  margin="normal"
                  value={ idCode }
                  variant="contained"
                  color="primary"
                  onClick={()=>sendAttendance()}
                >I'm here</Button>
              </Grid> : null }
              {requestSendReport?
              <Grid item xs={12}>
                <Button
                  className={classes.bigbtn}
                  style={{background:COLOR_ACTION, color:'white'}}
                  margin="normal"
                  value={ idCode }
                  variant="contained"
                  onClick={()=>sendAttendance(AT_TYPE_ID_REPORT)}
                >My Report</Button>
              </Grid>
                :
                null
              }
              {requestSendReport ?
                <Grid item xs={12}>
                  <Button
                    className={classes.bigbtn}
                    style={{background: COLOR_ACTION, color: 'white'}}
                    margin="normal"
                    value={idCode}
                    variant="contained"
                    onClick={() => viewAttendancePolicy()}
                  >Attendance Policy</Button>
                </Grid>
                :
                null
              }
              {(attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_OUT || attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_MULTI)?
                <Grid item xs={12}>
                  <Button
                    className={classInOut}
                    margin="normal"
                    value={ idCode }
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      if(isOutOfBuilding) {
                        sendAttendance(AT_TYPE_ID_OUT_OF_BUILDING)
                      } else {
                        sendAttendance(AT_TYPE_ID_OUT)
                      }
                    }}
                  >{isOutOfBuilding? 'Leaving building' : `I'm leaving`}</Button>
                </Grid>
                :
                null
              }
            </Grid>
          </Grid>
          :
          ''
        }
        {((currentActivity !== ACTIVITY_WAITING_TO_SEND && !showVideo))?
        <Grid item xs={12}>
          <KeyRow buttons={[{text:1, disabled:numDisabled}, {text:2, disabled:numDisabled},{text:3, disabled:numDisabled}]} spacing={spacing} classes={classes} />
          <KeyRow buttons={[{text:4, disabled:numDisabled}, {text:5, disabled:numDisabled},{text:6, disabled:numDisabled}]} spacing={spacing} classes={classes} />
          <KeyRow buttons={[{text:7, disabled:numDisabled}, {text:8, disabled:numDisabled},{text:9, disabled:numDisabled}]} spacing={spacing} classes={classes} />
          <KeyRow buttons={[
            {
              text:ClearIcon,
              disabled:numDisabled,
              onClick:() => removeIdCodeChar(DELETE_ALL_CHARS)
            },
            { text:0, disabled:numDisabled},
            {
              text:DoneIcon,
              disabled:(numDisabled || (idCode.length < idCodeLengthMin) || (idCode.length > idCodeLengthMax)),
              onClick:() => uiSetReadyToSend()
            }
            ]} spacing={spacing} classes={classes} />
        </Grid>
          : ''}
      </Grid>
    )
  }
}

GuttersGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(GuttersGrid))