import React from "react"
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {CSVLink} from "react-csv";

export default class ReportControls extends React.PureComponent {
  render() {
    const {firstDateYMD, lastDateYMD, fetchReportAction, report, reportType, xs=12, sm=6, md=4, style={}} = this.props
    return <Grid container style={style}>
      <Grid item xs={xs} sm={sm} md={md}>
        <Button onClick={fetchReportAction} color="primary">
          Create Report
        </Button>
        {report? <Button><CSVLink style={{textDecoration:'none'}} data={report} target="_blank" filename={`${reportType.displayName} - ${firstDateYMD} - ${lastDateYMD}.csv` }>Download</CSVLink></Button>
          : null }
      </Grid>
    </Grid>
  }
}