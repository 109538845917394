/**
 * We would eventually like to be able to add a new location with this interface. I believe that's not MVP.
 */

import React from "react"
import connect from "react-redux/es/connect/connect"
import {withStyles} from "@material-ui/core"
import {searchLocationsBy, searchFromUrl} from 'redux/admin/Actions'
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/es/Typography/Typography"
import { withRouter } from 'react-router-dom';

import {
  E_KEY_ENTER,
  SEARCH_CONTEXT_LOCATIONS_PAGE,
  TXT_EN_ENTITY_AGNOSTIC_PAGE,
  URL_LOCATION_DETAIL
} from "redux/constants"
import {URL_LOCATION} from 'redux/constants'
import { pageLink } from 'apps/admin/components/Link'

import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CurrentFacility from "apps/admin/components/CurrentFacility"
import RoomIcon from '@material-ui/icons/Room'
import FancySelect from "common/components/FancySelect";
import FullTextField from "common/components/FullTextField";
import styles from './style'

class Locations extends React.Component {
  componentDidMount() {
    this.props.searchFromUrl(SEARCH_CONTEXT_LOCATIONS_PAGE)
  }

  navigateToLocationDetails = (location) => {
    this.props.history.push(pageLink(URL_LOCATION_DETAIL, location.facilityId, location.id));
  };

  render() {
    const { byId, allBuildings, search, searchLocationsBy, classes, newBuildingLocationsSyncWait} = this.props
    const { matchingLocations, params } = search

    return (
      <div>
        <CurrentFacility contentOverride={TXT_EN_ENTITY_AGNOSTIC_PAGE}/>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={6}>
            <FullTextField
                       id="standard-name"
                       label="Search for a Location by Name"
                       variant="outlined"
                       fullWidth
                       value={params.locationName}
                       onChange={event => searchLocationsBy(Object.assign({}, params, {locationName:event.target.value}))}
                       onKeyDown={event => {
                         if(event.key === E_KEY_ENTER) {
                           searchLocationsBy()
                          }
                        }}
                        margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FancySelect
              xs={12} sm={4}
              dispatchUpdate={(prop)=>{
                searchLocationsBy(Object.assign({}, params, {buildingId: prop.val}))
              }}
              label='Building'
              propertyName={'buildingId'}
              currentVal={(params.buildingId > 0)? params.buildingId : 0}
              items={allBuildings}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} >
          <Grid item xs={12}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell>Building</TableCell>
                  <TableCell className={classes.locationTableLastCell}>Kiosks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newBuildingLocationsSyncWait.buildingsIds.includes(parseInt(search.params.buildingId)) ? <TableRow><TableCell colSpan={3}><Typography variant="h6">Syncing locations for the chosen building. Please wait a moment.</Typography></TableCell></TableRow> : null}
                {matchingLocations.length === 0 && !newBuildingLocationsSyncWait.buildingsIds.includes(parseInt(search.params.buildingId)) ? <TableRow><TableCell colSpan={3}><Typography variant="h6">No locations. </Typography></TableCell></TableRow> : null}
                {matchingLocations.map(location => {
                  return <TableRow key={location.id} onClick={() => this.navigateToLocationDetails(location)} className={classes.tableRow}>
                    <TableCell>{location.displayName}</TableCell>
                    <TableCell title={`SchoolTRAK ID: ${byId.building(location.buildingId).id}`}>{byId.building(location.buildingId).displayName}</TableCell>
                    <TableCell>{location.numKiosks}</TableCell>
                  </TableRow>})}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return Object.assign({}, state.admin.locationPage, {byId: state.admin.byId, allBuildings:state.admin.allBuildings, newBuildingLocationsSyncWait: state.admin.newBuildingLocationsSyncWait})
}

const mapDispatchToProps = {
  searchLocationsBy,
  searchFromUrl
}

const LocationsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles, { withTheme: true })(Locations)))

export const locationsPage = {
  searchContext: SEARCH_CONTEXT_LOCATIONS_PAGE,
  searchOnPathChange:true,
  icon: RoomIcon,
  component: LocationsPage,
  name: 'Locations',
  displayName: 'Locations',
  to: URL_LOCATION,
}

export default LocationsPage