import axios from 'axios'
import {
  ATR_ACCEPT_KEYBOARD_INPUT, ATR_ATTENDANCE_TYPE_ID,
  ATR_CAN_PRINT, ATR_CHECKIN_FLOW,
  ATR_ENABLE_EMAIL_SLIP,
  ATR_ENABLE_CAMERA,
  ATR_ENABLE_GPS, ATR_ENABLE_KEYPAD,
  ATR_IS_ENABLED,
  ATR_IS_LOCKED, ATR_IS_OUT_OF_BUILDING, ATR_REQUEST_SEND_REPORT
} from "common/kioskConstants";
import {put, select} from 'redux-saga/effects'
import {
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR_LONG,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS_LONG,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER,
  STATUS_MESSAGE_TYPE_ERROR,
  STATUS_MESSAGE_TYPE_SUCCESS,
  kioskFacilityAttendanceOverridesTypes
} from "redux/constants";
import Cookies from 'js-cookie'
import {
  snackMessageShow
} from 'redux/RootActions'

export const determineFailedLoginMessage = (resp) => {
  if(resp.systemMessage === 0) {
    return 'Too many failed login attempts. Reset your password.'
  }
  if(typeof resp.systemMessage === 'number') {
    return `There was an error logging you in. ${resp.systemMessage} attempts remaining.`
  }
  return 'There was an error logging you in. Please try again.'
}

export const filter = (keys, obj) => {
  if(obj)
    return keys.reduce((acc, key) => {
    if (keys.includes(key)) acc[key] = obj[key]
    return acc
  }, {})
  return (obj) => {
    return keys.reduce((acc, key) => {
      if (keys.includes(key)) acc[key] = obj[key]
      return acc
    }, {})
  }
}

export const reduce = (obj, fn, inAcc) => {
  let acc = inAcc
  for(let key in obj) if (obj.hasOwnProperty(key)) {
    acc = fn(acc, obj[key], key)
  }
  return acc
}

export const jsPretty = (obj) => JSON.stringify(obj, null, 2)

/**
 * Alphabetizes a list
 * @param propName
 * @param caseSensitive
 * @returns {Function}
 */
export const sortList = (propName, caseSensitive=false) => {
  if(!caseSensitive) {
    return (list) => {
      return list.slice().sort((a, b) => {
        if (a[propName] === b[propName])
          return 0

        if (a[propName] < b[propName])
          return -1

        return 1
      })
    }
  } else {
    return (list) => {
      return list.slice().sort((a, b) => {
        try {
          const propA = a[propName].toLowerCase()
          const propB = b[propName].toLowerCase()
          if (propA === propB) return 0
          if (propA < propB) return -1
          return 1
        } catch (e) {
          console.error(`sortList expected a string and likely got something else... returning 0. Values were as follows a.${propName}:`, a[propName], `b.${propName}:`, b[propName], 'list:', list)
          return 0
        }
      })
    }
  }
}

export const findOneBy = (list, propName, propValue, processor=false) => {
  for(let i = 0; i < list.length; i++) {
    if(list[i][propName] === propValue) {
      if(processor)
        return processor(Object.assign({}, list[i]))
      return Object.assign({}, list[i])

    }
  }
  return null
}

/**
 * Builds an index on the attribute defined by indexAttr. Creates a function that takes an
 * Output is a function that takes an indexAttr value 'index' and returns the object or undefined
 * If a second 'attrName' parameter is
 * supplied returns the value of [index][attrName] - returns 'null' if index is not found, undefined if attrName is not found
 * @param list
 * @param indexAttr
 * @returns {Function}
 */
export const indexify = (list, indexAttr) => {
  const obj = list.reduce((acc, item) => {
    if(acc.hasOwnProperty(item[indexAttr])) throw Error(`multiple objects in list have same .${indexAttr} value: ${item[indexAttr]}`)
    acc[item[indexAttr]] = item
    return acc
  }, {})
  const fn = (id, attrName=false) => {
    if(attrName) {
      return (obj[id])? obj[id][attrName] : null
    }
    return obj[id]
  }
  fn.obj = obj

  return fn
}

/**
 * Returns a new state with newProps assigned.
 * @param state
 * @param newProps
 * @returns {any}
 */
export const newState = (state, newPropsOrPath, propValue, merge=true ) => {
  if(typeof propValue === 'undefined')  return Object.assign({}, state, newPropsOrPath)
  const path = (typeof newPropsOrPath === 'string')? newPropsOrPath.split('.') : newPropsOrPath
  return newStateByPath(state, path, propValue, merge)
}

export const newStateByPath = (state, path, propValue, merge=false ) => {
  const attrName = path.shift();
  let childState
  if(path.length > 0) {
    childState = {[attrName]:newState(state[attrName], path, propValue, merge)}
  } else {
    if(merge && state.hasOwnProperty(attrName) && typeof propValue === 'object' && propValue.constructor !== Array) {
      childState = {[attrName]:Object.assign({}, state[attrName], propValue)}
    } else {
      childState =  {[attrName]:propValue}
    }
  }
  return Object.assign({}, state, childState)
}

/// SAGA RELATED

/**
 * Passed into redux-saga select
 * @param state
 * @param path
 * @returns {string}
 */
export const getStatePath = (state, path) => {
  const pathNodes = path.split('.')

  if(!path) return state

  return pathNodes.reduce((acc, pathNode) => {
    return acc[pathNode]
  }, state)
}


export const getState = (path) => {
  return select(getStatePath, path)
}

/**
 * Add a response handler to keep exceptions under control (and to keep our Sagas from crashing)
 */
axios.interceptors.response.use(
  (response) => {
    if(response.data && response.data.hasOwnProperty('success')) {
      return response.data
    }
    return {
      success: true,
      data: response.data
    }
  },
  function (error) {
    let data = (error.response && error.response.data && typeof error.response.data === 'object')? error.response.data : false
    if(data && data.hasOwnProperty('success')) {
      return error.response.data
    }

    let userMessage = data.userMessage ? data.userMessage : false

    if(!userMessage) {
      try {
        switch(error.response.status) {
          case 404 :
            userMessage = '404 error. Contact your system administrator'
            break
          default :
            userMessage = 'Unknown server error'
        }
      } catch(e) {
        userMessage = 'Unknown server error'
      }
    }

    return {
      success: false,
      data,
      userMessage
    }
  }
)


/**
 * Handle API delete
 * @param url
 * @returns {Promise<any>}
 */
export const del = (url) => { //use with yield
  return new Promise( (resolve, reject) => {
    axios.delete(url)
      .then((resp) => {
        resolve(resp)
      })
  })
}

/**
 * Handle API post
 * @param url
 * @param payload
 * @returns {Promise<any>}
 */
export const post = (url, payload) => { //use with yield
  return new Promise( (resolve, reject) => {
    axios.post(url, payload)
      .then((resp) => {
        resolve(resp)
      })
  })
}

/**
 * Handle API get
 * @param url
 * @param params
 * @returns {Promise<any>}
 */
export const get = (url, params=false) => { //use with yield
  return new Promise( (resolve, reject) => {
    axios.get(url, { params })
      .then((resp) => {
        resolve(resp)
      })
  })
}

export const payloadOnly = (fn) => (state, action=false) => {
  if(action === false) {
    return fn(state.payload)
  }
  return fn(state, action.payload)
}


export const action = (type, payload) =>{ return {type, payload} }

export const computeKioskCapabilities = (kioskDefaultConfiguration, kiosk=false, includeAdminExtras=false) => {
  const data = (kiosk)? kiosk : kioskDefaultConfiguration
  const resp = [
    ATR_CAN_PRINT,
    ATR_ENABLE_EMAIL_SLIP,
    ATR_ENABLE_GPS,
    ATR_ENABLE_CAMERA,
    ATR_ACCEPT_KEYBOARD_INPUT,
    ATR_REQUEST_SEND_REPORT,
    ATR_ENABLE_KEYPAD,
    ATR_IS_OUT_OF_BUILDING,
    ATR_ATTENDANCE_TYPE_ID,
    ATR_CHECKIN_FLOW,
    'attendanceDirection',
  ].reduce((acc, atrName) => {
    acc[atrName] = {
      val: data.hasOwnProperty(atrName)? data[atrName] : kioskDefaultConfiguration[atrName],
      changeable: kioskDefaultConfiguration[atrName + 'Changeable']
    }
    return acc
  }, {})

  if(includeAdminExtras && kiosk) {
    resp[ATR_IS_ENABLED] = {
      val: data[ATR_IS_ENABLED],
      changeable: true
    }
    resp[ATR_IS_LOCKED] = {
      val: data[ATR_IS_LOCKED],
      changeable: true
    }
  }
  return resp
}

export const computePPKKey = (group, device) => {
  const ppkKey = ((parseInt(group) - 1) * 255) + parseInt(device)
  return isNaN(ppkKey)? '' : ppkKey
}
/**
 *
 * @param fn
 * @param path
 * @returns {Function}
 */
export const sagaPayloadAndState = (fn, path='') => {
  return function* (action) {
    const state = yield getState(path)
    yield fn(state, action.payload)
  }
}

export const millis = seconds => seconds * 1000

export const sagaPayload = (fn) => {
  return function* (action) {
    yield fn(action.payload)
  }
}

export const getLocation = () => {
  let res, rej
  const prom = new Promise((resolve, reject)=>{
    res = resolve
    rej = reject
  })
  navigator.geolocation.getCurrentPosition(
    (position) => {
      res(position)
    },
    (err)=>{
      rej(err)
    })
  return prom
}

export const uiSnackMessageOnResponse = (snackMessageShow) => {
  return function* (response, showSuccess = true)
  {
    let message = response.userMessage ?
      response.userMessage
      : ((response.data && response.data.userMessage) ? response.data.userMessage : ((typeof showSuccess === 'string') ? showSuccess : false))
    if (response.success) {
      if (showSuccess) {
        const autoHideMillis = (message && message.indexOf('\n') > -1)? DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS_LONG : DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS
        yield put(snackMessageShow((message || 'Success'), STATUS_MESSAGE_TYPE_SUCCESS, autoHideMillis))
      }
    } else {
      const autoHideMillis = (response.nextAction === 'showLogin')? DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER : ((message && message.indexOf('\n') > -1)? DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR_LONG : DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR)
      yield put(snackMessageShow((message || 'Unknown error'), STATUS_MESSAGE_TYPE_ERROR, autoHideMillis))
    }
    return response.success
  }
}

export const isA = (obj, is) => {
  let type = typeof obj
  if(is) {
    switch (type) {
      case 'object' :
        if (obj === null) {
          return 'null' === is
        }
        if (obj.constructor === Array) {
          return 'array' === is
        }
        if (obj.constructor === Date) {
          return 'date'=== is
        }

        break
      case 'number' :
        if (isNaN(obj)) {
          return 'NaN' === is
        }
        break
      default :

    }
    return type === is
  } else {
    switch (type) {
      case 'object' :
        if (obj === null) {
          return 'null'
        }
        if (obj.constructor === Array) {
          return 'array'
        }
        if (obj.constructor === Date) {
          return 'date'
        }

        break;
      case 'number' :
        if (isNaN(obj)) {
          return 'NaN'
        }
        break
      default :
    }
  }
  return type
}

export const  logicallyIdentical = function(item1, item2, ignoreTree) {
  let i,
    key,
    item1Keys = [],
    item1Type = isA(item1);

  ignoreTree = (isA(ignoreTree) === 'object')? ignoreTree : {};

  if(item1Type !== isA(item2))
    return false;

  switch(item1Type) {
    case 'array' :
      if(item1.length !== item2.length) {
        return false;
      }
      for(i = 0; i < item1.length; i++) {
        if(!logicallyIdentical(item1[i], item2[i], ignoreTree))
          return false;
      };
      break;
    case 'object' :
      for(key in item1) if (item1.hasOwnProperty(key) && (ignoreTree[key] !== true)) {
        item1Keys.push(key);
        if(!item2.hasOwnProperty(key))
          return false;
        if(!logicallyIdentical(item1[key], item2[key], ignoreTree[key]))
          return false;
      }
      //make sure all properties of obj2 are in obj1
      for(key in item2) if(item2.hasOwnProperty(key) && (ignoreTree[key] !== true)) {
        if(item1Keys.indexOf(key) < 0) {
          return false;
        }
      }
      break;
    case 'date' :
      if(item1.getTime() !== item2.getTime())
        return false;
      break;
    case 'NaN' :
      //NaN are not equivalent in Javascript when evaluated with == or === but structurally they'd be the same, so 'pass'
      break;
    case 'number' :
    case 'string' :
    case 'null' :
      if(item1 !== item2) {
        return false;
      }
      break
    default :
  }

  return true;
};

export const getAtTypesByFacilityId = function (atTypes, facilities){
  const defaultAtType = atTypes.find(atType => atType.id === 0);

  const atTypesByFacilityId = atTypes.reduce((acc, atType) => {
      if (!acc[atType.facilityId]) {
          acc[atType.facilityId] = [defaultAtType];
      }
      if (atType.id !== 0) {
          acc[atType.facilityId].push(atType);
      }
      return acc;
  }, {});

  Object.keys(atTypesByFacilityId).forEach(facilityId => {
      if (atTypesByFacilityId[facilityId].length > 1 && atTypesByFacilityId[facilityId][0].id === 0) {
          const defaultElement = atTypesByFacilityId[facilityId].shift();
          atTypesByFacilityId[facilityId].sort((a, b) => a.displayName.localeCompare(b.displayName));
          atTypesByFacilityId[facilityId].unshift(defaultElement);
      }
  });

  facilities.forEach(facility => {
      if (!atTypesByFacilityId[facility.id]) {
          atTypesByFacilityId[facility.id] = [defaultAtType];
      }
  });

  return atTypesByFacilityId;
}

export const getAtReasonsByFacilityId = function (atReasons, facilities){
  const defaultAtReason = atReasons.find(atReason => atReason.id === 0);

  const atReasonsByFacilityId = atReasons.reduce((acc, atReason) => {
      if (!acc[atReason.facilityId]) {
          acc[atReason.facilityId] = [defaultAtReason];
      }
      if (atReason.id !== 0) {
          acc[atReason.facilityId].push(atReason);
      }
      return acc;
  }, {});

  Object.keys(atReasonsByFacilityId).forEach(facilityId => {
      if (atReasonsByFacilityId[facilityId].length > 1 && atReasonsByFacilityId[facilityId][0].id === 0) {
          const defaultElement = atReasonsByFacilityId[facilityId].shift();
          atReasonsByFacilityId[facilityId].sort((a, b) => a.displayName.localeCompare(b.displayName));
          atReasonsByFacilityId[facilityId].unshift(defaultElement);
      }
  });

  facilities.forEach(facility => {
      if (!atReasonsByFacilityId[facility.id]) {
          atReasonsByFacilityId[facility.id] = [defaultAtReason];
      }
  });

  return atReasonsByFacilityId;
}


export const changePageToHtml = (html) => {
  window.document.write(html)
  window.document.close()
}

export function* showBootMessageIfAny() {
  let bootMessage = Cookies.get('bootMessage')
  if(bootMessage === undefined){
    return false
  }

  bootMessage = JSON.parse(bootMessage)
  Cookies.remove('bootMessage')

  let isSuccess = bootMessage.isSuccess
  let message = bootMessage.message 

  if (isSuccess) {
    const autoHideMillis = (message && message.indexOf('\n') > -1)? DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS_LONG : DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS
    yield put(snackMessageShow((message), STATUS_MESSAGE_TYPE_SUCCESS, autoHideMillis))
  } else {
    yield put(snackMessageShow((message || 'Unknown error'), STATUS_MESSAGE_TYPE_ERROR, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  }

  return true
}

export function* performBootActionIfAny(actionDictionary) {
  let bootAction = Cookies.get('bootAction')
  if(bootAction === undefined){
    return false
  }

  bootAction = JSON.parse(bootAction)
  console.log(bootAction)
  Cookies.remove('bootAction')

  let isSuccess = bootAction.isSuccess
  let actionName = bootAction.message 

  let action = actionDictionary[actionName]
  if(!action){
    return false
  }

  if (isSuccess) {
    try{
      yield action()
    }
    catch(ex){
      console.log('Unexpected error with boot action')
    }
  } else {
    yield put(snackMessageShow(( 'Unknown error'), STATUS_MESSAGE_TYPE_ERROR, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  }

  return true
}

/**
 * Handles showing success and error messages based on response.success value.
 * @param response
 * @param showSuccess - defaults to true. If a string it is used if response.data.userMessage is not available
 * @param forceMessage - forces user of showSuccess string
 * @returns {boolean}
 */
export const uiDecoration = uiSnackMessageOnResponse(snackMessageShow, getState)

export const getFacilityOverrideSelectedValues = function(facilityOverride, atTypes, atReasons, facilities){
  const facility = facilities.find(f => f.id === facilityOverride.facilityId);
  const atTypesByFacilityId = getAtTypesByFacilityId(atTypes, facilities);
  const atReasonsByFacilityId = getAtReasonsByFacilityId(atReasons, facilities);

  const singleAttendanceId = facilityOverride.atTypeSingleId &&
  atTypesByFacilityId[facilityOverride.facilityId].some(atType => atType.id === facilityOverride.atTypeSingleId)
  ? atTypesByFacilityId[facilityOverride.facilityId].findIndex(atType => atType.id === facilityOverride.atTypeSingleId)
  : 0;

const singleAttendanceItem = atTypesByFacilityId[facilityOverride.facilityId][singleAttendanceId];

const atReasonId = facilityOverride.atReasonId &&
  atReasonsByFacilityId[facilityOverride.facilityId].some(atReason => atReason.id === facilityOverride.atReasonId)
  ? atReasonsByFacilityId[facilityOverride.facilityId].findIndex(atReason => atReason.id === facilityOverride.atReasonId)
  : 0;

const atReasonItem = atReasonsByFacilityId[facilityOverride.facilityId][atReasonId];

const atTypeTardyId = facilityOverride.atTypeTardyId &&
  atTypesByFacilityId[facilityOverride.facilityId].some(atType => atType.id === facilityOverride.atTypeTardyId)
  ? atTypesByFacilityId[facilityOverride.facilityId].findIndex(atType => atType.id === facilityOverride.atTypeTardyId)
  : 0;

const tardyItem = atTypesByFacilityId[facilityOverride.facilityId][atTypeTardyId];

const atTypeAbsentId = facilityOverride.atTypeAbsentId &&
  atTypesByFacilityId[facilityOverride.facilityId].some(atType => atType.id === facilityOverride.atTypeAbsentId)
  ? atTypesByFacilityId[facilityOverride.facilityId].findIndex(atType => atType.id === facilityOverride.atTypeAbsentId)
  : 0;

const absentItem = atTypesByFacilityId[facilityOverride.facilityId][atTypeAbsentId];

const overrideTypeName = kioskFacilityAttendanceOverridesTypes
.find(type => facilityOverride.overrideTypeId === type.id).displayName;

return {facility, overrideTypeName, singleAttendanceItem, tardyItem, absentItem, atReasonItem};
}