import React from 'react'
import connect from 'react-redux/es/connect/connect'
import { findOneBy } from '@relativity/js-util'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";


import KioskSettingListItem from "common/components/KioskSettingListItem"
import {
  computeKioskCapabilities
} from 'redux/utils'


import styles from 'apps/admin/components/KioskSettings/style'

import {
  createSettings
} from "common/kioskSettings"

import {
  ATR_ACCEPT_KEYBOARD_INPUT,
  ATR_ATTENDANCE_TYPE_ID,
  ATR_CAN_PRINT, ATR_CHECKIN_FLOW, ATR_ENABLE_CAMERA,
  ATR_ENABLE_EMAIL_SLIP,
  //ATR_ENABLE_GPS,
  ATR_ENABLE_KEYPAD, ATR_IS_OUT_OF_BUILDING,
  ATR_MAX_LEASE_LENGTH,
  ATR_REQUEST_SEND_REPORT
} from "common/kioskConstants"

import {
  kioskCheckinFlowModes,
  secondsIn,
} from 'redux/constants'
import TableBody from "@material-ui/core/es/TableBody/TableBody";

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import {sendKioskDefaultConfiguration, setKdcProperty, returnToSetPassword} from "redux/install/Actions";
import {INSTALL_STEP_KIOSK_DEFAULT_CONFIGURATION} from "redux/install/constants";

/**
 * Currys a function to the current configurationId, locationId, and the dispatcher (defaultConfiguration, or kiosk specific configuration)
 * @param configurationId
 * @param locationId
 * @param changeDispatcher
 * @returns {Function}
 */
const contextualizeChangeSetting = (changeDispatcher, changeable='') => {
  return (settingName, settingValue) => {
    changeDispatcher(settingName + changeable, settingValue)
  }
}


class KioskDefaultsDialog extends React.Component {
  constructor() {
    super()
    this.state = {controlsDisabled:false}
  }

  confirmThisStep(fn) {
    fn()
  }

  addHooks() {
    //testing

    window.stHooks.addHook('kioskDefaultsEnableEverything', (e)=>{
      [
        ATR_CAN_PRINT,
        ATR_ENABLE_KEYPAD,
        ATR_ACCEPT_KEYBOARD_INPUT,
        ATR_REQUEST_SEND_REPORT,
        //ATR_ENABLE_GPS,
        ATR_ENABLE_CAMERA].forEach((val) => {
          this.props.setKdcProperty(val, e)
          this.props.setKdcProperty(val + 'Changeable', e)
      })
      this.props.setKdcProperty(ATR_ATTENDANCE_TYPE_ID + 'Changeable', e)
      this.props.setKdcProperty(ATR_CHECKIN_FLOW + 'Changeable', e)
    })
  }
  removeHooks() {
    window.stHooks.removeHook(['kioskDefaultsEnableEverything'])
  }

  render() {
    if(!this.props.step3
      ||
      (this.props.browserCurrentStep !== INSTALL_STEP_KIOSK_DEFAULT_CONFIGURATION  )
    ) {
      this.removeHooks()
      return null
    } else {
      this.addHooks()
    }

    const {
      classes,
      sendKioskDefaultConfiguration,
      step3: {
        atTypes,
        kioskDefaultConfiguration,
      },
      setKdcProperty,
      saveKioskDefaultConfigurationIsEnabled,
      returnToSetPassword
    } = this.props

    const onEdit = contextualizeChangeSetting(setKdcProperty)
    const onEditChangeable = contextualizeChangeSetting(setKdcProperty, 'Changeable')

    const capabilities = computeKioskCapabilities(kioskDefaultConfiguration)
    const settingInit = [
      {
        name:ATR_MAX_LEASE_LENGTH,
        changeable: false,
        val: kioskDefaultConfiguration[ATR_MAX_LEASE_LENGTH],
        possibleValues: secondsIn,
        clickOn: (val) => onEdit(ATR_MAX_LEASE_LENGTH, val),
      },
      {
        name:ATR_CAN_PRINT,
        isOn: capabilities[ATR_CAN_PRINT].val,
        changeable: capabilities[ATR_CAN_PRINT].changeable,
        clickOn:() => onEdit(ATR_CAN_PRINT, !capabilities[ATR_CAN_PRINT].val),
        clickOnChangeable: () => onEditChangeable(ATR_CAN_PRINT, !capabilities[ATR_CAN_PRINT].changeable),
      },
      {
        name:ATR_ENABLE_EMAIL_SLIP,
        isOn: capabilities[ATR_ENABLE_EMAIL_SLIP].val,
        changeable: capabilities[ATR_ENABLE_EMAIL_SLIP].changeable,
        clickOn:() => onEdit(ATR_ENABLE_EMAIL_SLIP, !capabilities[ATR_ENABLE_EMAIL_SLIP].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_EMAIL_SLIP, !capabilities[ATR_ENABLE_EMAIL_SLIP].changeable),
      },
      {
        name:ATR_ENABLE_KEYPAD,
        isOn: capabilities[ATR_ENABLE_KEYPAD].val,
        changeable: capabilities[ATR_ENABLE_KEYPAD].changeable,
        clickOn:() => onEdit(ATR_ENABLE_KEYPAD, !capabilities[ATR_ENABLE_KEYPAD].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_KEYPAD, !capabilities[ATR_ENABLE_KEYPAD].changeable),
      },
      {
        name: ATR_ACCEPT_KEYBOARD_INPUT,
        isOn: capabilities[ATR_ACCEPT_KEYBOARD_INPUT].val,
        changeable: capabilities[ATR_ACCEPT_KEYBOARD_INPUT].changeable,
        clickOn: () => onEdit(ATR_ACCEPT_KEYBOARD_INPUT, !capabilities[ATR_ACCEPT_KEYBOARD_INPUT].val),
        clickOnChangeable: () => onEditChangeable(ATR_ACCEPT_KEYBOARD_INPUT, !capabilities[ATR_ACCEPT_KEYBOARD_INPUT].changeable),

      },
      {
        name:ATR_REQUEST_SEND_REPORT,
        isOn: capabilities[ATR_REQUEST_SEND_REPORT].val,
        changeable:capabilities[ATR_REQUEST_SEND_REPORT].changeable,
        clickOn: () => onEdit(ATR_REQUEST_SEND_REPORT, !capabilities[ATR_REQUEST_SEND_REPORT].val),
        clickOnChangeable: () => onEditChangeable(ATR_REQUEST_SEND_REPORT, !capabilities[ATR_REQUEST_SEND_REPORT].changeable),
      },
      {
        name:ATR_ATTENDANCE_TYPE_ID,
        changeable: capabilities[ATR_ATTENDANCE_TYPE_ID].changeable,
        val:capabilities[ATR_ATTENDANCE_TYPE_ID].val,
        possibleValues:atTypes,
        clickOn: (val) => onEdit(ATR_ATTENDANCE_TYPE_ID, val),
        clickOnChangeable: () => onEditChangeable(ATR_ATTENDANCE_TYPE_ID, !capabilities[ATR_ATTENDANCE_TYPE_ID].changeable),
        textProvider: findOneBy(atTypes, 'id', capabilities[ATR_ATTENDANCE_TYPE_ID].val)
      },

      {
        name: ATR_IS_OUT_OF_BUILDING,
        isOn: capabilities[ATR_IS_OUT_OF_BUILDING].val,
        changeable: capabilities[ATR_IS_OUT_OF_BUILDING].changeable,
        clickOn:() => onEdit(ATR_IS_OUT_OF_BUILDING, !capabilities[ATR_IS_OUT_OF_BUILDING].val),
        clickOnChangeable: () => onEditChangeable(ATR_IS_OUT_OF_BUILDING, !capabilities[ATR_IS_OUT_OF_BUILDING].changeable),
      },
      {
        name:ATR_CHECKIN_FLOW,
        changeable: capabilities[ATR_CHECKIN_FLOW].changeable,
        val: capabilities[ATR_CHECKIN_FLOW].val,
        possibleValues: kioskCheckinFlowModes,
        clickOn: (val) => onEdit(ATR_CHECKIN_FLOW, val),
        clickOnChangeable: () => onEditChangeable(ATR_CHECKIN_FLOW, !capabilities[ATR_CHECKIN_FLOW].changeable),
        textProvider: findOneBy(kioskCheckinFlowModes, 'id', capabilities[ATR_CHECKIN_FLOW].val)
      },
      /*{
        name:ATR_ENABLE_GPS,
        isOn: capabilities[ATR_ENABLE_GPS].val,
        changeable: capabilities[ATR_ENABLE_GPS].changeable,
        clickOn:() => onEdit(ATR_ENABLE_GPS, !capabilities[ATR_ENABLE_GPS].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_GPS, !capabilities[ATR_ENABLE_GPS].changeable),
      },*/
      {
        name:ATR_ENABLE_CAMERA,
        isOn: capabilities[ATR_ENABLE_CAMERA].val,
        changeable: capabilities[ATR_ENABLE_CAMERA].changeable,
        clickOn:() => onEdit(ATR_ENABLE_CAMERA, !capabilities[ATR_ENABLE_CAMERA].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_CAMERA, !capabilities[ATR_ENABLE_CAMERA].changeable),
      },
    ]

    const settingConfiguration = createSettings(settingInit)
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title">
          Kiosk Default Configuration (Step 3)
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Typography>Initial configuration for all kiosks</Typography>
          <Table style={{width:'100%'}}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">{'Enabled by Default'}</TableCell>
                <TableCell component="th"></TableCell>
                <TableCell padding="checkbox" align="right" style={{width:'20%'}}>Changeable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { settingConfiguration.map((item, index) => {
                return <KioskSettingListItem key={index} {...item} adminContext />
              })
              }
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions style={{justifyContent:"space-between"}}>
          <Button onClick={returnToSetPassword} color="secondary">
            Back
          </Button>
          <Button disabled={saveKioskDefaultConfigurationIsEnabled !== undefined && !saveKioskDefaultConfigurationIsEnabled} onClick={()=>this.confirmThisStep(sendKioskDefaultConfiguration)} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => state.install

const mapDispatchToProps = {
  setKdcProperty,
  sendKioskDefaultConfiguration,
  returnToSetPassword
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(KioskDefaultsDialog))
