import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

import styles from 'common/components/style'


import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import {licenseSend, sendBuilding, setBuildingIsEnabled, settingsUpdateProperty, returnToSetEntities} from 'redux/install/Actions'
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ToggleIconButton from "common/components/ToggleIconButton";
import {INSTALL_STEP_SET_BUILDINGS} from "redux/install/constants";
import TextField from "@material-ui/core/TextField";

class BuildingChooserDialogClass extends React.Component {
  constructor(props) {
    super(props);
    this.setSearch = this.setSearch.bind(this)
    this.state = {
      controlsDisabled:false,
      tabIndex: 0,
      searchString:'',
      totalEnabledBuildings: 0
    }
  }

  confirmThisStep(fn) {
    this.setState({
      controlsDisabled:true
    })
    fn()
  }

  addHooks() {
    //testing
    window.stHooks.addHook('building', (e)=> {
      const {buildingIds=true} = e || {}
      this.getBuildingStepData().buildings.map( building => {
        if(buildingIds === true || buildingIds.includes(building.id)) {
          this.props.setBuildingIsEnabled({id:building.id, isEnabled:true})
        }
        return null
      })
    })
  }

  removeHooks() {
    window.stHooks.removeHook(['building'])
  }

  getBuildingStepData() {
    return this.props['step' + INSTALL_STEP_SET_BUILDINGS]
  }

  switchTab = (event, tabIndex) => {
    this.setState({ tabIndex });
  }

  setSearch(event) {
    this.setState({
      searchString: event.target.value
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.browserCurrentStep !== this.props.browserCurrentStep && this.props.browserCurrentStep === INSTALL_STEP_SET_BUILDINGS) {
      this.initializeSelection();
    }
  }

  initializeSelection() {
    const stepData = this.getBuildingStepData();
    const buildings = [...stepData.buildings]
    this.setState({
      totalEnabledBuildings: buildings.filter(building => building.isEnabled).length,
      controlsDisabled: false
    });
  }


  handleToggleBuilding = (id, isEnabled) => {
    this.props.setBuildingIsEnabled({ id, isEnabled: !isEnabled });
    this.setState(prevState => ({
      totalEnabledBuildings: !isEnabled ? prevState.totalEnabledBuildings + 1 : prevState.totalEnabledBuildings - 1
    }));
  }

  render() {
    if(!this.props.settings) return null
    const stepData = this.getBuildingStepData()
    const {classes, close, sendBuilding, browserCurrentStep, returnToSetEntities} = this.props
    const onClose = (close)? close : ()=>{console.log('This should be an easter egg, but it\'s not')}
    if(browserCurrentStep !== INSTALL_STEP_SET_BUILDINGS) {
      this.removeHooks()
      return null
    } else {
      this.addHooks()
    }
    const buildingSorted = [...stepData.buildings]
    buildingSorted.sort((a, b) => {
      const aDisplayName = a.displayName.toLowerCase()
      const bDisplayName = b.displayName.toLowerCase()
      return bDisplayName < aDisplayName? 1 : bDisplayName > aDisplayName? -1 : 0
    })

    const buildingList = buildingSorted.reduce((acc, building) => {
      if(building.displayName.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1) {
        acc.push(<ListItem title={`id: ${building.id}`} key={building.id}><ToggleIconButton isOn={building.isEnabled} 
          onClick={()=> this.handleToggleBuilding(building.id, building.isEnabled)} />{building.displayName}</ListItem>)
      }
      return acc
    }, [])

    const totalEnabledBuildings = buildingSorted.filter(building => building.isEnabled).length;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title">
          Choose Buildings
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
            <Typography>Choose which Buildings will be used by SchoolTRAK</Typography>
            <Typography>{totalEnabledBuildings} of {buildingSorted.length}.</Typography>
            <TextField
              style={{marginTop:'10px'}}
              label={`Search for a Building`}
              variant="outlined"
              fullWidth
              onKeyUp={this.setSearch}
            />
            <List>
              {buildingList}
            </List>
        </DialogContent>
        <DialogActions style={{justifyContent:"space-between"}}>
          <Button onClick={returnToSetEntities} color="secondary">
            Back
          </Button>
          <Button disabled={this.state.controlsDisabled || totalEnabledBuildings === 0} onClick={()=>this.confirmThisStep(sendBuilding)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const YearEntityBuildingChooserDialog = connect(
  state => state.install,
  {
    licenseSend,
    sendBuilding,
    setBuildingIsEnabled,
    settingsUpdateProperty,
    returnToSetEntities
  }
)(withStyles(styles, { withTheme: true })(BuildingChooserDialogClass))

export default YearEntityBuildingChooserDialog
