import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import moment from 'moment-timezone'
import styles from './style'
import Typography from '@material-ui/core/Typography'
import {Divider} from "@material-ui/core";
import { FORMAT_AMPM_YMD } from '../../../../redux/constants'

class QueueInfo extends React.Component {

  render() {
    const {classes, queues, selectedStudent, synced, syncSuccess, retrieved} = this.props
    const anyActualQueueRecords = typeof queues !== "undefined" && queues.length

    if(selectedStudent.id == null) return (<span></span>);

    if(!synced) return (
      <div className={classes.singleContentSegment}>
        <Divider className={classes.queueDivider}/>
        <Typography
            variant={'h6'}>
            {'Sync queue...'}
        </Typography>
      </div>
    )

    if(synced && !syncSuccess) return (
      <div className={classes.singleContentSegment}>
        <Divider className={classes.queueDivider}/>
        <Typography
            variant={'h6'}>
            {'Could not synchronize queue'}
        </Typography>
      </div>
    )

    if(!retrieved) return (
      <div className={classes.singleContentSegment}>
        <Divider className={classes.queueDivider}/>
        <Typography
            variant={'h6'}>
            {"Retrieving student's queue info..."}
        </Typography>
      </div>
    );

    if(!anyActualQueueRecords) return (
        <div className={classes.singleContentSegment}>
          <Divider className={classes.queueDivider}/>
          <Typography
              variant={'h6'}>
              {'No guidance or nurse’s queue records for today'}
          </Typography>
        </div>
    );

    return (
        <div className={classes.singleContentSegment}>
          <Divider className={classes.queueDivider}/>
          {queues.map((q, index) => (
            <Grid container spacing={24} key={index}>     
                <Grid item xs={3}
                    className={classes.textInfoField}>
                    <Typography                        
                        variant={'h6'}>
                        {q.queueType}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                      disabled={true}
                      fullWidth={true}
                      variant="outlined"
                      label={'Status'}
                      value={q.status || ' '}
                      InputProps={{
                        classes: {
                          input: classes.input,
                        },
                      }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                      disabled={true}
                      fullWidth={true}
                      variant="outlined"
                      label={'In Time'}
                      value={q.inTime ? moment.unix(q.inTime).format(FORMAT_AMPM_YMD) : ' '}
                      InputProps={{
                        classes: {
                          input: classes.input,
                        },
                      }}
                    />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled={true}
                    fullWidth={true}
                    variant="outlined"
                    label={'Out Time'}
                    value={q.outTime !== null && q.outTime !== '0' ? moment.unix(q.outTime).format(FORMAT_AMPM_YMD) : ' '}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>                 
            </Grid>
          ))}  
        </div>      
    )
  }
}

const mapStateToProps = state => ({
  ...state.admin.locatorStudentInfo.queueInfo,
  ...state.admin.queueSyncResponse,
  selectedStudent: state.admin.locatorStudentPicker.selectedStudent
})

export default connect(
  mapStateToProps,
)(withStyles(styles, { withTheme: true })(QueueInfo))