import {newState, payloadOnly} from "@relativity/js-util";
import {IS_READY, LOGIN_SET_USER, LOGIN_SHOW_UI, STATE_UPDATE} from './Actions'
import {
  GO_TO_STEP,
  SET_BUILDING_IS_ENABLED,
  SET_ENTITY_IS_ENABLED, SET_KDC_PROPERTY,
  SETTINGS_UPDATE_PROPERTY,
  ENABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION,
  DISABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION,
  SETTINGS_SET_TEMP_PROPERTY
} from "redux/install/Actions";
import {logicallyIdentical} from "redux/utils";
import {INSTALL_STEP_SET_BUILDINGS, INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR} from "redux/install/constants";

const showLoginDialog = (state, payload) => {
  return newState(state, `dialogs.login`, payload)
}

const stateUpdate = (state, payload) => {


  if(logicallyIdentical(payload, state.lastServerState)) return state
/*
  for(let i = 0; i < state.lastStringify.length; i++) {
    if(state.lastStringify[i] !== stateString[i]) {
      console.log(`stateUpdate detected a change at ${i}`)
      let index = (i - 5 > 0)? i - 5 : 0
      console.log('last', state.lastStringify.substring(index, index + 10))
      console.log('new', stateString.substring(index, index + 10))
      console.log({last: payload})
      break;
    }
  }*/
  console.log('stateUpdate detected a change')
  const stateString = JSON.stringify(payload)
  if(payload[`step${INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR}`] && payload[`step${INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR}`].schoolYears) {
    for(let schoolYear of payload[`step${INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR}`].schoolYears) {
      if(schoolYear.isVendorCurrent) {
        schoolYear.displayName = schoolYear.displayName + ' - current'
      }
    }
  }
  const transitionState = newState(state, payload)
  transitionState.browserCurrentStep = payload.currentStep
  transitionState.lastServerState = JSON.parse(stateString)
  return transitionState
}

const goToStep = (state, step) => {
  const stepNum = (step === 'next')? state.browserCurrentStep + 1 : ((step === 'prev')? state.browserCurrentStep -1 : parseInt(step))
  if(stepNum > state.currentStep || stepNum < 1) return state
  return newState(state, 'browserCurrentStep', stepNum);
}

const loginSetUser = (state, payload) => {
  if(!payload) return state
  return newState(state, 'loggedInUser', payload)
}

const updateGeneralSetting = (state, data) => {
  if(data.propertyName.includes('.')) {
    return newState(newState(state, `settings.${data.propertyName}`, data.val), {isDirty: data.propertyName})
  }

  return newState(state, `settings`, {[data.propertyName]: data.val, isDirty:data.propertyName})
}

const setKdcProperty = (state, data) => {
  return newState(state, `step3.kioskDefaultConfiguration.${data.propertyName}`, data.val)
}

const setEntityIsEnabled = (state, data) => {
  const entities = state[`step${INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR}`].entities.map(entity => {
    if(entity.id === data.id)
      return Object.assign({}, entity, {isEnabled:data.isEnabled})
    return entity
  })

  return newState(state, `step${INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR}`, {entities})
}

const setBuildingIsEnabled = (state, data) => {
  let totalEnabledBuildings = (data.isEnabled)? 1 : 0

  const buildings = state[`step${INSTALL_STEP_SET_BUILDINGS}`].buildings.map(item => {
    if(item.isEnabled) totalEnabledBuildings++
    if(item.id === data.id)
      return Object.assign({}, item, {isEnabled:data.isEnabled})
    return item
  })

  //abort if they've chosen too many buildings
  if(data.isEnabled && totalEnabledBuildings > state.settings.licensingJson.maxLicensedBuildings) {
    return state
  }

  return newState(state, `step${INSTALL_STEP_SET_BUILDINGS}`, {buildings})
}

const enableSaveKioskDefaultConfiguration = (state, data) => {
  return newState(state, `saveKioskDefaultConfigurationIsEnabled`, true)
}

const disableSaveKioskDefaultConfiguration = (state, data) => {
  return newState(state, `saveKioskDefaultConfigurationIsEnabled`, false)
}

const settingsSetTempProperty = (state, data) => {
  return newState(state, `settings.temp`, {[data.propertyName]: data.val, isDirty:data.propertyName});
}

export default {
  [LOGIN_SHOW_UI] : payloadOnly(showLoginDialog),
  [IS_READY] : (state) => newState(newState(state, {isReady:true}), `dialogs.login`, false),
  [STATE_UPDATE] : payloadOnly(stateUpdate),
  [LOGIN_SET_USER] : payloadOnly(loginSetUser),
  [SETTINGS_UPDATE_PROPERTY] : payloadOnly(updateGeneralSetting),
  [SET_KDC_PROPERTY] : payloadOnly(setKdcProperty),
  [GO_TO_STEP] : payloadOnly(goToStep),
  [SET_ENTITY_IS_ENABLED] : payloadOnly(setEntityIsEnabled),
  [SET_BUILDING_IS_ENABLED] : payloadOnly(setBuildingIsEnabled),
  [ENABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION] : payloadOnly(enableSaveKioskDefaultConfiguration),
  [DISABLE_SAVE_KIOSK_DEFAULT_CONFIGURATION] : payloadOnly(disableSaveKioskDefaultConfiguration),
  [SETTINGS_SET_TEMP_PROPERTY] : payloadOnly(settingsSetTempProperty),
}