import React from 'react';
import SvgIcon from "@material-ui/core/es/SvgIcon/SvgIcon";

class STASwirl extends React.Component{
  render() {

    return <SvgIcon style={{backgroundImage:"url(/images/sta_logo_swirl.svg)", backgroundSize:"contain", backgroundRepeat:"no-repeat"}}><path/></SvgIcon>
  }
}

export default STASwirl
