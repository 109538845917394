import React from "react"
import connect from "react-redux/es/connect/connect"
import {withStyles} from "@material-ui/core";
import CloudOffIcon from '@material-ui/icons/CloudOff'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye'

import PassiveIconButton from "common/components/PassiveIconButton";
import ToggleIconButton from "common/components/ToggleIconButton";
import {ID_ZERO, WORKER_JOB_COORDINATOR_LONG_RUN_SECONDS, WORKER_JOB_NAME_COORDINATOR} from "redux/constants";

import Typography from "@material-ui/core/es/Typography";
import {findOneBy, seconds} from "@relativity/js-util";

const pad2 = (num) => {
  return (num + '').padStart(2, '0')
}

class SynchronizerStatusClass extends React.Component {
  render() {
    const {
      pretendSkywardConnected,
      workerJobs,
      workerJobEdit=false,
    } = this.props

    const workerJobCoordinator = findOneBy(workerJobs, 'name', WORKER_JOB_NAME_COORDINATOR)
    let skywardConnectionOk, skywardConnectionOkIcon, workerJobCoordinatorOverdueText, skywardConnectionOkText, workerJobCoordinatorOk, workerJobCoordinatorOverdueSeconds

    if(workerJobCoordinator) {
      if(pretendSkywardConnected) {
        workerJobCoordinator.uLastRunStart = seconds()
        workerJobCoordinator.jsbRunStatusDetail.skywardConnectionVerified = true
      }

      workerJobCoordinatorOverdueSeconds = seconds() -
        (workerJobCoordinator.uLastRunStart + (workerJobCoordinator.jsbCustomConfiguration.settings.coordinatorSleepMS.val / 1000) + WORKER_JOB_COORDINATOR_LONG_RUN_SECONDS)

      workerJobCoordinatorOk = workerJobCoordinatorOverdueSeconds < 0
      skywardConnectionOk = workerJobCoordinator.jsbRunStatusDetail.skywardConnectionVerified
      skywardConnectionOkIcon = workerJobCoordinatorOk ? CloudDoneIcon : CloudOffIcon
      workerJobCoordinatorOverdueText = pad2(Math.floor(workerJobCoordinatorOverdueSeconds / 3600)) + ':' + pad2(Math.floor((workerJobCoordinatorOverdueSeconds % 3600) / 60)) + ':' + pad2(workerJobCoordinatorOverdueSeconds % 60)
      skywardConnectionOkText = workerJobCoordinatorOk ? 'Synchronizer is connected to Skyward' : `Connection was ok, but synchronizer has not reported in for ${workerJobCoordinatorOverdueText} h:m:s`
    }
    return <Typography variant="h6" >Skyward connection status
      {workerJobCoordinator?
        (<span>
          <PassiveIconButton
            onTitle='Synchronization core is running'
            offTitle={`Synchronizer health check is laggy by ${workerJobCoordinatorOverdueText} h:m:s`}
            isOn={workerJobCoordinatorOk}
          />{
          workerJobEdit
            ?<ToggleIconButton
              onClick={()=>workerJobEdit(ID_ZERO)}
              onTitle={skywardConnectionOkText}
              offTitle={`Skyward connection is down!`}
              onIcon={skywardConnectionOkIcon}
              offIcon={ReportProblemIcon}
              isOn={skywardConnectionOk}
            />
            :<PassiveIconButton
              onTitle={skywardConnectionOkText}
              offTitle={`Skyward connection is down!`}
              onIcon={skywardConnectionOkIcon}
              offIcon={ReportProblemIcon}
              isOn={skywardConnectionOk}
            />}
        </span>)
        :<PassiveIconButton
          offIcon={PanoramaFishEyeIcon}
          offTitle='Determining status'
        />
      }</Typography>
  }
}


const mapStateToProps = state => {
  return state.admin
}

export const SynchronizerStatus = connect(
  mapStateToProps,
)(withStyles({}, { withTheme: true })(SynchronizerStatusClass))



export default SynchronizerStatus