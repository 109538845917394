import React from "react"
import connect from "react-redux/es/connect/connect"
import {IconButton, withStyles} from "@material-ui/core"
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/es/Typography/Typography"
import moment from 'moment-timezone'
import EditIcon from '@material-ui/icons/Edit'
import {seconds} from "@relativity/js-util"
import ToggleIconButton from 'common/components/ToggleIconButton'
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table"
import SyncIcon from '@material-ui/icons/Sync'
import HistoryIcon from '@material-ui/icons/History'
import {
  HourglassEmpty,
  PlayArrow,
  SlowMotionVideo, StopRounded,
  SyncDisabled,
  SyncProblem
} from "@material-ui/icons";


import styles from '../style'
import {
  ID_ZERO,

  SYNC_RUN_STATUS_STARTING,
  SYNC_RUN_STATUS_STOPPED,
  SYNC_RUN_STATUS_WAITING,
  SYNC_RUN_STATUS_FAILED_STOPPED,
  SYNC_RUN_STATUS_FAILED_WAITING,
  SYNC_RUN_STATUS_RUNNING,

  WORKER_JOB_ACTION_START,
  WORKER_JOB_ACTION_STOP,

  syncRunStatusDisplayNameByName,
} from 'redux/constants'

import {
  appSettingsSave,
  updateGeneralSetting,
  emailTest,
  workerJobEdit,
  workerJobEditSave,
  workerJobStartStop,
  workerJobFetchLogs,
  startAllWorkerJobsPoll,
  stopAllWorkerJobsPoll,
} from "redux/admin/Actions"

import WorkerJobConfigDialog from 'apps/admin/components/WorkerJob/Editor'
import WorkerJobLogDialog from 'apps/admin/components/WorkerJob/LogViewer'
import SynchronizerStatus from "apps/admin/components/SynchronizerStatus"

const lastRunElapsed = (worker) => {
  const totalSeconds = (worker.runStatus !== SYNC_RUN_STATUS_RUNNING)? worker.uLastRunEnd - worker.uLastRunStart : seconds() - worker.uLastRunStart
  return `Elapsed: ${Math.floor(totalSeconds / 60) + ':' + pad2(totalSeconds % 60)} - ${syncRunStatusDisplayNameByName[worker.runStatus]}`
}

const pad2 = (num) => {
  return (num + '').padStart(2, '0')
}

const renderRunStatusDetail = (runStatusDetail) => {
  if(runStatusDetail) {
    if(runStatusDetail.userMessage) {
      return runStatusDetail.userMessage
    }
    if(runStatusDetail.successIds && runStatusDetail.errorIds) {
      return `successful: ${runStatusDetail.successIds.length} errors: ${runStatusDetail.errorIds.length}`
    }
    return JSON.stringify(runStatusDetail)
  }
  return 'No information available.'

}

class Synchronization extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty:false
    }
  }

  componentDidMount() {
    this.props.startAllWorkerJobsPoll({msPolling:500})
  }

  componentWillUnmount() {
    this.props.stopAllWorkerJobsPoll()
  }

  isDirty(val='x') {
    if(val === 'x') {
      return this.state.isDirty
    }
    this.setState({isDirty:!!val})
  }

  render() {
    const {
      classes,
      workerJobs,
      workerJobEdit,
      workerJobEditSave,
      workerJobStartStop,
      workerJobFetchLogs,
      positiveAttendanceIsEnabled,
    } = this.props

    return (
      <div className={classes.root}>
        <WorkerJobConfigDialog/>
        <WorkerJobLogDialog/>
        <Grid container className={classes.firstRow}>
          <Grid item className={classes.gridItem} xs={12}>
            <SynchronizerStatus workerJobEdit={()=>workerJobEdit(ID_ZERO)}/>
          </Grid>
          <Grid item className={classes.gridItem} xs={12} >
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" style={{align:'center', width:'10px'}}>Status</TableCell>
                  <TableCell style={{width:'10px'}}>Job</TableCell>
                  <TableCell>Last Run</TableCell>
                  <TableCell>Next Run</TableCell>
                  <TableCell>Summary</TableCell>
                  <TableCell>History</TableCell>
                  <TableCell>Config</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workerJobs.length === 0? <TableRow><TableCell colSpan={3}><Typography variant="h6">No workers. </Typography></TableCell></TableRow> : null}
                {workerJobs.reduce((acc, worker) => {
                  if(!worker.isVisibleInAdmin) return acc
                  if(worker.isPaOnly && !positiveAttendanceIsEnabled) return acc

                  let ActionIcon = PlayArrow
                  let actionDescription = 'Manually start this job'
                  let RunStatusIcon
                  let statusDescription = ''
                  let runStatusDetail = renderRunStatusDetail(worker.jsbRunStatusDetail)

                  switch(worker.runStatus) {
                    case SYNC_RUN_STATUS_STARTING :
                      RunStatusIcon = SlowMotionVideo
                      statusDescription = 'Gathering information'
                      ActionIcon = StopRounded
                      actionDescription = 'Stop this job'
                      break
                    case SYNC_RUN_STATUS_RUNNING :
                      RunStatusIcon = SyncIcon
                      statusDescription = 'Synchronizing Data'
                      ActionIcon = StopRounded
                      actionDescription = 'Stop this job'
                      break
                    case SYNC_RUN_STATUS_STOPPED :
                      RunStatusIcon = SyncDisabled
                      statusDescription = 'Stopped and will not start again unless prompted'
                      break
                    case SYNC_RUN_STATUS_WAITING :
                      RunStatusIcon = HourglassEmpty
                      statusDescription = 'Will run at next scheduled time.'
                      break
                    case SYNC_RUN_STATUS_FAILED_STOPPED :
                      RunStatusIcon = SyncDisabled
                      statusDescription = 'The last run failed. Will not start again unless prompted'
                      break
                    case SYNC_RUN_STATUS_FAILED_WAITING :
                      RunStatusIcon = SyncProblem
                      statusDescription = 'The last run failed. Will run at next scheduled time.'
                      break
                    default :
                  }
                  const action = (ActionIcon === PlayArrow)? WORKER_JOB_ACTION_START : WORKER_JOB_ACTION_STOP

                  const lastRun = (worker.uLastRunStart < 2)? 'Never' : moment.unix(worker.uLastRunStart).format('ddd HH:mm:ss')
                  let nextRun = 'Must be enabled';
                  if(worker.isEnabled)
                    nextRun = (!worker.uNextRunStart)? 'Manual start' : (worker.uNextRunStart === 1? 'Start Triggered' : moment.unix(worker.uNextRunStart).format('ddd HH:mm:ss'))

                  acc.push(<TableRow key={worker.id}>
                    <TableCell padding="checkbox" style={{whiteSpace:'nowrap', width:'10px'}}>
                      <ToggleIconButton
                        onTitle='Enabled'
                        offTitle='Disabled (job will not run)'
                        onClick={()=>workerJobEditSave({id:worker.id, isEnabled:!worker.isEnabled})}
                        isOn={worker.isEnabled}
                      />
                      <Tooltip title={statusDescription}><IconButton style={{cursor:'default'}}>{React.createElement(RunStatusIcon)}</IconButton></Tooltip>
                      <Tooltip title={actionDescription}><IconButton onClick={()=>workerJobStartStop({id:worker.id, action})}>{React.createElement(ActionIcon)}</IconButton></Tooltip>
                    </TableCell>
                    <TableCell style={{width:'10px', whiteSpace:'nowrap'}}><Tooltip title={worker.description}><span>{worker.displayName}</span></Tooltip></TableCell>
                    <TableCell style={{width:'10px', whiteSpace:'nowrap'}}><Tooltip title={`${lastRunElapsed(worker)}`}><span>{lastRun}</span></Tooltip></TableCell>
                    <TableCell style={{width:'10px', whiteSpace:'nowrap'}}><span>{nextRun}</span></TableCell>
                    <TableCell style={{overflowX:'hidden'}}><Tooltip title={ runStatusDetail }><span>{worker.runStatusSummary}</span></Tooltip></TableCell>
                    <TableCell><Tooltip title={`View history of: ${worker.id}`}><IconButton onClick={()=>workerJobFetchLogs(worker.id)}><HistoryIcon/></IconButton></Tooltip></TableCell>
                    <TableCell><Tooltip title={`Edit settings for: ${worker.id}`}><IconButton onClick={()=>workerJobEdit(worker.id)}><EditIcon/></IconButton></Tooltip></TableCell>
                  </TableRow>)
                  return acc
                }, [])}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state.admin
}

const mapDispatchToProps = {
  updateGeneralSetting,
  emailTest,
  appSettingsSave,
  startAllWorkerJobsPoll,
  stopAllWorkerJobsPoll,
  workerJobEdit,
  workerJobEditSave,
  workerJobStartStop,
  workerJobFetchLogs
}

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Synchronization))


export default SettingsPage