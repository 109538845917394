import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ChooseLocation from "apps/kiosk/components/ChooseLocation";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  numkey: {
    padding: 20,
    width: 64,
    height: 70
  },
  bigbtn: {
    padding: 20,
    height: 70,
    minWidth:250
  },
  keycodeField: {
    minWidth:250
  },
  btnReport: {
    padding: 20,
    height: 70,
    minWidth:250
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  footerLink: {
    textDecoration:'none',
    '&:visited':{
      color: theme.colorDefault
    }
  },

})

class GuttersGrid extends React.Component {

  render() {
    return <div><ChooseLocation /></div>
  }
}

GuttersGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(GuttersGrid)