import React from 'react'
import connect from 'react-redux/es/connect/connect'
import PersonIcon from '@material-ui/icons/Person'

import {
    URL_ACCOUNT_SETTINGS,
} from 'redux/constants'

import ShooltrakAccountInfo from './ShooltrakAccountInfo'
import SkywardAccountInfo from './SkywardAccountInfo'

class AccountSettigs extends React.Component {

  render() {
    return (
      <div>
        <ShooltrakAccountInfo />
        <SkywardAccountInfo />
      </div>
    )
  }
}

const mapStateToProps = state => state.admin

export const AccountSettigsPage = connect(
  mapStateToProps,
)(AccountSettigs)

export const accountSettigsPage = {
  icon: PersonIcon,
  component: AccountSettigsPage,
  name: 'Account',
  displayName: 'Account',
  to: URL_ACCOUNT_SETTINGS
}

export default AccountSettigsPage