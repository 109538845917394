import baseState from './BaseState'
import actions from './actions'
import AdminReducer from 'redux/admin/Reducer'
import KioskReducer from 'redux/kiosk/KioskReducer'
import InstallReducer from './install/Reducer'
import {findOneBy, newState} from '@relativity/js-util'

import {
  BOOT_PRUNE_APP_STATE_EXCEPT, SNACK_MESSAGE_HIDE, SNACK_MESSAGE_SHOW
} from './RootActions'
import {DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS} from "redux/constants";

const reducers = {
  'admin': AdminReducer,
  'kiosk': KioskReducer,
  'install': InstallReducer
}

const newKioskState = (state, newPropsOrPath, propValue, merge=true ) => {
  if(typeof propValue === 'undefined')  return newState(state, 'kiosk', newPropsOrPath, merge)
  return newState(state, `kiosk.${newPropsOrPath}`, propValue, merge)
}

const kiosk = {

  setConfigurationLock: (state, action) => {
    return newKioskState(state, {isLocked:!!action.payload} )
  },

  updateLocationChooser: (state, locationId) => {
    const location = findOneBy(state.kiosk.locations, 'id', locationId)
    return newKioskState(state, 'locationChooser', {location}, true )
  },
}

const adminExpandMainNav = (state) => {
  const admin = Object.assign({}, state.admin, {mainNavExpanded: true})
  return Object.assign({}, state, {admin})
}

const adminCollapseMainNav = (state) => {
  const admin = Object.assign({}, state.admin, {mainNavExpanded: false})
  return Object.assign({}, state, {admin})
}

const adminLogin = (state) => {
  const admin = Object.assign({}, state.admin, {auth: true})
  return Object.assign({}, state, {admin})
}

const adminLogout = (state) => {
  const admin = Object.assign({}, state.admin, {auth: false})
  return Object.assign({}, state, {admin})
}


const bootPruneAppStateExcept = (state, preserveApp) => {
  const transitionState = ['admin','install','kiosk'].reduce((acc, appName)=>{
    if(appName !== preserveApp) {
      acc[appName] = false
    } else {
      acc[appName] = state[appName]
    }
    return acc;
  }, {})
  return newState(state, transitionState)
}

const snackMessageHide = (state) => {
  return newState(state, 'common.snackMessage', {show:false, autoHideMillis:DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS}, false)
}


const snackMessageShow =(state, payload) => {
  const snackMessage = Object.assign({show:true, autoHideMillis:DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS}, payload)
  return newState(state, 'common.snackMessage', snackMessage, false)
}


export const rootReducer = (inState = baseState, action) => {
  //console.log(action.type, action.payload)
  let state = Object.keys(reducers).reduce((state, key) => {
    return (reducers[key].hasOwnProperty(action.type))? newState(state, key, reducers[key][action.type](state[key], action), false) : state
  }, inState)

  switch(action.type) {
    case actions.NO_OP :
      return state

    case BOOT_PRUNE_APP_STATE_EXCEPT :
      return bootPruneAppStateExcept(state, action.payload)

    case actions.SET_MENU_DRAWER_OPEN :
      return newKioskState(state, {menuDrawerOpen: action.payload} )

    case actions.SET_CONFIGURATION_LOCK :
      return kiosk.setConfigurationLock(state, action)

    case actions.UPDATE_LOCATION_CHOOSER_LOCATION :
      return kiosk.updateLocationChooser(state, action.payload)


    case SNACK_MESSAGE_HIDE :
      return snackMessageHide(state, action.payload)

    case SNACK_MESSAGE_SHOW :
      return snackMessageShow(state, action.payload)

    case actions.ADMIN_EXPAND_MAIN_NAV :
      return adminExpandMainNav(state)

    case actions.ADMIN_COLLAPSE_MAIN_NAV :
      return adminCollapseMainNav(state)

    case actions.ADMIN_LOGOUT :
      return adminLogout(state)

    case actions.ADMIN_LOGIN :
      return adminLogin(state)

    case actions.ADMIN_DELETE_KIOSK_STATE :
      return newState(state, 'kiosk', false)
    default :
  }
  return state
}

export default rootReducer