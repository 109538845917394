import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './style'

function renderSuggestion({ suggestion, itemProps, itemToSelectOption }) {
  return (
    <MenuItem
      {...itemProps}
      component="div"
      style={{
        fontWeight: 400,
      }}  
    >
      {itemToSelectOption(suggestion)}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

export class DropDownSearchListClass extends React.Component {
  render() {
    const { searchString, label, placeholder, onInputChange, 
      onSelectChange, items, itemToSelectOption, itemKey, classes, statusMessage } = this.props   
    
    return (
      <div>
        <Downshift 
          id="downshift-simple">          
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            isOpen,
            selectedItem,
          }) => (
            <div className={classes.downshiftContainer}>
              <TextField
                fullWidth={true}
                label={label}
                value={searchString}               
                InputProps={{
                  ...getInputProps({
                    placeholder,
                    onChange: e => onInputChange(e.target.value),                        
                  }),
                }}
              />
              <div {...getMenuProps()}>
                {isOpen ?
                 (
                  <Paper square className={classes.paper}>   
                    <FormHelperText className={classes.formHelperText}>
                      {statusMessage}
                    </FormHelperText>                    
                    
                    {items.map((suggestion, index) =>                     
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ key: itemKey(suggestion), onClick: () => onSelectChange(suggestion), item: '' }),
                        highlightedIndex,
                        selectedItem,
                        onSelectChange,
                        itemToSelectOption
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

DropDownSearchListClass.propTypes = {
  searchString: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  itemToSelectOption: PropTypes.func.isRequired,
  itemKey: PropTypes.func.isRequired,
  statusMessage: PropTypes.string,
};

export const reducePayload = (obj) => {return {[obj.propertyName]:obj.val} }

export const DropDownSearchList = withStyles(styles, { withTheme: true })(DropDownSearchListClass)

export default DropDownSearchList