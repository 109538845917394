import React, { Component } from 'react'
import {connect} from 'react-redux'

import TopAppBar from './components/TopAppBar'
import TenKeyboard from './components/TenKeyboard'
import SnackMessage from 'common/components/SnackMessage'
import Footer from './components/Footer'

import {
  bootKiosk,
  idCodeKeyboardInput,
  startGpsPoll,
  forgotPasswordSend,
  snackMessageInfo,
  snackMessageError,
  loginSend,
  loginUIHide,
  logoutSend,
  snackMessageHide,
} from "redux/kiosk/KioskActions";
import StaticContentDialog from "apps/kiosk/components/StaticContentDialog";
import {SINGLETON_KIOSK_DESIGNATION} from "../../common/kioskConstants";


const mapStateToProps = state => state.kiosk

class KeyboardListenerClass extends React.Component {
    render() {
        const { idCodeKeyboardInput, children} = this.props;
        return (
            <div tabIndex="0" className="App" onKeyDown={(e)=>{
              if(!e.altKey && !e.metaKey && !e.ctrlKey) {
                idCodeKeyboardInput(e.key)
              }
            }}>
                {children}
            </div>)
    }
}

const KeyboardListener = connect(
    mapStateToProps,
  {idCodeKeyboardInput},
)(KeyboardListenerClass)

class SingletonKiosk extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bootKiosk:props.bootKiosk
    }
  }

  componentDidMount(){
    this.state.bootKiosk(SINGLETON_KIOSK_DESIGNATION)
    this.props.startGpsPoll()
  }

  render() {
    const {isReady} = this.props

    console.log('isReady', isReady)
    if(!isReady) {
      return null
    }
    return (
        <KeyboardListener>
            <SnackMessage snackMessageHide={snackMessageHide}/>
            <TopAppBar />
            <StaticContentDialog/>
            <TenKeyboard/>
            <Footer/>
        </KeyboardListener>
      )
  }
}

export const SingletonKioskConnected = connect(
  mapStateToProps,
  {
    bootKiosk,
    loginSend,
    logoutSend,
    loginUIHide,
    forgotPasswordSend,
    snackMessageInfo,
    snackMessageError,
    snackMessageHide,
    startGpsPoll
  }
)(SingletonKiosk)
