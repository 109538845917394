import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import styles from 'common/components/style'


import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FullTextField from "common/components/FullTextField";

import { licenseSend, settingsUpdateProperty } from 'redux/install/Actions'

import {timezoneNotSet, TIMEZONES} from "redux/constants";
import FancySelect from "common/components/FancySelect";
import moment from "moment-timezone";
import {INSTALL_STEP_SET_LICENSE_KEY} from "redux/install/constants";
import {findOneBy} from "redux/utils";

const timezones = [timezoneNotSet].concat(TIMEZONES)

class STALicenseDialogClass extends React.Component {
  constructor() {
    super()
    this.state = {controlsDisabled:false}
  }

  confirmThisStep(fn) {
    this.setState({
      controlsDisabled:true
    })
    fn()
  }

  addHooks() {
    //testing
    window.stHooks.addHook('serverHostUrl', (e)=>this.props.settingsUpdateProperty({propertyName:'serverHostUrl', val:e}))
    window.stHooks.addHook('licenseEmail', (e)=>this.props.settingsUpdateProperty({propertyName:'staLicenseEmail', val:e}))
    window.stHooks.addHook('licenseLicense', (e)=>this.props.settingsUpdateProperty({propertyName:'staLicense', val:e}))
    window.stHooks.addHook('licenseName', (e)=>this.props.settingsUpdateProperty({propertyName:'name', val:e}))
    window.stHooks.addHook('licenseTimezone', (e)=>{
      const payload = findOneBy(TIMEZONES, 'val', e)
      this.props.settingsUpdateProperty({propertyName:'jsbTimezone', val:payload})
    })
    window.stHooks.addHook('licenseSubmit', ()=>this.props.licenseSend())
  }

  removeHooks() {
    window.stHooks.removeHook(['licenseLicense', 'licenseName','licenseTimezone', 'licenseSubmit'])
  }

  render() {
    if(!this.props.settings) return null;
    const {browserCurrentStep, classes, close, licenseSend, settingsUpdateProperty, settings} = this.props
    const onClose = (close)? close : ()=>{console.log('This should be an easter egg, but it\'s not')}
    if(browserCurrentStep !== INSTALL_STEP_SET_LICENSE_KEY) {
      this.removeHooks()
      return null
    } else {
      this.addHooks()
    }
    const timezoneVal = settings.jsbTimezone.val
    const now = (timezoneVal === 'none')? moment() : moment().tz(timezoneVal)

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title">
          STA License & Timezone- (Step 1)
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container className={classes.firstRow}>
            <Grid item className={classes.gridItem} xs={12}>
              <FullTextField
                id="settings-license"
                label="Organization Name"
                propertyName='name'
                value={settings.name}
                dispatchUpdate={settingsUpdateProperty}
                margin="normal"
              />
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <FullTextField
                id="settings-serverHostUrl"
                label="SchoolTRAK Server Url"
                propertyName='serverHostUrl'
                value={settings.serverHostUrl}
                dispatchUpdate={settingsUpdateProperty}
                margin="normal"
              />
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <FullTextField
                id="settings-staLicenseEmail"
                label="SchoolTRAK registered email"
                propertyName='staLicenseEmail'
                value={settings.staLicenseEmail}
                dispatchUpdate={settingsUpdateProperty}
                margin="normal"
              />
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <FullTextField
                id="settings-license"
                label="Enter your SchoolTRAK License"
                propertyName='staLicense'
                value={settings.staLicense}
                dispatchUpdate={settingsUpdateProperty}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem} xs={12}>
            <FancySelect
              label='Timezone'
              currentVal={timezoneVal}
              dispatchUpdate={(payload) => {
                payload.val = findOneBy(timezones, 'val', payload.val)
                settingsUpdateProperty(payload)
              }}
              className={classes.selectEmpty}
              valAtr={'val'}
              propertyName={'jsbTimezone'}
              nameAtr={
                (item)=>{
                  if(item.val === 'none') return item.displayName
                  return item.displayName + ' ' + now.tz(item.val).format('h:mmA')
                }}
              items={timezones}
            >
            </FancySelect>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={settings.staLicense.length === 0
          || settings.staLicenseControlsDisabled
          || settings.staLicenseEmail.length === 0} onClick={()=>this.confirmThisStep(licenseSend)} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const STALicenseDialog = connect(
  state => state.install,
  {licenseSend,settingsUpdateProperty}
)(withStyles(styles, { withTheme: true })(STALicenseDialogClass))

export default STALicenseDialog
