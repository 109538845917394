import React from 'react';
import PropTypes from 'prop-types';

const SkywardIcon = ({ style, className }) => {
    return (
        <img 
            alt="icon" 
            style={style} 
            className={className} 
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAYFBMVEVRdLhQc7hPcrdefr11kMeAmcuwv9/f5fL2+Pv///9Yebuoudva4fBjgr+Sp9LI0+jx9PlUdrnm6/XP2OuitdmJoM/W3u5xjcWWq9RohsGcsNeGns6+y+Ts8PdmhMC5xuKeOMNLAAABXElEQVR4AWKgNWBkwi/LyMyCVxbQ9lggCRICQbDocWTc9f+/PKAZud3t0IosgSTNcvx9BE+Lovg7LGOlHf3TQMKUVV3c9yvcxGn70Lrrf+BoeMP52E9N8+Vk5jG/cZsmEsvafeJLnz3VVWlIbvvBb2Cs2jccg86V25iCMS/PhsT0vNRhMv2DqyEixNcR9FEBFM33dr0n0uK9DjpTEyC38cFxY8uv+t6yD6UIVf7BdKrj2ZJktzPcOrFYhjErN0m8zYbOaiDpAu5cunHbbMjmiASZNIzrUlo57byN4lCGhMCmvWYJObdBYo8FYOPsz/fJmmnZg7wmNAQhkvDVbHASW8ayS0CwJ9c6+E9ysmdZ95HHdIY+G3cyujg+JkJ4HuuwvpCTSxfaDMFfeO6xSm+f2F6vDcJxveY+ivl57WZVuMLdfhI+PIsJX0OuJPxNzPX04bB8hS8UpuP++Mv/AYibG2lqCn46AAAAAElFTkSuQmCC"
        />
    );
};

SkywardIcon.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

export default SkywardIcon;
