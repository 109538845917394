import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import connect from 'react-redux/es/connect/connect'
import styles from './style'
import SkywardIcon from '../../common/icons/SkywardIcon'

import {
  loginWithSkyward
} from 'redux/actions'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import {
  E_KEY_ENTER
} from "redux/constants";

import {Divider} from "@material-ui/core";

class LoginDialogClass extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.state = {
      fieldsEnabled:true,
      password:'',
      email:''
    }
    this.enableFields = this.enableFields.bind(this)
    this.submitLogin = this.submitLogin.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
    this.setValue = this.setValue.bind(this)
    this.checkKeystroke = this.checkKeystroke.bind(this)
    this.refEmail = React.createRef()
    this.refPassword = React.createRef()
  }
  
  enableFields(e) {
    const target = e.target
    this.setState({fieldsEnabled:true})
    setTimeout(()=>target.focus(), 50)
  }

  forgotPassword() {
    if(this.state.email.length === 0 || this.state.email.indexOf('@') < 1) {
      this.props.snackMessageError('Please enter a valid email address.')
    } else {
      this.props.snackMessageInfo('One moment please.')
      this.props.forgotPasswordSend({
        email: this.state.email,
      })
    }
  }

  componentDidMount() {
    //testing
    window.stHooks.addHook('loginEmail', (e)=>this.setValue(e, 'email'))
    window.stHooks.addHook('loginPwd', (e)=>this.setValue(e, 'password'))
    window.stHooks.addHook('loginSubmit', ()=>this.submitLogin())
  }
  componentWillUnmount() {
    window.stHooks.removeHook(['loginEmail', 'loginPwd','loginSubmit'])
  }

  setValue(e, label) {
    if(typeof e === 'string') {
      this.setState({[label]:e})
    } else {
      this.setState({[label]:e.target.value})
    }
  }

  submitLogin(e) {
    if(this.state.email.length > 0) {//} && this.state.password.length > 0) {
      this.props.loginSend({
        email: this.state.email,
        password: this.state.password
      })
    } else {
      this.props.snackMessageError('Please enter a valid email address.')
    }
  }

  checkKeystroke(e) {
    if(e.key === E_KEY_ENTER) {
      this.submitLogin()
    }
  }

  render() {
    const {classes, close, forgotPasswordSend, loginType, organizationName='', loginWithSkyward, loginWithSkywardParameters} = this.props
    const onClose = (close)? close : ()=>alert('Please login.')

    let titleText = 'Please login'
    let skywardRedirectClientUrl = ''
    switch(loginType) {
      case 'admin' :
        titleText = 'Administrator login'
        skywardRedirectClientUrl = '/mgmt'
        break
      case 'kiosk' :
        titleText = 'Kiosk login'
        skywardRedirectClientUrl = ''
        break
      case 'install' :
        titleText = 'Installer login'
        skywardRedirectClientUrl = '/instellerizer'
        break
      default :
        alert(loginType)
    }
    //titleText = (organizationName.length > 0)? organizationName + ' - ' + titleText : titleText
    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        {organizationName?<DialogTitle id="customized-dialog-title" style={{textAlign:'center'}}>
         {organizationName}
        </DialogTitle>:null}
        <DialogContent className={classes.dialogContentRoot} style={{marginTop: '10px'}}>
          <Grid container>
            <Grid item xs={12} style={{
              textAlign:'center',
              padding:0,
              background:'url(/images/school_trak_icon_dark.svg)',
              backgroundRepeat:'no-repeat',
              backgroundSize:'120px 120px',
              minHeight: '120px'
            }}>
              {/*<img
                src=''
                alt='SchoolTRAK logo'
                style={{
                  backgroundRepeat:'no-repeat',
                  height: '120px',
                  width: '120px',
                  display: 'inline-block',
                  marginLeft:0,
                  marginRight:0,
                  position:'relative',
                  left:12
                }}
              />
            */}
              <span style={{
                fontWeight:'normal',
                fontSize:53,
                display:'inline-block',
                fontFamily:'Roboto, Helvetica',
                position:'relative',
                left:'20px',
                top:'31px'
              }}>School<span style={{fontWeight:'bold'}}>TRAK</span>
                <span style={{fontSize:20, position:'absolute', right:-20}}>&trade;</span></span>
            </Grid>
            <Grid item xs={12} style={{
              textAlign:'center',
              padding:20
            }}>
              <h2 style={{margin:5, fontWeight:'normal'}}>{titleText}</h2>              
            </Grid>

          </Grid>
          <Grid container >
            <div style={{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto'}}>
              <Grid item xs={12}>
                <TextField
                  disabled={!this.state.fieldsEnabled}
                  id="login-email"
                  label="Email"
                  fullWidth
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  onClick={this.enableFields} //potential fix for not allowing login on anything but chrome - move this to parent Grid item
                  ref={this.refEmail}
                  value={this.state.email}
                  type="email"
                  autoComplete="email"
                  onChange={(e)=>this.setValue(e, 'email')}
                  onKeyDown={this.checkKeystroke}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!this.state.fieldsEnabled}
                  id="login-pwd"
                  label="Password"
                  fullWidth
                  type="password"
                  autoComplete="password"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  onClick={this.enableFields}
                  ref={this.refPassword}
                  value={this.state.password}
                  onChange={(e)=>this.setValue(e, 'password')}
                  onKeyDown={this.checkKeystroke}              
                />
                {forgotPasswordSend ?
                  <div style={{flexGrow: 8, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={this.forgotPassword} color="secondary">
                      forgot password
                    </Button>
                  </div>
                : null}
              </Grid>
            </div>
          </Grid>
          <div style={{flexGrow: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px', paddingBottom: '60px', marginTop: '30px'}}>
            <Button id='login-submit' onClick={this.submitLogin} variant="contained" color="primary" style={{width: '80%', height: '54px'}}>
              Login
            </Button>
            {/* {loginType !== 'install' ? <Divider style={{width: '80%'}}/> : null}
            {loginType !== 'install' ?             
            <Button id='login-submit' onClick={() => loginWithSkyward({redirectUrl: skywardRedirectClientUrl, parameters: loginWithSkywardParameters})} style={{width: '80%', height: '54px'}}
              color="primary" size="small" variant='outlined'>
              <SkywardIcon style={{width: '20px'}} />
              <span style={{marginLeft: '1rem'}}>Continue with Skyward</span>              
            </Button> : null}            */}
          </div>    
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {return {}}

const mapDispatchToProps = {
  loginWithSkyward
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(LoginDialogClass))