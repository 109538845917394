import React, { Component } from 'react'
import {connect} from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import SimpleTopAppBar from 'common/components/page/SimpleTopAppBar'
import Footer from 'common/components/page/Footer'

import styles from './style'

import {snackMessageHide, snackMessageInfo, snackMessageError} from "redux/RootActions";
import {loginSend, bootApp} from "redux/install/Actions"

import SkywardConnectionDialog from "apps/install/components/SkywardConnectionDialog";
import STALicenseDialog from "apps/install/components/STALicenseDialog";
import LoginDialog from "common/components/LoginDialog";
import AdminPasswordDialog from "apps/install/components/AdminPasswordDialog";
import YearEntityChooserDialog from "apps/install/components/YearEntityChooserDialog";
import SummaryDialog from "apps/install/components/SummaryDialog";
import KioskDefaultsDialog from "apps/install/components/KioskDefaultsDialog";
import DistrictDialog from "apps/install/components/DistrictDialog";
import BuildingChooserDialog from "./components/BuildingChooserDialog";



class App extends Component {
  componentDidMount() {
    this.props.bootApp()
  }

  render() {
    const {loginSend, snackMessageInfo, snackMessageError, dialogs, isReady, loggedInUser} = this.props
    return (
      <div>
        <SimpleTopAppBar appBarTitle='SchoolTRAK Installation' />
        {dialogs.login?
          <LoginDialog
            loginType='install'
            {...dialogs.login}
            loginSend={loginSend}
            forgotPasswordSend={false}
            snackMessageInfo={snackMessageInfo}
            snackMessageError={snackMessageError}
          />
          :
          null }
        {(isReady && loggedInUser) ?
          <div>
            <STALicenseDialog/>
            <AdminPasswordDialog/>
            <DistrictDialog/>
            <KioskDefaultsDialog/>
            <SkywardConnectionDialog/>
            <YearEntityChooserDialog/>
            <BuildingChooserDialog/>
            <SummaryDialog/>
          </div>
          :
          null
        }
          <Footer/>
      </div>
    )
  }
}

export default connect(
  (state) => { return state.install},
  {
    loginSend,
    snackMessageHide,
    snackMessageError,
    snackMessageInfo,
    bootApp
  }
)(withStyles(styles)(App))
