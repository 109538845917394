import React from 'react'
import connect from 'react-redux/es/connect/connect'
import {withStyles} from '@material-ui/core'
import AccountField from './AccountField';
import AccountListField from './AccountListField';
import { SECURITY_ROLE_ADMIN } from 'redux/constants'

import styles from '../style'

class ShooltrakAccountInfo extends React.Component {
    render() {
        const {fullName, email, securityRoles, allowedFacilities, allowedBuildings, classes} = this.props

        return (
            <div>
                <h2>
                    <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="icon" className={classes.headerIcon} />
                    SchoolTRAK
                </h2>
                <AccountField fieldName="Name" fieldValue={fullName} />
                <AccountField fieldName="Roles" fieldValue={securityRoles.join(', ')} />
                <AccountField fieldName="Email" fieldValue={email} />
                <AccountListField fieldName="Facilities" fieldValues={allowedFacilities} />
                <AccountListField fieldName="Buildings" fieldValues={allowedBuildings} />           
            </div>
        )
    }
}

const mapStateToProps = state => {
    const userAccount = {...state.admin.userAccount}
    const {facilities, buildings} = state.admin

    const facilityConvertor = facility => {return { id: facility.id, displayName: facility.displayName }}
    const buildingConvertor = building => {return { id: building.id, displayName: building.displayName }}

    const isAdmin = userAccount.securityRoles.includes(SECURITY_ROLE_ADMIN);

    //TODO: simplify after fixing to send only facilities user has access to
    let allowedFacilities
    if(isAdmin){
        allowedFacilities = facilities
    }
    else{
        allowedFacilities = facilities.filter(f => userAccount.securityAccess.facilityIds.some(userFacilityId => userFacilityId === f.id))
        
    }
    userAccount.allowedFacilities = allowedFacilities.map(f => facilityConvertor(f))

    //TODO: simplify after fixing to send only facilities user has access to
    let allowedBuildings
    if(isAdmin){
        allowedBuildings = buildings
    }
    else{
        allowedBuildings = buildings.filter(b => userAccount.securityAccess.buildingIds.some(userBuildingId => userBuildingId === b.id))
        
    }
    userAccount.allowedBuildings = allowedBuildings.map(f => buildingConvertor(f))

    return userAccount
}

export default connect(
  mapStateToProps,
)(withStyles(styles, { withTheme: true })(ShooltrakAccountInfo))
