import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'


import styles from 'common/components/style'


import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import { sendEntityIsEnabled, entityChooserValidateSet, hideAddEntitiesDialog } from '../../../redux/admin/Actions'
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ToggleIconButton from "common/components/ToggleIconButton";


class AddYearEntityChooserDialogClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlsDisabled: false,
      tabIndex: 0,
    }
  }

  confirmThisStep(fn) {
    fn()
  }

  addHooks() {
    //testing
    window.stHooks.addHook('yearEntity', (e) => {
      const { entityIds = true } = e || {}
      let schoolYear = false
      this.getYearEntityStepData().schoolYears.forEach(year => {
        if (year.isVendorCurrent) {
          schoolYear = year
        }
      })
      if (schoolYear) {
        this.props.settingsUpdateProperty({ propertyName: 'licensingJson.schoolYearId', val: schoolYear.id })
      }
      this.getYearEntityStepData().entities.map(entity => {
        if (entityIds === true || entityIds.includes(entity.id)) {
          this.props.setEntityIsEnabled({ id: entity.id, isEnabled: true })
        }
        return null
      })
    })
  }

  removeHooks() {
    window.stHooks.removeHook(['yearEntity'])
  }

  render() {
    if (!this.props.entityChooser.entities) return null
    const { classes, sendEntityIsEnabled, entityChooserValidateSet, hideAddEntitiesDialog } = this.props

    return (
      <Dialog
        onClose={hideAddEntitiesDialog}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title" onClose={hideAddEntitiesDialog}>
          Add Entities
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Typography>Choose which Entities will be used by SchoolTRAK</Typography>
          <List>
            {this.props.entityChooser.entities.map((entity) => {
              return <ListItem title={`id: ${entity.id}`} key={entity.id}><ToggleIconButton isOn={entity.isEnabled} onClick={() => entityChooserValidateSet({ id: entity.id, isEnabled: !entity.isEnabled })} />{entity.displayName}</ListItem>
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Typography>Activating an entity is final, and cannot be altered after confirmation.</Typography>
          <Button onClick={hideAddEntitiesDialog} color="secondary">
            Cancel
          </Button>
          <Button disabled={this.state.controlsDisabled} onClick={() => this.confirmThisStep(sendEntityIsEnabled)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const AddYearEntityChooserDialog = connect(
  state => state.admin,
  {
    sendEntityIsEnabled,
    hideAddEntitiesDialog,
    entityChooserValidateSet
  }
)(withStyles(styles, { withTheme: true })(AddYearEntityChooserDialogClass))

export default AddYearEntityChooserDialog
