export default theme => ({
  customSettingDescription: {
    whiteSpace:'pre-line',
    padding:'10px',
    marginBottom:'10px'
  },
  customSettingDescriptionWithRecommended:{
    whiteSpace:'pre-line',
    padding:'10px',
    paddingBottom:'5px',
  }
})
