import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../style';

const AccountField = ({ fieldName, fieldValue, classes }) => {
  return (
    <div className={classes.accountField}>
      <strong className={classes.fieldName}>{fieldName}:</strong>
      <span className={classes.fieldValue}>{fieldValue}</span>
    </div>
  );
};

AccountField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AccountField);