import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import {
  findOneBy,
} from '@relativity/js-util'

import {
  userAccountEditDone,
  userAccountEditUpdateProperty,
  userAccountEditSave,
  comingSoon, userAccountDelete,
} from 'redux/admin/Actions'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FancySelect from 'common/components/FancySelect'

import styles from './style'
import { FullTextField } from "common/components/FullTextField";
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import IconButton from "@material-ui/core/IconButton"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ToggleIconButton from 'common/components/ToggleIconButton'
import { SECURITY_ROLE_ADMIN, SECURITY_ROLE_STAFF, securityRoles, yesNoBoolList, ID_ZERO } from 'redux/constants'
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen'
import NopeIcon from 'common/icons/Nope'


const minuteList = []
const presentMinuteList = []
let mins, secs
for (let i = 0; i < 40; i++) {
  mins = Math.floor(i / 2)
  secs = (((i * 60 / 2) % 60) === 0) ? '00' : '30'
  minuteList.push({
    seconds: (i * 60 / 2),
    displayName: `${mins}:${secs}`
  })
  presentMinuteList.push({
    seconds: (i * 60 / 2),
    displayName: (mins === 0 && secs === '00') ? '0:00' : `-${mins}:${secs}`
  })
}

class UserEditorClass extends React.Component {

  render() {
    if (!this.props.userAccount) return null

    const {
      buildings,
      classes,
      userAccountDelete,
      userAccountEditDone,
      userAccountEditUpdateProperty,
      userAccountEditSave,
      userAccount,
      isDirty,
      facilities
    } = this.props
    const isNew = userAccount.hasOwnProperty('id') ? false : true
    const title = (isNew ? 'New User' : 'Edit User') + ' - ' + findOneBy(facilities, 'id', userAccount.facilityId).displayName
    return (
      <Dialog
        onClose={userAccountEditDone}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={userAccountEditDone}>
          <ToggleIconButton
            onTitle='User is active'
            offTitle='User is disabled'
            style={{ marginLeft: -12, marginTop: -9, position: 'absolute' }}
            onClick={() => userAccountEditUpdateProperty({ propertyName: 'isEnabled', val: !userAccount.isEnabled })}
            isOn={userAccount.isEnabled}
            onIcon={CheckCircleIcon}
          />
          <span style={{ marginLeft: 36 }}>{title}</span>
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container>
            <Grid item xs={12} className={classes.gridItem}>
              <FullTextField
                label="Name"
                value={userAccount.fullName}
                dispatchUpdate={userAccountEditUpdateProperty}
                propertyName={'fullName'}

              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FullTextField
                label="Email"
                autoComplete={'off'}
                value={userAccount.email}
                type={'email'}
                dispatchUpdate={userAccountEditUpdateProperty}
                propertyName={'email'}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FullTextField
                label="Password"
                autoComplete={'off'}
                value={userAccount.password}
                type={'password'}
                dispatchUpdate={userAccountEditUpdateProperty}
                propertyName={'password'}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FancySelect
                dispatchUpdate={userAccountEditUpdateProperty}
                label='Entitiy'
                propertyName='facilityId'
                currentItem={findOneBy(facilities, 'id', userAccount.facilityId)}
                items={facilities}
                valAtr={'id'}
                nameAtr='displayName'
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FancySelect
                dispatchUpdate={userAccountEditUpdateProperty}
                label='Role'
                propertyName='securityRoles'
                currentItem={findOneBy(securityRoles, 'value', userAccount.securityRoles[0])}
                items={securityRoles}
                valAtr={'value'}
                nameAtr='displayName'
              />
            </Grid>
            {userAccount.securityRoles.includes(SECURITY_ROLE_ADMIN) || (userAccount.facilities && userAccount.facilities.includes(ID_ZERO)) ?
              <Grid item xs={12} className={classes.gridItem}>
                <FancySelect
                  dispatchUpdate={userAccountEditUpdateProperty}
                  label='Receive maintenance alerts?'
                  propertyName='isToReceiveAlerts'
                  currentItem={findOneBy(yesNoBoolList, 'val', userAccount.isToReceiveAlerts)}
                  items={yesNoBoolList}
                  valAtr={'val'}
                  nameAtr='displayName'
                />
              </Grid>
              : null
            }
            {userAccount.securityRoles.includes(SECURITY_ROLE_STAFF) ?
              <Grid item xs={12} className={classes.gridItem}>
                <Table style={{ width: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">Authorized</TableCell>
                      <TableCell component="th">Entity Name</TableCell>
                      <TableCell padding="checkbox" align="right" style={{ width: '20%' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {facilities.map((facility) => {
                      const isEmpowered = userAccount.securityAccess.facilityIds.includes(facility.id)
                      return <TableRow key={facility.id}>
                        <TableCell padding="checkbox">
                          <IconButton onClick={() => userAccountEditUpdateProperty({ propertyName: 'securityAccess.facilityId', val: { id: facility.id, isEmpowered: !isEmpowered } })}>{
                            isEmpowered ?
                              <CheckCircleOutlineIcon />
                              :
                              <NopeIcon />
                          }</IconButton>
                        </TableCell>
                        <TableCell> {facility.displayName}</TableCell>
                        <TableCell padding="checkbox" align="right">{userAccount.facilityId !== facility.id ? <LockOpen /> : <Lock />}</TableCell>
                      </TableRow>
                    })
                    }
                  </TableBody>
                </Table>
              </Grid>
              : null}
            {userAccount.securityRoles.includes(SECURITY_ROLE_STAFF) ?
              <Grid item xs={12} className={classes.gridItem}>
                <Table style={{ width: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">Authorized</TableCell>
                      <TableCell component="th">Building</TableCell>
                      <TableCell padding="checkbox" align="right" style={{ width: '20%' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {buildings.map((building) => {
                      const isEmpowered = userAccount.securityAccess.buildingIds.includes(building.id)
                      return <TableRow key={building.id}>
                        <TableCell padding="checkbox">
                          <IconButton onClick={() => userAccountEditUpdateProperty({ propertyName: 'securityAccess.buildingId', val: { id: building.id, isEmpowered: !isEmpowered } })}>{
                            isEmpowered ?
                              <CheckCircleOutlineIcon />
                              :
                              <NopeIcon />
                          }</IconButton>
                        </TableCell>
                        <TableCell> {building.displayName}</TableCell>
                        <TableCell padding="checkbox" align="right"></TableCell>
                      </TableRow>
                    })
                    }
                  </TableBody>
                </Table>
              </Grid>
              : null}
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: 10 }}>
          <div style={{ flexGrow: 10 }}>
            {isNew ?
              null
              :
              <Button onClick={userAccountDelete} color="secondary">
                Delete
              </Button>
            }
          </div>
          <Button onClick={userAccountEditDone} color="secondary">
            Cancel
          </Button>
          <Button disabled={!isDirty} onClick={userAccountEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  const userAccountEditor = (state.admin.dialogs.userAccountEditor) ? state.admin.dialogs.userAccountEditor : {}
  userAccountEditor.facilities = state.admin.facilities
  userAccountEditor.buildings = state.admin.buildings
  return userAccountEditor
}


const mapDispatchToProps = {
  comingSoon,
  userAccountDelete,
  userAccountEditDone,
  userAccountEditUpdateProperty,
  userAccountEditSave,
}

export const UserEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(UserEditorClass))

export default UserEditor