import React from "react";
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core"
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {COLOR_ACTION} from "redux/constants";

const styles = theme => ({
  formControlLabel: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    paddingTop:0,
    paddingBottom:0,
    minWidth: 120,
    marginTop: theme.spacing.unit / 2,
  },
  formControlLabelAdmin: {
    margin: theme.spacing.unit,
    marginLeft:0,
    paddingLeft:0,
    minWidth: 120,
    marginTop: theme.spacing.unit / 2,
  },
})


class SelectFormControl extends React.Component {
  render() {
    const {adminContext, val, name, displayName, clickOn, classes, possibleValues} = this.props
    const elementId = `kiosk-setting-list-item-select-${name}`
    return <FormControl className={adminContext? classes.formControlLabelAdmin: classes.formControlLabel} variant="outlined">
      <InputLabel htmlFor={elementId}>{displayName}</InputLabel>
      <Select style={{marginTop:0}}
        id={elementId}
        value={val}
        onChange={(e)=> {
          clickOn(e.target.value)
        }}
        inputProps={{
          name: name,
          id: name,
        }}
      >
      {possibleValues.map(item =>
        <MenuItem
          key={(item.hasOwnProperty('val'))? item.val : item.id}
          value={(item.hasOwnProperty('val'))? item.val : item.id}
          title={item.description}>
          {item.text? item.text : (item.displayName? item.displayName : item.name)}
        </MenuItem>)}
      </Select>
    </FormControl>
  }
}


class KioskSettingListItem extends React.Component {
  render() {
    const props = this.props
    const {isDisabled, isOn, changeable, clickOnChangeable=()=>null, adminContext, textProvider, menuItemType, titleOn, titleOff, textOn, textOff, titleDisabled} = this.props
    let {color, title=''} = this.props
    const colorDefined = this.props.hasOwnProperty('color')
    let icon, onClick, text

    if (isOn) {
      icon = props.iconOn
      title = !titleOn? title : (typeof titleOn === 'function')? titleOn(textProvider) : titleOn
      text = (typeof textOn === 'function')? textOn(textProvider) : textOn
      onClick = props.clickOn
      color = colorDefined? color : COLOR_ACTION
    } else {
      icon = props.iconOff
      title = !titleOff? title : (typeof titleOff === 'function')? titleOff(textProvider) : titleOff
      text = (typeof textOff === 'function')? textOff(textProvider) : textOff
      onClick = (props.clickOff) ? props.clickOff : props.clickOn
      color = colorDefined? color : null
    }
    if (isDisabled) {
      icon = props.iconDisabled ? props.iconDisabled : icon
      title = !titleDisabled? title : (typeof titleDisabled === 'function')? titleDisabled(textProvider) : titleDisabled
      onClick = () => false
      //color = colorDefined? color : null
    }

    if(menuItemType !== 'binary') {
      onClick = () => {}
    }

    const iconStyle = clickOnChangeable? {} : {cursor:'default'}
    return ((!adminContext) ?
       <ListItem button disabled={(isDisabled || !changeable)} title={title} onClick={onClick}>
          <ListItemIcon  style={{color}}>{React.createElement(icon)}</ListItemIcon>
         {(menuItemType === 'binary')? <ListItemText primary={text}/> : <SelectFormControl {...this.props}/>}
          {!changeable ? <ListItemIcon><Lock/></ListItemIcon> : null}
        </ListItem>
        :
      <TableRow>
        <TableCell padding="checkbox" title={title} onClick={onClick}><IconButton style={{color}}>{React.createElement(icon)}</IconButton></TableCell>
        <TableCell title={title}>{(menuItemType === 'binary')? text : <SelectFormControl {...this.props}/>}</TableCell>
        <TableCell padding="checkbox" align="right">
          <IconButton style={iconStyle} onClick={clickOnChangeable}>{changeable? <LockOpen/> : <Lock/>}</IconButton>
        </TableCell>
      </TableRow>

    )
  }
}

export default withStyles(styles, { withTheme: true })(KioskSettingListItem)