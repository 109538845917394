import React from 'react'
import connect from "react-redux/es/connect/connect"
import {TXT_EN_ALL_FACILITIES} from "redux/constants";
import {findOneBy} from "redux/utils"

class CurrentFacility extends React.Component {
  render () {
    const {facility, allFacilities, contentOverride} = this.props
    const content = (contentOverride)? contentOverride : ((facility && !allFacilities)? facility.displayName: TXT_EN_ALL_FACILITIES)
    return <h3 style={{fontFamily:'Roboto, Helvetica', marginTop:0}}>{content}</h3>
  }
}

const mapStateToProps = state => {
  return {facility: findOneBy(state.admin.facilities, 'id', state.admin.currentFacilityId)}
}


export default connect(
  mapStateToProps,
)(CurrentFacility)