import React from "react"
import connect from "react-redux/es/connect/connect"
import {Typography, withStyles} from "@material-ui/core"

import AlarmIcon from '@material-ui/icons/Alarm'
import List from '@material-ui/core/List'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { Link as RouterLink } from 'react-router-dom'
import {URL_BELL_SCHEDULE, URL_FRAG_FACILITY_ID} from "redux/constants"
import {bellRulesEdit, bellScheduleFetchByFaciltyId} from "redux/admin/Actions"

import CurrentFacility from "apps/admin/components/CurrentFacility"
import BellRuleEditor from 'apps/admin/components/BellRule/Editor'
import BellRuleSummary from 'apps/admin/components/BellRule/Summary'

const styles = {}

const minuteList = []
let mins, secs
for(let i = 0; i < 40; i++) {
  mins = Math.floor(i / 2)
  secs = (((i * 60 /2) % 60) === 0)? '00':'30'
  minuteList.push({
    seconds:(i * 60 /2),
    displayName:`${mins}:${secs}`
  })
}

class ListItemLink extends React.Component {
  render() {
    const {facilityId, children} = this.props
    const link = URL_BELL_SCHEDULE.replace(URL_FRAG_FACILITY_ID, `/${facilityId}`)
    return <RouterLink to={link} style={{textDecoration: 'none'}}><ListItem button children={children} /></RouterLink>
  }
}

class BellSchedule extends React.Component {
  componentDidMount() {
    if(this.props.currentFacilityId) this.props.bellScheduleFetchByFaciltyId(this.props.currentFacilityId)
  }

  render() {
    const {facilities, currentFacilityId, bellRulesEdit, currentBellSchedule} = this.props
    if (!currentFacilityId) {
      return (
        <div>
          <CurrentFacility/>
          <Typography>Please choose an entity to view its period schedule.</Typography>
          <List>
            {
              facilities.map(facility =>
                <ListItemLink key={facility.id} facilityId={facility.id}>
                  <ListItemIcon><LocationCityIcon/></ListItemIcon>
                  <ListItemText primary={facility.displayName}/>
                </ListItemLink>)
            }
          </List>
        </div>
      )
    } else {
      if(!currentBellSchedule) return null
      return <div>
        <BellRuleEditor/>
        <CurrentFacility/>
        <Typography variant='h5'>Period Rules <IconButton onClick={()=>bellRulesEdit(currentBellSchedule)}><EditIcon/></IconButton></Typography>
        <BellRuleSummary bellSchedule={currentBellSchedule}/>
      </div>
    }
  }
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {
  bellScheduleFetchByFaciltyId,
  bellRulesEdit
}

export const BellSchedulePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(BellSchedule))

export const bellSchedulePage = {
  icon: AlarmIcon,
  component: BellSchedulePage,
  name: 'Period Rules',
  displayName: 'Period Rules',
  to: URL_BELL_SCHEDULE
}

export default BellSchedulePage