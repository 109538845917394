import React from "react"
import connect from "react-redux/es/connect/connect"

import {withStyles} from "@material-ui/core"

import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"

import TableHead from "@material-ui/core/TableHead"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"

import PeopleIcon from "@material-ui/icons/People"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import PersonIcon from "@material-ui/icons/Person"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"


import {E_KEY_ENTER, SEARCH_CONTEXT_USER_ACCOUNT_PAGE, URL_MANAGE_USERS} from "redux/constants"
import {userAccountSearch, userAccountEdit, userAccountOpenUploader} from "redux/admin/Actions"
import CurrentFacility from "apps/admin/components/CurrentFacility"
import UserEditor from 'apps/admin/components/UserEditor'
import UserUploader from 'apps/admin/components/UserUploader'
import Typography from "@material-ui/core/Typography";

const styles = {}

class UserSearch extends React.Component {
  componentDidMount() {
    this.props.userAccountSearch(this.props.searchString)
    //this.props.updateStudentSearchStatus('Ready')
  }

  render() {
    const { search, searchResults, userAccountSearch, userAccountEdit, statusMessage, userAccountOpenUploader} = this.props
    const {searchString} = search

    return (
      <div>
        <UserEditor/>
        <UserUploader/>
        <CurrentFacility />
        <Typography>(Entity choice will filters to primary only)</Typography>
        <Grid container spacing={16}>
          <Grid item xs={8} sm={6}>
            <TextField
                       id="search-students"
                       label={`Search for a User by name or email`}
                       variant="outlined"
                       fullWidth
                       style={{marginTop:0}}
                       value={searchString}
                       onChange={event => userAccountSearch(event.target.value)}
                       onKeyDown={event => {
                         if(event.key === E_KEY_ENTER) {
                           userAccountSearch(event.target.value, true)
                         }
                       }}
                       margin="normal"
                       helperText={statusMessage}
                       autoComplete="off"
            />

          </Grid>
          <Grid item xs={3}>
            <IconButton style={{marginTop:3}} onClick={()=>userAccountEdit()}><PersonAddIcon/></IconButton>
            <IconButton style={{marginTop:3}} onClick={userAccountOpenUploader} title="Upload user csv"><CloudUploadIcon/></IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={16} >
          <Grid item xs={12}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell padding={"none"} style={{paddingLeft:12}}>edit</TableCell>
                  <TableCell>Name (ID)</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              {searchResults.length > 0 ?
                <TableBody>
                  {searchResults.map(userAccount =>
                    <TableRow key={userAccount.id}>
                      <TableCell padding={"none"}><IconButton style={{marginTop:-5}} onClick={() => userAccountEdit(userAccount.id)} ><PersonIcon/></IconButton></TableCell>
                      <TableCell>{userAccount.fullName} ({userAccount.id})</TableCell>
                      <TableCell>{userAccount.email}</TableCell>
                      <TableCell>{userAccount.securityRoles.join(', ')}</TableCell>
                      <TableCell>{userAccount.isEnabled? <CheckCircleOutlineIcon/>:null}</TableCell>
                    </TableRow>)}
                </TableBody>
                :
                null
              }
            </Table>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const pageData = Object.assign({}, state.admin.userAccountSearchPage)
  pageData.facilities = state.admin.facilities
  return pageData
}

const mapDispatchToProps = {
  userAccountSearch,
  userAccountEdit,
  userAccountOpenUploader,
}

export const UserSearchPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(UserSearch))

export const userSearchPage = {
  searchContext: SEARCH_CONTEXT_USER_ACCOUNT_PAGE,
  searchOnPathChange:true,
  icon: PeopleIcon,
  component: UserSearchPage,
  name: 'Users',
  displayName: 'Users',
  to: URL_MANAGE_USERS
}

export default UserSearchPage