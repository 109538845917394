export default theme => ({
  /*gridItem: {
    padding:'2px'
  },*/
  selectField: {
    marginBottom:0,
    //paddingRight: theme.spacing.unit,
  },
  textField: {
    marginBottom:0,
    //paddingRight: theme.spacing.unit,
  },
  gridItem: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  dialogTitleRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  dialogTitleCloseButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  dialogContentRoot:{
    minWidth:'500px',
    paddingBottom:0,
    paddingTop:0,
  },
  formControlLabel: {
    margin: theme.spacing.unit,
    marginLeft:0,
    minWidth: 120,
  },
  downshiftContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  formHelperText: {
    marginLeft: '15px',
    marginBottom: '5px'
  }
})