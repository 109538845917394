import React from "react"
import {FORMAT_YMD} from 'redux/constants'
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";

export default class DatePickerFirstAndLastGrid extends React.PureComponent {
  render() {
    const {firstDateYMD, lastDateYMD, onChange, xs=12, sm=6, md=3} = this.props
    return <Grid container>
      <Grid item xs={xs} sm={sm} md={md}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            label='First Date'
            format='YYYY-MM-DD'
            value={firstDateYMD}
            onChange={(date)=>{
              onChange({propertyName:'firstDateYMD', val:date.format(FORMAT_YMD)})
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={xs} sm={sm} md={md}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            label='Last Date'
            format='YYYY-MM-DD'
            value={lastDateYMD}
            onChange={(date)=>{
              onChange({propertyName:'lastDateYMD', val:date.format(FORMAT_YMD)})
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  }
}