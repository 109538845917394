import TextField from '@material-ui/core/TextField'
import React from "react";

import styles from './style'
import {withStyles} from "@material-ui/core";

class FullTextFieldClass extends React.Component {
  render() {
    const {classes, dispatchUpdate, label,style={}, value, type='text', autoComplete='', onChange, multiline} = this.props
    const disabled = !!this.props.disabled
    let {propName = false, propertyName} = this.props
    if(!propName) propName = propertyName
    const onChangeFn = (dispatchUpdate)? ((e) => {dispatchUpdate({propName, propertyName: propName, val:e.target.value})}) : (onChange? onChange : ()=>{})

    return (
      <TextField
        disabled={disabled}
        fullWidth
        multiline={!!multiline}
        type={type}
        autoComplete={(type === 'password' && autoComplete)? 'new-password' : autoComplete}
        label={label}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={value}
        style={style}
        onChange={onChangeFn}
      />
    )
  }
}

class FullTextFieldDClass extends React.Component {
  render() {
    const {classes, label, value, style={}, type='text', autoComplete='', multiline} = this.props
    return (
      <TextField
        disabled
        fullWidth
        multiline={!!multiline}
        type={type}
        autoComplete={(type === 'password' && autoComplete)? 'new-password' : autoComplete}
        label={label}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={value}
        style={style}
      />
    )
  }
}


export const FullTextFieldD = withStyles(styles, { withTheme: true })(FullTextFieldDClass)

export const FullTextField = withStyles(styles, { withTheme: true })(FullTextFieldClass)
export default FullTextField