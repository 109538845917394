import React from "react"
import {withStyles} from "@material-ui/core"
import styles, {makeAtTypeClassName } from "./style"
import {
  AT_RECORD_CREATED_WITH_ADMIN,
  AT_RECORD_CREATED_WITH_AT_MONITOR,
  AT_RECORD_CREATED_WITH_KIOSK,
  AT_RECORD_CREATED_WITH_Q,
  AT_RECORD_CREATED_WITH_OUT_PROPAGATION,
  AT_TYPE_ID_ON_SITE
} from "redux/constants";
import Tooltip from "@material-ui/core/Tooltip";

const oubliette = () => {}
class AtRecordDotClass extends React.PureComponent {
  render() {
    const {atTypeId, classes, isReportingAtRecord=false, createdWith, onClick=false, style={}, title='', inline=false} = this.props

    const className = makeAtTypeClassName(atTypeId)
    let border = 'none'
    let opacity = 0.3
    if(isReportingAtRecord || atTypeId === AT_TYPE_ID_ON_SITE) {
      border = `1px solid`
      opacity = 1
    }
    const dotStyle = Object.assign(
      {
        border,
        opacity
      },
      style
    )

    if(!onClick) dotStyle.cursor = 'default'
    if(inline) {
      dotStyle.display = 'inline-block'
      dotStyle.marginRight = '5px'
    }

    let letter = ''
    switch(createdWith) {
      case AT_RECORD_CREATED_WITH_ADMIN :
        letter = 'a'
        break
      case AT_RECORD_CREATED_WITH_KIOSK :
        letter = 'k'
        break
      case AT_RECORD_CREATED_WITH_Q :
        letter = 's'
        break
      case AT_RECORD_CREATED_WITH_AT_MONITOR :
        letter = 'm'
        break
        case AT_RECORD_CREATED_WITH_OUT_PROPAGATION :
        letter = 'p'
        break
      default :
        letter = ''
    }

    return <Tooltip title={title}><div
      onClick={onClick? onClick: oubliette}
      className={classes[className]}
      style={dotStyle}
    >{letter}</div></Tooltip>
  }
}

const AtRecordDot = (withStyles(styles, { withTheme: true })(AtRecordDotClass))
export default AtRecordDot