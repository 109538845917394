import {ID_ZERO, SECURITY_ROLE_STAFF} from "redux/constants"

const UserAccount = (userAccount) => {
  const x =  Object.assign( {
    facilityId: ID_ZERO,
    fullName:'',
    nameFirst:'',
    nameLast:'',
    email:'',
    password:'',
    securityRoles: [SECURITY_ROLE_STAFF],
    securityAccess:{facilityIds:[], buildingIds:[]},
    isToReceiveAlerts:false,
    isEnabled:true,
    isDeleted:false,
  }, userAccount)
  if(x.facilityId !== ID_ZERO && x.securityAccess.facilityIds.length === 0) {
    x.securityAccess.facilityIds.push(x.facilityId)
  }
  return x
}

export default UserAccount