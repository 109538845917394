import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import styles from './styles'

class SimpleTopAppBar extends React.Component {
  render() {
    const {appBarTitle, classes} = this.props

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar style={{width:'100%!important'}}>
            <Typography variant='h4' color="inherit" style={{flexGrow:8}}>{appBarTitle}</Typography>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

SimpleTopAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  appBarTitle: PropTypes.string.isRequired,
}


export default withStyles(styles)(SimpleTopAppBar)
