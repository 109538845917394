

const styles = theme => ({
  root: {
    flexGrow: 1,
    '@media print': {
      display:'none'
    }
  },
  numkey: {
    padding: 20,
    width: 64,
    height: 70
  },
  bigbtn: {
    padding: 20,
    height: 70,
    minWidth:250
  },
  keycodeField: {
    minWidth:250
  },
  btnReport: {
    padding: 20,
    height: 70,
    minWidth:250
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  footerLink: {
    textDecoration:'none',
    '&:visited':{
      color: theme.colorDefault
    }
  },

})

export default styles