import React from "react"
import connect from "react-redux/es/connect/connect"
import {withStyles} from "@material-ui/core"
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/es/Typography/Typography"
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { ToggleButton } from '../components/ToggleButton'

import {indexify} from "redux/utils"
import KioskSettings from 'apps/admin/components/KioskSettings'
import PassPlusKioskSettings from 'apps/admin/components/PPKSettings'
import {
  kioskDefaultConfigurationEdit,
  ppkEdit,
  fetchRichLocationById,
  kioskDefaultConfigurationPropSetAndSave,
  kioskInstanceEdit
} from 'redux/admin/Actions'
import CurrentFacility from "apps/admin/components/CurrentFacility"
import RoomIcon from '@material-ui/icons/Room'
import DoneIcon from '@material-ui/icons/Done'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  KDCL_INSTANCE,
  KDCL_LOCATION,
  KIOSK_TYPE_PPK,
  TXT_EN_ENTITY_AGNOSTIC_PAGE,
  URL_LOCATION_DETAIL
} from "redux/constants";
import BuildingLocationName from "common/components/BuldingLocationName";


const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class LocationDetail extends React.Component {

  getCutomConfigurationInfo (kioskInstance){
    let count = 0;
    if (kioskInstance.customAttendanceConfiguration && kioskInstance.customAttendanceConfiguration.facilitiesOverrides) {
      count = kioskInstance.customAttendanceConfiguration.facilitiesOverrides.length;
    }
  
    if (count === 0) return "";
    const facilityLabel = count === 1 ? 'entity custom config' : 'entities custom configs';
    return count + " " + facilityLabel;
  }

  componentDidMount() {
    //Ideally props.currentItemId is always set by a listener of history.listen, but on browser '->' button clicks the tree re-renders before the history event fires
    //Considering the not-re-querying behavior 'safe' as the kiosk results are cached on the location page, and changes to the id should prevent forward navigation
    if(this.props.currentItemId) this.props.fetchRichLocationById(this.props.currentItemId)
  }

  render() {
    const {
      atTypes,
      kioskInstanceEdit,
      byId,
      kioskDefaultConfigurationEdit,
      kioskDefaultConfigurationPropSetAndSave,
      ppkEdit,
      ppkIsEnabled,
      locationDetailPage
    } = this.props
    const location = locationDetailPage.location
    const kioskInstances = locationDetailPage.kiosks
    if(!location) return null

    return (
      <div>
        <KioskSettings level={KDCL_LOCATION}/>
        <KioskSettings level={KDCL_INSTANCE}/>
        <PassPlusKioskSettings level={KDCL_INSTANCE}/>
        <CurrentFacility contentOverride={TXT_EN_ENTITY_AGNOSTIC_PAGE}/>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h5">
              <BuildingLocationName byId={byId} locationId={location.id}/>
              <IconButton onClick={() => kioskDefaultConfigurationEdit({parentType:'location', parentId:location.id})} style={{marginTop:-5}} ><EditIcon/></IconButton>
              <ToggleButton
                onClick={() => kioskDefaultConfigurationPropSetAndSave(
                  location.kioskDefaultConfiguration.id,
                  'isEnabled',
                  !location.kioskDefaultConfiguration.isEnabled
                )
                }
                style={{marginTop:-5}}
                title='Enable/Disable all kiosks for this location'
                iconMap={{ 'true': DoneIcon, 'false': HighlightOffIcon }}
                iconKey={location.kioskDefaultConfiguration.isEnabled}
              />
            </Typography>
            <Typography variant="subheading">{location.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell padding={"none"} style={{paddingLeft:12}}>edit</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Custom Configs</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell>Locked</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kioskInstances.reduce((acc, instance) => {
                  if(instance.kioskType !== KIOSK_TYPE_PPK) acc.push(
                    <TableRow key={instance.id}>
                      <TableCell padding={"none"}><IconButton style={{marginTop:-5}} onClick={() => kioskInstanceEdit(instance.id)} ><ImportantDevicesIcon/></IconButton></TableCell>
                      <TableCell>{instance.id}</TableCell>
                      <TableCell>{atTypes(instance.atTypeId, 'displayName')}</TableCell>
                      <TableCell>{this.getCutomConfigurationInfo(instance)}</TableCell>
                      <TableCell style={{textOverflow:'elipsis'}}>{instance.description}</TableCell>
                      <TableCell>{instance.isEnabled? 'Yes':'No'}</TableCell>
                      <TableCell>{instance.isLocked? 'Yes':'No'}</TableCell>
                    </TableRow>)
                  return acc
                }, [])}
              </TableBody>
            </Table>
          </Grid>
          {ppkIsEnabled?
            <Grid item xs={12}>
            <Typography variant="h5">
              PPK Hardware
              <IconButton onClick={() => ppkEdit({locationId: location.id})} style={{marginTop:-5}} ><AddCircleIcon/></IconButton>
            </Typography>
          </Grid>
            : null }
          {ppkIsEnabled?
          <Grid item xs={12}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell padding={"none"} style={{paddingLeft:12}}>edit</TableCell>
                  <TableCell>PPK ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Custom Configs</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell style={{opacity:0}}>Locked</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kioskInstances.reduce((acc, instance) => {
                  if(instance.kioskType === KIOSK_TYPE_PPK) acc.push(
                    <TableRow key={instance.id}>
                      <TableCell padding={"none"}><IconButton style={{marginTop:-5}} onClick={() => ppkEdit({id:instance.id})} ><ImportantDevicesIcon/></IconButton></TableCell>
                      <TableCell title={instance.id}>{instance.ppkKey}</TableCell>
                      <TableCell>{atTypes(instance.atTypeId, 'displayName')}</TableCell>
                      <TableCell>{this.getCutomConfigurationInfo(instance)}</TableCell>
                      <TableCell style={{textOverflow:'elipsis'}}>{instance.description}</TableCell>
                      <TableCell>{instance.isEnabled? 'Yes':'No'}</TableCell>
                      <TableCell></TableCell>
                  </TableRow>)
                  return acc
                }, [])}
              </TableBody>
            </Table>
          </Grid>
            : null}
        </Grid>
      </div>
    )
  }
}


const mapStateToProps = state => {
  const admin = Object.assign({}, state.admin)
  admin.atTypes = indexify(admin.zeroFacility.atTypes, 'id')
  return admin
}


const mapDispatchToProps = {
  kioskDefaultConfigurationEdit,
  kioskInstanceEdit,
  kioskDefaultConfigurationPropSetAndSave,
  fetchRichLocationById,
  ppkEdit,
}


export const LocationDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(LocationDetail))

export const locationDetailPage = {
  icon: RoomIcon,
  component: LocationDetailPage,
  name: 'Locations',
  displayName: 'Locations',
  to: URL_LOCATION_DETAIL,
}

export default LocationDetailPage