/* globals window */
import React from "react"
import PropTypes from 'prop-types'
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TableHead from "@material-ui/core/TableHead"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TablePagination from "@material-ui/core/TablePagination"
import TableFooter from "@material-ui/core/TableFooter"
//import Tooltip from '@material-ui/core/Tooltip'
import Typography from "@material-ui/core/es/Typography/Typography"

import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
//import GpsFixedIcon from '@material-ui/icons/GpsFixed'

import DateRangeIcon from '@material-ui/icons/DateRange'
import IconButton from '@material-ui/core/IconButton'
import SyncStatusIcon from 'apps/admin/components/SyncStatusIcon'
import styles from '../style'
import BuildingLocationName from "common/components/BuldingLocationName"
import AtRecordDot from "apps/admin/pages/StudentAttendance/AtRecordDot";
import { AT_TYPE_ID_ON_SITE } from '../../../../redux/constants'

const pageSizes = [10,20,50,100]

//BEGIN TABLEPAGINATIONACTIONS
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
})

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    )
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
)
//END TABLEPAGINATIONACTIONS
/*
class GpsLink extends React.Component {
  render() {
    const { atRecord } = this.props
    if(!atRecord.lon) return null

    return <a href={`https://www.google.com/maps/@${atRecord.lat},${atRecord.lon},17z`} target='_blank' rel='noopener noreferrer'><IconButton><GpsFixedIcon/></IconButton></a>
  }
} */

class AtRecordDateRowsClass extends React.Component {
  render() {
    const {atDate, setAttendanceViewWeek, byId, showReportingOnly, classes}  = this.props

    const rendered = atDate.atRecords.reduce((acc, atRecord) => {
      if(!atRecord.isDeleted && (atRecord.isReportingAtRecord || !showReportingOnly)) {
        let opacity = atRecord.isReportingAtRecord ? 1 : 0.2

        if(atRecord.atTypeId === AT_TYPE_ID_ON_SITE){
          opacity = 0.7;
        }

        acc.push(
          <TableRow key={atRecord.id} title={`Record ${atRecord.id}`}>
            <TableCell padding={"none"}><IconButton
              title={"Jump to week"}
              style={{opacity: 2}} onClick={() =>
              setAttendanceViewWeek({dateInWeek: atRecord.ymd})}><DateRangeIcon/></IconButton></TableCell>
            <TableCell style={{opacity}}>{atRecord.ymd}</TableCell>
            <TableCell style={{opacity}}>{atRecord.tod}</TableCell>
            <TableCell style={{opacity}}><BuildingLocationName byId={byId} locationId={atRecord.locationId}/>{/*<GpsLink
              atRecord={atRecord}/>*/}</TableCell>
            <TableCell
              style={{opacity, textTransform: 'capitalize'}}><AtRecordDot atTypeId={atRecord.atTypeId} isReportingAtRecord={atRecord.isReportingAtRecord} createdWith={atRecord.createdWith} inline/>{byId.atType(atRecord.atTypeId).displayName}</TableCell>
            <TableCell style={{opacity}}
                       title={atRecord.notes}>{(atRecord.notes.length > 15) ? atRecord.notes.substr(0, 12) + '...' : atRecord.notes}</TableCell>
            <TableCell style={{opacity}}><SyncStatusIcon syncState={atRecord.syncState}
                                                         titleExtra={atRecord.vendorId}/></TableCell>
          </TableRow>
        )
      }
      return acc
    }, [])

    rendered.unshift(<TableRow key={atDate.ymd} className={classes.studentAttendanceDateRow } ><TableCell  colSpan={99}><Typography variant='h6'>{atDate.ymd} - {atDate.weekday}</Typography></TableCell></TableRow>)

    return rendered
  }
}

const AtRecordDateRows = withStyles(styles, { withTheme: true })(AtRecordDateRowsClass)

class StudentAttendanceListViewClass extends React.Component {

  setPageNum = (event, pageNum) => {
    this.props.fetchStudentPageListAttendance({
      atUserId: this.props.student.id,
      pageNum,
      pageSize: this.props.listView.pageSize
    })
  }

  setPageSize = event => {
    this.props.fetchStudentPageListAttendance({
      atUserId: this.props.student.id,
      pageNum: 0,
      pageSize: parseInt(event.target.value)
    })
  }


  render() {
    if(!this.props.student || !this.props.listView ||  !this.props.show) return null

    const {listView: {atRecordsByDate, pageNum, pageSize, totalCount}, student, byId, setAttendanceViewWeek, showReportingOnly} = this.props

    return <Grid item xs={12}>
          <Table >
            <TableHead>
              <TableRow>
                <TablePagination
                  labelRowsPerPage='Records per page'
                  rowsPerPageOptions={pageSizes}
                  colSpan={10}
                  count={totalCount}
                  rowsPerPage={pageSize}
                  page={pageNum}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.setPageNum}
                  onChangeRowsPerPage={this.setPageSize}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
              <TableRow>
                <TableCell padding={"none"}></TableCell>
                <TableCell >Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Where</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Synced</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {atRecordsByDate.length === 0? <TableRow><TableCell colSpan={3}><Typography variant="h6">No attendance found. </Typography></TableCell></TableRow> : null}
              {atRecordsByDate.map(atDate =>
                <AtRecordDateRows key={atDate.ymd} atDate={atDate} setAttendanceViewWeek={setAttendanceViewWeek} byId={byId} showReportingOnly={showReportingOnly} student={student}/>
                )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage='Records per page'
                  rowsPerPageOptions={pageSizes}
                  colSpan={10}
                  count={totalCount}
                  rowsPerPage={pageSize}
                  page={pageNum}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.setPageNum}
                  onChangeRowsPerPage={this.setPageSize}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>

  }
}

export const StudentAttendanceListView = (withStyles(styles, { withTheme: true })(StudentAttendanceListViewClass))

export default StudentAttendanceListView