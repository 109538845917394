import {seconds} from '@relativity/js-util'
import {ID_ZERO} from "redux/constants"

const AtRecord = (atRecord) => {
  const x =  Object.assign( {
    isReportingAtRecord: true,
    vendorId:'',
    atUserId:0,
    kioskInstanceId:ID_ZERO,
    atUserSchedulePeriodId:ID_ZERO,
    yearSegmentId:ID_ZERO,
    facilityId: ID_ZERO,
    locationId: ID_ZERO,
    atTypeId:ID_ZERO,
    notes:'',
    createdWith:'',
    createdByUserId:ID_ZERO,
    needsSync:true,
    syncing:false,
    uTime: seconds(new Date().getTime()),
    uSynced:0
  }, atRecord)
  if(typeof x.uTime === 'string') x.uTime = parseInt(x.uTime)
  return x
}

export default AtRecord