import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/es/TableBody/TableBody'
import Typography from "@material-ui/core/es/Typography";
import moment from 'moment';
import { findOneBy } from '@relativity/js-util'
import styles from './style'
import { yesNoBoolList } from "redux/constants";

import { } from 'redux/admin/Actions'

const formatUnixTimestamp = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YYYY');
};

class SemesterRuleDialog extends React.Component {
  render() {
    if (!this.props.currentSemesterRuleSummary) return null

    const {
      currentSemesterRuleSummary,
    } = this.props

    const resetDatesUnix = currentSemesterRuleSummary.attendanceTerms
      .filter(term => term.isAttendanceCountResetEnabled)
      .map(term => term.uStart);

    const resetDatesMessage = resetDatesUnix.length > 0
      ? 'Attendance tardy and absent counts will be reset on the following dates:'
      : 'Please select attendance terms for attendance tardy and absence counts reset.';

    return (
      <div>
        {currentSemesterRuleSummary.attendanceTerms.length > 0 ? (
          <div>
            <Grid item xs={12} sm={8}>
              <Typography style={{ fontSize: 18, marginTop: 20 }}>{resetDatesMessage}</Typography>
              <Grid>
                <ul style={{ listStyleType: 'disc', paddingLeft: 20 }}>
                  {resetDatesUnix.map((unixDate) => (
                    <li key={unixDate} style={{ marginTop: 5 }}>
                      {formatUnixTimestamp(unixDate)}
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
            <Grid style={{ marginTop: 20 }} container>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Typography style={{ fontSize: 18, marginTop: 15 }}>Skyward Attendance Terms</Typography>
                  <Table style={{ width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell style={{ width: '20%' }}>Start Date</TableCell>
                        <TableCell style={{ width: '20%' }}>End Date</TableCell>
                        <TableCell style={{ width: '22.5%' }}>Is Reset Enabled</TableCell>
                        <TableCell style={{ width: '17.5%' }}>Days in term</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentSemesterRuleSummary.attendanceTerms.map((term) => {
                        return <TableRow key={term.id}>
                          <TableCell>{term.attendanceTermCode}</TableCell>
                          <TableCell>{formatUnixTimestamp(term.uStart)}</TableCell>
                          <TableCell>{formatUnixTimestamp(term.uEnd)}</TableCell>
                          <TableCell>{(findOneBy(yesNoBoolList, 'val', term.isAttendanceCountResetEnabled)).displayName}</TableCell>
                          <TableCell>{term.daysInTerm}</TableCell>
                        </TableRow>
                      })
                      }
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Typography style={{ fontSize: 18, marginTop: 20 }}>Attendance Terms are not available yet.</Typography>
            <Typography style={{ fontSize: 18, marginTop: 10 }}>To pull Skyward Attendance Terms please run Sync Attendance Terms.</Typography>
          </div>
        )
        }
      </div>

    )
  }
}

const mapStateToProps = state => {
  const adminState = state.admin;

  return {
    ...adminState,
  };
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(SemesterRuleDialog))