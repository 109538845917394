export default theme => ({
  dialogTitleRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  dialogTitleCloseButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  dialogContentRoot:{
    minWidth:500,
    paddingBottom:0,
    paddingTop:0,
  },
  formControlLabel: {
    margin: theme.spacing.unit,
    marginLeft:0,
    minWidth: 120,
  },
  gridItem: {
    padding:'2px'
  },
  textField: {
    marginBottom:0
  },
  logViewerCell: {
    paddingLeft:'10px',
    paddingRight:'10px'
  }
})
