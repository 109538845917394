import React from "react"
import connect from "react-redux/es/connect/connect"
import {IconButton, withStyles} from "@material-ui/core"
import Grid from '@material-ui/core/Grid'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck'
import Typography from "@material-ui/core/es/Typography/Typography"
import SettingsIcon from '@material-ui/icons/Settings'
import FancySelect from 'common/components/FancySelect'
import FullTextField from 'common/components/FullTextField'

import styles from '../style'
import {URL_ADMIN_ROOT, yesNoBoolList} from 'redux/constants'
import {
  appSettingsSave,
  updateGeneralSetting,
  emailTest,
} from "redux/admin/Actions"

class LicenseAndAuth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty:false
    }
    this.updateGeneralSetting = this.updateGeneralSetting.bind(this)
    this.updateGeneralSettingFromFancySelect = this.updateGeneralSettingFromFancySelect.bind(this)
  }

  isDirty(val='x') {
    if(val === 'x') {
      return this.state.isDirty
    }
    this.setState({isDirty:!!val})
  }
  //Added this to make easy use of FancySelect and clearly call out the difference between it and the other inputs.
  updateGeneralSettingFromFancySelect({propertyName, val}) {
    this.props.updateGeneralSetting({propertyName, val})
    if(!this.isDirty()) {
      this.isDirty(true)
    }
  }

  updateGeneralSetting(propertyName, val) {
    this.props.updateGeneralSetting({propertyName, val})
    if(!this.isDirty()) {
      this.isDirty(true)
    }
  }

  render() {
    const {
      classes,
      smtpHost,
      smtpPort,
      smtpIsSecure,
      smtpAuthUser,
      smtpAuthPassword,
      smtpFromEmail,
      updateGeneralSetting,
      emailTest,
    } = this.props

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item className={classes.gridItem} xs={12}>
            <Typography variant="h6">Email Server</Typography>
          </Grid>
          <Grid item className={classes.gridItem} xs={12}>
            <FullTextField
              id="settings-smtpHost"
              label="Host Url"
              propertyName='smtpHost'
              value={smtpHost}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"/>
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextField
              id="settings-smtpPort"
              type="number"
              label="Port Number"
              propertyName='smtpPort'
              value={smtpPort}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
              />
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FancySelect
              label='Secure Connection'
              currentVal={smtpIsSecure}
              valAtr={'val'}
              dispatchUpdate={this.updateGeneralSettingFromFancySelect}
              className={classes.selectEmpty}
              propertyName={'smtpIsSecure'}
              items={yesNoBoolList}
            >
            </FancySelect>
          </Grid>
        </Grid>
        <Grid container className={classes.firstRow}>
          <Grid item className={classes.gridItem} xs={12}>
            <Typography variant="h6">Email User <IconButton disabled={this.isDirty()} onClick={emailTest} title='Test settings by sending a loopback email (only available after settings have been saved'><NetworkCheckIcon/></IconButton></Typography>
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextField
              id="settings-smtpAuthUser"
              type=""
              label="Login"
              propertyName='smtpAuthUser'
              value={smtpAuthUser}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
              />
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextField
              id="settings-smtpAuthPassword"
              type="password"
              label="Password"
              propertyName='smtpAuthPassword'
              autoComplete={'off'}
              value={smtpAuthPassword}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
              />
          </Grid>
          <Grid item className={classes.gridItem} xs={12}>
            <FullTextField
              id="settings-smtpAuthPassword"
              type="email"
              label="Sender From: Email"
              propertyName='smtpFromEmail'
              value={smtpFromEmail}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
              />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state.admin.settings
}

const mapDispatchToProps = {
  updateGeneralSetting,
  emailTest,
  appSettingsSave,
}

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(LicenseAndAuth))

export const settingsPage = {
  icon: SettingsIcon,
  component: SettingsPage,
  name: 'LicenseAndAuth',
  to: `${URL_ADMIN_ROOT}/:facilityId?/settings`
}

export default SettingsPage