import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import moment from 'moment-timezone'
import { FORMAT_AMPM_YMD } from '../../../../redux/constants'
import styles from './style'

class StudentLocatorInfo extends React.Component {
  render() {
    const {classes, currentLocation, scheduledLocation, defaultEntityName, 
      grade, gradYear, lastCheckLocation, lastCheckTime, selectedStudent, queueInfo} = this.props
    
    let currentQueueLocationName = queueInfo.currentQueueLocation ? queueInfo.currentQueueLocation.officeName : ''
    
    let lastCheckInTimeView = lastCheckTime
    if(lastCheckTime == null){
      lastCheckInTimeView = ' '
    }
    else if(parseInt(lastCheckTime)){
      lastCheckInTimeView = moment.unix(lastCheckTime).format(FORMAT_AMPM_YMD)
    }

    return (
        <div>
            <Grid container spacing={24} className={classes.singleContentRow}>   
              <Grid item xs={3}>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  variant="outlined"
                  label={'Name'}
                  value={selectedStudent.fullName || ' '}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  variant="outlined"
                  label={'SSID'}
                  value={selectedStudent.attendancePasscode || ' '}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid> 
              <Grid item xs={3}>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  variant="outlined"
                  label={'Default Entity'}
                  value={defaultEntityName || ' '}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid> 
              <Grid item xs={3}>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  variant="outlined"
                  label={'Grade / Grad Year'}
                  value={grade && gradYear ? `${grade} / ${gradYear}` : ' '}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>                               
            </Grid>

            <Grid container spacing={24} className={classes.singleContentRow}>
              <Grid item xs={3}>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  variant="outlined"
                  label={'Current room / building'}
                  value={currentQueueLocationName || currentLocation || ' '}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  variant="outlined"
                  label={'Scheduled room / building'}
                  value={scheduledLocation || ' '}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>            
              <Grid item xs={3}>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  variant="outlined"
                  label={'Last check in room / building'}
                  value={lastCheckLocation || ' '}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                  <TextField
                    disabled={true}
                    fullWidth={true}
                    variant="outlined"
                    label={'Last check in time'}
                    value={lastCheckInTimeView}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
              </Grid>              
            </Grid>  
        </div>      
    )
  }
}

const mapStateToProps = state => ({
  ...state.admin.locatorStudentInfo,
  ...state.admin.locatorStudentPicker
})

export default connect(
  mapStateToProps,
)(withStyles(styles, { withTheme: true })(StudentLocatorInfo))