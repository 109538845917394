import React from "react"
import connect from "react-redux/es/connect/connect"
import {Typography, withStyles} from "@material-ui/core"
import CurrentFacility from "apps/admin/components/CurrentFacility"
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import {kioskDefaultConfigurationEdit } from "redux/admin/Actions"
import {KDCL_BUILDING, TXT_EN_ENTITY_AGNOSTIC_PAGE, URL_ADMIN_ROOT} from "redux/constants";
import KioskSettings from "apps/admin/components/KioskSettings"

const styles = {}

class Buildings extends React.Component {
  render() {
    const {buildings, kioskDefaultConfigurationEdit} = this.props
    return (
      <div>
        <KioskSettings level={KDCL_BUILDING}/>
        <CurrentFacility contentOverride={TXT_EN_ENTITY_AGNOSTIC_PAGE}/>
        <Typography>These are the buildings in your organization</Typography>
        <Typography>Use the pencil icon to edit or push Kiosk defaults for a building.</Typography>
        <List>
          {
            buildings.map(building =>
              <ListItem key={building.id}>
                <ListItemIcon><StoreMallDirectoryIcon/></ListItemIcon>
                <ListItemText>{building.displayName}  <IconButton onClick={()=>kioskDefaultConfigurationEdit({parentType:'building', parentId:building.id})}><EditIcon /></IconButton></ListItemText>
              </ListItem>)
          }
        </List>
      </div>
    )
  }
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {kioskDefaultConfigurationEdit}

export const BuildingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Buildings))

export const buildingsPage = {
  icon: StoreMallDirectoryIcon,
  component: BuildingsPage,
  name: 'Buildings',
  displayName: 'Buildings ',
  to: `${URL_ADMIN_ROOT}/:facilityId?/building`
}

export default BuildingsPage