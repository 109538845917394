import React from 'react';
import {connect} from "react-redux"
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import {STATUS_MESSAGE_TYPE_INFO, STATUS_MESSAGE_TYPE_SUCCESS, STATUS_MESSAGE_TYPE_WARNING, STATUS_MESSAGE_TYPE_ERROR} from "redux/constants";
import {snackMessageHide} from "redux/RootActions"


const variantIcon = {
  [STATUS_MESSAGE_TYPE_SUCCESS]: CheckCircleIcon,
  [STATUS_MESSAGE_TYPE_ERROR]: ErrorIcon,
  [STATUS_MESSAGE_TYPE_INFO]: InfoIcon,
  [STATUS_MESSAGE_TYPE_WARNING]: WarningIcon,
};

const snackMessageContentStyles = theme => ({
  [STATUS_MESSAGE_TYPE_SUCCESS]: {
    backgroundColor: green[600],
  },
  [STATUS_MESSAGE_TYPE_ERROR]: {
    backgroundColor: theme.palette.error.dark,
  },
  [STATUS_MESSAGE_TYPE_INFO]: {
    backgroundColor: theme.palette.primary.dark,
  },
  [STATUS_MESSAGE_TYPE_WARNING]: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function SnackMessageContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  let userMessage = message
  if(message.constructor === Array) {
    userMessage = <ul>{message.map((msg, i) => {
      return <li key={i}>{msg}</li>
    })}
    </ul>
  }
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <span style={{whiteSpace:"pre-line"}}>{userMessage}</span>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackMessageContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([STATUS_MESSAGE_TYPE_SUCCESS, STATUS_MESSAGE_TYPE_ERROR, STATUS_MESSAGE_TYPE_INFO, STATUS_MESSAGE_TYPE_WARNING]).isRequired,
};

const SnackMessageContentWrapper = withStyles(snackMessageContentStyles)(SnackMessageContent)

class SnackMessage extends React.Component {

  render() {
    const { snackMessageHide, show, userMessage, messageType, autoHideMillis  } = this.props;
    if(!show) return null
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={show}
          autoHideDuration={autoHideMillis}
          onClose={snackMessageHide}
        >
          <SnackMessageContentWrapper
            onClose={snackMessageHide}
            variant={messageType}
            message={userMessage}
          />
        </Snackbar>
      </div>
    );
  }
}



const mapStateToProps = state => {
  return state.common.snackMessage
}
const mapDispatchToProps = {
  snackMessageHide
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackMessage)