import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Menu from "@material-ui/core/Menu/Menu"
import { Link as RouterLink } from 'react-router-dom'

import { settingsPage } from 'apps/admin/pages/Settings'

import { adminExpandMainNav, adminCollapseMainNav, adminLogin } from 'redux/actions'
import { logoutSend } from "redux/admin/Actions";
import FacilityChooser from './FacilityChooser'
import Link from '../Link'
import styles from './style'
import STASwirl from "apps/admin/icons/STASwirl";

class MiniDrawer extends React.Component {
  state = {
    anchorEl: null,
  }

  adminAccountMenuShow = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  adminAccountMenuHide = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { appVersion, auth, classes, currentPage,
      theme,
      mainNavExpanded,
      adminExpandMainNav, adminCollapseMainNav,
      mainNavPages, accountMenuPages, logoutSend, loggedInUser, schoolYear = { displayName: '?' } } = this.props
    const anchorEl = this.state.anchorEl
    const open = Boolean(anchorEl)
    return (
      <div className={classes.root}>

        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: mainNavExpanded,
          })}
        >
          <Toolbar disableGutters={!mainNavExpanded}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={adminExpandMainNav}
              className={classNames(classes.menuButton, {
                [classes.hide]: mainNavExpanded,
              })}
              style={{
                backgroundImage: 'url(/images/school_trak_icon_light.svg)',
                backgroundRepeat: 'no-repeat',
                height: '36px',
                width: '36px',
                display: 'block',
                marginLeft: 18,
                marginRight: 18
              }}
            />

            <Typography variant="h5" color="inherit" >{currentPage.displayName} -</Typography>
            {settingsPage !== currentPage && <FacilityChooser />}
            <Typography variant="h5" color="inherit" className={classes.grow}>{' '}</Typography>
            
            {auth && (
              <div>
                {loggedInUser.fullName}
                <IconButton
                  aria-owns={open ? 'menu-admin-account-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.adminAccountMenuShow}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-admin-account-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.adminAccountMenuHide}
                >
                  <List>
                    {accountMenuPages.map((item, index) => {
                      const { name, to, icon } = item
                      return <RouterLink key={index} to={to} title={name} style={{textDecoration: 'none', color: 'inherit'}}>
                        <ListItem button >
                          <ListItemIcon style={{marginRight: '0'}}>{React.createElement(icon)}</ListItemIcon>
                          <ListItemText primary={name} />
                        </ListItem>
                      </RouterLink>
                    })}
                    <ListItem button onClick={logoutSend}>
                      <ListItemIcon style={{marginRight: '0'}}>{React.createElement(ExitToAppIcon)}</ListItemIcon>
                      <ListItemText primary={'Logout'} />
                    </ListItem>
                  </List>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: mainNavExpanded,
            [classes.drawerClose]: !mainNavExpanded,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: mainNavExpanded,
              [classes.drawerClose]: !mainNavExpanded,
            }),
          }}
          open={mainNavExpanded}
        >
          <div className={classes.toolbar}>
            <span style={{ flexGrow: 8, padding: 10 }}>Year {schoolYear.displayName}</span>
            <IconButton onClick={adminCollapseMainNav}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {mainNavPages.map((item, index) => {
              const { name, to, icon } = item
              return <Link key={index} to={to} title={name}><ListItem button >
                <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItem></Link>
            })}
          </List>
          <Divider />
          <List>
            <a href='https://k12sta.com/support/schooltrak/' target="_blank" rel="noopener noreferrer">
              <ListItem button >
                <ListItemIcon><STASwirl /></ListItemIcon>
                <ListItemText primary={`Support - ${appVersion}`} />
              </ListItem>
            </a>
          </List>
        </Drawer>
      </div>
    )
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}


const mapStateToProps = state => state.admin

const mapDispatchToProps = {
  adminCollapseMainNav,
  adminExpandMainNav,
  adminLogin,
  logoutSend
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(MiniDrawer))
