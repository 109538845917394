import React from "react";
import connect from "react-redux/es/connect/connect";
import { withStyles, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import DialogTitle from 'common/components/dialog/DialogTitle'
import SettingsIcon from '@material-ui/icons/Settings';
import TextField from '@material-ui/core/TextField'
import WarningIcon from '@material-ui/icons/Warning';
import styles from '../style';
import { URL_ADMIN_ROOT } from 'redux/constants';
import { migrateSchoolYear, stopSyncSchoolYearsStatePolling } from "redux/admin/Actions"


class SchoolYearMigration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      showErrorDialog: false,
      shouldDisplayMigrationInfo: false,
      migrationStarted: false,
      migrationFinished: false,
      isMigrateButtonEnabled: false,
      password: '',
      email: ''
    };
  }

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({
       openDialog: false,
       email: '',
       password: '',
       isMigrateButtonEnabled: false
      });
    
  };

  handleErrorDialogClose = () => {
    this.setState({ showErrorDialog: false });
  };

  handleConfirm = () => {
    this.props.migrateSchoolYear({
      email: this.state.email,
      password: this.state.password
    });
    this.setState({ openDialog: false });
    this.setState({ migrationStarted: true });
  };

  componentDidUpdate(prevProps) {
    if (this.props.schoolYears !== prevProps.schoolYears) {
      this.setState({ shouldDisplayMigrationInfo: true });
      return;
    }

    if (this.props.schoolYear !== prevProps.schoolYear) {
      this.setState({ migrationFinished: true });
      return;
    }

    if (this.props.schoolYearMigration !== prevProps.schoolYearMigration) {
      this.setState({ migrationFinished: true });
      return;
    }
  };

  setValue(e, label) {
    const newValue = typeof e === 'string' ? e : e.target.value;
    this.setState({ [label]: newValue }, () => {
      const { email, password } = this.state;
      this.setState({ isMigrateButtonEnabled: email.length > 0 && password.length > 0 });
    });
  }

  componentWillUnmount() {
    this.setState({ migrationStarted: false });
    this.setState({ migrationFinished: false });
    this.props.stopSyncSchoolYearsStatePolling();
  };

  componentDidMount() {
    window.stHooks.addHook('loginEmail', (e) => this.setValue(e, 'email'))
    window.stHooks.addHook('loginPwd', (e) => this.setValue(e, 'password'))
  }

  render() {
    const { classes, schoolYears, schoolYear, schoolYearMigration } = this.props;
    const { shouldDisplayMigrationInfo, openDialog, migrationStarted, migrationFinished } = this.state;

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item className={classes.gridItem} xs={12}>
            {migrationStarted === true ? (
              migrationFinished === true ? (
                schoolYearMigration.status !== 'error' ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15, marginBottom: 10 }}>
                    <Typography variant="subtitle1">Congratulations! Migration to the {schoolYear.displayName} school year has successfully finished!</Typography>
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15, marginBottom: 10 }}>
                    <Typography variant="subtitle1">{schoolYearMigration.message}</Typography>
                  </div>
                )
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15, marginBottom: 10 }}>
                  <Typography variant="subtitle1">Migration has started. Please wait a moment...</Typography>
                </div>
              )
            ) : (
              <div>
                <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                <WarningIcon style={{ marginRight: 15, color: 'orange' }} />
                Attention!
                </Typography>
                {shouldDisplayMigrationInfo === true ? (
                  schoolYears.skywardCurrent.id !== schoolYears.schooltrakCurrent.id ? (
                    <div>
                      <Typography variant="subtitle1">Skyward has a new school year {schoolYears.skywardCurrent.displayName} available!</Typography>
                      <Typography variant="subtitle1">To start the migration process, click the button below.</Typography>
                      <Typography variant="subtitle1">Current year students, schedules, and attendances will be deleted from SchoolTrak.</Typography>
                      <Typography variant="subtitle1">The next school year students will be pulled from Skyward.</Typography>
                      <Typography variant="subtitle1">Your current configurations, such as SchoolTrak users, kiosks, PPK devices, synchronizer settings, period rules, etc., will be preserved.</Typography>
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15, marginBottom: 10 }}>
                        <Button color="primary" onClick={this.handleClickOpen}>Migrate to {schoolYears.skywardCurrent.displayName} school year</Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Typography variant="subtitle1">Skyward current school year for your facility is {schoolYears.schooltrakCurrent.displayName}.</Typography>
                      <Typography variant="subtitle1">New school year in Skyward is not enabled yet. Please try again later.</Typography>
                    </div>
                  )
                ) : (
                  <Typography variant="subtitle1">Pulling school years information from Skyward...</Typography>
                )}
              </div>
            )}
          </Grid>
        </Grid>

        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Confirm migration
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: 5, marginBottom: 0 }}>
                <Typography variant="h4" style={{display: 'flex', alignItems:'center', marginTop: 15, marginBottom: 15 }}> 
                <WarningIcon style={{ fontSize: 35, alignItems: 'center', marginRight: 15, color: 'orange' }} />
                  Attention!
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="subtitle1">To perform migration to the school year <b>{schoolYears.skywardCurrent.displayName}</b></Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="subtitle1">please enter your email and password</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="subtitle1"><b>Migration can not be undone!</b></Typography>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    id="login-email"
                    label="Email"
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    ref={this.refEmail}
                    value={this.state.email}
                    type="email"
                    autoComplete="email"
                    onChange={(e) => this.setValue(e, 'email')}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    id="login-pwd"
                    label="Password"
                    fullWidth
                    type="password"
                    autoComplete="password"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    ref={this.refPassword}
                    value={this.state.password}
                    onChange={(e) => this.setValue(e, 'password')}
                  />
                </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button disabled = {!this.state.isMigrateButtonEnabled} onClick={this.handleConfirm} color="primary" autoFocus>
              Migrate
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

const mapStateToProps = state => {
  const schoolYears = state.admin.schoolYears;
  const schoolYear = state.admin.schoolYear;
  const schoolYearMigration = state.admin.schoolYearMigration;
  return { schoolYears, schoolYearMigration, schoolYear };
};

const mapDispatchToProps = {
  migrateSchoolYear, stopSyncSchoolYearsStatePolling
};

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(SchoolYearMigration));

export const settingsPage = {
  icon: SettingsIcon,
  component: SettingsPage,
  name: 'SchoolYearMigration',
  to: `${URL_ADMIN_ROOT}/:facilityId?/settings`
};

export default SettingsPage;