import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import moment from 'moment-timezone'
import MomentUtils from "@date-io/moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Divider} from "@material-ui/core";

import {
  setLocatorSchedulePickerDate
} from 'redux/admin/Actions'

import styles from './style'

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'rgb(1, 151, 215)',
    fontSize: 15,
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
}))(TableCell);

class LocatorSchedule extends React.Component {

  componentDidMount(){
    const { setLocatorSchedulePickerDate } = this.props   
    setLocatorSchedulePickerDate(moment())
  }

  render() {
    const {classes, pickerDate, setLocatorSchedulePickerDate, selectedStudent, periods } = this.props
  
    return (
        <div>          
          <Grid container spacing={24} className={classes.singleContentRow}>   
            <Grid item xs={12}>
              <Divider /> 
            </Grid>
                
            <Grid item xs={1}>            
              <MuiPickersUtilsProvider utils={MomentUtils} >
              <DatePicker
                className={classes.datePicker}
                disabled={Object.keys(selectedStudent).length === 0}
                fullWidth={true}
                margin="none"                
                value={pickerDate}                
                onChange={(dateInWeek)=>{
                  setLocatorSchedulePickerDate(dateInWeek)
                }} 
                InputProps={{
                  classes: {
                    input: classes.datePicker,
                    disabled: classes.datePickerDisalbed
                  },
                }}               
              />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid> 

          <Paper className={`${classes.tableRoot} ${periods.length === 0 ? classes.tablePadding : ''}`}>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell style={{paddingRight: '0'}}>Period</CustomTableCell>
                  <CustomTableCell>Start time</CustomTableCell>
                  <CustomTableCell>End time</CustomTableCell>
                  <CustomTableCell>Course name</CustomTableCell>
                  <CustomTableCell>Building</CustomTableCell>
                  <CustomTableCell>Room</CustomTableCell>
                  <CustomTableCell style={{width: '20%'}}>Attendance / Reason</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {periods.map(period => (                  
                  <TableRow key={period.id}>
                    <CustomTableCell>
                      {period.periodNumber}
                    </CustomTableCell>
                    <CustomTableCell>{moment.unix(period.uStart).format('h:mm A')}</CustomTableCell>
                    <CustomTableCell>{ period.uEnd === 0 ? '-' : moment.unix(period.uEnd).format('h:mm A') }</CustomTableCell>
                    <CustomTableCell>{period.courseDescription}</CustomTableCell>
                    <CustomTableCell>{period.building}</CustomTableCell>
                    <CustomTableCell>{period.location}</CustomTableCell>
                    <CustomTableCell>{period.attendance}</CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>         
        </div>      
    )
  }
}

const mapStateToProps = state => ({
  ...state.admin.locatorSchedule,
  selectedStudent: state.admin.locatorStudentPicker.selectedStudent
})

const mapDispatchToProps = {
  setLocatorSchedulePickerDate
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(LocatorSchedule))