/* globals window navigator */
import React from "react"
import {IconButton, withStyles} from "@material-ui/core"
import ShareIcon from '@material-ui/icons/Share'
import Grid from "@material-ui/core/Grid"
import TableHead from "@material-ui/core/TableHead"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Tooltip from "@material-ui/core/Tooltip";
import KeyboardArrowLeftIcon from "@material-ui/core/es/internal/svg-icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/core/es/internal/svg-icons/KeyboardArrowRight";
import {
  FORMAT_YMD,
  //URL_HOME_ROUTE, URL_MANAGE_STUDENT_DETAIL_WEEK,
  WEEKDAYS
} from "redux/constants"
import styles, {makeAuspClassName} from './style'
import moment from 'moment-timezone'
import AtRecord from "common/model/AtRecord";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import MomentUtils from "@date-io/moment";
import AtRecordDot from "./AtRecordDot";
import { fieldNames } from '../../../../redux/constants'


class StudentAttendanceWeekViewClass extends React.Component {

  nextWeek = () =>{
    const {weekCalendar, fetchStudentPageScheduleAttendance, student} = this.props
    fetchStudentPageScheduleAttendance({atUserId:student.id, dateInWeek:moment(weekCalendar.currentYMD).add(7, 'days')})
  }

  previousWeek = () => {
    const {weekCalendar, fetchStudentPageScheduleAttendance, student} = this.props
    fetchStudentPageScheduleAttendance({atUserId:student.id, dateInWeek:moment(weekCalendar.currentYMD).subtract(7, 'days')})
  }

  shareWeekLink = () => {
    const {location: {pathname}, weekCalendar:{ymdStart}} = this.props
    const mo = moment(ymdStart)
    const newPath = `${pathname.split('/week')[0]}/week/${mo.format(FORMAT_YMD)}`
    const newUrl = `${window.location.protocol}//${window.location.host}${newPath}`
    this.props.history.push(newPath)
    if(navigator.clipboard) {
      navigator.clipboard.writeText(newUrl)
      this.props.snackMessageInfo("Link copied to clipboard");
    } else {
      this.props.snackMessageInfo("Copy the link above to share with authorized people.");
    }
  }

  render() {
    if(!this.props.student || !this.props.weekCalendar.ymdEnd || !this.props.show) return null

    const {
      atRecordEdit,
      atReasonById,
      classes,
      fetchStudentPageScheduleAttendance,
      showReportingOnly,
      student,
      typeById,
      weekCalendar,
    } = this.props

    const earliestHour = 6 < weekCalendar.minHour? 6 : weekCalendar.minHour
    const latestHour = 18 > weekCalendar.maxHour? 18 : weekCalendar.maxHour
    const ymdList = []
    const iMoment = moment(weekCalendar.currentYMD)

    iMoment.subtract(iMoment.day(), 'day')
    const pickerDate = iMoment.format('MMM Do')
    for(let i = 0; i < 7; i++) {
      ymdList.push(iMoment.format(FORMAT_YMD))
      iMoment.add(1, 'day')
    }
    iMoment.subtract(7, 'day') //for the date-picker display

    const mnt = moment()
    mnt.set('hour', earliestHour).set('minute',0).set('second',0)
    const hours = []
    for(let i = earliestHour; i <= latestHour; i++) {
      hours.push(i)
    }

    return <Grid item xs={12}>
          <Table style={{width:'100%'}}>
            <TableHead>
              <TableRow>
                <TableCell style={{width:'12.5%', cursor:'pointer'}} padding={"none"}>
                  <Grid container>
                    <Grid item xs={5} xl={3}><IconButton onClick={this.shareWeekLink}><ShareIcon/></IconButton></Grid>
                    <Grid item xs={7} xl={5}>
                      <MuiPickersUtilsProvider utils={MomentUtils} >
                        <DatePicker
                          style={{
                            cursor:'pointer'
                          }}
                          variant='outlined'
                          margin="none"
                          label=""
                          labelFunc={()=>`${pickerDate}`}
                          value={iMoment}
                          onChange={(dateInWeek)=>{
                            fetchStudentPageScheduleAttendance({atUserId: student.id, dateInWeek})
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} xl={2}><IconButton onClick={this.previousWeek}><KeyboardArrowLeftIcon/></IconButton></Grid>
                    <Grid item xs={6} xl={2}><IconButton onClick={this.nextWeek}><KeyboardArrowRightIcon /></IconButton></Grid>
                  </Grid>
                  </TableCell>
                {ymdList.map((ymd, i) => {
                  return <TableCell style={{width:'12.5%'}} key={ymd} title={ymd} >{WEEKDAYS[i]} {ymd.split('-')[2]}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {hours.map(hour => {
                const initialSchedDispSettings = this.props.schedDispSettings.view.student.calendar.event;
                const nameFieldsLength = initialSchedDispSettings.nameFields.length;
                const fieldsEquivalent = nameFieldsLength < 3 ? 1
                  : (1 + (nameFieldsLength - 3) * 0.32);
                mnt.set('hour', hour)
                return <TableRow key={hour} style={{height: 60 * fieldsEquivalent}}> 
                  <TableCell style={{verticalAlign: 'text-top'}}>{mnt.format('h:mma')}</TableCell>
                  {ymdList.map((ymd, i) => {
                    let atRecords = []
                    let atRecordRightOffset = -14
                    let schedulePeriods = []
                    let schedulePeriodsById = {}

                    if(weekCalendar.byHour[hour] && weekCalendar.byHour[hour].dates[ymd]) {
                      atRecords = weekCalendar.byHour[hour].dates[ymd].atRecords
                      schedulePeriods = weekCalendar.byHour[hour].dates[ymd].schedulePeriods
                    }

                    return <TableCell
                      key={ymd}
                      style={{borderLeft:'1px solid #F1F1F1', position:'relative'}}  >
                      {schedulePeriods.map(schedulePeriod => {
                        schedulePeriod.atRecordCurrentCount = 0
                        schedulePeriodsById[schedulePeriod.id] = schedulePeriod

                        let mPeriodStart = moment.unix(schedulePeriod.uStart)
                        let mPeriodEnd = moment.unix(schedulePeriod.uEnd)
                        let title =  `${mPeriodStart.format('h:mma')}-${mPeriodEnd.format('h:mma')} (${schedulePeriod.minuteLength} mins)`

                        let periodCellClassName = '';

                        if (schedulePeriod.isEmptyPeriod) {
                          const atRecord = weekCalendar.atRecordsForNonAttendancePeriods.find(atRecord => 
                            atRecord.uTime >= schedulePeriod.uStart && atRecord.uTime <= schedulePeriod.uEnd
                          );
                          if (atRecord) {
                            periodCellClassName = classes[makeAuspClassName(schedulePeriod, atRecord)];
                          }
                          else{
                            periodCellClassName = classes[makeAuspClassName(schedulePeriod, weekCalendar.reportingAtRecordsByAuspId[schedulePeriod.id])];
                          }
                        } else {
                          periodCellClassName = classes[makeAuspClassName(schedulePeriod, weekCalendar.reportingAtRecordsByAuspId[schedulePeriod.id])];
                        }
                        return <Tooltip
                          title={<>
                              {title}
                              {initialSchedDispSettings.toolTipFields.map((opt) => <p style={{ margin: 0 }}>
                                {opt.replace('_', ' ')}: {schedulePeriod[fieldNames[opt]]}
                              </p>)}
                            </>}
                        >
                          <div>
                            <div
                              key={schedulePeriod.id}
                              className={classes.auspRoot}
                              style={
                                {
                                  top: schedulePeriod.minuteOffset * fieldsEquivalent,
                                  left: schedulePeriod.leftPercent + '%',
                                  height: schedulePeriod.minuteLength * fieldsEquivalent,
                                  width: schedulePeriod.widthPercent + '%',
                                  color: schedulePeriod.isEmptyPeriod ? '#595959' : ''
                                }
                              }
                              onClick={()=>{if(schedulePeriod.isLocked || schedulePeriod.isEmptyPeriod) return
                                atRecordEdit({student, atRecord:
                                  AtRecord({
                                    atUserId: student.id,
                                    facilityId: schedulePeriod.facilityId,
                                    atUserSchedulePeriodId: schedulePeriod.id,
                                    uTime: schedulePeriod.uStart
                                  }),
                                weekCalendar
                              })}
                              }
                            >
                              <div className={periodCellClassName}/>
                              <span style={{width: '95%'}} className={classes.auspName}>
                                {initialSchedDispSettings.nameFields.map((opt, i, dispSettings) => {
                                  const isItPeriodNumber = dispSettings[0] === 'period_number';
                                  return <div style={{
                                      display: isItPeriodNumber && i < 2 ? 'inline' : 'block',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                    }}>
                                      {schedulePeriod[fieldNames[opt]]}
                                      {isItPeriodNumber && i === 0 ? <>&nbsp;</> : <br />}
                                    </div>})}
                              </span>
                            </div>
                          </div>
                        </Tooltip>
                      })
                      }
                      {atRecords.reduce((acc, atRecord) => {
                        if(!atRecord.isDeleted && (atRecord.isReportingAtRecord || !showReportingOnly)) {
                          let atMoment = moment.unix(atRecord.uTime)
                          let editable = true
                          let rightOffset = atRecordRightOffset
                          let minuteOffset = atRecord.minuteOffset * fieldsEquivalent
                          const atReason = atReasonById(atRecord.atReasonId)
                          const externalType = typeById(atRecord.atTypeExternalId)
                          const internalTypeDisplayName = typeById(atRecord.atTypeId).displayName
                          const externalTypeDisplayName = externalType && externalType.id !== 0 ? externalType.displayName : 'No External Type'
                          const atReasonDisplayName = atReason && atReason.id !== 0 ? atReason.displayName : 'No Reason Specified'
                          if(atRecord.atUserSchedulePeriodId && schedulePeriodsById.hasOwnProperty(atRecord.atUserSchedulePeriodId)) {
                            const schedulePeriod = schedulePeriodsById[atRecord.atUserSchedulePeriodId]
                            const percent = 100 - (schedulePeriod.leftPercent + schedulePeriod.widthPercent)
                            const pixels = schedulePeriod.atRecordCurrentCount * 15 + 2
                            rightOffset = `calc(${percent}% + ${pixels}px)`
                            schedulePeriod.atRecordCurrentCount++
                          } else {
                            rightOffset = atRecordRightOffset = atRecordRightOffset + 15
                          }

                          acc.push(<AtRecordDot
                            atTypeId={atRecord.atTypeId}
                            isReportingAtRecord={atRecord.isReportingAtRecord}
                            createdWith={atRecord.createdWith}
                            title={`${atMoment.format('h:mma')} ${internalTypeDisplayName} / ${externalTypeDisplayName} / ${atReasonDisplayName}`}
                            key={atRecord.id}
                            onClick={() => {
                              if(!editable) {
                                alert('This item should not be edited')
                              }
                              atRecordEdit({student, atRecord, weekCalendar})
                            }}
                            style={
                              {
                                position: 'absolute',
                                top: minuteOffset,
                                right: rightOffset,
                              }
                            }
                          />)
                        }
                        return acc
                      }, [])
                      }
                    </TableCell>
                  })}
                </TableRow>
              })}

            </TableBody>
          </Table>
        </Grid>
  }
}

export const StudentAttendanceWeekView = (withStyles(styles, { withTheme: true })(StudentAttendanceWeekViewClass))

export default StudentAttendanceWeekView