import React, { Component } from 'react'
import { Route,  Switch } from "react-router-dom"
import MainNavigation from './components/MainNavigation'
import {withStyles} from "@material-ui/core"
import PropTypes from "prop-types"
import connect from "react-redux/es/connect/connect"
import { withRouter } from 'react-router-dom'
import {adminBoot, adminSetCurrentPath} from 'redux/actions'
import Home from "apps/admin/pages/Home"
import LoginDialog from 'common/components/LoginDialog'
import {snackMessageInfo, snackMessageError} from 'redux/RootActions'
import {loginSend, forgotPasswordSend, searchFromUrl} from "redux/admin/Actions";
import {URL_ADMIN_ROOT} from "../../redux/constants";


const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  }
})


class App extends Component {
  constructor(props) {
    super(props)

    const title = document.getElementsByTagName('title')[0]
    if(title) title.text = "Attendance Admin"

    props.history.listen( (location, x) =>  {
      props.adminSetCurrentPath(location.pathname)
      props.searchFromUrl(true)
    })
    props.adminSetCurrentPath(props.location.pathname)
    if(!window.application) {
      window.application = ''
    }
    this.state = {
      adminBoot:props.adminBoot
    }
  }

  componentDidMount(){
    this.state.adminBoot()
  }

  render() {
    const { classes, pages, isReady, dialogs, loginSend, forgotPasswordSend, loggedInUser, organizationName, snackMessageError, snackMessageInfo} = this.props

    return (
      <div className={classes.root}>
        {dialogs.login?
          <LoginDialog
            {...dialogs.login}
            loginType='admin'
            loginSend={loginSend}
            forgotPasswordSend={forgotPasswordSend}
            organizationName={organizationName}
            snackMessageInfo={snackMessageInfo}
            snackMessageError={snackMessageError}
          />
          :
          null }
        {(isReady && loggedInUser) ?
            [ <MainNavigation key='MainNavigation' />,

            <main key='main' className={classes.content}>
              <div className={classes.toolbar}/>
              <Switch>
                <Route path={`${URL_ADMIN_ROOT}/`} exact component={Home}/>
                {pages.map(page => <Route
                  key={page.to}
                  path={(page.route) ? page.route : page.to}
                  component={page.component} />)}
              </Switch>
            </main>]
            : null
        }
      </div>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {
  adminSetCurrentPath,
  adminBoot,
  loginSend,
  forgotPasswordSend,
  searchFromUrl,
  snackMessageInfo,
  snackMessageError,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(App)))
