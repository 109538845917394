import React from 'react'
import connect from 'react-redux/es/connect/connect'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

import {
  workerJobEditSave,
  workerJobEditDone,
  workerJobEditUpdateProperty,
  coursesFetch,
  coursesFetchResponse,
  attendanceMonitorCoursesEdit,
  attendanceMonitorLocationsEdit
} from 'redux/admin/Actions';

import {
  ID_ZERO,
  onFailedActions
} from 'redux/constants'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FancySelect from 'common/components/FancySelect'

import styles from './style'
import {FullTextField, FullTextFieldD} from "common/components/FullTextField";
import CustomConfiguration from "apps/admin/components/CustomConfiguration";
import CourseSelectionDialog from "apps/admin/components/CourseSelectionDialog";
import LocationSelectionDialog from "apps/admin/components/LocationSelectionDialog";

const WORKER_JOB_NAME_ATTENDANCE_MONITOR = 'WORKER_JOB_NAME_ATTENDANCE_MONITOR';


class WorkerJobDialog extends React.Component {

  render() {
    if(!this.props.workerJob) return null

    const {
      classes,
      workerJob,
      workerJobEditDone,
      workerJobEditSave,
      workerJobEditUpdateProperty,
      courses,
      locations,
      attendanceMonitorCoursesEdit,
      attendanceMonitorLocationsEdit } = this.props

    const enabledCoursesCount = courses.filter(course => course.isAttendanceMonitorEnabled).length;
    const totalCoursesCount = courses.length;

    const enabledLocationsCount = locations.filter(location => location.isAttendanceMonitorEnabled).length;
    const totalLocationsCount = locations.length;

    return (
      <Dialog
        onClose={workerJobEditDone}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={workerJobEditDone}>
          {workerJob.displayName} - Details
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          {(workerJob.id > ID_ZERO) ?
            <Grid container>
              <Grid item xs={12}>
                <FullTextFieldD
                  label="Last Run Summary"
                  value={workerJob.runStatusSummary}
                  multiline
                />
                <FullTextField
                  label="First sync of the day (seconds from midnight)"
                  dispatchUpdate={workerJobEditUpdateProperty}
                  propertyName='todFirstSeconds'
                  value={workerJob.todFirstSeconds}
                />
                <FullTextField
                  label="Frequency (seconds)"
                  dispatchUpdate={workerJobEditUpdateProperty}
                  propertyName='frequencySeconds'
                  value={workerJob.frequencySeconds}
                />
                <FullTextField
                  label="Min Resting Interval (seconds)"
                  dispatchUpdate={workerJobEditUpdateProperty}
                  propertyName='minGapSeconds'
                  value={workerJob.minGapSeconds}
                />
                <FancySelect
                  dispatchUpdate={workerJobEditUpdateProperty}
                  label='On Fail ...'
                  propertyName='onFailedAction'
                  currentVal={workerJob.onFailedAction}
                  valAtr='val'
                  items={onFailedActions}
                />
              </Grid>
            </Grid>
          :
          null
          }
          <CustomConfiguration jsbCustomConfiguration={workerJob.jsbCustomConfiguration} editUpdateProperty={workerJobEditUpdateProperty} />
          {workerJob.name === WORKER_JOB_NAME_ATTENDANCE_MONITOR &&
          <>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px 0', marginBottom: '5px' }}>
              <Button onClick={() => attendanceMonitorCoursesEdit()} color="primary">Select Courses</Button>
              <Typography style={{ marginLeft: '10px' }}>{`${enabledCoursesCount} of ${totalCoursesCount} courses selected`}</Typography>
              <CourseSelectionDialog />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '10px' }}>
              <Button onClick={() => attendanceMonitorLocationsEdit()} color="primary">Select Locations</Button>
              <Typography style={{ marginLeft: '10px' }}>{`${enabledLocationsCount} of ${totalLocationsCount} locations selected`}</Typography>
              <LocationSelectionDialog />
            </div>
          </>
        }
        </DialogContent>
        <DialogActions style={{marginTop:10}}>
          <Button onClick={workerJobEditDone} color="secondary">
            Cancel
          </Button>
          <Button onClick={()=>workerJobEditSave()} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  const workerJob = state.admin.dialogs.workerJob ? state.admin.dialogs.workerJob : null;
  const courses = state.admin.courses ? state.admin.courses : [];
  const locations = state.admin.allLocations ? state.admin.allLocations : [];
  return { workerJob, courses, locations };
};

const mapDispatchToProps = {
  workerJobEditDone,
  workerJobEditSave,
  workerJobEditUpdateProperty,
  attendanceMonitorCoursesEdit,
  coursesFetch,
  coursesFetchResponse,
  attendanceMonitorLocationsEdit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(WorkerJobDialog))