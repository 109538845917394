import React from "react"
import connect from "react-redux/es/connect/connect"
import {withStyles} from "@material-ui/core"
import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/es/Typography/Typography"
import SettingsIcon from '@material-ui/icons/Settings'
import FullTextField, {FullTextFieldD} from 'common/components/FullTextField'

import styles from '../style'

import {
  appSettingsSave,
  updateGeneralSetting,
  emailTest,
} from "redux/admin/Actions"
import {URL_ADMIN_ROOT} from "../../../../redux/constants";



class LicenseAndAuth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty:false
    }
    this.updateGeneralSetting = this.updateGeneralSetting.bind(this)
    this.updateGeneralSettingFromFancySelect = this.updateGeneralSettingFromFancySelect.bind(this)
  }

  isDirty(val='x') {
    if(val === 'x') {
      return this.state.isDirty
    }
    this.setState({isDirty:!!val})
  }
  //Added this to make easy use of FancySelect and clearly call out the difference between it and the other inputs.
  updateGeneralSettingFromFancySelect({propertyName, val}) {
    this.props.updateGeneralSetting({propertyName, val})
    if(!this.isDirty()) {
      this.isDirty(true)
    }
  }

  updateGeneralSetting(propertyName, val) {
    this.props.updateGeneralSetting({propertyName, val})
    if(!this.isDirty()) {
      this.isDirty(true)
    }
  }

  render() {
    const {
      classes,
      staLicense,
      skywardApiUrl,
      skywardClientId,
      skywardClientSecret,
      staLicenseEmail,
      updateGeneralSetting,
    } = this.props

    return (
      <div className={classes.root}>
        <Grid container >
          <Grid item className={classes.gridItem} xs={12}>
            <Typography variant="h6" >License & Authentications</Typography>
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={3}>
            <FullTextFieldD
              id="settings-license"
              label="SchoolTRAK License Email"
              propertyName='staLicense'
              value={staLicenseEmail}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
            />
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={3}>
            <FullTextFieldD
              id="settings-license"
              label="SchoolTRAK License Prefix"
              propertyName='staLicense'
              value={staLicense}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container className={classes.firstRow}>
          <Grid item className={classes.gridItem} xs={12}>
            <Typography variant="h6">Skyward Integration</Typography>
          </Grid>
          <Grid item className={classes.gridItem} xs={12}>
            <FullTextField
              id="settings-skywardAPI"
              label="URL for Skyward API"
              propertyName='skywardApiUrl'
              value={skywardApiUrl}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container >
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextField
              id="settings-skywardKey"
              label="Key for Skyward API"
              propertyName='skywardClientId'
              value={skywardClientId}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"/>
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextField
              id="settings-skywardSecret"
              type=""
              label="Secret for Skyward API"
              propertyName='skywardClientSecret'
              value={skywardClientSecret}
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state.admin.settings
}

const mapDispatchToProps = {
  updateGeneralSetting,
  emailTest,
  appSettingsSave,
}

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(LicenseAndAuth))

export const settingsPage = {
  icon: SettingsIcon,
  component: SettingsPage,
  name: 'LicenseAndAuth',
  to: `${URL_ADMIN_ROOT}/:facilityId?/settings`
}

export default SettingsPage