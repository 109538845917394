import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

import styles from 'common/components/style'


import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import {licenseSend, sendEntitySchoolYear, setEntityIsEnabled, settingsUpdateProperty, returnToDistrictSelection} from 'redux/install/Actions';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {findOneBy} from "@relativity/js-util";
import FancySelect from "common/components/FancySelect";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ToggleIconButton from "common/components/ToggleIconButton";
import {INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR} from "redux/install/constants";
import TextField from "@material-ui/core/TextField";


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class YearEntityChooserDialogClass extends React.Component {
  constructor(props) {
    super(props);
    this.setSearch = this.setSearch.bind(this)
    this.state = {
      controlsDisabled:false,
      tabIndex: 0,
      searchString:'',
      selectedSchoolYearId:'',
      enabledEntitiesCount:0,
    }
  }

  confirmThisStep(fn) {
    this.setState({
      controlsDisabled:true
    })
    fn()
  }

  addHooks() {
    //testing
    window.stHooks.addHook('yearEntity', (e)=> {
      const {entityIds=true} = e || {}
      let schoolYear = false
      this.getYearEntityStepData().schoolYears.forEach(year => {
        if(year.isVendorCurrent) {
          schoolYear = year
        }
      })
      if(schoolYear) {
        this.props.settingsUpdateProperty({propertyName:'licensingJson.schoolYearId', val:schoolYear.id})
      }
      this.getYearEntityStepData().entities.map( entity => {
        if(entityIds === true || entityIds.includes(entity.id)) {
          this.props.setEntityIsEnabled({id:entity.id, isEnabled:true})
        }
        return null
      })
    })
  }

  removeHooks() {
    window.stHooks.removeHook(['yearEntity'])
  }

  getYearEntityStepData() {
    return this.props['step' + INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR]
  }

  switchTab = (_, tabIndex) => {
    this.setState({ 
      tabIndex,
      searchString: tabIndex === 0 ? '' : this.state.searchString
     });
  }

  setSearch(event) {
    this.setState({
      searchString: event.target.value
    })
  }

  componentDidMount() {
    this.initializeSelections();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.browserCurrentStep !== this.props.browserCurrentStep && this.props.browserCurrentStep === INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR) {
      this.initializeSelections();
    }
  }

  initializeSelections() {
    const stepData = this.getYearEntityStepData();
    const enabledEntitiesCount = stepData && stepData.entities ? stepData.entities.filter(entity => entity.isEnabled).length : 0;
    const selectedSchoolYear =  stepData && stepData.schoolYears.find(s => s.isEnabled && s.id > 0);
    const selectedSchoolYearId = selectedSchoolYear ? selectedSchoolYear.id : 0;

    this.setState({
      selectedSchoolYearId: selectedSchoolYearId,
      enabledEntitiesCount,
      controlsDisabled: !(selectedSchoolYearId && enabledEntitiesCount > 0),
    });
  }

  handleSchoolYearChange = ({ propertyName, val }) => {
    this.setState({ selectedSchoolYearId: val }, this.validateSelections);
    this.props.settingsUpdateProperty({ propertyName, val });
  };

  handleEntityChange = (id, isEnabled) => {
    const { enabledEntitiesCount } = this.state;
    const newCount = isEnabled ? enabledEntitiesCount - 1 : enabledEntitiesCount + 1;
    this.setState({ enabledEntitiesCount: newCount }, this.validateSelections);
    this.props.setEntityIsEnabled({ id, isEnabled: !isEnabled });
  };

  validateSelections() {
    const { selectedSchoolYearId, enabledEntitiesCount } = this.state;
    const controlsDisabled = !(selectedSchoolYearId && enabledEntitiesCount > 0);
    this.setState({ controlsDisabled });
  }

  render() {
    if(!this.props.settings) return null
    const stepData = this.getYearEntityStepData()
    const {classes, close, sendEntitySchoolYear, browserCurrentStep, returnToDistrictSelection } = this.props
    const { tabIndex, selectedSchoolYearId } = this.state
    const onClose = (close)? close : ()=>{console.log('This should be an easter egg, but it\'s not')}
    if(browserCurrentStep !== INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR) {
      this.removeHooks()
      return null
    } else {
      this.addHooks()
    }

    let totalEnabledEntities = 0

    const entitySorted = stepData && stepData.entities ? [...stepData.entities] : [];
    entitySorted.sort((a, b) => {
      const aDisplayName = a.displayName.toLowerCase()
      const bDisplayName = b.displayName.toLowerCase()
      return bDisplayName < aDisplayName? 1 : bDisplayName > aDisplayName? -1 : 0
    })

    const entityList = entitySorted.reduce((acc, entity) => {
      if(entity.displayName.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1) {
        acc.push(<ListItem
          title={`id: ${entity.id}`}
          key={entity.id}
        >
          <ToggleIconButton
            isOn={entity.isEnabled}
            onClick={() => this.handleEntityChange(entity.id, entity.isEnabled)}
          />
            {entity.displayName}
        </ListItem>)
      }
      return acc
    }, [])

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title">
          Choose Year, Entities, Buildings
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot} style={{ minHeight: 500 }}>
          <Tabs value={tabIndex} onChange={this.switchTab}>
            <Tab label="School Year" />
            <Tab label="Entities" />
          </Tabs>
          {tabIndex === 0 && <TabContainer>
            <FancySelect
              dispatchUpdate={this.handleSchoolYearChange}
              label='School Year'
              propertyName='licensingJson.schoolYearId'
              currentItem={findOneBy(stepData && stepData.schoolYears, 'id', selectedSchoolYearId)}
              items={stepData ? stepData.schoolYears : []}
              nameAtr='displayName'
            />
          </TabContainer>}
          {tabIndex === 1 && <TabContainer>
            <Typography>Choose which Entities will be used by SchoolTRAK</Typography>
            <TextField
              style={{marginTop:'10px'}}
              label={`Entity Search`}
              variant="outlined"
              fullWidth
              onKeyUp={this.setSearch}
            />
            <List>
              {entityList}
            </List>
          </TabContainer>}
        </DialogContent>
        <DialogActions style={{justifyContent:"space-between"}}>
          <Button onClick={returnToDistrictSelection} color="secondary">
            Back
          </Button>
          <Button disabled={this.state.controlsDisabled} onClick={()=>this.confirmThisStep(sendEntitySchoolYear)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const YearEntityChooserDialog = connect(
  state => state.install,
  {
    licenseSend,
    sendEntitySchoolYear,
    setEntityIsEnabled,
    settingsUpdateProperty,
    returnToDistrictSelection
  }
)(withStyles(styles, { withTheme: true })(YearEntityChooserDialogClass))

export default YearEntityChooserDialog
