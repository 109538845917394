import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Tooltip from '@material-ui/core/Tooltip'

export default class ToggleIconButton extends React.Component {
  render() {
    const {
      dispatchUpdate,
      onIcon=CheckCircleIcon,
      offIcon=HighlightOffIcon,
      isOn=false,
      title,
      onTitle,
      offTitle,
      onColor="primary",
      offColor="secondary",
      propertyName
    } = this.props
    let {color} = this.props
    const props = Object.assign({}, this.props)
    delete props.offIcon
    delete props.onIcon
    delete props.isOn
    delete props.offTitle
    delete props.onTitle
    delete props.title
    delete props.propertyName
    delete props.dispatchUpdate

    const useTitle = (isOn)? (onTitle? onTitle : title) : (offTitle? offTitle : title)

    if(dispatchUpdate) {
      props.onClick = () => {
        dispatchUpdate({
          propertyName,
          val:!isOn
        })
      }
    }

    if(!color) {
      color = (isOn)? onColor : offColor
    }

    if(useTitle)
      return <Tooltip title={useTitle}><IconButton
        {...props}
      >{isOn? React.createElement(onIcon, {color}) : React.createElement(offIcon) }
      </IconButton></Tooltip>
    else
      return <IconButton
        {...props}
      >{isOn? React.createElement(onIcon, {color}) : React.createElement(offIcon) }
      </IconButton>
  }
}


ToggleIconButton.propTypes = {
  dispatchUpdate: PropTypes.func,
  propertyName: PropTypes.string,
  isOn: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  onTitle: PropTypes.string,
  offTitle: PropTypes.string,
  onIcon: PropTypes.any,
  offIcon: PropTypes.any,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
}