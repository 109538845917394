import React, { Component } from "react"
import Paper from "@material-ui/core/Paper"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"

class Dropzone extends Component {
  constructor(props) {
    super(props)

    this.state = { highlight: false }
    this.fileInputRef = React.createRef()
    this.openFileDialog = this.openFileDialog.bind(this)
    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  fileListToArray(list) {
    const array = []
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }

  onDragLeave() {
    this.setState({ highlight: false })
  }

  onDragOver(evt) {
    evt.preventDefault()

    if (this.props.disabled) return

    this.setState({ highlight: true })
  }

  onDrop(evt) {
    evt.preventDefault()

    if (this.props.disabled) return

    const files = evt.dataTransfer.files

    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
    this.setState({ highlight: false })
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return
    const files = evt.target.files
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
  }

  openFileDialog() {
    if (this.props.disabled) return
    this.fileInputRef.current.click()
  }

  render() {
    const backgroundColor = this.state.highlight? '#f3f9dc' : '#FEFFFE'
    const {
      height = 100,
      text = 'Drop files here.'
    } = this.props
    return <Paper
      onDragOver={this.onDragOver}
      onDragLeave={this.onDragLeave}
      onDrop={this.onDrop}

      style={{padding:10, display:'flex', justifyContent: 'center', alignItems:'center', height, background:backgroundColor}}
    >
      <IconButton onClick={this.openFileDialog}><CloudUploadIcon style={{}}/></IconButton> <Typography>{text}</Typography>
      <input
        ref={this.fileInputRef}
        style={{display:'none'}}
        type="file"
        multiple
        onChange={this.onFilesAdded}
      />
    </Paper>
  }
}

export default Dropzone