import React from 'react'
import connect from 'react-redux/es/connect/connect'
import { findOneBy } from '@relativity/js-util'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FancySelect from 'common/components/FancySelect'
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import KioskSettingListItem from "common/components/KioskSettingListItem"
import KioskFacilitiesCustomSetupDialog from "apps/admin/components/KioskFacilitiesCustomSetupDialog";
import {
  computeKioskCapabilities,
  getAtTypesByFacilityId,
  getAtReasonsByFacilityId,
  getFacilityOverrideSelectedValues
} from 'redux/utils'


import styles from './style'
import {
  closeKioskEditor,
  saveKioskSettings,
  saveLocationSettings,
  changeKioskSetting,
  deleteKiosk,
  kioskDefaultConfigurationPropSet,
  kioskDefaultConfigurationPropSetChangeable,
  kioskDefaultConfigurationEditSave,
  kioskDefaultConfigurationForcePush,
  kioskInstanceEdit,
  kioskFacilitiesCustomSetupWebEdit,
  kioskFacilitiesCustomSetupUpdateEditorProperty
} from 'redux/admin/Actions';

import {createSettings} from "common/kioskSettings"

import {
  ATR_ACCEPT_KEYBOARD_INPUT,
  ATR_ATTENDANCE_TYPE_ID,
  ATR_CAN_PRINT, ATR_CHECKIN_FLOW,
  ATR_ENABLE_EMAIL_SLIP,
  ATR_ENABLE_CAMERA,
  //ATR_ENABLE_GPS,
  ATR_ENABLE_KEYPAD,
  ATR_IS_ENABLED, ATR_IS_OUT_OF_BUILDING,
  ATR_MAX_LEASE_LENGTH,
  ATR_REQUEST_SEND_REPORT
} from "common/kioskConstants"

import {
  secondsIn,
  KIOSK_TYPE_WEB,
  KDCL_INSTANCE,
  KDCL_BUILDING,
  KDCL_LOCATION,
  KDCL_ROOT,
  kioskCheckinFlowModes,
  OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES,
  OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE,
  CUSTOMIZE_FACILITIES_TITLE
} from 'redux/constants'
import TableBody from "@material-ui/core/es/TableBody/TableBody";

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

/**
 * Currys a function to the current configurationId, locationId, and the dispatcher (defaultConfiguration, or kiosk specific configuration)
 * @param configurationId
 * @param locationId
 * @param changeDispatcher
 * @returns {Function}
 */
const contextualizeChangeSetting = (configurationId, changeDispatcher) => {
  return (settingName, settingValue) => {
    changeDispatcher(configurationId, settingName, settingValue)
  }
}


class KioskSettingsDialog extends React.Component {
  handleFacilitySetupChange = (facilityId) => ({ propertyName, val }) => {
    const payload = { facilityId, propertyName, val, kioskType: KIOSK_TYPE_WEB };
    this.props.kioskFacilitiesCustomSetupUpdateEditorProperty(payload);
  };

  render() {
    if(!this.props.kioskConfigurationEditor
      ||
      (this.props.level !== this.props.kioskConfigurationEditor.level)
    ) return null

    const {
      classes,
      deleteKiosk,
      kioskConfigurationEditor,
      closeKioskEditor,
      changeKioskSetting,
      kioskDefaultConfigurationPropSet,
      kioskDefaultConfigurationPropSetChangeable,
      saveKioskSettings,
      kioskDefaultConfigurationEditSave,
      kioskDefaultConfigurationForcePush,
      level,
      positiveAttendanceIsEnabled
    } = this.props

    const {
      atTypes,
      kioskDefaultConfiguration,
      kiosk,
      editorTitle,
      editorDescription
    } = kioskConfigurationEditor

    let forcePushTitle, onEdit, onEditChangeable, onSave

    if(level === KDCL_INSTANCE) {
      onEdit = contextualizeChangeSetting(kiosk.id, changeKioskSetting)
      onEditChangeable = contextualizeChangeSetting(kiosk.id, (id, name) => console.log(`Cannot update changeable for kiosk:${id}, setting:${name}`))
      onSave = contextualizeChangeSetting(kiosk.id, saveKioskSettings)
    } else {
      onEdit = contextualizeChangeSetting(kioskDefaultConfiguration.id, kioskDefaultConfigurationPropSet)
      onEditChangeable = contextualizeChangeSetting(kioskDefaultConfiguration.id, kioskDefaultConfigurationPropSetChangeable)
      onSave = kioskDefaultConfigurationEditSave
      let childLevel = ''
      let defaultsOrSettings = 'defaults'
      switch(level) {
        case KDCL_BUILDING :
          childLevel = 'locations'
          break
        case KDCL_LOCATION :
          childLevel = 'kiosks'
          defaultsOrSettings = 'settings'
          break
        case KDCL_ROOT :
          childLevel = 'buildings'
          break
        default :
      }

      forcePushTitle = `Push these ${defaultsOrSettings} to all child ${childLevel}`
    }

    const capabilities = computeKioskCapabilities(kioskDefaultConfiguration, kiosk, true)

    const settingInit = [
      {
        name:ATR_CAN_PRINT,
        isOn: capabilities[ATR_CAN_PRINT].val,
        changeable: capabilities[ATR_CAN_PRINT].changeable,
        clickOn:() => onEdit(ATR_CAN_PRINT, !capabilities[ATR_CAN_PRINT].val),
        clickOnChangeable: () => onEditChangeable(ATR_CAN_PRINT, !capabilities[ATR_CAN_PRINT].changeable),
      },
      {
        name:ATR_ENABLE_EMAIL_SLIP,
        isOn: capabilities[ATR_ENABLE_EMAIL_SLIP].val,
        changeable: capabilities[ATR_ENABLE_EMAIL_SLIP].changeable,
        clickOn:() => onEdit(ATR_ENABLE_EMAIL_SLIP, !capabilities[ATR_ENABLE_EMAIL_SLIP].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_EMAIL_SLIP, !capabilities[ATR_ENABLE_EMAIL_SLIP].changeable),
      },
      {
        name:ATR_ENABLE_KEYPAD,
        isOn: capabilities[ATR_ENABLE_KEYPAD].val,
        changeable: capabilities[ATR_ENABLE_KEYPAD].changeable,
        clickOn:() => onEdit(ATR_ENABLE_KEYPAD, !capabilities[ATR_ENABLE_KEYPAD].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_KEYPAD, !capabilities[ATR_ENABLE_KEYPAD].changeable),
      },
      {
        name: ATR_ACCEPT_KEYBOARD_INPUT,
        isOn: capabilities[ATR_ACCEPT_KEYBOARD_INPUT].val,
        changeable: capabilities[ATR_ACCEPT_KEYBOARD_INPUT].changeable,
        clickOn: () => onEdit(ATR_ACCEPT_KEYBOARD_INPUT, !capabilities[ATR_ACCEPT_KEYBOARD_INPUT].val),
        clickOnChangeable: () => onEditChangeable(ATR_ACCEPT_KEYBOARD_INPUT, !capabilities[ATR_ACCEPT_KEYBOARD_INPUT].changeable),

      },
      {
        name:ATR_ENABLE_CAMERA,
        isOn: capabilities[ATR_ENABLE_CAMERA].val,
        changeable: capabilities[ATR_ENABLE_CAMERA].changeable,
        clickOn:() => onEdit(ATR_ENABLE_CAMERA, !capabilities[ATR_ENABLE_CAMERA].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_CAMERA, !capabilities[ATR_ENABLE_CAMERA].changeable),
      },
      /*{
        name:ATR_ENABLE_GPS,
        isOn: capabilities[ATR_ENABLE_GPS].val,
        changeable: capabilities[ATR_ENABLE_GPS].changeable,
        clickOn:() => onEdit(ATR_ENABLE_GPS, !capabilities[ATR_ENABLE_GPS].val),
        clickOnChangeable: () => onEditChangeable(ATR_ENABLE_GPS, !capabilities[ATR_ENABLE_GPS].changeable),
      },*/
      {
        name:ATR_REQUEST_SEND_REPORT,
        isOn: capabilities[ATR_REQUEST_SEND_REPORT].val,
        changeable:capabilities[ATR_REQUEST_SEND_REPORT].changeable,
        clickOn: () => onEdit(ATR_REQUEST_SEND_REPORT, !capabilities[ATR_REQUEST_SEND_REPORT].val),
        clickOnChangeable: () => onEditChangeable(ATR_REQUEST_SEND_REPORT, !capabilities[ATR_REQUEST_SEND_REPORT].changeable),
      },
      {
        name:ATR_ATTENDANCE_TYPE_ID,
        changeable: capabilities[ATR_ATTENDANCE_TYPE_ID].changeable,
        val:capabilities[ATR_ATTENDANCE_TYPE_ID].val,
        possibleValues:atTypes,
        clickOn: (val) => onEdit(ATR_ATTENDANCE_TYPE_ID, val),
        clickOnChangeable: () => onEditChangeable(ATR_ATTENDANCE_TYPE_ID, !capabilities[ATR_ATTENDANCE_TYPE_ID].changeable),
        textProvider: findOneBy(atTypes, 'id', capabilities[ATR_ATTENDANCE_TYPE_ID].val)
      },
      {
        name: ATR_IS_OUT_OF_BUILDING,
        isOn: capabilities[ATR_IS_OUT_OF_BUILDING].val,
        changeable: capabilities[ATR_IS_OUT_OF_BUILDING].changeable,
        clickOn:() => onEdit(ATR_IS_OUT_OF_BUILDING, !capabilities[ATR_IS_OUT_OF_BUILDING].val),
        clickOnChangeable: () => onEditChangeable(ATR_IS_OUT_OF_BUILDING, !capabilities[ATR_IS_OUT_OF_BUILDING].changeable),
      },
      {
        name: ATR_CHECKIN_FLOW,
        changeable: capabilities[ATR_CHECKIN_FLOW].changeable,
        val:capabilities[ATR_CHECKIN_FLOW].val,
        possibleValues: kioskCheckinFlowModes,
        clickOn: (val) => onEdit(ATR_CHECKIN_FLOW, val),
        clickOnChangeable: () => onEditChangeable(ATR_CHECKIN_FLOW, !capabilities[ATR_CHECKIN_FLOW].changeable),
        textProvider: findOneBy(kioskCheckinFlowModes, 'id', capabilities[ATR_CHECKIN_FLOW].val)
      }
    ]

    if(kiosk) {
      settingInit.unshift({
        name:ATR_IS_ENABLED,
        isOn: capabilities[ATR_IS_ENABLED].val,
        changeable: true,
        clickOn:() => onEdit(ATR_IS_ENABLED, !capabilities.isEnabled.val),
      })
    } else {
      settingInit.push({
        name:ATR_MAX_LEASE_LENGTH,
        changeable: false,
        val: kioskDefaultConfiguration[ATR_MAX_LEASE_LENGTH],
        possibleValues: secondsIn,
        clickOn: (val) => onEdit(ATR_MAX_LEASE_LENGTH, val),
      })
    }


    const settingConfiguration = createSettings(settingInit)
    return (
      <Dialog
        onClose={closeKioskEditor}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeKioskEditor}>
          {editorTitle}
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Typography>{editorDescription}</Typography>
          <Table style={{width:'100%'}}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">{(!!kiosk)? 'Enabled' : 'Enabled by Default'}</TableCell>
                <TableCell component="th"></TableCell>
                <TableCell padding="checkbox" align="right" style={{width:'20%'}}>Changeable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { settingConfiguration.map((item, index) => {
                return <KioskSettingListItem key={index} {...item} adminContext />
              })
              }
              {kiosk && kiosk.isDeletable? <TableRow>
                <TableCell style={{borderBottom:'none'}} padding="checkbox" onClick={() => {
                  if(window.confirm('Delete this kiosk forever?')) {
                    deleteKiosk(kiosk.id)
                  }}}><IconButton><DeleteForeverIcon/></IconButton></TableCell>
                <TableCell style={{borderBottom:'none'}}>Delete Kiosk</TableCell>
                <TableCell style={{borderBottom:'none'}} padding="checkbox" ></TableCell>
              </TableRow>
                :
                null
              }
            </TableBody>
          </Table>
         
          {level === KDCL_INSTANCE &&          
          <Grid>
            <Grid style={{marginTop:'15px'}}>
              {kiosk.customAttendanceConfiguration && kiosk.customAttendanceConfiguration.facilitiesOverrides &&
                kiosk.customAttendanceConfiguration.facilitiesOverrides.map(facilityOverride => {   
                  const atTypesByFacilityId = getAtTypesByFacilityId(this.props.allAtTypes, this.props.facilities);
                  const atReasonsByFacilityId = getAtReasonsByFacilityId(this.props.allAtReasons, this.props.facilities);

                  const {facility, overrideTypeName, singleAttendanceItem, tardyItem, absentItem, atReasonItem} = getFacilityOverrideSelectedValues(
                    facilityOverride, this.props.allAtTypes, this.props.allAtReasons, this.props.facilities);
                  return (
                    <ListItem key={facilityOverride.facilityId} style={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
                      <div>
                        <span className={classes.facilityLabel}>{facility.name}</span>
                        <span className={classes.overrideLabel}>{overrideTypeName}</span>
                      </div>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE && (
                            <FancySelect
                              dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                              label='Single Attendance Type'
                              propertyName='atTypeSingleId'
                              currentItem={singleAttendanceItem}
                              items={atTypesByFacilityId[facilityOverride.facilityId]}
                              valAtr="id"
                              nameAttr='displayName'
                            />
                          )}
                          {facilityOverride.overrideTypeId === OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES && (
                            <>
                              <Grid container >
                                <Grid item xs={6} style={{ paddingRight: '10px' }}>
                                  <FancySelect
                                    dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                                    label='Tardy Type'
                                    propertyName='atTypeTardyId'
                                    currentItem={tardyItem}
                                    items={atTypesByFacilityId[facilityOverride.facilityId]}
                                    valAtr="id"
                                    nameAttr='displayName'
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FancySelect
                                    dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                                    label='Absent Type'
                                    propertyName='atTypeAbsentId'
                                    currentItem={absentItem}
                                    items={atTypesByFacilityId[facilityOverride.facilityId]}
                                    valAtr="id"
                                    nameAttr='displayName'
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <FancySelect
                          dispatchUpdate={this.handleFacilitySetupChange(facilityOverride.facilityId)}
                          label='Reason'
                          propertyName='atReasonId'
                          currentItem={atReasonItem}
                          items={atReasonsByFacilityId[facilityOverride.facilityId]}
                          valAtr="id"
                          nameAttr='displayName'
                        />
                      </Grid>
                    </ListItem>
                  );
                })}
            </Grid>
          </Grid>
          }
 
        </DialogContent>
        <DialogActions>
          {(level !== KDCL_INSTANCE && !kioskDefaultConfiguration.isDirty)?
            <Button title={forcePushTitle} onClick={()=>kioskDefaultConfigurationForcePush(kioskDefaultConfiguration.id)} color="secondary">
              Force Push
            </Button>
          :
          null}
          {level === KDCL_INSTANCE && positiveAttendanceIsEnabled &&
            <Grid>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={() => this.props.kioskFacilitiesCustomSetupWebEdit()} color="primary">{CUSTOMIZE_FACILITIES_TITLE}</Button>
                <KioskFacilitiesCustomSetupDialog />
              </div>
            </Grid>
          }     
          <div style={{flexGrow:8}}/>
          <Button onClick={closeKioskEditor} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  const settingsState = state.admin.dialogs;
  settingsState.admin = state.admin;
  settingsState.allAtTypes = state.admin.atTypes;
  settingsState.allAtReasons = state.admin.atReasons;
  settingsState.facilities = state.admin.facilities;
  settingsState.positiveAttendanceIsEnabled = state.admin.positiveAttendanceIsEnabled
  return settingsState;
}

const mapDispatchToProps = {
  closeKioskEditor,
  changeKioskSetting,
  kioskDefaultConfigurationPropSet,
  kioskDefaultConfigurationPropSetChangeable,
  kioskDefaultConfigurationEditSave,
  kioskDefaultConfigurationForcePush,
  saveKioskSettings,
  saveLocationSettings,
  deleteKiosk,
  kioskInstanceEdit,
  kioskFacilitiesCustomSetupWebEdit,
  kioskFacilitiesCustomSetupUpdateEditorProperty
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(KioskSettingsDialog))