import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../style';

const AccountField = ({ fieldName, fieldValues, classes }) => {
  return (
    <div className={classes.accountListField}>
        <div className={classes.fieldName}>
            <strong>{fieldName}:</strong>
        </div>
        <div>
        <ul style={{marginTop: '0', paddingLeft: '34px'}}>
            {fieldValues.map(f => (
                <li key={f.id} className={classes.listFieldValue}>{f.displayName}</li>
            ))}
        </ul>  
        </div>
    </div>
  );
};

AccountField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValues: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AccountField);