/**
 * THIS FILE PROVIDES THE BASE CAPABILITY STRUCTURE FOR KIOSKS
 */
import VideocamIcon from '@material-ui/icons/Videocam'
import DialpadIcon from '@material-ui/icons/Dialpad'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import GpsFixedIcon from "@material-ui/icons/GpsFixed"
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import FastForwardIcon from '@material-ui/icons/FastForward'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import Print from '@material-ui/icons/Print'
import Email from '@material-ui/icons/Email'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import RoomIcon from '@material-ui/icons/Room'
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory'
import TimerIcon from '@material-ui/icons/Timer'
import Whatshot from '@material-ui/icons/Whatshot'
import Wifi from '@material-ui/icons/Wifi'

import {seconds} from '@relativity/js-util'

import {
  ATR_ACCEPT_KEYBOARD_INPUT,
  ATR_ATTENDANCE_TYPE_ID,
  ATR_CAN_PRINT, ATR_CHECKIN_FLOW,
  ATR_ENABLE_EMAIL_SLIP,
  ATR_ENABLE_CAMERA,
  ATR_ENABLE_GPS,
  ATR_ENABLE_KEYPAD,
  ATR_IS_ENABLED,
  ATR_IS_LOCKED,
  ATR_LEASE_EXPIRES,
  ATR_REQUEST_SEND_REPORT
} from './kioskConstants'

import moment from 'moment'
import {ATR_IS_OUT_OF_BUILDING} from "common/kioskConstants";
import {ATR_MAX_LEASE_LENGTH} from "common/kioskConstants";

/**
 * Not intended for direct use. Call `createSettings`, exported from this file.
 * Provides UI construction guidance and default values for (web)Kiosk settings
 */
const webKioskSettingsConfigTemplate = [
  {
    name: ATR_IS_LOCKED,
    displayName:'Locked',
    iconOn: Lock,
    iconOff: LockOpen,
    titleOn: 'Settings Locked. Click to make changes',
    titleOff:'Settings Unlocked. Make changes and then re-lock to enable kiosk',
    textOn:'Locked',
    textOff:'Unlocked',
  },/*
  {
    name: ATR_LOCATION_ID,
    displayName:'Location',
    iconOn: WhereToVoteIcon,
    iconOff: ReportProblemIcon,
    titleOn: 'Change location',
    titleOff:'Location has not been set',
    titleDisabled:'Unlock to set location',
    textOn:'Location Set',
    textOff:'Location not Set',
  }, */
  {
    name: ATR_LEASE_EXPIRES,
    displayName:'Lease Renewal',
    iconOn: HourglassFullIcon,
    iconOff: ReportProblemIcon,
    titleOn: 'Click to renew lease',
    titleOff:'Lease expired. Click to renew',
    titleDisabled:'When this kiosk will stop working unless renewed',
    textOn:(leaseExpires)=>{
      if(leaseExpires === 0) {
        return 'Lease never expires'
      }
      const now = seconds()
      const expires = moment.unix(leaseExpires)
      const secondsRemaining = leaseExpires - now
      if(secondsRemaining > 0) {
        if(secondsRemaining > 604800) {
          return 'Good until ' + expires.format('MMM Do')
        }
        if(secondsRemaining > 86400) {
          return 'Renew by ' + expires.format('dddd')
        }
        return 'Renew by ' + expires.format('h:mm A')
      }
    },
    textOff:'Lease has expired',
  },
  {
    name:ATR_CAN_PRINT,
    displayName:'Printing',
    menuItemType: 'binary',
    iconOn: Print,
    iconOff: HighlightOffIcon,
    titleOn: 'Printing is enabled',
    titleOff:'Printing is disabled',
    titleDisabled: 'Unlock to toggle Printing',
    textOn:'Printing Enabled',
    textOff:'Printing Disabled',
  },
  {
    name:ATR_ENABLE_EMAIL_SLIP,
    displayName:'EmailSlip',
    menuItemType: 'binary',
    iconOn: Email,
    iconOff: HighlightOffIcon,
    titleOn: 'Email slip is enabled',
    titleOff:'Email slip is disabled',
    titleDisabled: 'Unlock to toggle emailing slip',
    textOn:'Email Slip On',
    textOff:'Email Slip Off',
  },
  {
    name:ATR_ENABLE_KEYPAD,
    displayName:'Keypad Input',
    menuItemType: 'binary',
    iconOn: DialpadIcon,
    iconOff: HighlightOffIcon,
    titleOn: 'Screen Keypad On',
    titleOff:'Screen Keypad Off',
    titleDisabled: 'Unlock to toggle Screen Keypad',
    textOn:'Screen Keypad On',
    textOff:'Screen Keypad Off',
  },
  {
    name:ATR_ACCEPT_KEYBOARD_INPUT,
    displayName:'Keyboard Input',
    iconOn: KeyboardIcon,
    iconOff: HighlightOffIcon,
    titleOn: 'Hardware Input On',
    titleOff:'Hardware Input Off',
    titleDisabled: 'Unlock to toggle Hardware Input',
    textOn:'Hardware Input On',
    textOff:'Hardware Input Off',
  },
  {
    name:ATR_ATTENDANCE_TYPE_ID,
    menuItemType: 'multi',
    iconOn: Whatshot,
    iconOff: Whatshot,
    titleOn: atType => `Attendance ${atType.displayName}`,
    titleOff: atType => `Attendance ${atType.displayName}`,
    titleDisabled: 'Unlock to change Attendance Type',
    textOn: atType => `Attendance ${atType.displayName}`,
    textOff: atType => `Attendance ${atType.displayName}`,
    textProvider:false
  },
  {
    name:ATR_CHECKIN_FLOW,
    menuItemType: 'multi',
    iconOn: FastForwardIcon,
    iconOff: FastForwardIcon,
    titleDisabled: 'Unlock to change checkin flow',
    textOn: item => `${item.displayName}`,
    textOff: item => `${item.displayName}`,
    textProvider: item => `Checkin flow - ${item.displayName}`
  },
  {
    name:ATR_ENABLE_CAMERA,
    iconOn: VideocamIcon,
    iconOff: HighlightOffIcon,
    titleOn: 'Camera On',
    titleOff:'Camera Off',
    titleDisabled: 'Unlock to toggle Camera',
    textOn:'Camera On',
    textOff:'Camera Off'
  },
  {
    name:ATR_ENABLE_GPS,
    displayName:'GPS',
    iconOn: GpsFixedIcon,
    iconOff: HighlightOffIcon,
    titleOn: 'GPS On',
    titleOff:'GPS Off',
    titleDisabled: 'Unlock to toggle GPS',
    textOn:'GPS On',
    textOff:'GPS Off'
  },
  {
    name:ATR_MAX_LEASE_LENGTH,
    menuItemType: 'multi',
    iconOn: TimerIcon,
    iconOff: TimerIcon,
    titleDisabled: 'Lease Length has been disabled',
  },
  {
    name:ATR_IS_ENABLED,
    iconOn: Wifi,
    iconOff: HighlightOffIcon,
    titleOn: 'Kiosk is enabled',
    titleOff:'Kiosk is disabled',
    titleDisabled: 'Unlock to enable/disable kiosk',
    textOn: 'Kiosk is enabled',
    textOff: 'Kiosk is disabled',
  },
  {
    name: ATR_IS_OUT_OF_BUILDING,
    menuItemType: 'binary',
    menuItemAction: 'onClick',
    iconOn: StoreMallDirectoryIcon,
    iconOff: RoomIcon,
    titleOn: 'Out leaves building',
    titleOff:'Out leaves location',
    titleDisabled: 'Unlock to change',
    textOn: 'Leave building',
    textOff: 'Leave location',
  },
  {
    name:ATR_REQUEST_SEND_REPORT,
    iconOn: FlashOnIcon,
    iconOff: HighlightOffIcon,
    titleOn: 'Request documents enabled',
    titleOff:'Request documents disabled',
    titleDisabled: 'Unlock to enable/disable request documents',
    textOn: 'Request documents on',
    textOff: 'Request documents off',
  }
].reduce((acc, item) => {
  const templateItem = {
    clickOn:() => console.log('No clickOn for ', item.name),
    menuItemType:'binary',
    isDisabled: false,
    isOn: true,
    changeable: true,
    displayName:'',
    textDisabled:'',
    textOn: '',
    textOff: '',
    textProvider:false,
    titleDisabled:'',
    titleOn: '',
    titleOff: '',
      ...item
  }
  acc[templateItem.name] = templateItem
  return acc
}, {})

/**
 * Takes a 'context', list of properties and returns a list of the same properties, extended by items of the same name in the webKioskSettingsConfigTemplate
 * @param context
 */
export const createSettings = (context=[]) => {
  return context.reduce((acc, item) => {
    if(webKioskSettingsConfigTemplate.hasOwnProperty(item.name)) {
      acc.push( {...webKioskSettingsConfigTemplate[item.name], ...item})
    }
    return acc
  }, [])
}

const passKioskSettingsConfigTemplate = [
  {
    name:ATR_MAX_LEASE_LENGTH,
    menuItemType: 'multi',
    iconOn: TimerIcon,
    iconOff: TimerIcon,
    titleDisabled: 'Lease Length has been disabled',
  },
  {
    name: ATR_LEASE_EXPIRES,
    displayName:'Lease Renewal',
    iconOn: HourglassFullIcon,
    iconOff: ReportProblemIcon,
    titleOn: 'Click to renew lease',
    titleOff:'Lease expired. Click to renew',
    titleDisabled:'When this kiosk will stop working unless renewed',
    textOn:(leaseExpires)=>{
      if(leaseExpires === 0) {
        return 'Lease never expires'
      }
      const now = seconds()
      const expires = moment.unix(leaseExpires)
      const secondsRemaining = leaseExpires - now
      if(secondsRemaining > 0) {
        if(secondsRemaining > 604800) {
          return 'Good until ' + expires.format('MMM Do')
        }
        if(secondsRemaining > 86400) {
          return 'Renew by ' + expires.format('dddd')
        }
        return 'Renew by ' + expires.format('h:mm A')
      }
    },
    textOff:'Lease has expired',
  },
  {
    name:ATR_ATTENDANCE_TYPE_ID,
    menuItemType: 'multi',
    iconOn: Whatshot,
    iconOff: Whatshot,
    titleOn: atType => `Attendance ${atType.displayName}`,
    titleOff: atType => `Attendance ${atType.displayName}`,
    textOn: atType => `Attendance ${atType.displayName}`,
    textOff: atType => `Attendance ${atType.displayName}`,
    textProvider:false
  },
  {
    name: ATR_REQUEST_SEND_REPORT,
    iconOn: FlashOnIcon,
    iconOff: HighlightOffIcon,
    titleOn: 'Request documents enabled',
    titleOff:'Request documents disabled',
    titleDisabled: 'Unlock to enable/disable request documents',
    textOn: 'Request documents on',
    textOff: 'Request documents off',
  },
  {
    name: ATR_IS_ENABLED,
    iconOn: Wifi,
    iconOff: HighlightOffIcon,
    titleOn: 'Kiosk is enabled',
    titleOff:'Kiosk is disabled',
    titleDisabled: 'Unlock to enable/disable kiosk',
    textOn: 'Kiosk is enabled',
    textOff: 'Kiosk is disabled',
  },
  {
    name: ATR_IS_OUT_OF_BUILDING,
    menuItemType: 'binary',
    menuItemAction: 'onClick',
    iconOn: StoreMallDirectoryIcon,
    iconOff: RoomIcon,
    titleOn: 'Out leaves building',
    titleOff:'Out leaves location',
    titleDisabled: 'Unlock to change',
    textOn: 'Leave building',
    textOff: 'Leave location',
  },
  {
    name:ATR_ENABLE_EMAIL_SLIP,
    displayName:'EmailSlip',
    menuItemType: 'binary',
    iconOn: Email,
    iconOff: HighlightOffIcon,
    titleOn: 'Email slip is enabled',
    titleOff:'Email slip is disabled',
    titleDisabled: 'Unlock to toggle emailing slip',
    textOn:'Email Slip On',
    textOff:'Email Slip Off',
  },
].reduce((acc, item) => {
  const templateItem = {
    clickOn:() => console.log('No clickOn for ', item.name),
    menuItemType:'binary',
    isDisabled: false,
    isOn: true,
    changeable: false,
    displayName:'',
    textDisabled:'',
    textOn: '',
    textOff: '',
    textProvider:false,
    titleDisabled:'',
    titleOn: '',
    titleOff: '',
    ...item
  }
  acc[templateItem.name] = templateItem
  return acc
}, {})

/**
 * Takes a 'context', list of properties and returns a list of the same properties, extended by items of the same name in the passKioskSettingsConfigTemplate
 * @param context
 */
export const createPPKSettings = (context=[]) => {
  return context.reduce((acc, item) => {
    if(passKioskSettingsConfigTemplate.hasOwnProperty(item.name)) {
      acc.push( {...passKioskSettingsConfigTemplate[item.name], ...item})
    }
    return acc
  }, [])
}

