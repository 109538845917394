import React from 'react'

export function useStateWithDep(defaultValue) {
  const [value, setValue] = React.useState(defaultValue)

  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return [value, setValue]
}
