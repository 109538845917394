import React from "react";
import {ID_ZERO} from "redux/constants";

export class BuildingLocationName extends React.Component {
  render() {
    const {byId, locationId, separator, reverse, nowrap=false} = this.props

    const displayText = []
    const location = byId.location(locationId)
    if(location) displayText.push(location.displayName)
    const building = location? byId.building(location.buildingId) : false
    if(building && building.id !== ID_ZERO) displayText.push(building.displayName)
    if(reverse) displayText.reverse()
    if(nowrap) return <span style={{whiteSpace:'nowrap'}}>{displayText.join(separator || ' - ')}</span>
    return <span>{displayText.join(separator || ' - ')}</span>
  }
}

export default BuildingLocationName