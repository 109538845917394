import {
  ACTIVITY_ACCEPTING_ID_CODE,
  ACTIVITY_PREVIOUS_ACTIVITY,
  ACTIVITY_CHOOSING_ATTENDANCE_TYPE
} from './constants'

export const SET_MENU_DRAWER_OPEN = 'SET_MENU_DRAWER_OPEN'


export const SET_CONFIGURATION_LOCK = 'SET_CONFIGURATION_LOCK'

export const NO_OP = 'NO_OP'
export const SET_ACTIVITY = 'SET_ACTIVITY'
export const SET_LOCATION = 'SET_LOCATION'
export const CLOSE_LOCATION_CHOOSER = 'CLOSE_LOCATION_CHOOSER'
export const UPDATE_LOCATION_CHOOSER_LOCATION = 'UPDATE_LOCATION_CHOOSER_LOCATION'
export const KIOSK_UPDATE_LOCATION_CHOOSER_FACILITY = 'KIOSK_UPDATE_LOCATION_CHOOSER_FACILITY'
export const TOGGLE_KIOSK_DISABLED = 'TOGGLE_KIOSK_DISABLED'


export const TOGGLE_DEMO_MODE = 'TOGGLE_DEMO_MODE'






export const KIOSK_DELETE_ADMIN_STATE = 'KIOSK_DELETE_ADMIN_STATE'




//export const KIOSK_IS_READY = 'KIOSK_IS_READY' //Is the kiosk ready to receive user input? (of any kind)

export const ADMIN_BOOT = 'ADMIN_BOOT'
export const ADMIN_EXPAND_MAIN_NAV = 'ADMIN_EXPAND_MAIN_NAV'
export const ADMIN_COLLAPSE_MAIN_NAV = 'ADMIN_COLLAPSE_MAIN_NAV'
export const ADMIN_DELETE_KIOSK_STATE = 'ADMIN_DELETE_KIOSK_STATE'

export const ADMIN_IS_READY = 'ADMIN_IS_READY'
export const ADMIN_LOGIN = 'ADMIN_LOGIN'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'
export const ADMIN_SET_CURRENT_FACILITY_ID = 'ADMIN_SET_CURRENT_FACILITY_ID'
export const ADMIN_SET_CURRENT_PATH = 'ADMIN_SET_CURRENT_PATH'
export const ADMIN_SET_CONFIGURATION = 'ADMIN_SET_CONFIGURATION'
export const ADMIN_EDIT_KIOSK_INSTANCE_SETTINGS = 'ADMIN_EDIT_KIOSK_INSTANCE_SETTINGS'




const action = (type, payload) => {
  return {type, payload}
}

export const closeMenuDrawer = () => {
  return action(SET_MENU_DRAWER_OPEN, false)
}

export const openMenuDrawer = () => {
  return action(SET_MENU_DRAWER_OPEN, true)
}

export const lockConfiguration = () => {
  return action(SET_CONFIGURATION_LOCK, true)
}
export const unlockConfiguration = () => {
  return action(SET_CONFIGURATION_LOCK, false)
}

export const setActivityPreviousActivity = () => {
  return action(SET_ACTIVITY, ACTIVITY_PREVIOUS_ACTIVITY)
}

export const updateLocationChooserLocation = (location) => {
  return action(UPDATE_LOCATION_CHOOSER_LOCATION, location)
}

export const kioskUpdateLocationChooserFacility = (facility) => {
  return action(KIOSK_UPDATE_LOCATION_CHOOSER_FACILITY, facility)
}

export const setLocationFromChooser = () => {
  return action(SET_LOCATION)
}

export const closeLocationChooser = () => {
  return action(CLOSE_LOCATION_CHOOSER)
}

export const adminBoot = () => {
  return action (ADMIN_BOOT)
}

export const adminExpandMainNav = () => {
  return action (ADMIN_EXPAND_MAIN_NAV)
}

export const adminCollapseMainNav = () => {
  return action (ADMIN_COLLAPSE_MAIN_NAV)
}

export const adminLogin = () => {
  return action (ADMIN_LOGIN)
}

export const adminLogout = () => {
  return action (ADMIN_LOGOUT)
}

export const adminSetCurrentPath = (path) => {
  return action (ADMIN_SET_CURRENT_PATH, path)
}

export const adminEditKioskInstanceSettings = (kioskId) => {
  return action (ADMIN_EDIT_KIOSK_INSTANCE_SETTINGS, kioskId)
}

export const setActivity = (activity = ACTIVITY_ACCEPTING_ID_CODE) => action(SET_ACTIVITY, activity)

export const adminIsReady = () => {
  return action(ADMIN_IS_READY)
}

export const adminSetConfiguration = (payload) => {
  return action(ADMIN_SET_CONFIGURATION, payload)
}

export const LOGIN_WITH_SKYWARD = 'LOGIN_WITH_SKYWARD'
export const loginWithSkyward = ({redirectUrl, parameters}) => {
  return action (LOGIN_WITH_SKYWARD, {redirectUrl, parameters})
}

export const toggleDemoMode = () => action(TOGGLE_DEMO_MODE)
export default  {
  SET_MENU_DRAWER_OPEN,
  SET_CONFIGURATION_LOCK,
  NO_OP,
  SET_ACTIVITY,
  UPDATE_LOCATION_CHOOSER_LOCATION,
  SET_LOCATION,
  CLOSE_LOCATION_CHOOSER,
  TOGGLE_KIOSK_DISABLED,
  TOGGLE_DEMO_MODE,
  ADMIN_BOOT,
  ADMIN_COLLAPSE_MAIN_NAV,
  ADMIN_EXPAND_MAIN_NAV,
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  ADMIN_SET_CURRENT_FACILITY_ID,
  ADMIN_SET_CURRENT_PATH,
  ADMIN_EDIT_KIOSK_INSTANCE_SETTINGS,
  ADMIN_DELETE_KIOSK_STATE,
  ADMIN_SET_CONFIGURATION,
  ADMIN_IS_READY,
  KIOSK_UPDATE_LOCATION_CHOOSER_FACILITY,
  ACTIVITY_CHOOSING_ATTENDANCE_TYPE,
  KIOSK_DELETE_ADMIN_STATE,
}
