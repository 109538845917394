const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  firstRow: {
    marginTop:'20px'
  },
  gridItem: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  textFieldNoWidth: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  menu: {
    width: 200,
  },
  studentAttendanceDateRow: {
    borderTop:'2px solid #808080',
    borderLeft:'2px solid #808080',
    borderRight:'2px solid #808080',
    backgroundColor: theme.palette.background.paper,
  },
  studentDetailPageReportControl: {
    position: 'absolute',
    display: 'none',
    top: 10,
    left: -45,
    width: 400,
    padding: 25,
    zIndex: 1200
  },
  legendParagraph: {
    marginTop:'5px',
    marginBottom:'5px'
  },
  paper: {
    padding:'10px'
  },
  tableRow: {
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#ebebeb',
    },
  },
  studentTableLastCell: {
    [theme.breakpoints.down('sm')]: {
      width: '10%', 
    },
    [theme.breakpoints.up('sm')]: {
      width: '10%', 
    },
    [theme.breakpoints.up('md')]: {
      width: '20%', 
    },
    [theme.breakpoints.up('lg')]: {
      width: '40%', 
    },
  },
  locationTableLastCell: {
    [theme.breakpoints.down('sm')]: {
      width: '10%', 
    },
    [theme.breakpoints.up('sm')]: {
      width: '10%', 
    },
    [theme.breakpoints.up('md')]: {
      width: '30%', 
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%', 
    },
  },
})

export default styles