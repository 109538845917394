import {withStyles} from "@material-ui/core";
import styles from "common/components/style";
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ToggleIconButton from "common/components/ToggleIconButton";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";


export class ItemListClass extends React.Component {
  render() {
    const {items, onClick=()=>{}, valAtr='isEnabled', displayAtr='displayName'} = this.props

    const display = (typeof displayAtr === 'function')? displayAtr : (item)=>item[displayAtr]
    return (
      <List>
        {items.map((item) => {
          return <ListItem key={item.id}><ToggleIconButton isOn={!!item[valAtr]} onClick={()=>onClick(item)} />{display(item)}</ListItem>
        })}
      </List>
    );
  }
}

ItemListClass.propTypes = {
  items: PropTypes.array.isRequired,
  valAtr: PropTypes.string,
  onClick: PropTypes.func,
  displayAtr: PropTypes.any,
}

export const ItemList = withStyles(styles, { withTheme: true })(ItemListClass)

export default ItemList