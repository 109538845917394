import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DropDownSearchList from 'common/components/DropDownSearchList'
import TextField from '@material-ui/core/TextField'
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button'

import {
  searchLocatorStudents,
  selectLocatorStudent
} from 'redux/admin/Actions'

import styles from './style'

function studentToSelectOption(student){
  return (
    <Grid container>
      <Grid item xs={6}>
        {student.fullName}
      </Grid>
      <Grid item xs={6} style={{textAlign: 'end'}}>
        {student.attendancePasscode}
      </Grid>
    </Grid>
  )
}
class StudentPicker extends React.Component {
  refreshStudent = () => {
    const { selectedStudent, selectLocatorStudent } = this.props;
    
    if (selectedStudent && selectedStudent.id) {
      selectLocatorStudent(selectedStudent);
    }  
  }

  componentDidMount(){
    this.refreshStudent()
  }

  render() {
    const {
      searchString,
      matchingStudents,
      searchLocatorStudents,
      selectLocatorStudent,
      statusMessage
    } = this.props
    
    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={3}>
            <DropDownSearchList 
              searchString={searchString} 
              onInputChange={searchLocatorStudents}
              onSelectChange={selectLocatorStudent}
              placeholder={'Enter Student Name or SIS ID'}
              items={matchingStudents}
              itemToSelectOption={studentToSelectOption}
              itemKey={(i)=> i.id}
              statusMessage={statusMessage}
            />             
          </Grid> 
          <Grid item xs={3}>
            <Button style={{ height: '32px', width: '32px' }} variant='outlined' color='primary' onClick={this.refreshStudent}>
              <RefreshIcon />  
            </Button>                   
          </Grid>           
        </Grid>
      </div>    
    )
  }
}

const mapStateToProps = state => ({
  ...state.admin.locatorStudentPicker
})

const mapDispatchToProps = {
  searchLocatorStudents,
  selectLocatorStudent
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(StudentPicker))