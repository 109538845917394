import parse from 'csv-parse/lib/sync'
import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'


import {
  findOneBy,
} from '@relativity/js-util'

import {
  userAccountUpload,
  userAccountUploadUpdateProperty,
  userAccountCloseUploader,
  userAccountEditSave,
  comingSoon,
} from 'redux/admin/Actions'

import {
  snackMessageError,
  snackMessageInfo,
  snackMessageWarning
} from 'redux/RootActions'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FancySelect from 'common/components/FancySelect'

import styles from './style'
import IconButton from "@material-ui/core/IconButton"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {SECURITY_ROLE_ADMIN, securityRoles} from 'redux/constants'
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen'
import NopeIcon from 'common/icons/Nope'
import Dropzone from '../Dropzone'
import {Typography} from "@material-ui/core";

const tplMultipleColumns = (type) => `Warning: multiple columns with '${type}' in the header`

class UserUploaderClass extends React.Component {
  constructor(props) {
    super(props)
    this.parseFiles = this.parseFiles.bind(this)
  }

  parseFiles(files) {
    const reader = new FileReader()
    const file = files[0]

    reader.onload = (e) => {
      const records = parse(e.target.result,
        {
          skip_empty_lines: true,
          skip_lines_with_error: true
        })
      if(!records || records.length < 2) {
        this.props.snackMessageError('Unable to find any users in that file')
      }
      if(records.length > 1001) {
        this.props.snackMessageError('A maximum of 500 users can be uploaded at a time.')
      }
      const colNums = {}

      records.shift().forEach((val, i) => {
        if(val.toLowerCase().indexOf('first') > -1) {
          if(colNums.hasOwnProperty('nameFirst')) {
            this.props.snackMessageWarning(tplMultipleColumns('first'))
          }
          colNums.nameFirst = i
        }
        else if(val.toLowerCase().indexOf('last') > -1) {
          if(colNums.hasOwnProperty('nameLast')) this.props.snackMessageWarning(tplMultipleColumns('last'))
          colNums.nameLast = i
        }
        else if(val.toLowerCase().indexOf('email') > -1) {
          if(colNums.hasOwnProperty('email')) this.props.snackMessageWarning(tplMultipleColumns('email'))
          colNums.email = i
        }
      })

      if(!colNums.hasOwnProperty('email') || !colNums.hasOwnProperty('nameFirst') || !colNums.hasOwnProperty('nameLast')) {
        return this.props.snackMessageError('Importer requires csv file with the first line defining first name, last name, and email columns.')
      }

      const newUserFLEs = records.map((record, i) => {
        if(record[colNums.email].indexOf('@') < 1) {
          const msg = `Invalid email found in record ${i} '${record[colNums.email]}'`
          this.props.snackMessageError(msg)
          throw Error(msg)
        }
        return {
          nameFirst: record[colNums.nameFirst].trim(),
          nameLast: record[colNums.nameLast].trim(),
          email: record[colNums.email].trim(),
        }
      })

      this.props.userAccountUploadUpdateProperty({propertyName:'newUserFLEs', val: newUserFLEs})
    }
    reader.readAsText(file)
  }

  render() {
    if(!this.props.userAccount) return null

    const {
      buildings,
      classes,
      userAccount,
      userAccountUpload,
      userAccountUploadUpdateProperty,
      userAccountCloseUploader,
      isDirty,
      facilities,
    } = this.props
    const userAccountUploaderDone = userAccountCloseUploader
    const title = 'Upload Users - ' + findOneBy(facilities, 'id', userAccount.facilityId).displayName
    const allAccess = (userAccount.securityRoles.includes(SECURITY_ROLE_ADMIN))


    const exampleUserFLE = (userAccount.newUserFLEs.length > 0)? userAccount.newUserFLEs[0] : false

    //console.log(userAccount.newUserFLEs.length, exampleUserFLE)

    return (
      <Dialog
        onClose={userAccountUploaderDone}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={userAccountUploaderDone}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container>
            <Grid item xs={12} className={classes.gridItem}>
              <FancySelect
                dispatchUpdate={userAccountUploadUpdateProperty}
                label='Entity'
                propertyName='facilityId'
                currentItem={findOneBy(facilities, 'id', userAccount.facilityId)}
                items={facilities}
                valAtr={'id'}
                nameAtr='displayName'
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FancySelect
                dispatchUpdate={userAccountUploadUpdateProperty}
                label='Role'
                propertyName='securityRoles'
                currentItem={findOneBy(securityRoles, 'value', userAccount.securityRoles[0])}
                items={securityRoles}
                valAtr={'value'}
                nameAtr='displayName'
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Table style={{width:'100%'}}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">Authorized</TableCell>
                    <TableCell component="th">Entity Name</TableCell>
                    <TableCell padding="checkbox" align="right" style={{width:'20%'}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {facilities.map((facility)=>{
                    const isEmpowered = userAccount.securityAccess.facilityIds.includes(facility.id)
                    return <TableRow key={facility.id}>
                        <TableCell padding="checkbox">
                          <IconButton disabled={allAccess} onClick={()=>userAccountUploadUpdateProperty({propertyName:'securityAccess.facilityId', val:{id:facility.id, isEmpowered:!isEmpowered}})}>{
                            (isEmpowered || allAccess)?
                              <CheckCircleOutlineIcon/>
                              :
                              <NopeIcon/>
                          }</IconButton>
                        </TableCell>
                      <TableCell> {facility.displayName}</TableCell>
                      <TableCell padding="checkbox" align="right">{(userAccount.facilityId === facility.id || allAccess)? <Lock/> : <LockOpen/>}</TableCell>
                    </TableRow>
                  })
                  }
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Table style={{width:'100%'}}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">Authorized</TableCell>
                    <TableCell component="th">Building</TableCell>
                    <TableCell padding="checkbox" align="right" style={{width:'20%'}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {buildings.map((building)=>{
                    const isEmpowered = userAccount.securityAccess.buildingIds.includes(building.id)
                    return <TableRow key={building.id}>
                      <TableCell padding="checkbox">
                        <IconButton disabled={allAccess} onClick={()=>userAccountUploadUpdateProperty({propertyName:'securityAccess.buildingId', val:{id:building.id, isEmpowered:!isEmpowered}})}>{
                          (isEmpowered || allAccess)?
                            <CheckCircleOutlineIcon/>
                            :
                            <NopeIcon/>
                        }</IconButton>
                      </TableCell>
                      <TableCell> {building.displayName}</TableCell>
                      <TableCell padding="checkbox" align="right"></TableCell>
                    </TableRow>
                  })
                  }
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              {!exampleUserFLE ?
                <div>
                  <Dropzone onFilesAdded={this.parseFiles} />
                </div>
                :
                <div>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={99}><Typography variant='h6'>{userAccount.newUserFLEs.length} records found.</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{exampleUserFLE.nameFirst}</TableCell>
                      <TableCell>{exampleUserFLE.nameLast}</TableCell>
                      <TableCell>{exampleUserFLE.email}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </div>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{marginTop:10}}>
          <Typography style={{float:'left', flexGrow:10, marginLeft:10, fontStyle:'italic'}}>CSV file should contain 'first', 'last', 'email'</Typography>
          <Button onClick={userAccountUploaderDone} color="secondary">
            Cancel
          </Button>
          <Button disabled={!isDirty} onClick={userAccountUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  const userAccountUploader = (state.admin.dialogs.userAccountUploader)?  state.admin.dialogs.userAccountUploader :  {}
  userAccountUploader.facilities = state.admin.facilities
  userAccountUploader.buildings = state.admin.buildings
  return userAccountUploader
}


const mapDispatchToProps = {
  comingSoon,
  userAccountCloseUploader,
  userAccountUpload,
  userAccountUploadUpdateProperty,
  userAccountEditSave,
  snackMessageError,
  snackMessageWarning,
  snackMessageInfo,
}

export const UserUploader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(UserUploaderClass))

export default UserUploader