export default theme => ({
  accountField: {    
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  fieldName: {
    fontSize: '1.15rem',
    fontWeight: 'bold',
    marginRight: '5px',
    textAlign: 'end',
    width: '9rem'
  },
  fieldValue: {
    fontSize: '1.15rem',
    color: '#555',
    paddingLeft: '15px'
  },
  accountListField: {    
    display: 'flex',
    marginBottom: '10px',
  },
  listFieldValue: {
    fontSize: '1rem',
    color: '#555',
  },
  headerIcon: {
    height: '20px',
    marginRight: '10px'
  },
  button: {
    marginLeft: '15px'
  }
});
