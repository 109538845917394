import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import styles from 'common/components/style';
import DialogTitle from 'common/components/dialog/DialogTitle';
import DialogContent from 'common/components/dialog/DialogContent';
import DialogActions from 'common/components/dialog/DialogActions';
import ToggleIconButton from "common/components/ToggleIconButton";

import {
  overridableAtTypesSave,
  overridableAtTypesSaveResponse,
  overridableAtTypesEditDone,
  overridableAtTypesChooserSetIsEnabled,
  overridableAtTypesEdit
} from 'redux/admin/Actions';

class OverridableAttendanceTypesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.setSearch = this.setSearch.bind(this);

    this.state = {
      controlsDisabled: false,
      tabIndex: 0,
      searchString: '',
      selectAllChecked: this.getIsAllChecked(props.atTypes),
    };
  };

  getIsAllChecked(atTypes) {
    return atTypes.every(atType => atType.kioskCanOverride);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const isAllChecked = nextProps.atTypes.every(atType => atType.kioskCanOverride);
    if (isAllChecked !== prevState.selectAllChecked) {
      return { selectAllChecked: isAllChecked };
    }
    return null;
  };

  setSearch(event) {
    this.setState({
      searchString: event.target.value
    })
  };

  handleClose = () => {
    this.setState({ searchString: '' });
    if (this.props.overridableAtTypesEditDone) {
      this.props.overridableAtTypesEditDone();
    }
  };

  handleSave = () => {
    this.setState({ searchString: '' });
    if (this.props.overridableAtTypesSave) {
      this.props.overridableAtTypesSave();
    }
  };

  handleSelectAllChange = () => {
    const { selectAllChecked } = this.state;
    const { overridableAtTypesChooserSetIsEnabled, atTypes } = this.props;

    atTypes.forEach(atType => {
      overridableAtTypesChooserSetIsEnabled({ id: atType.id, isEnabled: !selectAllChecked });
    });

    this.setState({ selectAllChecked: !selectAllChecked });
  };

  handleItemToggle = (atType) => {
    const { overridableAtTypesChooserSetIsEnabled, atTypes } = this.props;
    overridableAtTypesChooserSetIsEnabled({ id: atType.id, isEnabled: !atType.kioskCanOverride });
    const allSelected = atTypes.every(atType => atType.kioskCanOverride);
    this.setState({ selectAllChecked: allSelected });
  }

  render() {
    if (!this.props.overridableAttendanceTypesEditor) return null;

    const {
      classes,
      atTypes,
    } = this.props

    const enabledAtTypesCount = atTypes.filter(atTypes => atTypes.kioskCanOverride).length;
    const totalAtTypesCount = atTypes.length;

    const atTypesListSorted = atTypes;
    atTypesListSorted.sort((a, b) => {
      const aDisplayName = a.displayName.toLowerCase()
      const bDisplayName = b.displayName.toLowerCase()
      return bDisplayName < aDisplayName ? 1 : bDisplayName > aDisplayName ? -1 : 0
    });

    const atTypesList = atTypes.reduce((acc, atType) => {
      if (atType.displayName.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1) {
        acc.push(
          <ListItem style={{margin: 0}} title={`id: ${atType.id}`} key={atType.id}>
            <ToggleIconButton isOn={atType.kioskCanOverride} onClick={() => this.handleItemToggle(atType)} />
            {atType.displayName}
          </ListItem>
        );
      }
      return acc;
    }, []);

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="attypes-selection-dialog-title"
        open={true}
        PaperProps={{
          style: { minWidth: '600px' }
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Choose attendance types
        </DialogTitle>

        <DialogContent className={classes.dialogContentRoot}>
          <Typography style={{ marginTop: '10px' }}>Choose which attendances are allowed to be overridden by kiosk</Typography>
          <Typography style={{ marginBottom: '10px' }}>{enabledAtTypesCount} of {totalAtTypesCount}.</Typography >
          <TextField
            style={{ marginTop: '10px' }}
            label={`Search for an attendance type`}
            variant="outlined"
            fullWidth
            onKeyUp={this.setSearch}
          />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', paddingLeft: '16px' }}>
            <Checkbox checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />
            <Typography>Select All</Typography>
          </div>
          <List>{atTypesList}</List>
        </DialogContent>

        <DialogActions style={{ marginTop: 10 }}>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  overridableAtTypesSave,
  overridableAtTypesSaveResponse,
  overridableAtTypesEditDone,
  overridableAtTypesChooserSetIsEnabled,
  overridableAtTypesEdit
};

const mapStateToProps = state => {
  const atTypes = state.admin.overridableAttendanceTypesChooser.atTypes;
  const overridableAttendanceTypesEditor = state.admin.dialogs.overridableAttendanceTypesEditor ? state.admin.dialogs.overridableAttendanceTypesEditor : null;
  return { atTypes, overridableAttendanceTypesEditor };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(OverridableAttendanceTypesDialog));