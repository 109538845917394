import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import {
  hideStaticContentDialog
} from 'redux/kiosk/KioskActions'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import styles from './styles'
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";

class StaticContentDialogClass extends React.Component {
  render() {
    if(!this.props.staticContentDialog) return null
    const {
      classes,
      hideStaticContentDialog
    } = this.props
    const {
      title,
      content,
    } = this.props.staticContentDialog

    return (
      <Dialog
        onClose={hideStaticContentDialog}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={hideStaticContentDialog}>
          {title} - Details
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{whiteSpace:'pre'}}>{content}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{marginTop:10}}>
          <Button onClick={hideStaticContentDialog} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => state.kiosk.dialogs


const mapDispatchToProps = {
  hideStaticContentDialog
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
) (withStyles(styles, { withTheme: true })(StaticContentDialogClass))