import React from 'react'
import connect from "react-redux/es/connect/connect"
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import {
  findOneBy,
} from '@relativity/js-util'

import styles from './style'
import {
  atRecordEditUpdateProperty,
  atRecordEditDone,
  atRecordDelete,
  atRecordEditSave
} from 'redux/admin/Actions'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FancySelect from 'common/components/FancySelect'
import ToggleIconButton from 'common/components/ToggleIconButton'
import BuildingLocationName from "common/components/BuldingLocationName";
import {FORMAT_YMD, ID_ZERO} from "redux/constants";
import {FullTextFieldD} from "common/components/FullTextField";

class AtRecordEditor extends React.Component {

  constructor(props) {
    super(props)
    this.toggleIsReportingAtRecord = this.toggleIsReportingAtRecord.bind(this)
    this.deleteAtRecord = this.deleteAtRecord.bind(this)
  }

  toggleIsReportingAtRecord() {
    if (this.props.atRecord.isReportingAtRecord && this.props.atRecord.vendorId !== '') {
      alert("Once synced, you cannot un-mark this record from being reporting. You must create a different reporting record, or explicitly delete this one")
    } else {
      this.props.atRecordEditUpdateProperty({
        propertyName: 'isReportingAtRecord',
        val: !this.props.atRecord.isReportingAtRecord
      })
    }
  }

  deleteAtRecord() {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("Are you sure you want to delete this record? This action cannot be undone")) {
      this.props.atRecordDelete()
    }
  }

  render() {
    if(!this.props.atRecord) return null

    let atReason, atType, building, locations

    let { buildings, location } = this.props
    const {
      classes,
      //atRecordDelete, //leave this!
      atRecordEditDone,
      atRecordEditSave,
      atRecordEditUpdateProperty,
      atRecord,
      atTypes,
      atReasons,
      byId,
      student,
      weekCalendar
    } = this.props

    const {
      byDate,
      schedulePeriodByIdFn,
    } = weekCalendar

    const atMoment = moment.unix(atRecord.uTime)
    const ymd = atMoment.format(FORMAT_YMD)
    const schedulePeriods = byDate[ymd].schedulePeriods.concat()
    const atUserSchedulePeriod = schedulePeriodByIdFn(atRecord.atUserSchedulePeriodId? atRecord.atUserSchedulePeriodId : 0)

    atType = findOneBy(atTypes, 'id', atRecord.atTypeId? atRecord.atTypeId : 0)
    atReason = findOneBy(atReasons, 'id', atRecord.atReasonId? atRecord.atReasonId : 0)

    if(!findOneBy(schedulePeriods, 'id', atUserSchedulePeriod.id)) {
      schedulePeriods.unshift(atUserSchedulePeriod)
    }
    schedulePeriods.sort((a,b)=> a.todStartSeconds - b.todStartSeconds)

    const locationZero = byId.location(ID_ZERO)
    const buildingZero = byId.building(ID_ZERO)
    const externalTypeDisplayName = (atRecord.atTypeExternalId > 0 && byId.atType(atRecord.atTypeExternalId))? ' (Current external:' + byId.atType(atRecord.atTypeExternalId).displayName + ')': ''

    building = byId.building(atRecord.buildingId? atRecord.buildingId : 0)
    if(building.id === 0) {
      buildings = [buildingZero].concat(buildings)
    }

    locations = byId.locationsByBuilding(building.id)
    if(building.id !== location.buildingId) {
      location = locationZero
      locations = [locationZero].concat(locations? locations: [])
    }


    return (
      <Dialog
        onClose={atRecordEditDone}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={atRecordEditDone}>
          <ToggleIconButton
            onTitle='Use for attendance'
            offTitle='Will not be used for attendance'
            style={{marginLeft:-12, marginTop:-9,  position:'absolute'}}
            onClick={this.toggleIsReportingAtRecord}
            isOn={atRecord.isReportingAtRecord}
            onIcon={CheckCircleIcon}
          />
          {(!!atRecord.id)?
            (<span title={`Record ${atRecord.id}`} style={{marginLeft:36}}>
              <b>{atMoment.format('YYYY-MM-DD')}</b><span> / </span>
              <b>{atUserSchedulePeriod.periodNumber}</b><span> / </span>
              <b>{location.id === ID_ZERO? '' : location.displayName}</b><span> </span>
              <span style={{whiteSpace:'nowrap'}}>{building.id === ID_ZERO? 'No location specified' : building.displayName}</span>
            </span>)
            :
            <span style={{marginLeft:36}}>Create a new attendance record - <b style={{whiteSpace:'nowrap'}}>{student.fullName}</b></span>
          }
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
            <Grid container>
              {!!atRecord.id? null : <Grid item xs={12}>
                <FullTextFieldD label={'Date'} value={atMoment.format('MMMM D')} />
              </Grid> }
              {!!atRecord.id? null : <Grid item xs={12}>
                <FullTextFieldD label={'Period'} value={atUserSchedulePeriod.periodNumber} />
              </Grid> }
              <Grid item xs={12}>
                <FancySelect label={`Type${externalTypeDisplayName}`} propertyName={'atTypeId'} nameAtr={'displayName'} currentItem={atType} items={atTypes} dispatchUpdate={atRecordEditUpdateProperty}/>
              </Grid>
              <Grid item xs={12}>
                <FancySelect label={'Reason'} propertyName={'atReasonId'} nameAtr={'displayName'} currentItem={atReason} items={atReasons} dispatchUpdate={atRecordEditUpdateProperty}/>
              </Grid>
              {!!atRecord.id? <Grid item xs={12}>
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="When"
                  fullWidth
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={atMoment.format('YYYY-MM-DD HH:mm:ss')}
                />
              </Grid> : null }
              {!!atRecord.id? null : <Grid item xs={12}>
                <FancySelect
                  label={'Building'}
                  propertyName={'buildingId'}
                  currentItem={building}
                  items={buildings}
                  nameAtr={'displayName'}
                  dispatchUpdate={atRecordEditUpdateProperty}/>
                <FancySelect
                  label={'Location'}
                  propertyName={'locationId'}
                  currentItem={location}
                  items={locations}
                  nameAtr={(item)=>{return <BuildingLocationName byId={byId} locationId={item.id} />}}
                  dispatchUpdate={atRecordEditUpdateProperty}/>
              </Grid> }
              <Grid item xs={12}>
              <TextField
                id="outlined-multiline-notes"
                label="Notes"
                multiline
                fullWidth
                rows="4"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                value={atRecord.notes}
                onChange={(e)=>{
                  atRecordEditUpdateProperty({propertyName:'notes', val:e.target.value})
                }}
              />
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <div style={{flexGrow:10}}>
            <Button onClick={this.deleteAtRecord} color="secondary" style={{float:'left'}}>
              Delete
            </Button>
          </div>
          <Button onClick={atRecordEditDone} color="secondary">
            Cancel
          </Button>
          <Button onClick={atRecordEditSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => (state.admin.dialogs.atRecordEditor)?
  Object.assign({byId:state.admin.byId, buildings: state.admin.permittedBuildings}, state.admin.dialogs.atRecordEditor)
  :
  {}

const mapDispatchToProps = {
  atRecordDelete,
  atRecordEditDone,
  atRecordEditSave,
  atRecordEditUpdateProperty
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(AtRecordEditor))