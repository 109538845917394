import {findAllBy, findOneBy, newState, payloadOnly} from '@relativity/js-util'
import handlebars from 'handlebars'
import {
  ACTIVITY_ACCEPTING_ID_CODE,
  ACTIVITY_KIOSK_DISABLED,
  ACTIVITY_PREVIOUS_ACTIVITY,
  ACTIVITY_SENDING_ATTENDANCE,
  ACTIVITY_WAITING_FOR_LOCATION,
  ACTIVITY_WAITING_TO_SEND, CHECKIN_FLOW_CHOOSER,
  CHECKIN_FLOW_CHOOSER_FAST,
  CHECKIN_FLOW_CHOOSER_SLOW, CHECKIN_FLOW_INSTANT,
  CHECKIN_FLOW_INSTANT_FAST, CHECKIN_FLOW_INSTANT_SLOW,
  CHECKIN_FLOW_STANDARD,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS,
  DELETE_ALL_CHARS,
  FACILITY_NOT_SET,
  KIOSK_ATTENDANCE_DIRECTION_IN,
  KIOSK_ATTENDANCE_DIRECTION_MULTI,
  KIOSK_ATTENDANCE_DIRECTION_OUT, KIOSK_UI_FAST_DELAY_MS,
  KIOSK_UI_GLACIAL_DELAY_MS, KIOSK_UI_SLOW_DELAY_MS,
  LOCATION_NOT_SET,
  KIOSK_TYPE_WEB
} from "redux/constants";
import {
  SET_ACTIVITY
} from "redux/actions";

import {
  ADD_ID_CODE_CHAR,
  REMOVE_ID_CODE_CHAR,
  KIOSK_IS_READY,
  KIOSK_SETUP_WIZARD_UPDATE_PROPERTY,
  KIOSK_SNACK_MESSAGE_HIDE,
  KIOSK_SNACK_MESSAGE_SHOW,
  KIOSK_SET_PRINTABLE_SLIP_CONTENT,
  KIOSK_BOOT_L1_NEW_SET_WIZARD_DATA,
  KIOSK_ACTIVITY_SET_READY_TO_SEND,
  KIOSK_LOGIN_UI_SHOW,
  KIOSK_LOGIN_SET_USER,
  KIOSK_LOGIN_UI_HIDE,
  HIDE_STATIC_CONTENT_DIALOG,
  SHOW_STATIC_CONTENT_DIALOG,
  CAMERA_SET_DEVICE_LIST, CAMERA_TOGGLE_SHOW_VIDEO,
  BOOT_L1_EXISTING_SET_CONFIGURATION, CAMERA_TORCH_ON,
  KIOSK_FACILITIES_CUSTOM_SETUP_EDIT,
  KIOSK_FACILITIES_CUSTOM_SETUP_APPLY,
  KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE,
  KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_FROM_ACTIVE_KIOSK,
  KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY_FROM_ACTIVE_KIOSK,
  KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE_FROM_ACTIVE_KIOSK
} from 'redux/kiosk/KioskActions'

import {computeKioskCapabilities, sortList} from 'redux/utils'

const addCharToIdCode = (state, char) => {
  let transitionState
  if(state.configuration.rIdCodeChar.exec(char) === null) {
    return state
  }
  if(state.idCodeLengthMax <= state.idCode.length) {
    return state
  }

  const idCode = state.idCode + '' + char

  /*if(state.idCodeLengthMax === idCode.length && state.idCodeLengthMin === idCode.length) {
    transitionState = setActivity(state, ACTIVITY_WAITING_TO_SEND);
    transitionState.idCode = idCode
  } else { */
    transitionState = {
      uIdCodeLastModified: Date.now(),
      idCode
    }
  //}
  return newState(state, transitionState)
}

const setActivityReadyToSend = (state) => {
  if(state.idCode.length < state.idCodeLengthMin || state.idCode.length > state.idCodeLengthMax)
    return state
  return setActivity(state, ACTIVITY_WAITING_TO_SEND);
}

const bootSetConfiguration = (state, payload) => {
  const transitionState = {
    id: payload.id,
    location: payload.location,
    isLocked: payload.isLocked,
    building: payload.building,
    atTypeId: payload.atTypeId,
    atTypes: payload.atTypes,
    atType: findOneBy(payload.atTypes, 'id', payload.atTypeId),
    description: payload.description,
    leaseExpires: payload.leaseExpires,
    attendanceDirection: payload.attendanceDirection,
    idCodeLengthMax: payload.idCodeLengthMax,
    idCodeLengthMin: payload.idCodeLengthMin,
    isDeletable: payload.isDeletable,
    capabilities: computeKioskCapabilities(payload.kioskDefaultConfiguration, payload),
    cameraConfiguration: payload.jsbCameraConfiguration,
    cameraTorchOn: false,
    organizationName: payload.organizationName,
    customAttendanceConfiguration: payload.customAttendanceConfiguration,
    allFacilities: payload.allFacilities,
    allAtTypes: payload.allAtTypes,
    allAtReasons: payload.allAtReasons,
    positiveAttendanceIsEnabled: payload.positiveAttendanceIsEnabled
  }

  switch(transitionState.capabilities.checkinFlow.val) {
    case CHECKIN_FLOW_CHOOSER_FAST :
      transitionState.checkinFlow = CHECKIN_FLOW_CHOOSER
      transitionState.checkinFlowDelayMS = KIOSK_UI_FAST_DELAY_MS
      break
    case CHECKIN_FLOW_CHOOSER_SLOW :
      transitionState.checkinFlow = CHECKIN_FLOW_CHOOSER
      transitionState.checkinFlowDelayMS = KIOSK_UI_SLOW_DELAY_MS
      break
    case CHECKIN_FLOW_INSTANT_FAST :
      transitionState.checkinFlow = CHECKIN_FLOW_INSTANT
      transitionState.checkinFlowDelayMS = KIOSK_UI_FAST_DELAY_MS
      break
    case CHECKIN_FLOW_INSTANT_SLOW :
      transitionState.checkinFlow = CHECKIN_FLOW_INSTANT
      transitionState.checkinFlowDelayMS = KIOSK_UI_SLOW_DELAY_MS
      break
    case CHECKIN_FLOW_STANDARD :
    default :
      transitionState.checkinFlow = CHECKIN_FLOW_STANDARD
      transitionState.checkinFlowDelayMS = KIOSK_UI_GLACIAL_DELAY_MS
      break
  }

  if(payload.atRecordTemplates) {
    transitionState.templates = {
      atRecordSlips: Object.keys(payload.atRecordTemplates).reduce((acc, atTypeId) => {
        const byEntity = payload.atRecordTemplates[atTypeId]
        acc[atTypeId] = Object.keys(byEntity).reduce((acc, entityId) => {
          acc[entityId] = {
            template: handlebars.compile(byEntity[entityId].templateString),
            style: byEntity[entityId].style
          }
          return acc
        }, {})
        return acc
      }, state.templates.atRecordSlips )}
  }
  transitionState.appVersion = payload.appVersion
  transitionState.idCode = ''
  const newActivity = (payload.isEnabled)? ACTIVITY_ACCEPTING_ID_CODE : ACTIVITY_KIOSK_DISABLED
  return setActivity(newState(state, transitionState), newActivity)
}

const cameraSetDeviceList = (state, payload) => {
  return newState(state, 'cameraDeviceList', payload)
}

const hideStaticContentDialog = (state) => {
  return newState(state, 'dialogs.staticContentDialog', false)
}

const showStaticContentDialog = (state, payload) => {
  return newState(state, 'dialogs.staticContentDialog', payload)
}

const removeCharFromIdCode = (state, payload) => {
  if(state.idCode.length > 0) {
    const updatedIdCode = (payload === DELETE_ALL_CHARS)? '' : state.idCode.substr(0, state.idCode.length - 1)
    return setActivity(newState(state, {
      uIdCodeLastModified: Date.now(),
      idCode: updatedIdCode
    }), ACTIVITY_ACCEPTING_ID_CODE)
  }
  return state
}

const setActivity = (state, activity) => {
  if(activity === ACTIVITY_PREVIOUS_ACTIVITY && state.previousActivity.length === 0) {
    console.warn(`Application should not set ${ACTIVITY_PREVIOUS_ACTIVITY} when previousActivity.length === 0`)
    return state
  }

  const capabilities = state.capabilities
  const previousActivityLength = state.previousActivity.length
  const newActivity = (activity === ACTIVITY_PREVIOUS_ACTIVITY)? state.previousActivity.pop() : activity

  //this who
  if(previousActivityLength === state.previousActivity.length) {
    state.previousActivity.push(state.currentActivity) //if previousActivity is ever used for UI component rendering, it needs to be cloned instead
  }

  let transitionState = {currentActivity:newActivity}

  switch(newActivity) {
    case ACTIVITY_ACCEPTING_ID_CODE :
      transitionState.enabled = {
        keyboardCodeInput:capabilities.acceptKeyboardInput.val,
        keypad: capabilities.enableKeyPad.val,
        report:false,
        checkIn:false,
        checkOut:false
      }
      break
    case ACTIVITY_WAITING_TO_SEND :
      transitionState.enabled = {
        keyboardCodeInput:true,
        keypad: false,
        report: capabilities.requestSendReport,
        checkIn: capabilities.attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_IN || capabilities.attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_MULTI,
        checkOut: capabilities.attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_OUT || capabilities.attendanceDirection === KIOSK_ATTENDANCE_DIRECTION_MULTI
      }
      break
    case ACTIVITY_KIOSK_DISABLED :
      transitionState.enabled = {
        keyboardCodeInput:false,
        keypad: false,
        report:false,
        checkIn:false,
        checkOut:false
      }
      break
    case ACTIVITY_SENDING_ATTENDANCE :
      break
    case ACTIVITY_WAITING_FOR_LOCATION :
      transitionState.enabled = {
        keyboardCodeInput: false,
        keypad: false,
        report:false,
        checkIn:false,
        checkOut:false
      }
      break
    default :

  }
  return newState(state, transitionState)
}

const snackMessageHide = (state) => {
  return newState(state, 'snackMessage', {show:false, autoHideMillis: DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS})
}

const snackMessageShow = (state, payload) => {
  const snackMessage = Object.assign({show:true, autoHideMillis:DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS}, payload)
  return newState(state, 'snackMessage', snackMessage, false)
}

const setupWizardUpdateProperty = (state, payload) => {
  const {buildings, facilities } = state
  let building, locations, location, facility

  switch (payload.propertyName) {
    case 'facilityId' :
      if(parseInt(payload.val) === -1) return state
      facility = findOneBy(facilities, 'id', payload.val)
      locations = [LOCATION_NOT_SET].concat(findAllBy(state.locations, 'facilityId', facility.id))
      return newState(state, 'locationChooser', {facility, location: LOCATION_NOT_SET, locations})
    case 'buildingId' :
      if(parseInt(payload.val) === -1) return state
      building = findOneBy(buildings, 'id', payload.val)
      locations = [LOCATION_NOT_SET]
      if(building.id > 0) locations = locations.concat(findAllBy(state.locations, 'buildingId', building.id))
      return newState(state, 'locationChooser', {building, location: LOCATION_NOT_SET, locations})
    case 'locationId' :
      if(parseInt(payload.val) === -1) return state
      location = findOneBy(state.locations, 'id', payload.val)
      return newState(state, 'locationChooser', {location} )
    case 'description' :
      return newState(state, 'locationChooser', {description: payload.val} )
    case 'overrideTypeId':
      const facilitiesOverridesChooser = state.locationChooser.facilitiesOverridesChooser.facilitiesOverrides.map(facilityOverride => {
        if (facilityOverride.facilityId === payload.facilityId) {
            return { ...facilityOverride, [payload.propertyName]: payload.val };
        }
        return facilityOverride;
      });
      return newState(state, 'locationChooser.facilitiesOverridesChooser', {facilitiesOverrides: facilitiesOverridesChooser});
    case 'atReasonId':
    case 'atTypeAbsentId':
    case 'atTypeTardyId':
    case 'atTypeSingleId':
      const facilitiesOverrides = state.locationChooser.customAttendanceConfiguration.facilitiesOverrides.map(facilityOverride => {
        if (facilityOverride.facilityId === payload.facilityId) {
            return { ...facilityOverride, [payload.propertyName]: payload.val };
        }
        return facilityOverride;
      });
      return newState(state, 'locationChooser.customAttendanceConfiguration', {facilitiesOverrides: facilitiesOverrides});
    default :
  }
  return state
}

const setPrintableSlipContent = (state, payload) => {
  return newState(state, {printSlip:payload})
}

const bootSetWizardData = (state, data) => {
  let buildingNotSet
  data.facilities.push(FACILITY_NOT_SET)
  data.locations = sortList('displayName')(data.locations)
  data.buildings = sortList('displayName')(data.buildings.reduce((acc, item) => {
    if(item.id === 0) {
      buildingNotSet = item
    } else {
      acc.push(item)
    }
    return acc
  }, []))
  data.buildings.unshift(buildingNotSet)
  return newState(newState(state, data), 'locationChooser', {building:buildingNotSet})
}

const loginUIHide = (state) => {
  return newState(state, `dialogs.login`, false)
}

const loginUIShow = (state, payload) => {
  return newState(state, `dialogs.login`, payload)
}

const setLoggedInuser = (state, payload) => {
  return newState(state, `loggedInUser`, payload)
}

const cameraToggleShowVideo = (state) => {
  return newState(state, {showVideo: !state.showVideo})
}

const isReady = (state) => newState(state, 'isReady', true)

const cameraTorchOn = (state, isOn) => {
  return newState(state, {cameraTorchOn:!!isOn})
}

const kioskFacilitiesCustomSetupEdit = (state, payload) => {
  let existingFacilitiesOverrides;
  if(!state.locationChooser.customAttendanceConfiguration){
    existingFacilitiesOverrides = [];
  }
  else{
    existingFacilitiesOverrides = state.locationChooser.customAttendanceConfiguration.facilitiesOverrides || [];
  }
  const facilities = state.facilities.filter(facility => facility.id > 0); 
  const facilitiesOverrides = facilities.map(facility => {
      const facilityOverride = existingFacilitiesOverrides.find(override => override.facilityId === facility.id) || {};
      const overrideTypeId = facilityOverride.overrideTypeId || 0;  
      return { facilityId: facility.id, overrideTypeId };
  });

  return {
      ...state,   
      locationChooser: {
        ...state.locationChooser,
        facilitiesOverridesChooser:{
          ...state.locationChooser.facilitiesOverridesChooser,
          facilitiesOverrides
        }
      },     
      kioskFacilitiesCustomSetupEditor: {kioskType: KIOSK_TYPE_WEB}
  };
};

const kioskFacilitiesCustomSetupApply = (state, payload) => {
  const facilitiesOverridesChooser = state.locationChooser.facilitiesOverridesChooser.facilitiesOverrides;

  let facilitiesOverrides;
  if(!state.locationChooser.customAttendanceConfiguration){
    facilitiesOverrides = []
  }
  else{
    facilitiesOverrides = state.locationChooser.customAttendanceConfiguration.facilitiesOverrides || [];
  }

  const updatedfacilitiesOverrides = facilitiesOverridesChooser.reduce((acc, chooserOverride) => {
    if (chooserOverride.overrideTypeId === 0) {
      return acc.filter(item => item.facilityId !== chooserOverride.facilityId);
    }
    const existingEditorOverride = facilitiesOverrides.find(editor => editor.facilityId === chooserOverride.facilityId);
    if (!existingEditorOverride && chooserOverride.overrideTypeId > 0) {
      return acc.concat({ ...chooserOverride });
    } else if (existingEditorOverride && existingEditorOverride.overrideTypeId !== chooserOverride.overrideTypeId) {
      return acc.map(editor => {
        if (editor.facilityId === chooserOverride.facilityId) {
          return {
            ...editor,
            overrideTypeId: chooserOverride.overrideTypeId
          };
        }
        return editor;
      });
    }
    return acc;
  }, facilitiesOverrides.slice());

  return {
    ...state,
    locationChooser: {
      ...state.locationChooser,
      customAttendanceConfiguration:{
        facilitiesOverrides: updatedfacilitiesOverrides 
      }
    },
    kioskFacilitiesCustomSetupEditor: false
  };
};

const kioskFacilitiesCustomSetupEditDone = (state, payload) => {
  return newState(state, `kioskFacilitiesCustomSetupEditor`, false);
};

const kioskFacilitiesCustomSetupEditFromActiveKiosk = (state, payload) => {
  let existingFacilitiesOverrides;
  if(!state.customAttendanceConfiguration){
    existingFacilitiesOverrides = [];
  }
  else {
    existingFacilitiesOverrides = state.customAttendanceConfiguration.facilitiesOverrides || [];
  }
  
  const facilities = state.allFacilities.filter(facility => facility.id > 0); 
  const facilitiesOverrides = facilities.map(facility => {
      const facilityOverride = existingFacilitiesOverrides.find(override => override.facilityId === facility.id) || {};
      const overrideTypeId = facilityOverride.overrideTypeId || 0;  
      return { facilityId: facility.id, overrideTypeId, ...facilityOverride };
  });

  const kioskFacilitiesCustomSetupFromActiveKioskEditor = {
    facilitiesOverrides: facilitiesOverrides
  };

  return newState(state, `kioskFacilitiesCustomSetupFromActiveKioskEditor`, kioskFacilitiesCustomSetupFromActiveKioskEditor);
};

const kioskFacilitiesCustomSetupUpdatePropertyFromActiveKiosk = (state, payload) => {
  const { facilityId, propertyName, val } = payload;
  const facilitiesOverrides = state.kioskFacilitiesCustomSetupFromActiveKioskEditor.facilitiesOverrides.map(facilityOverride => {
      if (facilityOverride.facilityId === facilityId) {
          return { ...facilityOverride, [propertyName]: val, };
      }
      return facilityOverride;
  });
  const kioskFacilitiesCustomSetupFromActiveKioskEditor = {
    facilitiesOverrides: facilitiesOverrides
  }
  return newState(state, 'kioskFacilitiesCustomSetupFromActiveKioskEditor', kioskFacilitiesCustomSetupFromActiveKioskEditor);
};

const kioskFacilitiesCustomSetupEditDoneFromActiveKiosk = (state, payload) => {
  return newState(state, `kioskFacilitiesCustomSetupFromActiveKioskEditor`, false);
};

const KioskReducer = {
  [BOOT_L1_EXISTING_SET_CONFIGURATION]:payloadOnly(bootSetConfiguration),
  [KIOSK_SNACK_MESSAGE_HIDE]:payloadOnly(snackMessageHide),
  [KIOSK_SNACK_MESSAGE_SHOW]:payloadOnly(snackMessageShow),
  [SET_ACTIVITY]:payloadOnly(setActivity),
  [REMOVE_ID_CODE_CHAR]:payloadOnly(removeCharFromIdCode),
  [ADD_ID_CODE_CHAR]:payloadOnly(addCharToIdCode),
  [HIDE_STATIC_CONTENT_DIALOG]:payloadOnly(hideStaticContentDialog),
  [SHOW_STATIC_CONTENT_DIALOG]:payloadOnly(showStaticContentDialog),
  [KIOSK_SETUP_WIZARD_UPDATE_PROPERTY]:payloadOnly(setupWizardUpdateProperty),
  [KIOSK_SET_PRINTABLE_SLIP_CONTENT]:payloadOnly(setPrintableSlipContent),
  [KIOSK_BOOT_L1_NEW_SET_WIZARD_DATA]:payloadOnly(bootSetWizardData),
  [KIOSK_ACTIVITY_SET_READY_TO_SEND]:payloadOnly(setActivityReadyToSend),
  [KIOSK_LOGIN_UI_SHOW] : payloadOnly(loginUIShow),
  [KIOSK_LOGIN_UI_HIDE] : loginUIHide,
  [KIOSK_LOGIN_SET_USER] : payloadOnly(setLoggedInuser),
  [KIOSK_IS_READY]: payloadOnly(isReady),
  [CAMERA_SET_DEVICE_LIST]: payloadOnly(cameraSetDeviceList),
  [CAMERA_TOGGLE_SHOW_VIDEO]: payloadOnly(cameraToggleShowVideo),
  [CAMERA_TORCH_ON]: payloadOnly(cameraTorchOn),
  [CAMERA_TORCH_ON]: payloadOnly(cameraTorchOn),
  [KIOSK_FACILITIES_CUSTOM_SETUP_EDIT] : payloadOnly(kioskFacilitiesCustomSetupEdit),
  [KIOSK_FACILITIES_CUSTOM_SETUP_APPLY] : payloadOnly(kioskFacilitiesCustomSetupApply),
  [KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE] : payloadOnly(kioskFacilitiesCustomSetupEditDone),
  [KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_FROM_ACTIVE_KIOSK] : payloadOnly(kioskFacilitiesCustomSetupEditFromActiveKiosk),
  [KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY_FROM_ACTIVE_KIOSK] : payloadOnly(kioskFacilitiesCustomSetupUpdatePropertyFromActiveKiosk),
  [KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE_FROM_ACTIVE_KIOSK] : payloadOnly(kioskFacilitiesCustomSetupEditDoneFromActiveKiosk),
}

export default KioskReducer