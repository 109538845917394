import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {withStyles} from "@material-ui/core";
import styles from './styles'

class FooterClass extends React.Component {
  render() {
    const {classes} = this.props
    return <Grid container className={classes.root} spacing={16}>
      <Grid item xs={12}>
        <Paper className={classes.control}>
          <a href='https://k12sta.com/support/schooltrak/' target="_blank" rel="noopener noreferrer" className={classes.footerLink}
          ><div style={{display:'flex', justifyContent: 'center'}}>
            <img alt='' style={{maxWidth:'100%', maxHeight:'70px'}} src="/images/sta_logo_full.svg"/>
          </div></a>
        </Paper>
      </Grid>
    </Grid>
  }
}

export default withStyles(styles)(FooterClass)