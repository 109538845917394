import React from "react"
import PropTypes from 'prop-types'
import connect from "react-redux/es/connect/connect"
import {Paper, withStyles} from "@material-ui/core"
import Typography from "@material-ui/core/es/Typography/Typography"
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import LicenseAndAuth from './LicenseAndAuth'
import General from './General'
import Synchronization from './Synchronization'
import Email from './Email'
import SchoolYearMigration from "./SchoolYearMigration"
import BuildingChooserDialog from "apps/admin/components/BuildingChooserDialog";

import styles from '../style'
import {KDCL_INSTANCE, KDCL_ROOT, URL_SETTINGS} from 'redux/constants'
import {
  appSettingsFetch,
  appSettingsSave, dangerousUpdateClearGeneralPropertyCookies,
  dangerousUpdateGeneralProperty,
  updateGeneralSetting,
  startSyncSchoolYears
} from "redux/admin/Actions"
import KioskSettings from "apps/admin/components/KioskSettings"
import PiratesAndDragons from "apps/admin/pages/Settings/PiratesAndDragons";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};


class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex:0
    }
  }


  componentDidMount() {
    this.props.appSettingsFetch()
  }


  switchTab = (event, tabIndex) => {
    this.setState({ tabIndex });

    if(tabIndex === 4 ){
      this.props.startSyncSchoolYears();
    }
  }


  render() {

    const {
      appSettingsSave,
      classes,
      dialogs: {
        kioskConfigurationEditor
      },
      ids: {
        singletonKioskId
      },
      piratesAndDragonsEnabled,
      settings: {
        isDirty
      },
    } = this.props
    const {tabIndex} = this.state
    let displayedTab = null
    switch(tabIndex) {
      case 0 :
        displayedTab = <Paper className={classes.paper}><General/></Paper>
        break
      case 1 :
        displayedTab = <Paper className={classes.paper}><LicenseAndAuth/></Paper>
        break
      case 2 :
        displayedTab = <Paper className={classes.paper}><Email/></Paper>
        break
      case 3 :
        displayedTab = <Paper className={classes.paper}><Synchronization/></Paper>
        break
      case 4 :
          displayedTab = <Paper className={classes.paper}><SchoolYearMigration/></Paper>
          break
      case 5 :
          displayedTab = <Paper className={classes.paper}><PiratesAndDragons/></Paper>
          break
      default: 
        displayedTab = <Paper className={classes.paper}><General/></Paper>
        break
    }
    return (
      <div className={classes.root}>
        <KioskSettings level={KDCL_ROOT}/>
        {kioskConfigurationEditor && kioskConfigurationEditor.kiosk && kioskConfigurationEditor.kiosk.id === singletonKioskId? <KioskSettings level={KDCL_INSTANCE}/> : null}
        <BuildingChooserDialog/>
        <AppBar position="static" color="default">
          <Button style={{float:'right', margin:5}} disabled={!isDirty} variant="contained" color="primary" onClick={appSettingsSave}>
            Save All
          </Button>
          <Tabs value={tabIndex} onChange={this.switchTab}>
            <Tab label="General" />
            <Tab label="License & Authentication" />
            <Tab label="Email" />
            <Tab label="Synchronization" />
            <Tab label="School Year End Rollover" />
            {piratesAndDragonsEnabled? <Tab label="Pirates & Dragons" /> : null}
            <Tab style={{flexGrow:8}} disabled>

            </Tab>
          </Tabs>
        </AppBar>
        {displayedTab}
      </div>
        )
  }
}

const mapStateToProps = state => state.admin

const mapDispatchToProps = {
  appSettingsFetch,
  appSettingsSave,
  updateGeneralSetting,
  dangerousUpdateClearGeneralPropertyCookies,
  dangerousUpdateGeneralProperty,
  startSyncSchoolYears
}

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Settings))

export const settingsPage = {
  icon: SettingsIcon,
  component: SettingsPage,
  name: 'Settings',
  displayName: 'Settings',
  to: URL_SETTINGS
}

export default SettingsPage