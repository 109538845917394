import React from 'react'
import connect from "react-redux/es/connect/connect"
import {findOneBy} from "redux/utils";
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import styles from 'common/components/style'


import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'

import { sendInstallComplete, returnToSetBuildings } from 'redux/install/Actions'
import Typography from "@material-ui/core/Typography";
import {List, ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import {studentSearchPage} from "apps/admin/pages/Students";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ItemList from "common/components/ItemList";
import {DateRangeIcon} from "material-ui-pickers/_shared/icons/DateRangeIcon";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import {Assessment} from "@material-ui/icons";
import {INSTALL_STEP_CONFIRM_ALL} from "../../../redux/install/constants";

class STASummaryDialogClass extends React.Component {
  constructor() {
    super()
    this.state = {controlsDisabled:false}
  }

  confirmThisStep(fn) {
    this.setState({
      controlsDisabled:true
    })
    fn()
  }
  render() {
    if(!this.props.settings || this.props.browserCurrentStep !== INSTALL_STEP_CONFIRM_ALL) return null;
    const {atUserCount, classes, close, sendInstallComplete, settings, syncedObjects, returnToSetBuildings} = this.props
    const onClose = (close)? close : ()=>{console.log('This should be an easter egg, but it\'s not')}
    const schoolYear = findOneBy(syncedObjects.schoolYears, 'id', settings.schoolYearId)
    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <DialogTitle id="customized-dialog-title">
          {settings.name} Install Summary
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container className={classes.firstRow}>
            <Grid item className={classes.gridItem} xs={12}>
              <Typography paragraph>Basic installation complete! Please review and confirm your settings below to finish.</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.firstRow}>
            <Grid item className={classes.gridItem} xs={12}>
              <List>
                <ListItem><ListItemIcon><DateRangeIcon/></ListItemIcon><ListItemText>School Year: {schoolYear.displayName}</ListItemText></ListItem>
                <ListItem><ListItemIcon><DateRangeIcon/></ListItemIcon><ListItemText>Timezone: {settings.jsbTimezone.displayName}</ListItemText></ListItem>
                <ListItem><ListItemIcon>{React.createElement(studentSearchPage.icon)}</ListItemIcon><ListItemText>Students Imported: {atUserCount}</ListItemText></ListItem>
              </List>
              <Typography variant='h6'>Additional features enabled (Yes/No).</Typography>
              <List>
                <ListItem><ListItemIcon><Assessment/></ListItemIcon><ListItemText>Positive Attendance: {settings.licensingJson.positiveAttendanceIsEnabled? 'Yes':'No'}</ListItemText></ListItem>
                <ListItem><ListItemIcon><ImportantDevicesIcon/></ListItemIcon><ListItemText>PPK Hardware Aware: {settings.licensingJson.ppkIsEnabled? 'Yes':'No'}</ListItemText></ListItem>
              </List>
              <Typography variant='h6'>The following Buildings (of {settings.licensingJson.maxLicensedBuildings} maximum) have been selected.</Typography>
              <ItemList items={syncedObjects.buildings} displayAtr={(item)=>(item.isEnabled)? `${item.displayName} (${item.locationCount} locations)` : item.displayName}/>
              <Typography variant='h6'>The following Entities will be used.</Typography>
              <ItemList items={syncedObjects.entities} />
            </Grid>
          </Grid>
          <Grid container className={classes.firstRow}>
            <Grid item className={classes.gridItem} xs={12}>
              <Typography variant='h6'>Next Steps</Typography>
              <Typography paragraph>Once you have confirmed installation, we recommend you immediately do the following:</Typography>
              <ol style={{fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}>
                <li component="li">Add email credentials.</li>
                <li>Set the timings for all synchronizers you want to run on a regular schedule (ex: Student, Student Schedule, Attendance).</li>
                <li>Start those synchronizers!</li>
                <li>Add Admins.</li>
                <li>Add Staff.</li>
                <li>Customize kiosk defaults to suit your needs</li>
                <li>Test attendance tracking with a 'dummy' student if possible</li>
              </ol>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{justifyContent:"space-between"}}>
          <Button onClick={returnToSetBuildings} color="secondary">
            Back
          </Button>
          <Button disabled={this.state.controlsDisabled}  onClick={()=>this.confirmThisStep(sendInstallComplete)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const STASummaryDialog = connect(
  state => state.install,
  {sendInstallComplete, returnToSetBuildings}
)(withStyles(styles, { withTheme: true })(STASummaryDialogClass))

export default STASummaryDialog
