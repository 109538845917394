import React from 'react'
import connect from 'react-redux/es/connect/connect'
import {withStyles} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom';

import {
  E_KEY_ENTER,
  SEARCH_CONTEXT_AT_USER_LIST_PAGE,
  URL_MANAGE_STUDENT_DETAIL,
  URL_MANAGE_STUDENTS
} from 'redux/constants'

import { pageLink } from 'apps/admin/components/Link'

import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import {searchStudents, updateStudentSearchStatus} from 'redux/admin/Actions'
import CurrentFacility from 'apps/admin/components/CurrentFacility'
import SchoolIcon from '@material-ui/icons/School'
import styles from './style'

class StudentSearch extends React.Component {
  componentDidMount() {
    this.props.updateStudentSearchStatus('Ready')
  }

  navigateToStudentDetails = (student) => {
    let facilityId = this.props.currentFacilityId || student.facilityId
    this.props.history.push(pageLink(URL_MANAGE_STUDENT_DETAIL, facilityId, student.id));
  };

  render() {
    const { searchBy, searchString, matchingStudents, searchStudents, statusMessage, classes} = this.props
    const x = {searchString}
    window.matchingStudents = matchingStudents
    return (
      <div>
        <CurrentFacility />
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <TextField xs={12} md={6}
                       id="search-students"
                       label={`Search for a Student by Name or SIS ID`}
                       variant="outlined"
                       fullWidth
                       value={x.searchString}
                       onChange={event => searchStudents(event.target.value, searchBy)}
                       onKeyDown={event => {
                         if(event.key === E_KEY_ENTER) {
                           searchStudents(event.target.value, searchBy)
                         }
                       }}
                       margin="normal"
                       helperText={statusMessage}
                       autoComplete="off"
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} >
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>DOB</TableCell>
                  <TableCell>SIS ID</TableCell>
                  <TableCell className={classes.studentTableLastCell}>Tardies/Absences</TableCell>
                  {/*<TableCell>Synced</TableCell>*/}
                </TableRow>
              </TableHead>
              {matchingStudents.length > 0 ?
                <TableBody>
                  {matchingStudents.map(student =>
                    <TableRow key={student.id} onClick={() => this.navigateToStudentDetails(student)} className={classes.tableRow}>
                      <TableCell>{student.fullName}</TableCell>
                      <TableCell>{student.dob}</TableCell>
                      <TableCell>{student.attendancePasscode}</TableCell>
                      <TableCell>{student.numTardy}/{student.numAbsent}</TableCell>
                      {/*<TableCell><SyncStatusIcon syncState={student.syncState}/></TableCell>*/}
                    </TableRow>)}
                </TableBody>
                :
                null
              }
            </Table>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.admin.studentSearchPage,
  currentFacilityId: state.admin.currentFacilityId,
})

const mapDispatchToProps = {
  searchStudents,
  updateStudentSearchStatus,
}

export const StudentSearchPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles, { withTheme: true })(StudentSearch)));

export const studentSearchPage = {
  searchContext: SEARCH_CONTEXT_AT_USER_LIST_PAGE,
  searchOnPathChange:true,
  icon: SchoolIcon,
  component: StudentSearchPage,
  name: 'Student Search',
  displayName: 'Student Search',
  to: URL_MANAGE_STUDENTS
}

export default StudentSearchPage