export const deactivatedAtType = {
  seconds: -1,
  displayName:'Inactive'
}
export const minuteList = [deactivatedAtType]
export const presentMinuteList = [deactivatedAtType]

const maxMinutes = 99

let mins
for(let i = 0; i < maxMinutes; i++) {
  mins = i
  minuteList.push({
    seconds:(i * 60),
    displayName:`${mins}:00`
  })
  if(i < (maxMinutes -1)) {
    minuteList.push({
      seconds:(i * 60) + 30,
      displayName:`${mins}:30`
    })
  }
  if(i < 30) {
    presentMinuteList.push({
      seconds:(i * 60),
      displayName:(mins === 0)? '0:00':`-${mins}:00`
    })
    presentMinuteList.push({
      seconds:(i * 60) + 30,
      displayName:(mins === 0)? '0:30':`-${mins}:30`
    })
  }
}