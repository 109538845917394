import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import {
  findOneBy,
} from '@relativity/js-util'

import {
  bellRulesEditDone,
  bellRulesEditUpdateProperty,
  bellRulesEditSave,
} from 'redux/admin/Actions'

import BellRuleSVG from "common/components/BellRuleSVG"
import styles from './style'
import {AT_TYPE_ID_NOT_SET, AT_TYPE_ID_PRESENT, paModeList, yesNoBoolList} from "redux/constants";
import {FullTextFieldD} from "common/components/FullTextField";
import {Divider} from "@material-ui/core";

import {minuteList, presentMinuteList} from './objects'
import PassiveIconButton from "common/components/PassiveIconButton";
import Typography from "@material-ui/core/es/Typography";

class BellRulesDialog extends React.Component {

  render() {
    if(!this.props.bellSchedule) return null

    const {
      classes,
      bellSchedule,
      facilities,
      positiveAttendanceIsEnabled,
      zeroFacility,
      allAtTypes
    } = this.props

    let atTypes = findOneBy(facilities, 'id', bellSchedule.facilityId).atTypes.slice()
    atTypes.unshift(findOneBy(zeroFacility.atTypes, 'id', AT_TYPE_ID_PRESENT))
    atTypes.unshift(findOneBy(zeroFacility.atTypes, 'id', AT_TYPE_ID_NOT_SET))

    let atReasons = findOneBy(facilities, 'id', bellSchedule.facilityId).atReasons.slice()
    //atReasons.unshift(findOneBy(zeroFacility.atReasons, 'id', AT_TYPE_ID_PRESENT))
    atReasons.unshift(findOneBy(zeroFacility.atReasons, 'id', AT_TYPE_ID_NOT_SET))

    const facilityOverridableAtTypes = allAtTypes
      .filter(atType => atType.facilityId === bellSchedule.facilityId && atType.kioskCanOverride);

    const overridableAtTypeNames = facilityOverridableAtTypes.map(atType => atType.name).sort().join(', ');

    const overridableAtTypesInfo = facilityOverridableAtTypes.length > 0
    ? `${facilityOverridableAtTypes.length} attendance types are allowed to be overridden by the kiosk entry: ${overridableAtTypeNames}`
    : "No attendance types are allowed to be overridden by the kiosk entry";

    return (
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={4}>
                  <Grid container>
                    {positiveAttendanceIsEnabled?
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="Early"
                        valud={findOneBy(atTypes, 'id', bellSchedule.atTypePresentId )['displayName']}
                        value={"Present"}
                      />
                    </Grid>
                      :
                      null}
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="Tardy Type"
                        value={findOneBy(atTypes, 'id', bellSchedule.atTypeTardyId )['displayName']}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="Absent Type"
                        value={findOneBy(atTypes, 'id', bellSchedule.atTypeAbsentId )['displayName']}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    { positiveAttendanceIsEnabled ?
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="How Early - Minutes"
                        value={findOneBy(presentMinuteList, 'seconds', bellSchedule.bellPeriodPreStartPresentSeconds )['displayName']}
                      />
                    </Grid>
                      :
                      null}
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="Start Tardy - Minutes"
                        value={findOneBy(minuteList, 'seconds', bellSchedule.bellPeriodPostStartTardySeconds )['displayName']}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label='Start Absent - Minutes'
                        value={findOneBy(minuteList, 'seconds', bellSchedule.bellPeriodPostStartAbsentSeconds )['displayName']}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} >
                  <BellRuleSVG style={{maxHeight:'250'}} {...bellSchedule} positiveAttendanceIsEnabled={positiveAttendanceIsEnabled}/>
                </Grid>
              </Grid>

              <Divider item xs={8} style={{marginTop:10}}/>

              <Grid container>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="Tardy Excused Type"
                        value={findOneBy(atTypes, 'id', bellSchedule.atTypeTardyExcusedId )['displayName']}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="Absent Excused Type"
                        value={findOneBy(atTypes, 'id', bellSchedule.atTypeAbsentExcusedId )['displayName']}
                      />
                    </Grid>                 
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label="Out of Building"
                        value={findOneBy(atTypes, 'id', bellSchedule.atTypeOutOfBuildingId )['displayName']}
                      />
                  </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label='Set Out for the rest periods when out of building'
                        value={findOneBy(yesNoBoolList, 'val', bellSchedule.isOutOfBuildingPropagationEnabled)['displayName']}
                      />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12} className={classes.gridItem}>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={4}>
                  <Grid container>          
                  </Grid>
                </Grid>
              </Grid>


              <Grid container>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12} className={classes.gridItem}>
                      <FullTextFieldD
                        label='Kiosk Reason'
                        value={findOneBy(atReasons, 'id', bellSchedule.atReasonDefaultId )['displayName']}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider style={{marginTop:10}}/>

              {positiveAttendanceIsEnabled ?
                <Grid container>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <Typography style={{ fontSize: '16px', margin:'20px 0px 20px 0px', opacity: 0.5}}>
                          <PassiveIconButton
                            onTitle='Enabled'
                            offTitle='Disabled'
                            isOn={!!bellSchedule.isPaEnabled}/>
                          Positive Attendance
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <Typography style={{margin:'0px 0px 10px 0px', marginLeft:'0px', fontSize: '16px', opacity: 0.5}}>
                          <PassiveIconButton
                            onTitle='Enabled'
                            offTitle='Disabled'
                            isOn={!!bellSchedule.isNonAttendancePeriodsEnabled}/>
                                Track attendance at open periods
                        </Typography>
                      </Grid>        
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <FullTextFieldD
                          label='Attendance Monitor'
                          value={findOneBy(paModeList, 'val', bellSchedule.paMode)['displayName']}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                :
                null
              }
               <Divider style={{marginBottom:15}}/>

              <Grid >
                <Typography style={{ fontSize:'15px', marginLeft: '10px', opacity: 0.5 }}>{overridableAtTypesInfo}</Typography>
              </Grid>
            </Grid>
          </Grid>

    )
  }
}

const mapStateToProps = state => {
  const adminState = state.admin;
  const atTypes = state.admin.atTypes ? state.admin.atTypes.filter(atType => atType.facilityId === state.admin.currentFacilityId) : [];

  return {
    ...adminState,
    allAtTypes: atTypes
  };
}

const mapDispatchToProps = {
  bellRulesEditDone,
  bellRulesEditUpdateProperty,
  bellRulesEditSave,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(BellRulesDialog))